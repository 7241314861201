import React, { useState, useContext } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Typography, useTheme } from '@material-ui/core';
import { AppContext } from '../../AppContext';
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    backgroundColor: "#fff",
    height: "352px",
    width: "600px",
    padding: 30,
    borderRadius: "8px",
    // flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    textAlign: "center",
    // marginTop: theme.spacing(3),
    paddingBottom: "20px"
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  bottomText:{
    marginTop: "4px",
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#213955",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "16px",
  },
  formContainer: {
    height: "calc(100% - 111px)",
    padding: "20px  45px",
    border: "1px solid #ffffff",
    borderBottomColor: "#DEDEDE",
    borderTopColor: "#DEDEDE",
  },
}));

const SignInReception = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: true,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const appContext = useContext(AppContext);

  const handleSignIn = event => {
    event.preventDefault();
    appContext.receptionApi
      .receptionLogin({
        password: formState.values.pin,
      })
      .then(payload => {
        appContext.login(payload.data);
        appContext.history.push('/reception');
      })
      .catch(error => {
        const data = error.response.data;
        setFormState(formState => ({
          ...formState,
          errors: {
            meetupId: [data.message],
            pin: [data.message],
          },
        }));
      });
  };

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <ErrorBlock message={formState.errors.token}/>
          <form className={classes.form} onSubmit={handleSignIn}>
            <Typography className={classes.title} variant='h2'>
              Вход регистратора
            </Typography>
            <div className={classes.formContainer}>
              <TextField
                className={classes.textField}
                error={hasError('pin')}
                fullWidth
                helperText={hasError('pin') ? formState.errors.pin[0] : null}
                label='Пин-код регистратора'
                name='pin'
                onChange={handleChange}
                type='text'
                value={formState.values.pin || ''}
                variant='outlined'
              />
              <Button
                className={classes.signInButton}
                color='primary'
                fullWidth
                size='large'
                type='submit'
                variant='contained'
              >
                Войти
              </Button>
            </div>
            <div className={classes.bottomText} style={{marginTop:"16px"}}>
              <p onClick={()=> window.location.href = "https://docs.eventos42.ru/offlain-registraciya/kak-zaiti-na-stoiku-registracii"} style={{cursor:"pointer"}}>Где взять пин-код?</p>
            </div>
            <div className={classes.bottomText}>
              <p onClick={()=>appContext.history.push('/sign-in')} style={{cursor:"pointer"}}>Вход администратора</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

SignInReception.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignInReception);
