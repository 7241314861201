import React, {useContext, useEffect, useState} from 'react';
import {Button, TextField, Typography} from "@material-ui/core";
import {useStyles} from "../RequestForChangePassword";
import {convertErrors} from "../../MeetupList/components/MeetupEditForm/MeetupEditForm";
import {AppContext} from "../../../AppContext";
import {useParams} from "react-router-dom";
import ErrorBlock from "../../../components/ErrorBlock/ErrorBlock";
import {Visibility, VisibilityOff} from "@material-ui/icons";



const ChangePassword = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const {token} = useParams();
  const [inputType, setInputType] = useState("password")
  const [formState, setFormState] = useState({
    values: {},
    touched: {},
    errors: {},
  });


  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      errors: errors || {},
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (errors) {
      setFormState({
        ...formState,
        ...convertErrors(errors),
      });
    }
  }, [errors]);

  const handleRegister =event=>{
    event.preventDefault();
    const password = formState.values.password
    const data = {token, password}
    appContext.authApi.changePassword(data)
      .then(({ data }) => {
        appContext.history.push('/sign-in')
      })
      .catch(error => {
        const data = error.response.data;
        setErrors(data.errors || [data]);
      });
  }

  const handleChange =event=>{
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  }

  const hasError = field => {
    return (!!(formState.touched[field] && formState.errors[field]));
  };

  const showPassword=()=>{
    if (inputType === "password"){
      setInputType("text")
    }else {
      setInputType("password")
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <ErrorBlock message={formState.errors.token}/>

            <form className={classes.form} onSubmit={handleRegister}>
              <Typography className={classes.title} variant='h2'>
                Новый пароль
              </Typography>
              <ErrorBlock message={formState.errors.token}/>
              <div className={classes.formContainer} >
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={hasError('password') ? formState.errors.password : null}
                  label='Новый пароль'
                  name='password'
                  onChange={handleChange}
                  type={inputType}
                  value={formState.values.password || ''}
                  variant='outlined'
                  required={false}
                  autoComplete={'off'}
                />
                <span style={{position: "relative"}}>
                  {inputType === "password"
                  ?
                    <VisibilityOff onClick={showPassword} style={{position:"absolute", top: 36,  right: 10, cursor: "pointer"}}/>
                  :
                    <Visibility onClick={showPassword} style={{position:"absolute", top: 36,  right: 10, cursor: "pointer" }}/>
                  }
                </span>
              <Button
                className={classes.signUpButton}
                color='primary'
                fullWidth
                size='large'
                type='submit'
                variant='contained'
              >
                Изменить пароль
              </Button>
              </div>
              <div className={classes.bottomText}>
                <p onClick={()=>appContext.history.push('/sign-in')} style={{cursor:"pointer"}}>Вход администратора</p>
              </div>
            </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
