import {useParams} from "react-router-dom";
import {MeetupContextProvider} from "../../MeetupContext";
import BadgeEdit from "../BadgeDetails/components/BadgeEdit/BadgeEdit";
import React, {Component, lazy, useContext, useEffect, useState} from "react";
import BadgeViewKonva from "../BadgeDetails/components/BadgeViewKonva/BadgeViewKonva";
import {AppContext} from "../../AppContext";
import {addPageBreak, renderBadgePDF_ToDataUrl} from "../BadgeDetails/components/BadgeView/BadgeView";
import Button from "@material-ui/core/Button";
import { saveAs } from 'file-saver';

const BadgeCardForVisitor = lazy(() =>
  import('../BadgeDetails/components/BadgeViewWithDownload/BadgeViewForVisitor')
);

let stageRef = null;

function useQuery() {
  return new URLSearchParams(window.location.search);
}

class BadgePrintInternal extends Component {
  static contextType = AppContext;

  state = {
    data: null,
    eventCount: 0,
    completed: false
  }

  handleDownloadPdf = async () => {
    const {data} = this.state;
    const pdfUrl = await renderBadgePDF_ToDataUrl(data.badge, data.visitor, false, true, this.stageRef);

    saveAs(pdfUrl, "badge.pdf");
  };

  mountCounter = () => {
    this.handleDownloadPdf().then(() => {
      this.setState({
        completed: true
      });
    })
  }

  imageCounter = () => {
  }

  getRef = ref => {
    this.stageRef = ref;
  };

  componentDidMount() {
    document.addEventListener('KonvaDidMount', this.mountCounter);
    document.addEventListener('KonvaImageEvent', this.imageCounter);

    let query = useQuery();
    if (query.has("accountId")) {
      const { visitorApi } = this.context
      visitorApi.getAllDataInternal(
        query.get("accountId"),
        query.get("meetupId"),
        query.get("visitorId"),
        query.get("badgeId")).then(({ data }) => {
        this.setState({
          data: data
        });
      });
    }
  }

  handleData = (e) => {
    this.setState({
      data: JSON.parse(e.target.value)
    });
  }

  componentWillUnmount() {
    document.removeEventListener('KonvaImageEvent', this.imageCounter);
    document.removeEventListener('KonvaDidMount', this.mountCounter);
  }

  render() {
    if (!this.state.data) {
      return <textarea id={"badge-data-area"} onChange={this.handleData}></textarea>;
    }
    return (
      <>
        <BadgeViewKonva {...this.state.data} getRef={this.getRef} noFontsCheck={false} />
        <Button onClick={this.handleDownloadPdf}>PDF</Button>
        {"loaded"}
        {this.state.completed && "completed"}
      </>
    );
  }

}

export default BadgePrintInternal;
