import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../../AppContext';
import SpeakerEditForm from '../SpeakerEditForm/SpeakerEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../../../components/Forms/form_utils';

const SpeakerAdd = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const form = useForm();

  const handleSave = () => {
    const data = form.getValues();
    appContext.speakerApi
      .createSpeaker(meetupId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  return (
    <ModalDialog title={'Добавление спикера'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <SpeakerEditForm meetupId={meetupId} form={form} />
    </ModalDialog>
  );
};

SpeakerAdd.propTypes = {};

export default SpeakerAdd;
