import React from 'react';
import {Link, makeStyles, Typography} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from '../Registration';
import theme from "../../../theme/eventos";
import ErrorBlock from "../../../components/ErrorBlock/ErrorBlock";

const RegistrationConfirmed = props => {


  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>

          <div className={classes.form} style={{height: "150px"}}>
            <Typography className={classes.title} variant='h2'>
              Аккаунт подтверждён.
            </Typography>
            <Typography>Можете пользоваться.</Typography>
            <Link color='primary' component={RouterLink} to='/sign-in' underline='always' variant='h6'>
              Войти
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationConfirmed;
