import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  row: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1,
  },
}));

const GroupsToolbar = props => {
  const { className, meetupId, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <RouterLink to={'/m/' + meetupId + '/group/add'}>
          <Button color='primary' variant='contained'>
            Добавить группу
          </Button>
        </RouterLink>
      </div>
    </div>
  );
};

GroupsToolbar.propTypes = {
  className: PropTypes.string,
  meetupId: PropTypes.string,
};

export default GroupsToolbar;
