import {makeStyles} from "@material-ui/styles";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../AppContext";
import {useParams} from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Grid from "@material-ui/core/Grid";
import ModalDialog from "../../../components/ModalDialog/ModalDialog";
import DeskEditForm from "../../DeskList/components/DeskEditForm/DeskEditForm";
import {ECCommonErrorBlock} from "../../../components/Forms/ECCommonErrorBlock";
import {ECTextField} from "../../../components/Forms/ECTextField";
import {useForm} from "react-hook-form";
import {valueByName} from "../../../components/Forms/form_utils";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {ECRadioGroupField} from "../../../components/Forms/ECRadioGroupField";
import {MailAttachmentDataAttachmentTypeEnum} from "../../../api";
import {ECPhotoField} from "../../../components/Forms/ECPhotoField";
import {ECSelectField} from "../../../components/Forms/ECSelectField";

const useStyles = makeStyles(theme => ({
}));

const AttachmentEditForm = (props) => {
  const { meetupId, form, initial } = props;
  const appContext = useContext(AppContext);

  const at = form.watch("attachmentType");

  const [badges, setBadges] = useState([]);

  useEffect(() => {
    appContext.badgeApi
      .listBadges(meetupId)
      .then(({ data }) => {
        setBadges(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId]);

  const onTypeChange = (v) => {
    if (v === MailAttachmentDataAttachmentTypeEnum.QrCode) {
      form.setValue('filename', "QR-код.png")
    }
    if (v === MailAttachmentDataAttachmentTypeEnum.Badge) {
      form.setValue('filename', "Бейдж.pdf")
    }
    if (v === MailAttachmentDataAttachmentTypeEnum.Image) {
      form.setValue('filename', "Изображение.png")
    }
  }

  if (at === MailAttachmentDataAttachmentTypeEnum.Badge && badges.length === 0) return null;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ECRadioGroupField label='Тип вложения' name='attachmentType' initial={initial} form={form} options={[
            {id:MailAttachmentDataAttachmentTypeEnum.QrCode, name:"QR-код"},
            {id:MailAttachmentDataAttachmentTypeEnum.Badge, name:"Бейдж"},
            {id:MailAttachmentDataAttachmentTypeEnum.Image, name:"Изображение"},
          ]} defaultValue={MailAttachmentDataAttachmentTypeEnum.QrCode} onchange={onTypeChange} row/>
        </Grid>
        <Grid item xs={12}>
          <ECTextField label='Имя файла' name='filename' initial={initial} form={form} />
        </Grid>
        <Grid item xs={12}>
          {at === MailAttachmentDataAttachmentTypeEnum.Badge &&
            <ECSelectField
              label='Бейдж'
              name='badgeId'
              options={badges}
              fid={it => it.badgeId}
              initial={initial}
              defaultValue={badges[0].badgeId}
              form={form}
            />
          }
          {at === MailAttachmentDataAttachmentTypeEnum.Image &&
            <ECPhotoField label='Изображение' name='imageId' form={form} initial={initial} height={197}/>
          }
        </Grid>
      </Grid>

    </>
  );
}

export const AttachmentsList = (props) => {
  const { className, initial, name, form, ...rest } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const classes = useStyles();
  const innerForm = useForm();

  const [list, setList] = useState(valueByName(initial, name) || []);

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editItemIndex, setEditItemIndex] = useState(-1);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    form.register({ name: name });
    form.setValue(name, list);
  }, [form.register]);

  const handleChange = (value) => {
    setList(value);
    form.setValue(name, value);
    form.clearError(name);
  }

  const handleDelete = (i) => () => {
    handleChange(list.filter((_, i2) => i2 !== i))
  }

  const handleAdd = () => {
    innerForm.reset()
    setAddDialogOpen(true);
  }

  const handleEdit = (i) => () => {
    innerForm.reset()
    setEditDialogOpen(true);
    setEditItemIndex(i);
  }

  const validateAttachment = (data, form) => {
    let error = false
    if (!data.filename) {
      form.setError("filename", "empty", "Имя файла обязательно");
      error = true;
    }
    if (data.filename.length > 200) {
      form.setError("filename", "empty", "Имя не должно быть длинее 200 символов");
      error = true;
    }
    if (data.attachmentType === MailAttachmentDataAttachmentTypeEnum.Image) {
      if (!data.imageId) {
        form.setError("imageId", "empty", "Изображение обязательно");
        error = true;
      }
    }
    if (data.attachmentType === MailAttachmentDataAttachmentTypeEnum.Badge) {
      if (!data.badgeId) {
        form.setError("badgeId", "empty", "Бейдж обязателен");
        error = true;
      }
    }

    if (error) {
      setRefresh(refresh + 1)
      return false
    } else {
      return true
    }
  }

  const handleSaveAdd = () => {
    const data = innerForm.getValues();
    if (validateAttachment(data, innerForm)) {
      handleChange(list.concat([data]))
      setAddDialogOpen(false);
    }
  }

  const handleSaveEdit = () => {
    const data = innerForm.getValues();
    if (validateAttachment(data, innerForm)) {
      const newList = [...list];
      newList[editItemIndex] = data;
      handleChange(newList)
      setEditDialogOpen(false);
    }
  }

  return (
    <>
      <div>
        {list.map((a, i) =>
          <Chip
            key={i}
            label={a.filename}
            onClick={handleEdit(i)}
            onDelete={handleDelete(i)}
            variant="outlined"
          />
        )}
        <IconButton  size={'small'} onClick={handleAdd}>
          <AddCircleIcon />
        </IconButton>
      </div>

      {addDialogOpen &&
        <ModalDialog
          title={'Добавить вложение'}
          okButtonText={'Сохранить'}
          handleOk={handleSaveAdd}
          handleClose={() => setAddDialogOpen(false)}
        >
          <AttachmentEditForm meetupId={meetupId} form={innerForm} initial={{
            attachmentType: MailAttachmentDataAttachmentTypeEnum.QrCode,
            filename: "QR-код.png"
          }}/>
        </ModalDialog>
      }

      {editDialogOpen &&
      <ModalDialog
        title={'Редактировать вложение'}
        okButtonText={'Сохранить'}
        handleOk={handleSaveEdit}
        handleClose={() => setEditDialogOpen(false)}
      >
        <AttachmentEditForm meetupId={meetupId} form={innerForm} initial={list[editItemIndex]}/>
      </ModalDialog>
      }

    </>
  )
}
