import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import MerchsToolbar from './components/MerchsToolbar';
import MerchsTable from './components/MerchsTable';
import { Route, useParams } from 'react-router-dom';
import MerchAdd from './components/MerchAdd';
import MerchEdit from './components/MerchEdit';
import {MeetupContextProvider} from "../../MeetupContext";
import BadgeEdit from "../BadgeDetails/components/BadgeEdit/BadgeEdit";
import {Divider, Tab, Tabs} from "@material-ui/core";
import PurchaseList from "../Purchase/PurchaseList";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const MerchList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  const [refreshCounter, setRefresh] = useState(0);
  const [merchs, setMerchs] = useState([]);

  useEffect(() => {
    appContext.merchApi
      .listMerchs(meetupId)
      .then(({ data }) => {
        setMerchs(data)
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const handleDelete = (meetupId, merchId) => {
    appContext.merchApi.deleteMerch(meetupId, merchId).then(() => {
      setMerchs(merchs => merchs.filter(u => u.merchId !== merchId));
    });
  };

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/merch');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };

  const valueFromUrl = () => {
    const path = appContext.history.location.pathname;
    if (path.indexOf('merch') !== -1) {
      return 'merch';
    }
    if (path.indexOf('purchase') !== -1) {
      return 'purchase';
    }
    return '';
  };

  return (
    <div className={classes.root}>
      <Tabs
        onChange={() => {}}
        scrollButtons='auto'
        value={valueFromUrl()}
        variant='scrollable'
        style={{ margin: 'auto', maxWidth: 'max-content' }}
      >
        <Tab
          label={'Товары'}
          value={'merch'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/merch');
          }}
        />
        <Tab
          label={'История покупок'}
          value={'purchase'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/purchase');
          }}
        />
      </Tabs>
      <Divider />

      <Route path='/m/:mid/merch'>
        <MerchsToolbar meetupId={meetupId} />
        <MerchsTable
          meetupId={meetupId}
          merchs={merchs}
          handleDelete={handleDelete}
        />
      </Route>

      <Route path='/m/:mid/purchase'>
        <PurchaseList/>
      </Route>

      <Route path={'/m/:mid/merch/add'} exact>
        <MeetupContextProvider meetupId={meetupId}>
          <MerchAdd goBack={backAndRefresh} />
        </MeetupContextProvider>
      </Route>

      <Route path={'/m/:mid/merch/id/:merchId'} exact>
        <MeetupContextProvider meetupId={meetupId}>
          <MerchEdit goBack={backAndRefresh} />
        </MeetupContextProvider>
      </Route>
    </div>
  );
};

export default MerchList;
