import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { VisitorStatusType } from '../../../../../api';

const SystemStatusField = forwardRef((props, ref) => {
  const { data: initial } = props;
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    fillValue: data => {
      data.status = data.status || [];
      data.status['status'] = inputRef.current.value;
      return true;
    },
  }));

  return (
    <TextField
      select
      name={'status'}
      label={'Статус'}
      inputRef={inputRef}
      fullWidth
      margin='dense'
      variant='outlined'
      autoComplete='new-password'
      defaultValue={(initial.status && initial.status['status']) || VisitorStatusType.Initial}
    >
      {Object.keys(VisitorStatusType).map(k => (
        <MenuItem key={k} value={VisitorStatusType[k]}>
          {k}
        </MenuItem>
      ))}
    </TextField>
  );
});

export default SystemStatusField;
