import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../../AppContext';
import SpeakersToolbar from './components/SpeakersToolbar/SpeakersToolbar';
import SpeakersTable from './components/SpeakersTable/SpeakersTable';
import { Route, useParams } from 'react-router-dom';
import SpeakerAdd from './components/SpeakerAdd/SpeakerAdd';
import SpeakerEdit from './components/SpeakerEdit/SpeakerEdit';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const SpeakerList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  const [refreshCounter, setRefresh] = useState(0);
  const [speakers, setSpeakers] = useState([]);

  useEffect(() => {

    appContext.speakerApi
      .listSpeakers(meetupId)
      .then(({ data }) => {
        setSpeakers(data)
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const handleDelete = (meetupId, speakerId) => {
    appContext.speakerApi.deleteSpeaker(meetupId, speakerId).then(() => {
      setSpeakers(speakers => speakers.filter(u => u.speakerId !== speakerId));
    });
  };

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/program/speaker');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };

  return (
    <div className={classes.root}>
      <SpeakersToolbar meetupId={meetupId} />
      <SpeakersTable
        meetupId={meetupId} 
        speakers={speakers}
        handleDelete={handleDelete} 
      />

      <Route path={'/m/:mid/program/speaker/add'} exact>
        <SpeakerAdd goBack={backAndRefresh} />
      </Route>

      <Route path={'/m/:mid/program/speaker/id/:speakerId'} exact>
        <SpeakerEdit goBack={backAndRefresh} />
      </Route>
    </div>
  );
};

export default SpeakerList;
