import React, {useContext, useState} from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../AppContext';
import NewsEditForm from './NewsEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../components/Forms/form_utils';
import {NewsType} from "../../../api";
import NewsTypeForm from "./NewsTypeForm";
import {fixDotProperties} from "../../Merch/components/MerchEdit";

const NewsAdd = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const form = useForm();
  const [step, setStep] = useState(0)

  const handleSave = () => {
    if (step === 0) {
      setStep(1)
    } else {
      let data = form.getValues();
      data = fixDotProperties(data);
      data.recipients.tags = data.recipients.tagsStr && data.recipients.tagsStr.split(",") || []
      appContext.newsApi
        .createNews(meetupId, data)
        .then(({ data }) => {
          goBack(data);
        })
        .catch(errorsToForm(form));
    }
  };

  const title = {
    "news": "Новая новость",
    "notification": "Новая нотификация",
    "question": "Новый опрос"
  }
  return (
    step === 0 ?
      <ModalDialog title={'Выберите тип новости'} okButtonText={'Дальше'} handleOk={handleSave} handleClose={goBack}>
        <NewsTypeForm meetupId={meetupId} form={form} />
      </ModalDialog>
      :
      <ModalDialog title={title[form.watch("type")]} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
        <NewsEditForm meetupId={meetupId} form={form} type={form.watch("type")} />
      </ModalDialog>
  );
};

NewsAdd.propTypes = {};

export default NewsAdd;
