import React, { useContext } from 'react';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { AppContext } from '../../AppContext';
import { Route, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import VisitorList from '../VisitorList/VisitorList';
import FieldSettings from '../FieldSettings/FieldSettings';
import GroupList from '../GroupList/GroupList';
import RouteWithLayout from 'components/RouteWithLayout/RouteWithLayout';
import Empty from 'layouts/Empty/Empty'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const Visitors = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();

  const fieldsSettings = appContext.session.features.includes('fieldsSettings');

  const valueFromUrl = () => {
    const path = appContext.history.location.pathname;
    if (path.indexOf('visitor') !== -1) {
      return 'visitor';
    }
    if (path.indexOf('group') !== -1) {
      return 'group';
    }
    if (path.indexOf('settings') !== -1) {
      return 'settings';
    }
    return '';
  };

  return (
    <div className={classes.root}>
      <Tabs
        onChange={() => {}}
        scrollButtons='auto'
        value={valueFromUrl()}
        variant='scrollable'
        style={{ margin: 'auto', maxWidth: 'max-content' }}
      >
        <Tab
          label={'Список посетителей'}
          value={'visitor'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/visitor');
          }}
        />
        <Tab
          label={'Группы'}
          value={'group'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/group');
          }}
        />
        {
          fieldsSettings && 
          <Tab
            label={'Настройки полей'}
            value={'settings'}
            onClick={() => {
              appContext.history.push('/m/' + meetupId + '/settings');
            }}
          />
        }
        
      </Tabs>
      <Divider />
      <Route path='/m/:mid/visitor'>
        <VisitorList />
      </Route>
      <Route path='/m/:mid/group'>
        <GroupList />
      </Route>
       
      <RouteWithLayout 
        path='/m/:mid/settings'
        component={FieldSettings}
        auth='admin'
        feature='fieldsSettings'
        layout={Empty}
      />
    </div>
  );
};

export default Visitors;
