import React, { useContext,Fragment, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ECCommonErrorBlock } from '../../../../components/Forms/ECCommonErrorBlock';
import { ECTextField } from '../../../../components/Forms/ECTextField';
import { ECSelectField } from '../../../../components/Forms/ECSelectField';
import { ECCheckboxField } from '../../../../components/Forms/ECCheckboxField';
import { ECMultiDateField } from '../../../../components/Forms/ECMultiDateField';
import { ECSwitchField } from 'components/Forms/ECSwitchField';
import { AppContext } from '../../../../AppContext';
import { HelpHint } from '../../../../components/HelpHint/HelpHint';
import { makeStyles } from '@material-ui/styles';
import { MeetupContext } from 'MeetupContext';
import { MenuItem, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisitorAdd from 'views/VisitorList/components/VisitorAdd/VisitorAdd';
import { checkVisitor } from 'common/desks';
import {valueByName} from "../../../../components/Forms/form_utils";
import {DeskType} from "../../../../api";

const useStyles = makeStyles(theme => ({
  formSubheader: {
    fontSize: 16,
    color: 'rgba(33,57,85,0.7)',
  },

  switchLabel: {
    fontSize: 15,
    color: 'rgba(33,57,85,1)',
    marginRight: 16,
  },

  textField: {
    width: 170
  }
}));

const DeskEditForm = props => {
  const { form, initial, edit, conditions, setConditions,visitors, meetupId } = props;
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const classes = useStyles();

  const forTest = form.watch('forTest');
  const accessReject = form.watch('settings.accessReject')
  const andOr = form.watch('settings.andOr')

  const [result, setResult] = useState({
    accepted: visitors.length,
    rejected: 0
  })

  useEffect(() => {
    let trueCount = 0;
    for (let v of visitors) {
      v.group = v.groupName
      let accepted = checkVisitor(v, {andOr, accessReject: accessReject, conditions})

      if (accepted) {
        ++trueCount;
      }
    }

    setResult({
      rejected: visitors.length - trueCount,
      accepted: trueCount
    })
 
  }, [andOr, accessReject, meetupId, conditions])

  let deskTypes = [
    { id: DeskType.RegistrationDesk, name: 'Стойка регистрации' },
    { id: DeskType.SelfServiceDesk, name: 'Стойка саморегистрации' },
    { id: DeskType.AccessDesk, name: 'Контроль доступа' },
  ];

  const fields = meetupContext.fields.map(field => ({
    id: field.systemField || field.name,
    name: field.label,
  }));

  const operators = [
    { id: 'equal', name: 'равно' },
    { id: 'notEqual', name: 'не равно' },
  ];

  const fid = item => item.id;
  const fname = item => item.name;

  const handleChangeFields  = (ind, name, value) => {
    let newConditions = [ ...conditions ]
    newConditions[ind][name] = value
    setConditions(newConditions);
  }

  const handleDeleteCondition = (ind) => {
    let newConditions = [ ...conditions ]
    newConditions.splice(ind, 1);
    setConditions(newConditions)
  }

  const handleAddCondition = () => {
    let newConditions = [ ...conditions ]
    newConditions =  newConditions.concat({field: fields[0].id, operator: 'equal', value: 'Значение'})
    setConditions(newConditions)
  }

  return (
    <>
      <ECCommonErrorBlock form={form} />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ECTextField name={'name'} label='Название' required form={form} initial={initial} />
        </Grid>

        <Grid item xs={6}>
          <ECSelectField
            name={'settings.type'}
            label='Тип стойки'
            required
            form={form}
            initial={initial}
            options={deskTypes}
            a1={fid}
            a2={fname}
          />
        </Grid>

        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <ECMultiDateField
            name={'activeDays'}
            label='Даты активности стойки'
            disabled={forTest}
            form={form}
            initial={initial}
            style={{ marginRight: 10 }}
          />
        </Grid>

        <Grid item xs={6}>
          <ECCheckboxField name={'forTest'} label='Тестовая стойка' form={form} initial={initial} disabled={edit} />
          <HelpHint iconSize={16} style={{ width: 16, height: 16, minHeight: 16 }}>
            В тестовой стойке доступен весь функционал рабочей стойки. Время действия стойки - 5 минут после генерации
            кода. Зайти на стойку с кодом тестовой стойки можно один раз.
          </HelpHint>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.formSubheader}>Действия стойки:</Typography>
        </Grid>

        {/* =======================  Свойства общие для всех типов стоек  ======================= */}
        <Grid item xs={6}>
          <ECCheckboxField
            name={'settings.webCamBarScannerEnabled'}
            label='Встроенный сканер бар-кода'
            form={form}
            initial={initial}
          />
        </Grid>
        <Grid item xs={6}>
          <ECTextField
            name={'settings.webCamNumber'}
            label='Номер камеры'
            form={form}
            initial={initial}
            defaultValue={1}
            disabled={!form.watch('settings.webCamBarScannerEnabled')}
          />
        </Grid>

        {/* =========  Бейдж может печататься автоматически на стойке администратора и на саморегистрации  ========= */}

        {(form.watch('settings.type') === 'registrationDesk' || form.watch('settings.type') === 'selfServiceDesk') && (
          <Grid item xs={6}>
            <ECCheckboxField
              name={'settings.actionPrintBadgeEnabled'}
              label='Печатать бейдж автоматически'
              form={form}
              initial={initial}
            />
          </Grid>
        )}

        {/* =======================  Свойства только для стойки саморегистрации  ======================= */}
        {(form.watch('settings.type') === 'selfServiceDesk') && (
          <>
            <Grid item xs={6}>
              <ECCheckboxField
                name={'settings.selfRegistrationEnabled'}
                label='Разрешить регистрацию'
                form={form}
                initial={initial}
              />
            </Grid>
            {/*<Grid item xs={6}>*/}
            {/*  <ECCheckboxField*/}
            {/*    name={'settings.selfSearchEnabled'}*/}
            {/*    label='Разрешить искать по фамилии/коду'*/}
            {/*    form={form}*/}
            {/*    initial={initial}*/}
            {/*  />*/}
            {/*</Grid>*/}
          </>
        )}

        {/* =======================  Свойства для стойки администратора  ======================= */}
        {form.watch('settings.type') === 'registrationDesk' && (
          <>
          <Grid item xs={6}>
            <ECCheckboxField
              name={'settings.actionCheckinEnabled'}
              label='Ставить флаг checkin'
              form={form}
              initial={initial}
            />
          </Grid>

          {/*<Grid item xs={6}>*/}
          {/*  /!*TODO: make a component to check feature*!/*/}
          {/*  {appContext.session.features && appContext.session.features.indexOf('faceId') !== -1 && (*/}
          {/*    <ECCheckboxField name={'settings.faceScannerEnabled'} label='Сканер лица' form={form} initial={initial} />*/}
          {/*  )}*/}
          {/*</Grid>*/}
          </>
        )}

        {/* =======================  Настройки для стойки контроля доступа  ======================= */}
        { form.watch('settings.type') === 'accessDesk'   &&
        <>
        <Grid item xs={12}>
          <Typography className={classes.formSubheader}>Настройки доступа:</Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography component='span' className={classes.switchLabel}>
                Пускать
              </Typography>
              <ECSwitchField name={'settings.accessReject'} form={form} initial={initial} />
              <Typography component='span'>Блокировать</Typography>
            </div>
            <div>
              <Typography component='span' className={classes.switchLabel}>
                Объединять по И
              </Typography>
              <ECSwitchField name={'settings.andOr'} form={form} initial={initial} />
              <Typography component='span'>по ИЛИ</Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.switchLabel}>Поле</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.switchLabel}>Условие</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.switchLabel}>Значение</Typography>
        </Grid>
        {(conditions || initial.settings.conditions).map((condition, ind) => {
          return (
            <Fragment key={ind+condition.field+condition.operator}>
              <Grid item xs={4}>
                <TextField
                  className={classes.textField}
                  name={'field' + ind}
                  value={conditions[ind].field}
                  onChange={(e) => handleChangeFields(ind, 'field', e.target.value)}
                  select
                  margin='dense'
                  variant='outlined'
                >
                  {
                    fields.map(field => (
                      <MenuItem key={field.id} value={field.id}>
                        {field.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={'operator' + ind}
                  value={conditions[ind].operator}
                  onChange={(e) => handleChangeFields(ind, 'operator', e.target.value)}
                  select
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  className={classes.textField}
                >
                  {
                    operators.map(field => (
                      <MenuItem key={field.id} value={field.id}>
                        {field.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                   name={'value' + ind}
                   value={conditions[ind].value}
                   onChange={(e) => handleChangeFields(ind, 'value', e.target.value)}
                   fullWidth
                   className={classes.textField}
                   margin='dense'
                   variant='outlined'
                />
                <span 
                  style={{height: 50, display: 'inline-flex', alignItems: 'center', marginLeft: 10, cursor: 'pointer'}}
                  onClick={() => handleDeleteCondition(ind)}
                >
                  <DeleteIcon style={{color : '#DADADA'}} />
                </span>
                
              </Grid>
          
            </Fragment>
          );
         
        })}
        <Grid item xs={12}>
          <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => handleAddCondition()}>
            <AddCircleIcon style={{marginRight: 10, color: '#E83C53'}}/> <Typography className={classes.formSubheader}>Добавить условие</Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Typography style={{color: 'rgba(33,57,85,0.7)'}}>Будет пропущено: {result.accepted} посетителей, заблокировано: {result.rejected}</Typography>
          </div>
          
        </Grid>
        </>
        }
        {/* =======================  Настройки для стойки контроля доступа  ======================= */}

      </Grid>
    </>
  );
};

export default DeskEditForm;
