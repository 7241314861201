import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactQuill from 'react-quill';
import { makeStyles } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';
import {valueByName} from "./form_utils";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .ql-toolbar': {
      borderLeft: 'none',
      borderTop: 'none',
      borderRight: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& .ql-picker-label:hover': {
        color: theme.palette.secondary.main
      },
      '& .ql-picker-label.ql-active': {
        color: theme.palette.secondary.main
      },
      '& .ql-picker-item:hover': {
        color: theme.palette.secondary.main
      },
      '& .ql-picker-item.ql-selected': {
        color: theme.palette.secondary.main
      },
      '& button:hover': {
        color: theme.palette.secondary.main,
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main
        }
      },
      '& button:focus': {
        color: theme.palette.secondary.main,
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main
        }
      },
      '& button.ql-active': {
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main
        }
      },
      '& .ql-stroke': {
        stroke: theme.palette.text.primary
      },
      '& .ql-picker': {
        color: theme.palette.text.primary
      },
      '& .ql-picker-options': {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        border: 'none',
        boxShadow: theme.shadows[10],
        borderRadius: theme.shape.borderRadius
      }
    },
    '& .ql-container': {
      border: 'none',
      '& .ql-editor': {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        color: theme.palette.text.primary,
        '&.ql-blank::before': {
          color: theme.palette.text.secondary
        }
      }
    }
  },
  stepButton: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
}));

export const ECQuillEditor = props => {
  const { name, className, label, form, initial, defaultValue, formats, ...rest } = props;
  const classes = useStyles();

  const [value, setValue] = useState(valueByName(initial, name) || defaultValue || "");
  const [error, setError] = useState(false);

  useEffect(() => {
    form.register({ name: name });
    form.setValue(name, value);
  }, [form.register]);

  const handleChange = (value) => {
    form.setValue(name, value);
    setValue(value);
    form.clearError(name)
  }

  const tg_modules = {
    toolbar:[
      ['bold', 'italic', 'underline', 'strike'],
      ['link'], ['code-block']
      ],
  };

  const tg_formats = [
    'bold', 'italic', 'underline', 'strike',
    'link', 'code-block'
  ];

  return (
    <ReactQuill
      className={clsx(classes.root,className)}
      {...rest}
      value={value}
      theme="snow"
      modules={formats === 'tg' ? tg_modules : undefined}
      formats={formats === 'tg' ? tg_formats : undefined}
      onChange={handleChange}
    />
  );
}
