import React, { useContext, useEffect, useState, lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import PrintIcon from '@material-ui/icons/Print';

import Fab from '@material-ui/core/Fab';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import DropDownMenu from '../../../../components/DropDownMenu/DropDownMenu';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import filter from 'common/filterVisitors';
import { MeetupContext } from '../../../../MeetupContext';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { AppContext } from 'AppContext';
import { SystemFieldType } from '../../../../api';

const BadgeCardForVisitor = lazy(() =>
  import('../../../BadgeDetails/components/BadgeViewWithDownload/BadgeViewForVisitor')
);

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
    height: '36px !important',
    width: '36px !important',
    '& svg': {
      height: '24px !important',
      width: '24px !important',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  photo: {
    maxWidth: '70px',
    maxHeight: '70px',
    borderRadius: '7%',
  },
}));

export const makeFIO = visitor => {
  if (!visitor) {
    return "";
  }
  return [visitor.lastName, visitor.firstName, visitor.middleName || ''].join(' ').trim();
  // if (visitor.middleName) {
  //   return [visitor.lastName, visitor.firstName, (visitor.middleName || "")].join(" ");
  // } else {
  //   return [visitor.firstName, visitor.lastName].join(" ");
  // }
};

const VisitorsTable = props => {
  const {
    className,
    meetupId,
    visitors: originalVisitors,
    handleDelete,
    selected,
    setSelected,
    setDeleteConf,
    search,
    group,
    ...rest
  } = props;

  const classes = useStyles();
  const meetupContext = useContext(MeetupContext);
  const appContext = useContext(AppContext);

  const [modal, setModal] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    parseInt(localStorage.getItem('visitors-rows-per-page') || '10')
  );
  const [selectedAll, setSelectedAll] = React.useState(false);

  const visitors = filter(originalVisitors, search, group);
  const optionalFields = meetupContext.fields.filter(
    f =>
      f.systemField !== SystemFieldType.FirstName &&
      f.systemField !== SystemFieldType.LastName &&
      f.systemField !== SystemFieldType.MiddleName &&
      f.systemField !== SystemFieldType.Group
  );

  const handleClose = () => {
    setModal({});
  };
  const showModal = visitorId => {
    setModal({ visitorId: visitorId });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('visitors-rows-per-page', event.target.value);
    setPage(0);
  };

  const isSelected = visitorId => {
    if (selected[visitorId]) {
      return true;
    }
    return false;
  };
  const handleSelect = (visitorId, checked) => {
    selected[visitorId] = checked;
    let newSelected = {};
    visitors
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(e => e.visitorId)
      .forEach(e => {
        if (selected[e]) {
          newSelected[e] = selected[e];
        }
      });
    setSelected(newSelected);
    setSelectedAll(false);
  };

  const isSelectedAll = () => {
    return selectedAll;
  };
  const handleSelectAll = checked => {
    if (checked) {
      let newSelected = {};
      visitors.forEach(e => {
        newSelected[e.visitorId] = true;
      });
      setSelected(newSelected);
      setSelectedAll(true);
    } else {
      setSelected({});
      setSelectedAll(false);
    }
  };

  useEffect(() => {
    if (visitors.length < page * rowsPerPage) {
      setPage(0);
    }
  }, [visitors, page]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox checked={isSelectedAll()} onChange={e => handleSelectAll(e.target.checked)} />
                </TableCell>
                <TableCell>ФИО</TableCell>
                <TableCell>Группа</TableCell>
                {optionalFields.map(f => (
                  <TableCell key={f.systemField || f.name}>{f.label}</TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visitors.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(visitor => (
                <TableRow className={classes.tableRow} hover key={visitor.visitorId}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={isSelected(visitor.visitorId)}
                      onChange={e => handleSelect(visitor.visitorId, e.target.checked)}
                    />
                  </TableCell>
                  <TableCell>
                    <RouterLink to={'/m/' + meetupId + '/visitor/id/' + visitor.visitorId}>
                      <ListItemText primary={makeFIO(visitor)} data-test-id='visitors-fio-cell' />
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={visitor.groupName} />
                  </TableCell>

                  {optionalFields.map(f => (
                    <TableCell key={f.systemField || f.name}>
                      {f.systemField === SystemFieldType.Photo ? (
                        visitor.photo && <img className={classes.photo} src={'/api/image/' + visitor.photo} />
                      ) : (
                        <ListItemText
                          primary={f.systemField ? visitor[f.systemField] : (visitor.extended || {})[f.name]}
                        />
                      )}
                    </TableCell>
                  ))}

                  <TableCell style={{ textAlign: 'center', width: '150px' }}>
                    <Fab
                      size='small'
                      color='secondary'
                      aria-label='print'
                      className={classes.fab}
                      onClick={() => showModal(visitor.visitorId)}
                    >
                      <PrintIcon />
                    </Fab>
                    <DropDownMenu>
                      <MenuItem>
                        <RouterLink to={'/m/' + meetupId + '/visitor/id/' + visitor.visitorId}>
                          <ListItemText primary='Редактировать' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem onClick={() => setDeleteConf(visitor.visitorId)}>
                        <ListItemText primary='Удалить' />
                      </MenuItem>
                      <MenuItem onClick={() => showModal(visitor.visitorId)}>
                        <ListItemText primary='Печатать бейдж' />
                      </MenuItem>
                    </DropDownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          count={visitors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>

      <Modal
        className={classes.modal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={!!modal.visitorId}
        onClose={handleClose}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <div className={classes.paper}>
            <BadgeCardForVisitor meetupId={meetupId} visitorId={modal.visitorId} />
          </div>
        </Suspense>
      </Modal>
      
    </Card>
  );
};

VisitorsTable.propTypes = {
  className: PropTypes.string,
  visitors: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
};

export default VisitorsTable;
