import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { AppContext } from '../../../../../AppContext';
import { ECCommonErrorBlock } from '../../../../../components/Forms/ECCommonErrorBlock';
import { ECTextField } from '../../../../../components/Forms/ECTextField';
import { ECSelectField } from '../../../../../components/Forms/ECSelectField';
import {ECPhotoField} from "../../../../../components/Forms/ECPhotoField";

const SpeakerEditForm = props => {
  const { form, initial } = props;

  return (
    <>
      <ECCommonErrorBlock form={form} />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <ECPhotoField label='Фото' name='imageId' form={form} initial={initial} height={197}/>
        </Grid>
        <Grid item xs={9}>
          <Grid item xs={12}>
            <ECTextField label='Имя' name='firstName' initial={initial} form={form} />
          </Grid>
          <Grid item xs={12}>
            <ECTextField label='Фамилия' name='lastName' initial={initial} form={form} />
          </Grid>
          <Grid item xs={12}>
            <ECTextField label='Компания' name='company' initial={initial} form={form} />
          </Grid>
          <Grid item xs={12}>
            <ECTextField label='Должность' name='position' initial={initial} form={form} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ECTextField label='Подробная информация' name='description' initial={initial} form={form}
                       multiline
                       rows={4}/>
        </Grid>
        <Grid item xs={12}>
          <ECTextField label='Позиция в списке' name='posInList' initial={initial} form={form}/>
        </Grid>
      </Grid>
    </>
  );
};

export default SpeakerEditForm;
