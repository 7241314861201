import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { Link, MenuItem, TextField } from '@material-ui/core';
import { dateFormat } from '../../VisitorList/components/ExportXLS/ExportXLS';
import ChatView from './chat/ChatView';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

const ChatLogHistory = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId, channelId, clientId } = useParams();

  const classes = useStyles();

  const [refreshCounter, setRefresh] = useState(0);
  // const [history, setHistory] = useState(null);
  // useEffect(() => {
  //   appContext.chatLogsApi.listClientLogs(meetupId, channelId, clientId).then(({data}) => {
  //     setHistory(data)
  //   }).catch( appContext.errorHandler )
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [meetupId, clientId, refreshCounter]);

  const [threads, setThreads] = useState(null);

  useEffect(() => {
    const fetchThreads = async () => {
      try {
        let clients = (await appContext.chatLogsApi.getClients(meetupId, null)).data;
        let data = {};
        for (let client of clients) {
          data[client.chatId.chatClientId] = {};
          data[client.chatId.chatClientId].name = client.chatUserName;
          data[client.chatId.chatClientId].link = client.channel.channelLink;
          data[client.chatId.chatClientId].clientId = client.chatId.chatClientId;
          data[client.chatId.chatClientId].chatConfigId = client.chatId.chatConfigId;
          data[client.chatId.chatClientId].tgUsername = client.chatId.username; 
        }

        let chatHistories = await Promise.all(
          clients.map(client =>
            appContext.chatLogsApi.listClientLogs(meetupId, client.chatId.chatConfigId, client.chatId.chatClientId)
          )
        );
        for (let client of chatHistories) {
          client = client.data;
          data[client.chatId.chatClientId].messages = client.messages;
        }

        setThreads(data);
      } catch (err) {
        console.log(err);
        appContext.errorHandler(err);
      }
    };
    fetchThreads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  if (!threads) {
    return null;
  }

  return <ChatView threads={threads} />;
};

export default ChatLogHistory;
