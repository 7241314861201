import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Button, Link, Typography} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  row: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1,
  },
  buttonSpacer: {
    marginRight: theme.spacing(2)
  },
  copied: {
    padding: theme.spacing(2),
  },
}));

const RegFormToolbar = props => {
  const { className, regForm, save, ...rest } = props;

  const classes = useStyles();
  const anchorEl = useRef();
  const [copied, setCopied] = useState(false);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <FormControlLabel
          control={<Switch checked={regForm.enabled} onChange={() => {
            regForm.enabled = !regForm.enabled
            save(regForm)
          }}/>}
          label={"Включить онлайн регистрацию"}
          />
        <span className={classes.spacer} />
        <RouterLink to={"/regform/" + regForm.token} target={'_blank'}>
          <Button color='primary' variant='contained' className={classes.buttonSpacer}>
            Показать страницу
          </Button>
        </RouterLink>

        <CopyToClipboard text={window.location.origin + "/regform/" + regForm.token} onCopy={() => setCopied(true)}>
          <Button color='primary' variant='contained' ref={anchorEl}>
            Скопировать url
          </Button>
        </CopyToClipboard>
        {copied && (
          <Popover
            open={true}
            anchorEl={anchorEl.current}
            onClose={() => setCopied(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Typography className={classes.copied}>URL скопирован</Typography>
          </Popover>
        )}
      </div>
    </div>
  );
};

export default RegFormToolbar;
