import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { MeetupContext } from '../../../../../MeetupContext';

const SystemGroupField = forwardRef((props, ref) => {
  const { field: f, data: initial, autoFocus, errors, onChange, groups:groups0 } = props;
  const inputRef = useRef();
  const meetupContext = useContext(MeetupContext);

  useImperativeHandle(ref, () => ({
    fillValue: data => {
      data['groupId'] = inputRef.current.value;
      return true;
    },
  }));

  let groups = groups0 || meetupContext.groups;

  let defaultGroup = groups.find(p => p.name === f.defaultValue || p.groupId === f.defaultValue);
  if (defaultGroup) {
    defaultGroup = defaultGroup.groupId;
  } else {
    defaultGroup = 0;
  }

  return (
    <TextField
      select
      name={f.systemField}
      label={f.label}
      required={f.required}
      inputRef={inputRef}
      fullWidth
      margin='dense'
      variant='outlined'
      autoComplete='new-password'
      autoFocus={autoFocus}
      helperText={errors[f.systemField]}
      error={!!errors[f.systemField]}
      defaultValue={initial['groupId'] || defaultGroup}
      onChange={e => onChange && onChange(e.target.value)}
    >
      {groups.map(g => (
        <MenuItem key={g.groupId} value={g.groupId}>
          {g.name}
        </MenuItem>
      ))}
    </TextField>
  );
});

export default SystemGroupField;
