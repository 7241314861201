import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Button, Checkbox, TextField} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {MeetupContext} from "../../../MeetupContext";
import {Link, useParams} from "react-router-dom";
import {Link as LinkCore} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import {RegFormType, SystemFieldType} from "../../../api";
import Radio from "@material-ui/core/Radio";
import {useForm} from "react-hook-form";
import {ECTextField} from "../../../components/Forms/ECTextField";
import {ECRadioGroupField} from "../../../components/Forms/ECRadioGroupField";
import {fieldKey} from "../../VisitorList/components/ImportXLS/import_utils";
import {ECMultiCheckboxField} from "../../../components/Forms/ECMultiCheckboxField";
import * as _ from "underscore";
import CardBlock from "../../../components/CardBlock/CardBlock";

const useStyles = makeStyles(theme => ({
  groupLabel: {
    paddingTop: 12 //theme.spacing(1)
  }
}));

const MatchingSettings = props => {
  const { className, fields, regForm, save, ...rest } = props;
  const { mid: meetupId } = useParams();

  const classes = useStyles();
  const form = useForm();

  const handleSave = (data) => {
    //save(data, form)
  }

  return (
    <CardBlock title={"Настройка матчинга"} form={form} save={handleSave}>
      <Grid container spacing={3} style={{margin:"0px"}}>
        <Grid xs={12}>
          <Typography style={{color:"#546e7a"}}>
            Для того, чтобы показ анкет был менее рандомный, вы можете настроить по каким полям в анкете делать матчинг. В таком случае бот будет анализировать информацию из указанных полей и в первую очередь предлагать наиболее подходящие анкеты.
          </Typography>
        </Grid>

        <Grid xs={4} className={classes.groupLabel}>
          <FormLabel component="legend" style={{color:"#546e7a"}}>Сопоставлять поле из одной анкеты:</FormLabel>
        </Grid>
        <Grid xs={8}>
          <Typography>
            тут выбор
          </Typography>
        </Grid>
        <Grid xs={4} className={classes.groupLabel}>
          <FormLabel component="legend" style={{color:"#546e7a"}}>С полем в другой анкете:</FormLabel>
        </Grid>
        <Grid xs={8}>
          <Typography>
            тут выбор
          </Typography>
        </Grid>
        <Grid xs={4} className={classes.groupLabel}>
          <FormLabel component="legend" style={{color:"#546e7a"}}>Действие после матчинга:</FormLabel>
        </Grid>
        <Grid xs={8}>
          <Typography>
            тут выбор
          </Typography>
        </Grid>

      </Grid>
    </CardBlock>
  )

}

export default MatchingSettings;
