import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Bar, HorizontalBar } from 'react-chartjs-2';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Roboto, sans-serif',
    display: 'flex',
    flexDirection: 'column',
  },

  chartContainer: {
    width: '100%',
    padding: '0 25px 25px 25px',
    marginBottom: theme.spacing(4),
    flexBasis: '50%',
  },

  chartContainer__title: {
    height: 45,

    lineHeight: '45px',
    fontSize: 20,
    fontWeight: 'normal',
    color: '#1665D8',
  },
}));

function mapToChartFormat(data) {
  const res = { datasets: [{}] };
  res.labels = data.map(item => item.tag);
  res.datasets[0].data = data.map(item => item.count);
  res.datasets[0].backgroundColor = '#1976D2';

  return res;
}

function timestampToTime(timestamp) {
  let date = new Date(timestamp);

  let hour = ('0' + date.getHours()).slice(-2);
  let minutes = ('0' + date.getMinutes()).slice(-2);

  return hour + ':' + minutes;
}

const Desks = props => {
  const classes = useStyles();
  const { data, checked } = props;
  const countBadgesByDesk = mapToChartFormat(data.countBadgesByDesk);
  const countVisitorsByDesk = mapToChartFormat(data.countVisitorsByDesk);
  const countVisitorsByTime = mapToChartFormat(data.countVisitorsByTime);
  countVisitorsByTime.labels = countVisitorsByTime.labels.map(timestampToTime);
  const countBadgesByTime = mapToChartFormat(data.countBadgesByTime);
  countBadgesByTime.labels = countBadgesByTime.labels.map(timestampToTime);

  return (
    <div className={classes.root}>
      <Card className={classes.chartContainer}>
        <Typography className={classes.chartContainer__title} variant='h1'>
          Количество участников, обработанных стойками
        </Typography>
        <HorizontalBar
          data={checked ? countBadgesByDesk : countVisitorsByDesk}
          height={100}
          options={{
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  barThickness: 8,
                },
              ],
            },
          }}
        />
      </Card>
      <Card className={classes.chartContainer}>
        <Typography className={classes.chartContainer__title} variant='h1'>
          Поминутная статистика регистраций по всем стойкам
        </Typography>
        <Bar
          data={checked ? countBadgesByTime : countVisitorsByTime}
          height={100}
          options={{
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  barThickness: 13,
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                  gridLines: {
                    borderDash: [8, 4],
                  },
                },
              ],
            },
          }}
        />
      </Card>
    </div>
  );
};

export default Desks;
