import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { AppContext } from 'AppContext';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';

const VisitorDeleteConfirmation = ({ selected, deleteConf, open, meetupId, refresh }) => {
  const appContext = useContext(AppContext);


  return (
    <ModalDialog
      open={open}
      size='xs'
      noDivider
      okButtonText='Ок'
      handleClose={() => refresh()}
      handleOk={() => {
        if (deleteConf === 'selected') {
          appContext.visitorApi.deleteVisitors(meetupId, Object.keys(selected)).then(() => refresh('refresh'));
        } else {
          appContext.visitorApi.deleteVisitor(meetupId, deleteConf).then(() => refresh('refresh'));
        }
      }}
    >
      <Typography style={{textAlign: 'center', padding: 20}}>
        Вы действительно хотите удалить посетителя?
      </Typography>
    </ModalDialog>
  );
}

export default VisitorDeleteConfirmation;