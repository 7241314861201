import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import DropDownMenu from '../../../../components/DropDownMenu/DropDownMenu';
import {shortenDate, shortenTime} from "../../../MeetupList/components/MeetupsTable/MeetupsTable";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

const AccountsTable = props => {
  const { className, accounts, handleDelete, handleLogin, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Email владельца</TableCell>
                <TableCell style={{ width: '200px' }}>Дата регистрации</TableCell>
                <TableCell style={{ width: '200px' }}>Последняя активность</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.map(account => (
                <TableRow hover key={account.owner.email}>
                  <TableCell>
                    <RouterLink to={'/sa/account/id/' + account.accountId}>
                      {account.name}
                    </RouterLink> [{account.accountId}]<br/>
                    <span style={{color:"gray"}}>{account.internalComment}</span>
                  </TableCell>
                  <TableCell>{account.owner.email}</TableCell>
                  <TableCell style={{ width: '200px' }}>
                    {shortenDate(account.creationDate)}
                  </TableCell>
                  <TableCell style={{ width: '200px' }}>
                    {shortenTime(account.lastActiveDate)} [{account.actionsCount}]
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '100px' }}>
                    <DropDownMenu>
                      <MenuItem
                        onClick={() => {
                          handleLogin(account.accountId);
                        }}
                      >
                        <ListItemText primary='Войти в аккаунт' />
                      </MenuItem>
                      <MenuItem>
                        <RouterLink to={'/sa/account/id/' + account.accountId + '/payment'}>
                          <ListItemText primary='Изменить баланс' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem>
                        <RouterLink to={'/sa/account/id/' + account.accountId + '/tariff'}>
                          <ListItemText primary='Сменить тариф' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem>
                        <RouterLink to={'/sa/account/id/' + account.accountId + '/pack'}>
                          <ListItemText primary='Применить пакет' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem>
                        <RouterLink to={'/sa/account/id/' + account.accountId}>
                          <ListItemText primary='Редактировать' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDelete(account.accountId);
                        }}
                      >
                        <ListItemText primary='Удалить аккаунт' />
                      </MenuItem>
                    </DropDownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

AccountsTable.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.array.isRequired,
};

export default AccountsTable;
