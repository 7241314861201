import { SystemFieldType, VisitorStatusType, VisitorBadgeStatusType } from 'api/api';
import filter from 'common/filterVisitors';

let wscols;
const systemHeaders = () => {
  return ['Источник регистрации', 'Время добавления в систему', 'Время регистрации', 'Время печати бейджа'];
};

const sourceAlias = {
  imported: 'Из файла',
  manual: 'Добавлен вручную',
  onLine: 'OnLine форма',
  onSite: 'OnSite форма',
  chat: 'Чат-бот',
};

export const dateFormat = dateString => {
  let date = new Date(dateString);
  return (
    date.toLocaleString('ru', { day: 'numeric' }) +
    ' ' +
    date.toLocaleString('ru', { month: 'short' }) +
    ' ' +
   ('0' + date.toLocaleString('ru', { hour: 'numeric' })).slice(-2) +
    ':' +
    ('0' + date.toLocaleString('ru', { minute: 'numeric' })).slice(-2)
  );
};

const systemValues = visitor => {
  const st = visitor.status || {};
  const values = [
    sourceAlias[st.source],
    dateFormat(st.registrationTime),
    st.status === VisitorStatusType.Come ? dateFormat(st.statusChangeTime) : '',
    st.badgeStatus === VisitorBadgeStatusType.Printed ? dateFormat(st.badgeStatusTime) : '',
  ];
  for (let index = wscols.length - values.length; index < wscols.length; ++index) {
    if (values[index - wscols.length + values.length]) {
      wscols[index] = { wch: Math.max(wscols[index].wch, values[index - wscols.length + values.length].length) };
    }
  }
  return values;
};

export const visitorsToCells = (fields, groups, data, selected, withSystem) => {
  wscols = new Array(fields.length + (withSystem ? systemHeaders().length : 0));
  wscols.fill({ wch: 10 });
  for (let index = fields.length; index < wscols.length; ++index) {
    wscols[index] = { wch: Math.max(wscols[index].wch, systemHeaders()[index - fields.length].length) };
  }
  let table = [];
  const noSelection = Object.keys(selected).length === 0;
  table.push(fields.map(f => f.label).concat(withSystem ? systemHeaders() : []));

  data.forEach(r => {
    if (selected[r.visitorId] || noSelection) {
      table.push(
        fields
          .map((f, index) => {
            if (f.systemField) {
              let value;
              if (f.systemField === SystemFieldType.Group) {
                const g = groups.filter(u => u.groupId === r.groupId || u.name === r.groupName);
                if (g.length > 0) {
                  value = g[0].name;
                } else {
                  value = r.groupId;
                }
              } else if (f.systemField === SystemFieldType.Photo && r[f.systemField]) {
                value = window.location.origin + '/api/image/' + r[f.systemField];
              } else {
                value = r[f.systemField];
              }
              wscols[index] = { wch: Math.max(wscols[index].wch, value ? value.length : 0) };
              return value;
            } else {
              return (r.extended || {})[f.name];
            }
          })
          .concat(withSystem ? systemValues(r, wscols) : [])
      );
    }
  });
  return table;
};

export const exportVisitorsXLS = async (appContext, meetupContext, meetupId, selected, search, group) => {
  const XLSX = await import('xlsx');
  appContext.meetupApi.getMeetup(meetupId).then(({ data }) => {
    let name = data.name;
    appContext.visitorApi.listVisitors(meetupId, null, true).then(({ data }) => {
      let wb = XLSX.utils.book_new();
      let visitors = data;
      if (search || group) {
        visitors = filter(visitors, search, group);
      }

      let table = visitorsToCells(meetupContext.fields, meetupContext.groups, visitors, selected, true);
      let ws = XLSX.utils.aoa_to_sheet(table);
      ws['!cols'] = wscols;
      XLSX.utils.book_append_sheet(wb, ws, 'Visitors');
      name = name.split(' ').join('_');
      XLSX.writeFile(wb, 'Участники__' + name + '.xlsx');
    });
  });
};
