import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../../AppContext';
import HallEditForm from '../HallEditForm/HallEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../../../components/Forms/form_utils';

const HallAdd = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const form = useForm();

  const handleSave = () => {
    const data = form.getValues();
    appContext.hallApi
      .createHall(meetupId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  return (
    <ModalDialog title={'Добавление зала'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <HallEditForm meetupId={meetupId} form={form} />
    </ModalDialog>
  );
};

HallAdd.propTypes = {};

export default HallAdd;
