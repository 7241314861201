import React, { useContext, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, TextField, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';

import ModalDialog from 'components/ModalDialog/ModalDialog';
import { AppContext } from 'AppContext';

const ChangeTariffBasic = ({ accountId }) => {
  const appContext = useContext(AppContext);
  const { tariff } = useParams();


  const handleOk = () => {
    appContext.accountApi.changePlan(accountId, {
      planId: 'basic_30d',
      noCharge: 'false',
      deferred: 'true',
      comment: ''
    })
      .then(() => window.location.href = window.location.origin + '/profile/account')
      .catch((err) => console.log(err))

  }

  return (
    <ModalDialog
      noDivider
      size='sm'
      handleClose={() => appContext.history.push('/profile/account')}
      handleOk={() => handleOk()}
      okButtonText={'ok'}
    >
      <Typography style={{ marginBottom: 20, textAlign: 'center', padding: 20 }}>
        При наступлении следующего платежного периода вы будете автоматически переведены на Базовый тариф с ограничениями.
      </Typography>
    </ModalDialog>
  );
}

export default ChangeTariffBasic;