import React, { useState, useEffect, useContext} from 'react' 
import { Typography, TextField} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import ModalDialog from 'components/ModalDialog/ModalDialog'
import { AppContext } from 'AppContext';

const useStyles = makeStyles(theme => ({
  example: {
    fontSize: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center'
  }
}));

const FontAdd = ({ isShown, handleClose, changeFontFamily, currentFontList }) => {
  const appContext = useContext(AppContext);
  const [fonts, setFonts] = useState();
  const classes = useStyles();
  useEffect(() => {
    const fetchFonts = async () => {
      try {
        let data = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GF_API_KEY}`)
        data = await data.json()
        setFonts(data)
      } catch(err) {
        console.log(err)
      } 
    }
    fetchFonts()
  }, [])

  const [fontPresent, setFontPresent] = useState(null)

  const handleChange = async (e) => {
    for (let item of fonts.items) {
      
      if (item.subsets.includes('cyrillic') && item.family.toLowerCase() === e.target.value.toLowerCase() ) {
        const font = new FontFace(item.family, `url(${item.files.regular.replace('http://', 'https://')})`);
        await font.load();
        document.fonts.add(font);
        setFontPresent({family: item.family, file: item.files.regular});
        return;
      }
    }
    setFontPresent(false)
  }

  const handleOk = () => {
    if (fontPresent) {
      changeFontFamily(fontPresent.family)
      handleClose();
      let currentSavedFonts = JSON.parse(window.localStorage.getItem('fonts' + appContext.session.accountId)) || []
      

      if (!currentFontList.includes(fontPresent.family)) {
        currentSavedFonts.push(fontPresent)
        window.localStorage.setItem('fonts' + appContext.session.accountId, JSON.stringify(currentSavedFonts))
      }
     
    } 
  }

  if (!fonts) {
    return null
  }

  return (
    <ModalDialog 
    open={isShown} 
    noCloseButton
    title='Добавление шрифта'
    handleClose={handleClose}
    okButtonText={'Добавить'}
    handleOk={handleOk}
    size={'sm'}
  >
    <Typography style={{fontSize: 16}}>Выберите на <a href='https://fonts.google.com/?subset=cyrillic'>fonts.google.com</a> кириллический шрифт, который вы хотите добавить и введите его название (например, Montserrat):</Typography>
    <TextField
      data-test-id='font-input'
      onChange={handleChange} 
      variant='outlined'
      margin='dense'
      style={{width: '100%', margin: '10px 0 20px 0'}}
    />
  
    {fontPresent !== null ? (
      fontPresent ? <Typography className={classes.example} style={{fontFamily: fontPresent.family }}> <CheckCircleOutlineIcon style={{color: '#4CAF50', width: 23, height: 23, marginRight:5}}/> Almost before we knew it, we had left the ground.</Typography> 
       : 
       <Typography className={classes.example}> <HighlightOffIcon style={{color: '#E83C53', width: 23, height: 23, marginRight:5}}/> Шрифт с таким названием не найден.</Typography>
      )   : ''}

  </ModalDialog>
  )
}


export default FontAdd;
