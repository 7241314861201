import React, { useEffect, useState, useContext } from 'react';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FieldEditForm from '../FieldEditForm/FieldEditForm';

const FieldEdit = props => {
  const { idx, mid: meetupId } = useParams();
  const { goBack, fields, handleSendField } = props;

  const appContext = useContext(AppContext);
  const [data, setData] = useState(undefined);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState({});

  const [groups, setGroups] = useState();

  useEffect(() => {
    appContext.groupApi
      .listGroups(meetupId)
      .then(({ data }) => {
        setGroups(data.map(group => group.name));
      })
      .catch(appContext.errorHandler);
  }, [meetupId])
  

  useEffect(() => {
    setData(JSON.parse(JSON.stringify(fields[idx])));
  }, [fields, idx]);

  const handleDataChange = (data, isValid) => {
    setData(data);
  };

  const handleSave = () => {
    if (!data.systemField) {
      data.systemField = undefined;
    }
    if (!data.type) {
      data.type = undefined;
    }
    let newFields = [...fields];
    newFields[idx] = data;
    handleSendField(newFields).then(res => {
      if (res.error) {
        if (res.errors) {
          setErrors(res.errors)
        } else {
          setErrors([res]);
        }
      } else {
        setErrors([]);
        appContext.history.push('/m/' + meetupId + '/settings');
      }
    });
  };

  if (!data || !groups) {
    return null;
  }

  return (
    <ModalDialog title={'Редактирование поля'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <FieldEditForm data={data} errors={errors} handleDataChange={handleDataChange} idx={idx} groups={groups} fields={fields}/>
    </ModalDialog>
  );
};

FieldEdit.propTypes = {};

export default FieldEdit;
