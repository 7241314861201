import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { Card, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const ProfileUserView = props => {
  const { className, data, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <br />
      <Typography variant='caption'>Имя пользователя</Typography>
      <Typography variant='h3'>{data.user.displayName}</Typography>

      <br />
      <Typography variant='caption'>email</Typography>
      <Typography variant='h3'>{data.user.email}</Typography>
    </Card>
  );
};

export default ProfileUserView;
