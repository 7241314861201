import React, { useEffect, useState, useMemo } from 'react';
import { Text, Transformer, Rect, Line, Group } from 'react-konva';
import { calcPoints, fitTextOnCanvas, makeFieldText, useCompare } from './utils';

const BadgeViewField = ({
  field,
  visitor,
  onSelect,
  isSelected,
  setFormStateFieldPosition,
  fieldIndex,
  showBorders,
}) => {
  const valueRef = React.useRef();
  const textRef = React.useRef();
  const trRef = React.useRef();

  let text = useMemo(() => makeFieldText(field, visitor), [field, visitor]);

  const [position, setPosition] = useState({
    x: field.position.x,
    y: field.position.y,
  });

  const [size, setSize] = useState({
    x: field.size.x,
    y: field.size.y,
  });

  const [rotation, setRotation] = useState(field.rotation);

  useEffect(() => {
    setPosition(field.position);
    setSize(field.size);
  }, [field.position, field.font, field.size]);

  let fs = useMemo(() => fitTextOnCanvas(text, { ...field, size: { x: size.x, y: size.y } }), [
    text,
    field.adjustSize,
    field.fontSize,
    field.font,
    size.x,
    size.y,
  ]);
  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.setNode(textRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const points = useMemo(() => calcPoints(position.x, position.y, size.x, size.y, rotation), [
    position.x,
    position.y,
    size.x,
    size.y,
    rotation,
  ]);

  return (
    <Group /*onMouseEnter={onMouseEnter}
           onMouseLeave={onMouseLeave}*/>
      {showBorders ? (
        <Line
          points={points}
          stroke={'rgba(0,0,0,.5)'}
          strokeWidth={1}
          lineJoin={'round'}
          dash={[5, 5]}
          /*
           * line segments with a length of 5
           * with a gap of 5px
           */
        />
      ) : null}
      <Rect
        x={position.x}
        y={position.y}
        width={size.x}
        height={size.y}
        rotation={rotation}
        fill={field.backgroundColor}
      />
      <Text
        text={text}
        ref={valueRef}
        x={position.x}
        y={position.y}
        align={field.align}
        originalAlign={field.align}
        verticalAlign='middle'
        width={size.x}
        height={size.y}
        fontSize={fs}
        wrap={field.allowWrap}
        rotation={rotation}
        fontFamily={field.font}
        fontStyle={`${field.bold ? 'bold' : ''} ${field.italics ? 'italic' : ''}`}
        bold={field.bold}
        italics={field.italics}
        bg={field.backgroundColor}
      />
      {setFormStateFieldPosition ? (
        <>
          <Rect
            ref={textRef}
            draggable
            x={position.x}
            y={position.y}
            width={size.x}
            height={size.y}
            rotation={rotation}
            onClick={onSelect}
            onDragStart={onSelect}
            onDragMove={e => {
              setPosition({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
            onDragEnd={e => {
              setFormStateFieldPosition(fieldIndex, {
                position: {
                  x: e.target.x(),
                  y: e.target.y(),
                },
              });
            }}
            onTransform={e => {
              const node = textRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
              node.scaleX(1);
              node.scaleY(1);

              setPosition({
                x: e.currentTarget.x(),
                y: e.currentTarget.y(),
              });
              setSize({
                x: Math.max(5, node.width() * scaleX),
                y: Math.max(node.height() * scaleY),
              });
              setRotation(node.getRotation());
            }}
            onTransformEnd={e => {
              // transformer is changing scale of the node
              // and NOT its width or height
              // but in the store we have only width and height
              // to match the data better we will reset scale on transform end
              const node = textRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
              // we will reset it back
              node.scaleX(1);
              node.scaleY(1);

              const size = {
                x: Math.max(5, node.width() * scaleX),
                y: Math.max(node.height() * scaleY),
              };
              const position = {
                x: e.target.x(),
                y: e.target.y(),
              };

              setFormStateFieldPosition(fieldIndex, {
                size: size,
                position: position,
                rotation: node.getRotation(),
              });

              setPosition(position);
              setSize(size);
              setRotation(node.getRotation());
              /*console.log({
                    x: node.x(),
                    y: node.y(),
                    rotation: node.getRotation(),
                    // set minimal value
                    width: Math.max(5, node.width() * scaleX),
                    height: Math.max(node.height() * scaleY)
                  });*/
            }}
          />
          {isSelected && (
            <Transformer
              ref={trRef}
              rotateEnabled={false}
              keepRatio={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </>
      ) : null}
    </Group>
  );
};

export default BadgeViewField;
