import React, { createContext } from 'react';
import { WSClientMessage, WSServerMessage } from './api';
import { TopNoticeContextData } from './components/TopNotice/TopNoticeContext';
import { v4 as uuidv4 } from 'uuid';

interface WSContextProps {
  topNotice: TopNoticeContextData;
}

export interface WSContextData {
  connected: boolean;
  windowId: string;

  send: (message: WSClientMessage) => void;
  addListener: (callback: (message: WSServerMessage) => void) => void;
  removeListener: (callback: (message: WSServerMessage) => void) => void;
}

interface WSContextState {
  ws?: WebSocket;
  messagesForSend: WSClientMessage[];
  state: 'init' | 'ready' | 'error' | 'check';
}

const WSContext = createContext<WSContextData>({
  connected: false,
  windowId: "__default__",
  send: () => {},
  addListener: () => {},
  removeListener: () => {},
});

class WSContextProvider extends React.Component<WSContextProps, WSContextState> {
  constructor(props: WSContextProps) {
    super(props);
    this.state = {
      state: 'init',
      messagesForSend: [],
    };
  }

  getWindowId = (): string => {
    let windowId = window.localStorage.getItem('windowId')
    if (!windowId) {
      windowId = uuidv4()
      // console.log("Generate new windowId: " + windowId)
      window.localStorage.setItem('windowId', windowId)
    }
    return windowId
  }

  listeners: ((message: WSServerMessage) => void)[] = [];
  shouldReconnect: boolean = true;
  reconnectTimerId?: NodeJS.Timeout = undefined;
  heartbeatTimerId?: NodeJS.Timeout = undefined;
  getUrlForWS = () => {
    let url = '';
    if (window.location.host.startsWith('localhost') && process.env.REACT_APP_WS_PROXY) {
      url = process.env.REACT_APP_WS_PROXY;
    } else {
      if (window.location.protocol === 'https:') {
        url = 'wss://';
      } else {
        url = 'ws://';
      }
      url += window.location.host;
    }
    url += '/api/ws';
    return url;
  };

  onopen = () => {
    // console.log('===================== WS opened =====================');
    let sessionId = localStorage.getItem('sessionId') || undefined;
    if (sessionId) {
      this.sendMessage({ register: sessionId, windowId: this.getWindowId() });
    }
    this.setState({ state: 'ready' });
    this.props.topNotice.removeMessage('connectionError');

    if (this.state.messagesForSend.length > 0) {
      // console.log('Sending remembered messages: ' + JSON.stringify(this.state.messagesForSend));
      this.state.messagesForSend.forEach(m => this.state.ws!!.send(JSON.stringify(m)));
      this.setState({ messagesForSend: [] });
    }
  };

  onerror = (e: any) => {
    // console.log('WSContextProvider::onerror ' + JSON.stringify(e));
  };

  onmessage = (evt: any) => {
    if (evt.data !== '{"pong":"Ok"}') {
      // dont print ping-pong messages
      // console.log('WSContextProvider::onmessage ' + evt.data);
    }
    const message = JSON.parse(evt.data);
    this.listeners.forEach(c => c(message));
  };

  checkServerState = () => {
    if (this.state.state !== 'error' && this.state.state !== 'check') {
      this.setState({ state: 'error' });
      this.props.topNotice.putMessage({ message: 'connectionError' });
    }
  };

  onclose = (evt: any) => {
    // console.log('WSContextProvider::onclose');
    this.checkServerState();
    this.reconnect();
  };

  reconnect = () => {
    // console.log('WSContextProvider::reconnect');
    if (this.shouldReconnect) {
      this.reconnectTimerId = setTimeout(this.setupWebsocket, 1000);
    }
  };

  setupWebsocket = () => {
    // console.log('WSContextProvider::setupWebsocket');
    try {
      const websocket = new window.WebSocket(this.getUrlForWS());

      websocket.onopen = this.onopen;
      websocket.onerror = this.onerror;
      websocket.onmessage = this.onmessage;
      websocket.onclose = this.onclose;

      this.setState({ ws: websocket });
    } catch (e) {
      console.log(e);
      this.reconnect();
    }
  };

  sendMessage = (message: WSClientMessage) => {
    // console.log('WSContextProvider::sendMessage ' + JSON.stringify(message));
    if (this.state.ws && this.state.ws.readyState === WebSocket.OPEN) {
      this.state.ws!!.send(JSON.stringify(message));
    } else {
      this.state.messagesForSend.push(message);
    }
  };

  addListener = (callback: (message: WSServerMessage) => void) => {
    // console.log('WSContextProvider::addListener');
    this.listeners.push(callback);
  };

  removeListener = (callback: (message: WSServerMessage) => void) => {
    // console.log('WSContextProvider::removeListener');
    const index = this.listeners.indexOf(callback);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  };

  hearbeat = () => {
    if (this.state.ws && this.state.ws.readyState === WebSocket.OPEN) {
      this.state.ws!!.send(JSON.stringify({ ping: {} }));
    }
    this.heartbeatTimerId = setTimeout(this.hearbeat, 5000);
  };

  componentDidMount(): void {
    // console.log('WSContextProvider::mount');
    const query = new URLSearchParams(window.location.search)
    if ( !(window.location.href.indexOf("badge-print-internal/") !== -1 || window.location.href.indexOf("regform/") !== -1) ) {
      this.setupWebsocket();

      this.heartbeatTimerId = setTimeout(this.hearbeat, 5000);
    }
  }

  componentWillUnmount(): void {
    // console.log('WSContextProvider::unmount');
    this.shouldReconnect = false;
    if (this.reconnectTimerId) {
      clearTimeout(this.reconnectTimerId);
      this.reconnectTimerId = undefined;
    }
    if (this.heartbeatTimerId) {
      clearTimeout(this.heartbeatTimerId);
      this.heartbeatTimerId = undefined;
    }
    if (this.state.ws) {
      this.state.ws.close();
    }
    this.setState({ ws: undefined });
  }

  render() {
    // console.log('WSContextProvider::render');

    return (
      <WSContext.Provider
        value={{
          connected: this.state.state == 'ready',
          windowId: this.getWindowId(),
          send: this.sendMessage,
          addListener: this.addListener,
          removeListener: this.removeListener,
        }}
      >
        {this.props.children}
      </WSContext.Provider>
    );
  }
}

export { WSContext, WSContextProvider };
