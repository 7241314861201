import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import DeskEditForm from '../DeskEditForm/DeskEditForm';
import { errorsToForm } from '../../../../components/Forms/form_utils';
import { useForm } from 'react-hook-form';
import moment from 'moment';

const DeskAdd = props => {
  const { goBack, desks } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const form = useForm();

  const [meetupData, setMeetupData] = useState(undefined);

  const [visitors, setVisitors] = useState();
  useEffect(() => {
    appContext.visitorApi
      .listVisitors(meetupId)
      .then(({ data }) => {
        setVisitors(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId]);

  useEffect(() => {
    appContext.meetupApi.getMeetup(meetupId).then(({ data }) => {
      setMeetupData(data);
    });
  }, [meetupId]);


  const [conditions, setConditions] = useState( [
    {
      field: null,
      operator: 'equal',
      value: '',
    },
  ])

  if (!meetupData || !visitors) {
    return null;
  }

  const meetupDatesShort = () => {
    const getDatesBetween = (startDate, endDate) => {
      const dates = [];
      // Strip hours minutes seconds etc.
      let currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1 // Will increase month if over range
        );
      }
      return dates;
    };
    let dates = getDatesBetween(new Date(meetupData.startTime), new Date(meetupData.endTime));
    dates = dates.map(d => moment(d).format('YYYY-MM-DD'));
    return dates;
  };

  const selectNextNumber = () => {
    if (!desks || desks.length === 0) {
      return 1;
    }
    const nums = desks.map(d => {
      const m = /\d+$/.exec(d.name);
      if (m) {
        return parseInt(m[0]);
      } else {
        return 0;
      }
    });
    return Math.max.apply(Math, nums) + 1;
  };

  const initial = {
    name: 'Стойка #' + selectNextNumber(),
    forTest: true,
    activeDays: meetupDatesShort(),
    settings: {
      type: 'registrationDesk',
      actionCheckinEnabled: true
    },
  };

 

  const handleSave = data => {
    data.settings.conditions = [...conditions];
    appContext.receptionApi
      .createDesk(meetupId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  return (
    <ModalDialog
      title={'Добавление стойки'}
      okButtonText={'Сохранить'}
      handleOk={form.handleSubmit(handleSave)}
      handleClose={goBack}
    >
      <DeskEditForm meetupId={meetupId} form={form} initial={initial} conditions={conditions} setConditions={setConditions} visitors={visitors}/>
    </ModalDialog>
  );
};

DeskAdd.propTypes = {};

export default DeskAdd;
