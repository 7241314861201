import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import VisitorsToolbar from './components/VisitorsToolbar/VisitorsToolbar';
import VisitorsTable from './components/VisitorsTable/VisitorsTable';
import { Route, useParams } from 'react-router-dom';
import VisitorAdd from './components/VisitorAdd/VisitorAdd';
import { colors } from '@material-ui/core';
import { MeetupContextProvider } from '../../MeetupContext';
import VisitorEditWithBadge from './components/VisitorEditWithBadge/VisitorEditWithBadge';
import ImportVisitors from './components/ImportXLS/ImportVisitors';
import ImportPhoto from './components/ImportPhoto/ImportPhoto';
import VisitorDeleteConfirmation from './components/VisitorDeleteConfirmation/VisitorDeleteConfirmation';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
}));

const VisitorList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  const [deleteConf, setDeleteConf] = useState();

  // retrieve users list
  const [refreshCounter, setRefresh] = useState(0);
  const [visitors, setVisitors] = useState([]);
  const [selected, setSelected] = React.useState({});
  const [search, setSearch] = React.useState('');
  const [group, setGroup] = React.useState('');

  useEffect(() => {
    appContext.visitorApi
      .listVisitors(meetupId)
      .then(({ data }) => {
        setVisitors(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const handleDelete = (meetupId, visitorId) => {
    return appContext.visitorApi.deleteVisitor(meetupId, visitorId).then(() => {
      setVisitors(visitors => visitors.filter(u => u.visitorId !== visitorId));
    });
  };

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/visitor');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };

  const refresh = (data) => {
    if (data) {
      setRefresh(refreshCounter + 1);
    }
    setDeleteConf();
  }

  return (
    <MeetupContextProvider meetupId={meetupId}>
      <div className={classes.root}>
        <VisitorsToolbar
          meetupId={meetupId}
          refresh={backAndRefresh}
          selected={selected}
          search={search}
          setSearch={setSearch}
          visitors={visitors}
          group={group}
          setGroup={setGroup}
          setDeleteConf={setDeleteConf}
        />
        <VisitorsTable
          meetupId={meetupId}
          visitors={visitors}
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
          setDeleteConf={setDeleteConf}
          search={search}
          group={group}
        />

        <Route path={'/m/:mid/visitor/add'} exact>
          <VisitorAdd goBack={backAndRefresh} />
        </Route>
        <Route path={'/m/:mid/visitor/import'} exact>
          <ImportVisitors meetupId={meetupId} backAndRefresh={backAndRefresh} />
        </Route>
        <Route path={'/m/:mid/visitor/id/:visitorId'} exact>
          <VisitorEditWithBadge goBack={backAndRefresh} showStatus={true} />
        </Route>
        
        <Route path={'/m/:mid/visitor/import-photo'} exact>
          <ImportPhoto visitors={visitors} backAndRefresh={backAndRefresh}/>
        </Route>

        <Route path='/m/:meetupId/visitor/confirmation-delete/:visitorId'>
          <VisitorDeleteConfirmation handleDelete={handleDelete} backAndRefresh={backAndRefresh}/>
        </Route>
        <VisitorDeleteConfirmation 
          deleteConf={deleteConf}
          open={!!deleteConf} 
          refresh={refresh}
          selected={selected}
          meetupId={meetupId}
        />
      </div>
    </MeetupContextProvider>
  );
};

export default VisitorList;
