import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import { Route, useParams } from 'react-router-dom';
import PurchasesToolbar from "./components/PurchasesToolbar";
import PurchasesTable from "./components/PurchasesTable";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const PurchaseList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  const [refreshCounter, setRefresh] = useState(0);
  const [info, setInfo] = useState();

  useEffect(() => {
    appContext.merchApi
      .listPurchases(meetupId)
      .then(({ data }) => {
        setInfo(data)
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  if (!info) {
    return null;
  }

  return (
    <>
      <PurchasesToolbar meetupId={meetupId} info={info}/>
      <PurchasesTable
        meetupId={meetupId}
        purchases={info.purchases}
      />
    </>
  );
};

export default PurchaseList;
