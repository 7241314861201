import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tab, Tabs, FormControlLabel, Checkbox, Switch, Divider, MenuItem } from '@material-ui/core';
import DropDownMenu from 'components/DropDownMenu/DropDownMenu';
import { Route, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Common from './Common';
import Desks from './Desks';
import { dateFormat } from '../VisitorList/components/ExportXLS/ExportXLS';
import { makeFIO } from '../VisitorList/components/VisitorsTable/VisitorsTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },

  controlsContainer: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },

  switch__track: {
    backgroundColor: '#3F51B5 !important',
    opacity: 0.5,
  },

  switch__thumb: {
    background: '#3F51B5',
  },

  switch: {
    fontFamily: 'Roboto, sans-serif',
    color: '#90A4AE',
    fontSize: 14,
  },

  switch__label_check: {
    color: '#3F51B5',
  },

  tabs: {
    margin: 'auto',
    maxWidth: 'max-content',
  },
}));

const Report = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();

  const classes = useStyles();

  const [refreshCounter, setRefresh] = useState(0);
  const [report, setReport] = useState(undefined);
  const [deskReport, setDeskReport] = useState(undefined);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(true);
  const intervalId = useRef(0);

  useEffect(() => {
    appContext.meetupApi
      .getReport(meetupId)
      .then(({ data }) => {
        setReport(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  useEffect(() => {
    appContext.meetupApi
      .getDeskReport(meetupId)
      .then(({ data }) => {
        setDeskReport(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const refresh = data => {
    setRefresh(prevCounter => prevCounter + 1);
  };


  const exportDeskStats = async () => {
    let {data:meetup} = await appContext.meetupApi.getMeetup(meetupId)
    let {data:visitors} = await appContext.visitorApi.listVisitors(meetupId)
    let {data:deskLogs} = await appContext.receptionApi.listDeskLogs(meetupId)
  
    let visitorsObj = {};
    for (let v of visitors) {
      visitorsObj[v.visitorId] = v;
    }

    let aoa = [[
      "Время",
      "Id стойки",
      "Имя стойки",
      "Id посетителя",
      "ФИО",
      "Действие"
    ]]

    let rowLength = aoa[0].length
    let wscols = new Array(rowLength);
    wscols.fill({ wch: 5 });
    

    for (let i = 0; i < rowLength; ++i) {
      wscols[i] = {wch: Math.max(wscols[i].wch, aoa[aoa.length - 1][i].toString().length)}
    }

    
    for (let log of deskLogs) {
      aoa.push([
        dateFormat(log.timestamp),
        log.deskId,
        log.deskName,
        log.visitorId,
        makeFIO(visitorsObj[log.visitorId]),
        log.action
      ]);

      for (let i = 0; i < rowLength; ++i) {
        wscols[i] = {wch: Math.max(wscols[i].wch, aoa[aoa.length - 1][i].toString().length)}
      }
    }

    const XLSX = await import('xlsx');
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(aoa);
    ws['!cols'] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, 'Logs');

    let name = meetup.name.split(' ').join('_');
    XLSX.writeFile(wb, 'АктивностьСтоек__' + name + '.xlsx');
  }



  const valueFromUrl = () => {
    const path = appContext.history.location.pathname;
    if (path.endsWith('common')) {
      return 'common';
    }
    if (path.endsWith('of-desks')) {
      return 'of-desks';
    }
    return '';
  };

  if (!report || !deskReport) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        onChange={() => {}}
        scrollButtons='auto'
        value={valueFromUrl()}
        variant='scrollable'
      >
        <Tab
          label={'Общая'}
          value={'common'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/stat/common');
          }}
        />
        <Tab
          label={'По стойкам'}
          value={'of-desks'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/stat/of-desks');
          }}
        />
      </Tabs>
      <Divider />
      <div className={classes.controlsContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={autoRefresh}
              onChange={() => {
                if (autoRefresh) {
                  clearInterval(intervalId.current);
                } else {
                  intervalId.current = setInterval(() => refresh(), 60000);
                }
                setAutoRefresh(!autoRefresh);
              }}
              name='autoRefresh'
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
            />
          }
          label='Автоматически обновлять статистику раз в минуту'
        />
        <Route path='/m/:mid/stat/of-desks'>
          <div className={classes.switch}>
            <span className={!switchChecked && classes.switch__label_check}>Поставлена отметка "пришёл"</span>
            <Switch
              checked={switchChecked}
              onChange={e => {
                setSwitchChecked(e.target.checked);
              }}
              classes={{
                track: classes.switch__track,
                thumb: classes.switch__thumb,
              }}
            />
            <span className={switchChecked && classes.switch__label_check}>Напечатано бейджей</span>
            <DropDownMenu style={{ marginLeft: 10 }}>
              <MenuItem onClick={() => exportDeskStats()}>
                Выгрузить данные
              </MenuItem>
            </DropDownMenu>
          </div>
        </Route>
      </div>
      <Route path='/m/:mid/stat/common'>
        <Common data={{ ...report }} />
      </Route>
      <Route path='/m/:mid/stat/of-desks'>
        <Desks data={{ ...deskReport }} checked={switchChecked} />
      </Route>
    </div>
  );
};

export default Report;
