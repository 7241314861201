import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles(() => ({
  menu: {
    width: 200,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
}));

const ColorPicker = props => {
  const { colors, currentColor, setCurrentColor = () => {} } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <div 
        style={{
          width: 20,
          height: 20,
          backgroundColor: currentColor,
          cursor: 'pointer',
          border: `1px solid #B7B7B7`,
          borderRadius: 10
        }}
        onClick={handleMenuOpen}
      >  
      </div>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        open={open}
        anchorEl={anchorEl}
      >
        {
          colors.map((color) => (
            <div 
              key={color}
              style={{
                width: 20,
                height: 20,
                backgroundColor: color,
                cursor: 'pointer',
                border: `1px solid ${color === currentColor ? '#213955' : '#B7B7B7'}`,
                borderRadius: 10,
                margin: '5px 10px'
              }}
              onClick={() => setCurrentColor(color)}
            >

            </div>
          ))
        }
      </Popover>
    </Fragment>
  );
};


export default ColorPicker;
