import * as _ from 'underscore';
import './BadgeView.css';
import Konva from 'konva';
import { template } from 'underscore';
import { fetchFontTtf } from 'common/fonts'

_.templateSettings.interpolate = /\{\{(.+?)\}\}/g;

export const mmToPx = (mm, dpi) => {
  // делим на 25,4 - получаем дюймы, умножаем на dpi - получаем пиксели
  return (mm / 25.4) * dpi;
};
export const pxTomm = (px, dpi) => {
  // делим на 25,4 - получаем дюймы, умножаем на dpi - получаем пиксели
  return (px / dpi) * 25.4;
};

export const groupForPreview = () => {
  return {
    name: 'Group name',
    text: 'Group text',
  };
};

export const defaultVisitorForBadge = {
  visitorId: 101,
  firstName: 'Виктор',
  lastName: 'Бейджиков',
  middleName: 'Иванович',
  phoneNumber: '+7 (812) 666-00-99',
  email: 'super_mail@mail.com',
  groupName: 'Участник',
  photo: 'portrait_placeholder',
  extended: {
    company: 'ООО Большие залы',
    jobPosition: 'Директор по маркетингу',
  },
  // TODO: надо проверить метод генерации qrToken'а и проверить, обеспечивается ли уникальность токена в пределах митапа
  qrToken: '12346548',
  makeContactLink: "https://t.me/some_bot?start=5866832050"
};

export const visitorForPreview = fields => {
  let baseData = JSON.parse(JSON.stringify(defaultVisitorForBadge))
  if (fields) {
    fields.forEach(f => {
      if (!f.systemField && f.name) {
        if (!baseData.extended[f.name]) {
          baseData.extended[f.name] = f.label;
        }
      }
    });
  }
  return baseData;
};

export const visitorForExport = fields => {
  let baseData = {
    firstName: 'Фёдя',
    lastName: 'Сумкин',
    middleName: 'Бильбович',
    phoneNumber: '+7 (812) 666-00-99',
    email: 'super_mail@mail.ml',
    groupName: 'Участник',
    photo: 'https://app.eventos42.ru/api/image/xxxxPhotoIdxxxx',
    extended: {
      company: 'ООО Драконы и сокровища',
      jobPosition: 'Самый главный специалист',
    },
    qrToken: '12346548',
  };
  if (fields) {
    fields.forEach(f => {
      if (f.name) {
        if (!baseData.extended[f.name]) {
          baseData.extended[f.name] = '';
        }
      }
    });
  }

  return baseData;
};

// Это специальный костыль, для того, чтобы выравнивать текст в pdf по вертикали, по центру поля.
// Аналогичным способом можно сделать и выравнивание по нижнему краю
const setTopMarginOfCellForVerticalCentering = (ri, node) => {
  if (node.table.body[ri][0]._inlines.length === 0) {
    return 0;
  }
  const textHeight = node.table.body[ri][0]._inlines[0].height + 1

  const width = node.table.widths[0]
  const lines = Math.ceil(node.table.body[ri][0]._inlines.map(i => i.width).reduce( (a,b) => a + b ) / width.width);

  const cellHeight = node.table.heights[0];

  return Math.max(0, (cellHeight - textHeight*lines) / 2)
}

export const buildContentFromStage = async (dd, stage) => {
  stage.find('Text').forEach(text => {
    let fontProps = {
      font: text.fontFamily(),
      fontSize: text.fontSize(),
      bold: text.getAttr('bold'),
      italics: text.getAttr('italics'),
    };

    const bg = text.getAttr('bg');

    if (bg) {
      dd.content.push({
        absolutePosition: {
          x: text.x(),
          y: text.y(),
        },
        width: text.width(),
        height: text.height(),
        svg: `<svg viewBox="0 0 ${text.width()} ${text.height()}" xmlns="http://www.w3.org/2000/svg" version="1.1"><rect x="0" y="0" width="${text.width()}" height="${text.height()}" style="fill:${bg};"/></svg>`,
      });
    }

    dd.content.push({
      text: text.text(),
      absolutePosition: {
        x: text.x(),
        y: text.y() - 1
      },
      table: {
        widths: [text.width()],
        heights: [text.height()],
        body: [[text.text()]],
      },
      layout: {
        hLineWidth: () => {
          return 0;
        }, //{ return showBorders ? 1 : 0 },
        vLineWidth: () => {
          return 0;
        }, //{ return showBorders ? 1 : 0 },
        hLineColor: () => {
          return text.fill();
        },
        vLineColor: () => {
          return text.fill();
        },
        paddingLeft: () => {
          return 0;
        },
        paddingRight: () => {
          return 0;
        },
        paddingTop: setTopMarginOfCellForVerticalCentering
      },
      ...fontProps,
      color: text.fill(),
      alignment: text.getAttr('originalAlign'),
      lineHeight: 0.8,
    });

    /* {
      baseline: 'top',
      angle: -text.getAbsoluteRotation()
    }*/
  });

  for (let image of stage.find('Image')) {
    if (image.name() === '.qr' || image.name() === '.bar') {
      const rotation = image.name() === '.qr' ? image.getAttr('angle') : image.rotation();
      const angle = Konva.getAngle(rotation);
      const scaleFactor = image.getAttr('scaleFactor');

      const halfW = image.width() / 2;
      const halfH = image.height() / 2;

      
      let width = image.name() === '.bar' ? image.width() / scaleFactor.x : image.width();
      let height = image.name() === '.bar' ? image.height() / scaleFactor.y : image.height()

      dd.content.push({
        absolutePosition: {
          x: image.x() - (halfW * Math.cos(angle) + halfH * Math.sin(-angle)),
          y: image.y() - (halfH * Math.cos(angle) + halfW * Math.sin(angle)),
        },
        width: width,
        height:  height,
        svg: image.getAttr('svg'),
        fit: [width, height],
      });
    }

    const imageId = String(image.getAttr('imageId'));
    let imagePreview = String(image.getAttr('imagePreview'));
  
    if (imagePreview !== 'undefined') {
      imagePreview = await fetch(imagePreview).then(r => r.blob());
      imagePreview = await toBase64(imagePreview);
    }
    if (image.name() === '.image' && imageId !== 'undefined' && !imageId.startsWith('#')) {
      const rotation = image.rotation();
      const angle = Konva.getAngle(rotation);
      if (image) {
        dd.content.push({
          image: imagePreview !== 'undefined' ? imagePreview : imageId,
          width: image.width(),
          height: image.height(),
          absolutePosition: {
            x: image.x() - ((image.width() / 2) * Math.cos(angle) + (image.height() / 2) * Math.sin(-angle)),
            y: image.y() - ((image.height() / 2) * Math.cos(angle) + (image.width() / 2) * Math.sin(angle)),
          },
        });
      }
    }
  };

  return dd;
};

export const addPageBreak = dd => {
  dd.content.push({
    text: '',
    pageBreak: 'after',
  });
};

const toBase64 = blob =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

const getImageBlob = async (field, visitor) => {
  let imageId;
  if (field.imageId) {
    imageId = field.imageId;
  } else if (field.text) {
    imageId = template(field.text)(visitor);
  } else {  
    imageId = field.backgroundColor;
  }
  const imageUrl = '/api/image/' + imageId;
  const response = await fetch(imageUrl);

  const blob = await response.blob();
  const imageData = await toBase64(blob);
  return {
    imageId: imageId,
    imageData,
  };
};

export const buildBadgeDefaults = async (badge, forPrint, visitors0) => {
  const visitors = Array.isArray(visitors0) ? visitors0 : [visitors0];
  // console.log('buildBadgeDefaults', badge, visitors);
  const bgData = await getImageBlob(badge, visitors[0]);

  const badgeImages = badge.fields.filter(
    field => field.type === 'image' && !(field.backgroundColor && field.backgroundColor.startsWith('#'))
  );
  // console.log('images', badgeImages);
  //const promiseImagesArray = [...badgeImages.map(image => getImageBlob(image, visitor))];
  const promiseImagesArray = visitors.flatMap(v => badgeImages.map(image => getImageBlob(image, v)));

  let images = [];
  await Promise.all(promiseImagesArray).then(allPayload => {
    images = allPayload;
  });

  const dd = {
    pageSize: {
      width: mmToPx(badge.width, 72),
      height: mmToPx(badge.height, 72),
    },
    pageMargins: [0, 0],
    content: [],
  };

  dd.images = {};
  if (!(badge.dontPrintImage && forPrint) && bgData.imageData) {
    dd.images['substrate'] = bgData.imageData;
    dd.background = [
      {
        image: 'substrate',
        width: mmToPx(badge.width, 72),
        height: mmToPx(badge.height, 72),
      },
    ];
  }

  images.forEach(image => {
    dd.images[image.imageId] = image.imageData;
  });

  return dd;
};



export const createPdf = async dd => {
  let vfs = {}
  let fonts = {}

  // console.log('createPdf', dd);

  const pdfMake = await import('pdfmake');
  let data = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GF_API_KEY}`)
  let googleFonts = await data.json()

  for (let obj of dd.content) {
    if (obj.font) {
      for (let gfData of googleFonts.items) {
        if (gfData.family === obj.font) {
          let ttf = await fetchFontTtf(gfData.files.regular.replace('http://', 'https://'));
          vfs[gfData.family + '-Regular.ttf'] = ttf.slice(21)

          if (!fonts[gfData.family]) {
            fonts[gfData.family] = {}
          }

          fonts[gfData.family].normal = gfData.family + '-Regular.ttf'

          if (obj.bold) {
            if (gfData.files.bold) {
              let ttf = await fetchFontTtf(gfData.files.bold.replace('http://', 'https://'));
              vfs[gfData.family + '-Bold.ttf'] = ttf.slice(21)
              fonts[gfData.family].bold = gfData.family + '-Bold.ttf'
            } else {
              fonts[gfData.family].bold = gfData.family + '-Regular.ttf'
            }
          }

          if (obj.italics) {
            if (gfData.files.italic) {
              let ttf = await fetchFontTtf(gfData.files.italic.replace('http://', 'https://')); 
              vfs[gfData.family + '-Italic.ttf'] = ttf.slice(21)
              fonts[gfData.family].italics = gfData.family + '-Italic.ttf'
            } else {
              fonts[gfData.family].italics = gfData.family + '-Regular.ttf'
            }
          }
          fonts[gfData.family].bolditalics = gfData.family + '-Regular.ttf'
     
        }
      } 
    }
  }

  return pdfMake.createPdf(dd, null, fonts, vfs);
};

export const renderBadgePDF = async (badge, visitor, showBorders, forPrint, stage) => {
  const dd = await buildBadgeDefaults(badge, forPrint, visitor);

  await buildContentFromStage(dd, stage);

  return createPdf(dd);
};

export const renderToPrint = doc => {
  return new Promise((resolve, reject) => {
    doc.getBase64(data => {
      resolve(data);
    });
  });
};

export const renderToSave = doc => {
  return new Promise((resolve, reject) => {
    doc.getDataUrl(url => {
      resolve(url);
    });
  });
};

export const renderBadgePDF_ToDataUrl = async (badge, visitor, showBorders, forPrint, stage) => {
  const doc = await renderBadgePDF(badge, visitor, showBorders, forPrint, stage);
  return renderToSave(doc);
};

export const renderBadgePDF_ToBlob = async (badge, visitor, showBorders, forPrint, stage) => {
  const doc = await renderBadgePDF(badge, visitor, showBorders, forPrint, stage);
  return renderToPrint(doc);
};

