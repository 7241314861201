import React, { useState, useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { AppContext } from '../../AppContext';
import SAInnerMenu from '../../components/SAInnerMenu/SAInnerMenu';
import AccountToolbar from './components/AccountToolbar/AccountToolbar';
import AccountsTable from './components/AccountsTable/AccountsTable';
import AccountAdd from './components/AccountAdd/AccountAdd';
import AccountEdit from './components/AccountEdit/AccountEdit';
import ChangeBalance from './components/ChangeBalance/ChangeBalance';
import ChangeServicePlan from './components/ChangeServicePlan/ChangeServicePlan';
import ApplyServicePack from './components/ApplyServicePack/ApplyServicePack';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const Account = props => {
  const appContext = useContext(AppContext);

  const classes = useStyles();

  const [refreshCounter, setRefresh] = useState(0);
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    appContext.accountApi
      .listAccounts()
      .then(({ data }) => {
        setAccounts(data.data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const backAndRefresh = data => {
    appContext.history.push('/sa/account');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };

  const handleDelete = accountId => {
    appContext.accountApi.deleteAllByAccount(accountId).then(() => {
      setAccounts(accounts => accounts.filter(a => a.accountId !== accountId));
    });
  };

  const handleLogin = accountId => {
    appContext.accountApi.loginIntoAccount(accountId).then(({ data }) => {
      appContext.login(data);
      appContext.history.push('/meetup');
    });
  };

  return (
    <div className={classes.root}>
      <SAInnerMenu />

      <AccountToolbar />
      <AccountsTable accounts={accounts} handleDelete={handleDelete} handleLogin={handleLogin} />

      <Route path={'/sa/account/add'} exact>
        <AccountAdd goBack={backAndRefresh} />
      </Route>

      <Route path={'/sa/account/id/:accountId'} exact>
        <AccountEdit goBack={backAndRefresh} />
      </Route>

      <Route path={'/sa/account/id/:accountId/payment'} exact>
        <ChangeBalance goBack={backAndRefresh} />
      </Route>

      <Route path={'/sa/account/id/:accountId/tariff'} exact>
        <ChangeServicePlan goBack={backAndRefresh} />
      </Route>

      <Route path={'/sa/account/id/:accountId/pack'} exact>
        <ApplyServicePack goBack={backAndRefresh} />
      </Route>
    </div>
  );
};

export default Account;
