import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {defaultVisitorForBadge} from "../../BadgeDetails/components/BadgeView/BadgeView";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const templateParams = [
  ["${visitor.firstName}", defaultVisitorForBadge["firstName"]],
  ["${visitor.lastName}", defaultVisitorForBadge["lastName"]],
  ["${visitor.middleName}", defaultVisitorForBadge["middleName"]],
  ["${visitor.photo}", "", "Тут нужен пример, как вставлять фото"],
  ["${visitor.phoneNumber}", defaultVisitorForBadge["phoneNumber"]],
  ["${visitor.email}", defaultVisitorForBadge["email"]],
  ["${visitor.extended.<field-name>}", "",
    "Здесь находятся поля, которые вы создали вручную. Например, если вы создали поле jobPosition, то в шаблоне вы сможете сослаться на него так: ${visitor.extended.jobPosition}"],
  ["${meetup.name}", "Большой Форум Волшебников", ""],
  // ["${meetup.startTime}", "", ""],
  // ["${meetup.endTime}", "", ""],
  // ["${meetup.timezone}", "", ""],
  ["${group.name}", "Участник", ""],
  ["${editCancelLink}", "Ссылка для редактирования записи или для отмены регистрации", ""]
]

const MailTemplateParams = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Typography variant={"h3"}>Список переменных для использования в шаблонах</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Переменная</TableCell>
            <TableCell>Пример</TableCell>
            <TableCell>Описание</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {templateParams.map(p =>
            <TableRow>
              <TableCell>{p[0]}</TableCell>
              <TableCell>{p[1]}</TableCell>
              <TableCell>{p[2]}</TableCell>
            </TableRow>
          )}

        </TableBody>
      </Table>

    </div>
  );
};

export default MailTemplateParams;
