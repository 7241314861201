import React, { createContext } from 'react';

interface ScannerEventContextProps {
}

export interface ScannerEventContextData {
  addListener: (callback: (code: string) => void) => void;
  removeListener: (callback: (code: string) => void) => void;
}

interface ScannerEventContextState {
}

const ScannerEventContext = createContext<ScannerEventContextData>({
  addListener: () => {},
  removeListener: () => {},
});

class ScannerEventContextProvider extends React.Component<ScannerEventContextProps, ScannerEventContextState> {
  constructor(props: ScannerEventContextProps) {
    super(props);
    this.state = {
      lastEvents: []
    };
  }

  listeners: ((code: string) => void)[] = [];
  lastEvents: Array<{key: string, ts: number}> = [];
  timerId?: NodeJS.Timeout;
  shift: boolean = false;

  addListener = (callback: (code: string) => void) => {
    // console.log('ScannerEventContextProvider::addListener');
    this.listeners.push(callback);
  };

  removeListener = (callback: (code: string) => void) => {
    // console.log('ScannerEventContextProvider::removeListener');
    const index = this.listeners.indexOf(callback);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  };

  checkCode = () => {
    if (this.lastEvents.length > 0) {
      const lastTs = this.lastEvents[this.lastEvents.length - 1].ts;
      this.lastEvents = this.lastEvents.filter(e => lastTs - e.ts < 1000);
    }
    if (this.lastEvents.length >= 6) {
      let code = this.lastEvents.reduce( (a,b) => a + b.key, '');
      this.listeners.forEach(c => c(code));
      this.lastEvents = [];
      if (this.timerId) {
        clearTimeout(this.timerId)
      }
      return true
    }
    return false
  };

  handleKey = (e:any) => {
    if (!window.location.pathname.startsWith('/reception')) {
      return;
    }
    if (e.keyCode === 16) {
      this.shift = true;
      return;
    }

    // Суть такая - храним массив кнопок и их таймштампов. Записываем в него только цифирки.
    // Проверяем наличие кода по "не цифирке" и по таймеру. Таймер ставим на 200мс и ожидаем, что все нажатия должны укладываться в 1000мс
    const ts = new Date().getTime();
    let key = String.fromCharCode(e.keyCode);
    if (this.shift) {
      key = key.toUpperCase();
      this.shift = false;
    }

    if (e.keyCode >= 32 && e.keyCode <= 127) {
      // если цификра, то просто клеим к lastEvents (но теперь проверяем не только цифирки, а любые ascii-символы
      this.lastEvents = this.lastEvents.concat({key, ts});
      if (this.timerId) {
        clearTimeout(this.timerId)
      }
      this.timerId = setTimeout(this.checkCode, 200)
    } else {
      if (this.checkCode()) {
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  componentDidMount(): void {
    // console.log('ScannerEventContextProvider::mount');
    document.body.addEventListener('keydown', this.handleKey, {capture: true});
  }

  componentWillUnmount(): void {
    // console.log('ScannerEventContextProvider::unmount');
    document.body.removeEventListener('keydown', this.handleKey)
  }

  render() {
    // console.log('ScannerEventContextProvider::render');
    return (
      <ScannerEventContext.Provider
        value={{
          addListener: this.addListener,
          removeListener: this.removeListener,
        }}
      >
        {this.props.children}
      </ScannerEventContext.Provider>
    );
  }
}

export { ScannerEventContext, ScannerEventContextProvider };
