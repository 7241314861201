import React, { useEffect, useRef } from 'react';
import { useParams, Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Page from 'components/Page/Page';
// import { getContacts } from 'src/actions/chatActions';
import Sidebar from './Sidebar';
import ThreadDetails from './ThreadDetails';
import ThreadNew from './ThreadNew';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    boxSizing: 'border-box',
  },
}));

function ChatView({ threads }) {
  const classes = useStyles();
  const { clientId } = useParams();
  const pageRef = useRef(null);

  // useEffect(() => {
  //   dispatch(getContacts());
  // }, [dispatch]);
  return (
    <Page className={classes.root} title='Chat' ref={pageRef}>
      <Sidebar containerRef={pageRef} threads={threads} />
      <Switch>
        <Route path='/m/:mid/chat/logs/:channelId/:clientId'>
          <ThreadDetails threads={threads} />
        </Route>
        <Route>
          <ThreadNew />
        </Route>
      </Switch>
    </Page>
  );
}

export default ChatView;
