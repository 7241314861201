import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import QrReader from 'react-qr-reader';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const ReceptionBarScanner = props => {
  const {
    className,
    meetupId,
    refresh,
    selected,
    search: outerSearch,
    setSearch: setOuterSearch,
    group: outerGroup,
    setGroup: setOuterGroup,
    ...rest
  } = props;

  const handleScan = data => {
    if (data) {
      setOuterSearch(data);
    }
  };
  const handleError = err => {
    console.error(err);
  };

  return (
    <div>
      <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '300px' }} />
    </div>
  );
};

ReceptionBarScanner.propTypes = {
  className: PropTypes.string,
  meetupId: PropTypes.string,
};

export default ReceptionBarScanner;
