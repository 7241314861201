import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  topLogo: '/images/default/logo--white.svg',
  topIcon: '/images/default/favicon.ico',
  appBarColor: 'primary',
  documentTitle: 'Eventos42',
  registrationAvailable: true,
});

export default theme;
