import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';
import { AppContext } from '../../../../AppContext';
import {shortenDate, shortenTime} from '../../../MeetupList/components/MeetupsTable/MeetupsTable';
import StatLogView from '../StatLogView/StatLogView';
import { CommentSharp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
  logFilter: {
    width: 170,
    padding: '2px 4px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '20px 0'
  }
}));

const StatLogTable = props => {
  const { className, logFilter, setLogFilter, ...rest } = props;
  const appContext = useContext(AppContext);

  const filters = {
    'all': 'Все действия',
    'visitors': 'Посетители',
    'meetups': 'Мероприятия',
    'desks': 'Стойки',
    'payments': 'Платежи'
  }

  const classes = useStyles();

  const [data, setData] = useState([]);
  
  const [modal, setModal] = useState(undefined);
  useEffect(() => {
    appContext.accountApi
      .listStatLog(logFilter)
      .then(({ data }) => {
        setData(data.data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logFilter]);

  const goBack = () => {
    appContext.history.push('/profile/log');
  };

  return (
    <>
      <div className={classes.toolbar}>
        <TextField  
          select
          className={classes.logFilter}
          margin='dense'
          value={logFilter}
          onChange={e => setLogFilter(e.target.value)}
        >
          {
            Object.keys(filters).map(filter => (
              <MenuItem key={filter} value={filter}>
                {filters[filter]}
              </MenuItem>
            ))
          }
  
        </TextField>
      </div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Время</TableCell>
                  <TableCell>Действие</TableCell>
                  <TableCell>Коммент</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((log, i) => (
                  <TableRow hover key={i} onClick={() => setModal(log)} style={{ cursor: 'pointer' }}>
                    <TableCell>
                      <ListItemText primary={shortenTime(log.timestamp)} />
                    </TableCell>
                    <TableCell>
                      <ListItemText primary={log.message.text} />
                    </TableCell>
                    <TableCell>
                      <ListItemText primary={log.comment} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        {modal && <StatLogView data={modal} handleClose={() => setModal(undefined)} />}
      </Card>
    </>
  );
};

export default StatLogTable;
