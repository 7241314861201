import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Button, Checkbox, TextField} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {MeetupContext} from "../../../MeetupContext";
import {Link, useParams} from "react-router-dom";
import {Link as LinkCore} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import {RegFormType, SystemFieldType} from "../../../api";
import Radio from "@material-ui/core/Radio";
import {useForm} from "react-hook-form";
import {ECTextField} from "../../../components/Forms/ECTextField";
import {ECRadioGroupField} from "../../../components/Forms/ECRadioGroupField";
import {fieldKey} from "../../VisitorList/components/ImportXLS/import_utils";
import {ECMultiCheckboxField} from "../../../components/Forms/ECMultiCheckboxField";
import * as _ from "underscore";
import CardBlock from "../../../components/CardBlock/CardBlock";

const useStyles = makeStyles(theme => ({
  groupLabel: {
    paddingTop: 12 //theme.spacing(1)
  }
}));

const FormFields = props => {
  const { className, fields, regForm, save, ...rest } = props;
  const { mid: meetupId } = useParams();

  const classes = useStyles();
  const form = useForm();

  const handleSave = (data) => {
    //save(data, form)
  }

  return (
    <CardBlock title={"Настройка анкеты участника"} form={form} save={handleSave}>
      <Grid container  spacing={3}  style={{margin:"0px"}}>
        <Grid xs={12}>
          <Typography style={{color:"#546e7a", marginTop: "8px", marginBottom: "16px"}}>
            В нетворкинге могут участвовать только зарегистрированные посетители.
            <ul style={{marginLeft: 16}}>
              <li>Если посетитель регистрировался через чат-бота, то он сможет сразу участвовать в нетворкинге.</li>
              <li>Если посетитель был добавлен в систему Eventos другим способом (через форму онлайн-регистрации, либо вручную администратором), то бот попросит ввести Email посетителя, указанный при регистрации. Если Email будет указан верно, то на него придет числовой код, который надо будет ввести в ботике и тем самым подтвердить, что этот Email принадлежит данному посетителю. После успешной проверки будет доступен модуль нетворкинга.</li>
            </ul>
          </Typography>
        </Grid>

        <Grid xs={4} style={{paddingTop: 12}}>
          <Typography style={{color:"#546e7a"}}>Список полей в анкете:</Typography>
        </Grid>
        <Grid xs={4}>
          <FormLabel component="legend">тут список</FormLabel>
        </Grid>

      </Grid>
    </CardBlock>
  )

}

export default FormFields;
