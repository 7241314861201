import strings from "../../strings";

export const errorsToForm = form => error => {
  const data = error.response.data;

  Object.keys(form.errors).forEach(k => form.clearError(k));
  const errors = data.errors || [data];

  errors.forEach(e => {
    form.setError((e.args && e.args.field) || '_', e.error, e.message);
  });
};

export const errorsToObject = error => {
  const data = error.response.data;

  const ret = {};
  const errors = data.args ? data.errors || [data] : [];
  errors.forEach(e => {
    if (strings.errors[e.error]) {
      ret[e.args.field || e.args.limit] = strings.formatString(strings.errors[e.error], e.args);
    } else {
      ret[e.args.field || e.args.limit] = e.message;
    }
  });
  return ret;
};

export const valueByName = (obj, name) => {
  if (!obj || !name) {
    return null;
  }
  const parts = name.split('.');
  let val = obj;
  parts.forEach(p => (val = (val && val[p])));
  return val;
};
