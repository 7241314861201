import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import DropDownMenu from '../../../components/DropDownMenu/DropDownMenu';
import Typography from "@material-ui/core/Typography";
import {NewsStatusType, NewsType} from "../../../api";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
  hint: {
    marginLeft: 10,
    height: 16,
    width: 16,
    minHeight: 16,
  },
  photo: {
    maxWidth: '70px',
    maxHeight: '70px',
    borderRadius: '7%',
  },
}));

export const NewsTypes = {
  "notification": "Нотификация",
  "news": "Новость",
  "question": "Опрос"
}

const shortenTime = time => {
  return moment(time).format('DD MMMM HH:mm');
};

const sendingTime = (n) => {
  if (n.status === NewsStatusType.Draft || n.status === NewsStatusType.ReadyToSend) {
    if (n.sendingTime.immediate) {
      return "Сразу"
    } else {
      return shortenTime(n.sendingTime.time);
    }
  }
  if (n.status === NewsStatusType.Sending) {
    return shortenTime(n.progress.startTime);
  }
  if (n.status === NewsStatusType.Published) {
    return shortenTime(n.progress.endTime);
  }
  return "fallback"
}

const sendingStatus = (n) => {
  switch (n.status) {
    case NewsStatusType.Draft:       return "Черновик";
    case NewsStatusType.ReadyToSend: return "Назначено к отправке";
    case NewsStatusType.Sending:     return `Отправляется (${n.progress.completed}/${n.progress.scheduled + n.progress.completed})`;
    case NewsStatusType.Published:   return `Опубликовано (${n.progress.completed})`;
  }
}

const NewsTable = props => {
  const { className, meetupId, news, handleDelete, handleSendNow, handleReport, ...rest } = props;

  const classes = useStyles();

  if (!news) {
    return null
  }

  const textForRecipients = (it) => {
    let ret = ""
    const rc = it.recipients
    if (rc) {
      if (rc.registered !== null && rc.registered.length > 0) {
        ret += "Регистрация: " + rc.registered.join(", ")
      }
      if (rc.groups !== null && rc.groups.length > 0) {
        ret += "Группа: " + rc.groups.join(", ")
      }
      if (rc.statuses !== null && rc.statuses.length > 0) {
        if (ret.length > 0) {
          ret += "\n"
        }
        ret += "Статус: " + rc.statuses.join(", ")
      }
      if (rc.tags !== null && rc.tags.length > 0) {
        if (ret.length > 0) {
          ret += "\n"
        }
        ret += "Тэги: " + rc.tags.join(", ")
      }
    }
    let count = rc.preliminaryCount;
    if (it.status !== NewsStatusType.Draft) {
      count = it.progress.scheduled + it.progress.completed;
    }

    if (ret.length === 0) {
      return `Все (${count})`
    } else {
      ret += "\n"
      ret += `(${count})`
    }

    return ret;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Тип</TableCell>
                <TableCell>Время отправки</TableCell>
                <TableCell>Текст</TableCell>
                <TableCell>Получатели</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {news.map((it, i) => (
                <TableRow className={classes.tableRow} hover key={it.newsId}>
                  <TableCell style={{ width: '120px' }}>
                      <Typography>{NewsTypes[it.type]}</Typography>
                  </TableCell>
                  <TableCell style={{width: '200px' }}>
                    <ListItemText primary={sendingTime(it)} secondary={sendingStatus(it)} />
                  </TableCell>
                  <TableCell>
                    <RouterLink to={'/m/' + meetupId + '/news/id/' + it.newsId}>
                      <Typography style={{fontWeight:"bold"}}>{(it.title || it.text || "Без текста").replace(/(<([^>]+)>)/gi, "")}</Typography>
                      {it.title && it.text &&
                        <Typography variant={"subtitle2"}>{it.text.replace(/(<([^>]+)>)/gi, "")}</Typography>
                      }
                    </RouterLink>
                  </TableCell>
                  <TableCell style={{ width: '200px' }}>
                    <Typography style={{whiteSpace: "pre-wrap"}}>{textForRecipients(it)}</Typography>
                  </TableCell>

                  <TableCell style={{ textAlign: 'center', width: '100px' }}>
                    <DropDownMenu data-test-id="merch-menu">
                      <MenuItem>
                        <RouterLink to={'/m/' + meetupId + '/news/id/' + it.newsId} data-test-id="news-menu-edit">
                          <ListItemText primary='Редактировать' />
                        </RouterLink>
                      </MenuItem>
                      {/*{(it.status === "draft") && (*/}
                      {/*  <MenuItem onClick={() => handleSendNow(meetupId, it.newsId)} data-test-id="news-menu-send-now">*/}
                      {/*    <ListItemText primary='Отправить сейчас' />*/}
                      {/*  </MenuItem>*/}
                      {/*)}*/}
                      {(it.type === "question") && (
                        <MenuItem onClick={() => handleReport(meetupId, it.newsId)} data-test-id="news-menu-results">
                          <ListItemText primary='Результаты' />
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => handleDelete(meetupId, it.newsId)} data-test-id="news-menu-delete">
                        <ListItemText primary='Удалить' />
                      </MenuItem>
                    </DropDownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

NewsTable.propTypes = {
  className: PropTypes.string,
  news: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
};

export default NewsTable;
