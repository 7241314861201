import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { AppContext } from '../../../../../AppContext';
import { ECCommonErrorBlock } from '../../../../../components/Forms/ECCommonErrorBlock';
import { ECTextField } from '../../../../../components/Forms/ECTextField';
import { ECSelectField } from '../../../../../components/Forms/ECSelectField';
import {ECDateField} from "../../../../../components/Forms/ECDateField";
import {ECDateTimeField} from "../../../../../components/Forms/ECDateTimeField";
import {ECMultiSelectField} from "../../../../../components/Forms/ECMultiSelectField";
import moment from "moment";

const TimeSlotEditForm = props => {
  const { meetupId, form, initial } = props;
  const appContext = useContext(AppContext);

  const [halls, setHalls] = useState(null);
  const [speakers, setSpeakers] = useState(null);

  useEffect(() => {
    appContext.hallApi
      .listHalls(meetupId)
      .then(({ data }) => {
        setHalls(data);
      })
      .catch(appContext.errorHandler);

    appContext.speakerApi
      .listSpeakers(meetupId)
      .then(({ data }) => {
        setSpeakers(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId]);

  const shiftEndTime = (v) => {
    const v1 = moment(form.getValues()["startTime"])
    const v2 = moment(form.getValues()["endTime"])
    const diff = v2.diff(v1, 'minutes')
    const v3 = v.clone().add(diff, 'minutes')
    form.setValue("endTime", v3.toJSON());
  }

  if (!halls || !speakers) return null;

  return (
    <>
      <ECCommonErrorBlock form={form} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ECTextField label='Название' name='name' initial={initial} form={form} />
        </Grid>
        <Grid item xs={6}>
          <ECDateTimeField label='Начало' name='startTime' form={form} initial={initial} onChange={shiftEndTime} />
        </Grid>
        <Grid item xs={6}>
          <ECDateTimeField label='Окончание' name='endTime' form={form} initial={initial} />
        </Grid>
        <Grid item xs={6}>
          <ECSelectField
            label='Зал'
            name='hallId'
            options={halls}
            fid={it => it.hallId}
            defaultValue={(initial && initial.hall && initial.hall.hallId)}
            form={form}
          />
        </Grid>
        <Grid item xs={6}>
          <ECMultiSelectField
            label='Спикер'
            name='speakerIds'
            options={speakers}
            fid={it => it.speakerId}
            fname={it => it.firstName + " " + it.lastName}
            defaultValue={(initial && initial.speakers && initial.speakers.map(it => it.speakerId))}
            form={form}
          />
        </Grid>
        <Grid item xs={12}>
          <ECTextField label='Подробная информация' name='description' initial={initial} form={form}
                       multiline
                       rows={4}/>
        </Grid>
      </Grid>
    </>
  );
};

export default TimeSlotEditForm;
