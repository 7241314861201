import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { AppContext } from '../../../../AppContext';
import { ECCommonErrorBlock } from '../../../../components/Forms/ECCommonErrorBlock';
import { ECTextField } from '../../../../components/Forms/ECTextField';
import { ECSelectField } from '../../../../components/Forms/ECSelectField';

const GroupEditForm = props => {
  const { meetupId, form, initial } = props;
  const appContext = useContext(AppContext);

  const [badges, setBadges] = useState([]);

  useEffect(() => {
    appContext.badgeApi
      .listBadges(meetupId)
      .then(({ data }) => {
        setBadges(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId]);

  if (badges.length === 0) return null;

  return (
    <>
      <ECCommonErrorBlock form={form} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ECTextField label='Название' name='name' initial={initial} form={form} />
        </Grid>
        <Grid item xs={12}>
          <ECSelectField
            label='Бейдж'
            name='badgeId'
            options={badges}
            fid={it => it.badgeId}
            initial={initial}
            defaultValue={badges[0].badgeId}
            form={form}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GroupEditForm;
