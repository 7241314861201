import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en:{
    register:"Register",
    registrationOnTitle:"Registration for \"{meetupName}\"",
    registration:"Registration",
    errors: {
      INTERNAL_SERVER_ERROR: "Internal server error",
      AUTHENTICATION_REQUIRED: "Authentication required",
      INVALID_ACCESS_TOKEN: "Invalid access token",
      INVALID_ACCESS_TOKEN_FOR_ACCOUNT: "Access token and accountId don't match",
      ACCOUNT_INACTIVE: "Account is disabled. Contact the support service",
      SESSION_EXPIRED: "Session expired. Authentication required",
      AUTH_FAILED: "Authentication failed: invalid email or password",
      AUTH_FAILED_RECEPTION: "Authentication failed: invalid pin",
      DESK_UNAVAILABLE_DAY: "Desk is inactive today",
      UNSUPPORTED_MEDIA_TYPE: "Unsupported media type {type}, supported types: {supported}",
      HANDLER_NOT_FOUND: "No handler found for uri {uri} and method  {method}",
      RESOURCE_NOT_FOUND: "No resource found for type={entity}, id={id} and uri={uri}",
      DATA_INTEGRITY_VIOLATION: "Data integrity violation",
      EMAIL_ALREADY_EXIST: "A user with this email address is already registered",
      VALIDATION_EMAIL: "Incorrect email format",
      EMAIL_ALIASED: "Incorrect email format. Don't use name+suffix format",

      VALIDATION_MUST_BE_NULL: "Field must be null",
      VALIDATION_MUST_BE_EQUAL_TO_PATH: "Field value must be equal to the path value",
      VALIDATION_MUST_NOT_BE_NULL: "Field is required",
      VALIDATION_MUST_NOT_BE_NULL_AND_EMPTY: "Field must not be empty",
      VALIDATION_ENTITY_NOT_FOUND: "Not found",
      QR_TOKEN_SHOULD_CONTAINS_LETTERS_AND_DIGITS: "Only numbers and letters are allowed in QR code",
      QR_TOKEN_SHOULD_BE_UNIQUE: "QR code must be unique",
      VALIDATION_IMAGE_NOT_FOUND: "Image not found",
      VALIDATION_IMAGE_SIZE: "Image size must be less than {size} Mb",
      VALIDATION_IMAGE_TYPE: "Wrong image format. Only {format} is supported",
      VALIDATION_IMAGE_RESOLUTION: "Max image resolution is {resolution}",
      VALIDATION_IMAGE_CANNOT_READ: "Cannot process image",
      VALIDATION_MIN_LENGTH: "Minimum length is {min} symbols",
      VALIDATION_MAX_LENGTH: "Maximum length is {max} symbols",

      VALIDATION_MUST_BE_ISO_TIME: "Field must contain time in ISO format, for example: 2019-12-25T22:22:22Z",
      VALIDATION_MUST_BE_TIME_IN_FUTURE: "Field value must be a time in the future",
      VALIDATION_DAYS_PERIOD_EXCEEDED: "Desk period of activity cannot exceed {max} days",
      VALIDATION_INCORRECT_TIME: "The value does not meet the constraints",
      VALIDATION_INCORRECT_VALUE: "The value does not meet the constraints",
      VALIDATION_INCORRECT_AMOUNT: "Amount for changing the balance is in incorrect format",
      VALIDATION_MIN: "Min allowed value is  {min}",
      VALIDATION_MAX: "Max allowed value is {max}",
      VALIDATION_PHONE: "Phone number formatted incorrectly",
      VALIDATION_PATTERN: "Value does not match the allowed pattern ",
      VALIDATION_JSON_FORMAT: "Value does not match allowed JSON-format",
      VALIDATION_SYSTEM_FIELD_REQUIRED: "System field {systemField} must be presented in the fields array",
      VALIDATION_DUPLICATE_FIELD: "Field with variable name {name} already exists",
      VALIDATION_USER_DOESNT_MATCH_ACCOUNT: "AccountId and userId do not match",

      NO_PERMISSION: "No permission to access resource. Permission required: {permission}",
      NO_FEATURE: "No permission to access resource. Permission required: {feature}",
      DESK_LIMIT_EXCEEDED: "Cannot log in to the desk {deskName} due to tariff limits. Contact the administrator.",
      DESKS_LIMIT_EXCEEDED:
    "Desks limit exceeded (allowed: {max}, already used this month {current})." +
    " Upgrade to a different tariff plan to add more desks.",
      VISITOR_LIMIT_EXCEEDED:
    "Visitors limit exceeded (allowed: {max}, already used this month {current})." +
    " Upgrade tariff plan to add more visitors.",
      VISITOR_LIMIT_EXCEEDED_ON_IMPORT:
    "Visitors limit exceeded (tried to upload {count}, already used this month: " +
    "{current}, allowed: {max}). Upgrade tariff plan to upload more visitors",
      SMS_LIMIT_EXCEEDED:
    "SMS limit exceeded (allowed: {max}, already used this month {current})." +
    " Upgrade tariff plan to send more SMS",
      MEETUPS_LIMIT_EXCEEDED:
    "Meetups limit exceeded (allowed: {max}, already used this month {current})." +
    "  Upgrade tariff plan to add more meetups",
      INSUFFICIENT_FUNDS: "Inadequate account balance. Price: {price}, balance: {balance}",
      VISITOR_DUPLICATE: "Visitor already exist",
      VISITORS_DUPLICATE: "Violation of the uniqueness conditions",
      INCORRECT_PAYMENT_DESCRIPTION: "Payment description contains prohibited symbols",
      INCORRECT_REDIRECT_URL: "Redirect link must not lead to third-party resource",
      PAYMENT_DATA_INTEGRITY_VIOLATION: "Payment data integrity violation",
      PAYMENT_CANT_BE_PROCESSED: "Payment can not be processed",
      PAYMENT_WITH_INCORRECT_GOAL: "Payment goal is incorrect",
      PAYMENT_AMOUNT_DONT_MATCH_PRICE: "Payment not equal to purchase price",
      PAYMENT_AMOUNT_INCORRECT: "Payment amount must be a positive number",
      PRICE_INCORRECT: "Price must be a positive number",
      CARD_IN_TIMEOUT: "So far, no background payments are possible, please try again later",
      LINKED_CARD_DOESNT_EXIST: " No card attached to the account ",
      NO_VISITOR_FOR_EMAIL: "So far, there are no visitors to create a email",
      CANNOT_HIDE_REQUIRED_FIELD: "Required field {fields} cannot be hidden from the registration form",
      CANNOT_CREAT_CHAT_DEMO: "Chat cannot be added to demo account",
    }
  },
  ru: {
    register:"Зарегистрироваться",
    registrationOnTitle:"Регистрация на \"{meetupName}\"",
    registration:"Регистрация",
    errors: {
    }
  }
}, {logsEnabled: false});

export default strings;
