import React, { useContext, useState } from 'react';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import UserEditForm from '../UserEditForm/UserEditForm';

const UserAdd = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);

  const [data, setData] = useState({
    email: '',
    displayName: '',
    password: '',
    password2: '',
    active: true,
  });
  const [errors, setErrors] = useState([]);

  const handleDataChange = (data, isValid) => {
    setData(data);
  };

  const handleSave = () => {
    appContext.userApi
      .createUser(data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(error => {
        const data = error.response.data;
        setErrors(data.errors || [data]);
      });
  };

  return (
    <ModalDialog
      title={'Добавление администратора'}
      okButtonText={'Сохранить'}
      handleOk={handleSave}
      handleClose={goBack}
    >
      <UserEditForm data={data} errors={errors} handleDataChange={handleDataChange} />
    </ModalDialog>
  );
};

UserAdd.propTypes = {};

export default UserAdd;
