import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import { renderBadgePDF_ToDataUrl } from '../../../BadgeDetails/components/BadgeView/BadgeView';
import { saveAs } from 'file-saver';
import DropDownMenu from '../../../../components/DropDownMenu/DropDownMenu';
import PrintVisitors from '../../../VisitorList/components/VisitorsToolbar/PrintVisitors';
import ColorPicker from 'components/ColorPicker/ColorPicker'
import { HelpHint } from 'components/HelpHint/HelpHint'

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
  hint: {
    marginLeft: 10,
    height: 16,
    width: 16,
    minHeight: 16,
  }
}));

const colors = [
  '#79CDFF',
  '#7DE9D9',
  '#7FE4AC',  
  '#FFE19D',
  '#FFFFFF',
  '#B985FF',
  '#FF90D2',
  '#FF7582',
  '#FFA787',
  '#DFDFDF'
];

const GroupsTable = props => {
  const { className, meetupId, groups, handleDelete, currentColors, handleChangeColor, ...rest } = props;

  const classes = useStyles();

  const appContext = useContext(AppContext);
  const name = useRef('');
  const [printVisitors, setPrintVisitors] = useState([]);


  const handleGroupPrint = async (meetupId, groupId) => {
    const { data: meetupData } = await appContext.meetupApi.getMeetup(meetupId);
    const { data: visitors } = await appContext.visitorApi.listVisitors(meetupId, groupId);

    //    This piece of code will build a zip-file with separate pdf documents
    //
    // let zip = new JSZip();
    //
    // for (let index = 0; index < visitors.length; index++) {
    //   let v = visitors[index];
    //
    //   const blob = await renderBadgePDF_ToBlob(badgeData, {...v, group: groupData}, false);
    //
    //   const filename = v.firstName + "_" + v.lastName + ".pdf";
    //
    //   zip.file(filename, blob, {base64: true});
    // }
    //
    // let content = await zip.generateAsync({type:"blob"});
    // saveAs(content, meetupData.name + "__" + groupData.name + ".zip");

    /*for (let index = 0; index < visitors.length; index++) {
      visitors[index] = {...visitors[index], group:groupData};
    }
    const blob = await renderBadgePDF_ToDataUrl(badgeData, visitors, false);*/

    name.current = meetupData.name;
    setPrintVisitors(visitors);
  };

  if (!currentColors || !groups) {
    return null
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      {printVisitors.length > 0 ? (
        <PrintVisitors
          printVisitors={printVisitors}
          meetupId={meetupId}
          setPrintVisitors={setPrintVisitors}
          saveAs={true}
          saveName={name.current}
        />
      ) : null}
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Бейдж</TableCell>
                <TableCell>Количество посетителей</TableCell>
                <TableCell>
                  Цвет группы
                  <HelpHint className={classes.hint} iconSize={17}>
                    На стойке регистратора посетители будут подсвечиваться цветом своей группы. Это поможет администратору стойки не ошибиться с выбором цвета бейджа, который нужно выдать посетителю.
                  </HelpHint>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map((group, i) => (
                <TableRow className={classes.tableRow} hover key={group.groupId}>
                  <TableCell>
                    <RouterLink to={'/m/' + meetupId + '/group/id/' + group.groupId}>
                      <ListItemText primary={group.name} />
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <RouterLink to={'/m/' + meetupId + '/badge/id/' + group.badgeId}>
                      <ListItemText primary={group.badgeName} />
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={group.visitorsCount} />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '200px' }} >
                    <ColorPicker 
                      colors={colors}
                      currentColor={currentColors[i]}
                      setCurrentColor={(color) => { handleChangeColor(i, color)}}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '100px' }}>
                    <DropDownMenu data-test-id="group-menu">
                      <MenuItem>
                        <RouterLink to={'/m/' + meetupId + '/group/id/' + group.groupId} data-test-id="group-menu-edit">
                          <ListItemText primary='Редактировать' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(meetupId, group.groupId)} data-test-id="group-menu-delete">
                        <ListItemText primary='Удалить' />
                      </MenuItem>
                      <MenuItem onClick={() => handleGroupPrint(meetupId, group.groupId)} data-test-id="group-menu-download-badges">
                        <ListItemText primary='Скачать все бейджи' />
                      </MenuItem>
                    </DropDownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div id={'temp-for-print'} style={{ position: 'absolute', left: '-9999px' }}></div>
        </div>
      </CardContent>
    </Card>
  );
};

GroupsTable.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
};

export default GroupsTable;
