import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../AppContext';
import FieldEditForm from '../FieldEditForm/FieldEditForm';
import { FieldType } from '../../../api';


function generateTextFieldName(fields) {
  let fieldNames = fields.map(field => (field.systemField || field.name))
  let i = -1;
  do {
    ++i;
  } while(fieldNames.includes('textField' + (i ? (i) : '')));

  return 'textField' + (i ? (i) : '')
}

const FieldAdd = props => {
  const { goBack, fields, handleSendField } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  
  let uniqueTextFieldName =  generateTextFieldName(fields)

  const [data, setData] = useState({
    systemField: undefined,
    name: uniqueTextFieldName,
    label: 'Текстовое поле',
    type: FieldType.Text,
    sizeOnCard: 12,
    required: false,
    capitalLetter: false
  });
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState({});

  const handleDataChange = (data, isValid) => {
    setData(data);
  };

  const handleSave = () => {
    if (!data.systemField) {
      data.systemField = undefined;
    }
    if (!data.type) {
      data.type = undefined;
    }

    handleSendField([...fields, data]).then(res => {
      if (res.error) {
        if (res.errors) {
          setErrors(res.errors)
        } else {
          setErrors([res]);
        }
      } else {
        setErrors([]);
        appContext.history.push('/m/' + meetupId + '/settings');
      }
    });
  };

  return (
    <ModalDialog title={'Добавление поля'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <FieldEditForm data={data} errors={errors} handleDataChange={handleDataChange} idx={fields.length} fields={fields} uniqueTextFieldName={uniqueTextFieldName}/>
    </ModalDialog>
  );
};

FieldAdd.propTypes = {};

export default FieldAdd;
