import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import ErrorBlock from '../../../../components/ErrorBlock/ErrorBlock';
import { Grid } from '@material-ui/core';
import { convertErrors, MyFormTextField } from '../../../MeetupList/components/MeetupEditForm/MeetupEditForm';
import validate from 'validate.js';

const schema = {};

export function makeid(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const AccountAdd = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);

  const [errors, setErrors] = useState([]);

  const [formState, setFormState] = useState({
    values: {
      ownerPassword: makeid(12),
    },
    errors: {},
    touched: {},
    isValid: true,
  });

  const handleSave = () => {
    appContext.accountApi
      .createAccount(formState.values)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(error => {
        const data = error.response.data;
        setErrors(data.errors || [data]);
      });
  };

  useEffect(() => {
    if (errors) {
      setFormState({
        ...formState,
        ...convertErrors(errors),
      });
    }
  }, [errors]);

  const handleChange = (name, value) => {
    formState.values[name] = value;
    formState.touched[name] = true;

    const errors = validate(formState.values, schema);

    setFormState(formState => {
      return {
        ...formState,
        isValid: !errors,
        errors: errors || {},
      };
    });
  };

  const error = field => {
    if (!formState.touched || !formState.errors) {
      return null;
    }
    if (formState.touched[field] && formState.errors[field]) {
      return formState.errors[field];
    } else {
      return null;
    }
  };

  const form = {
    // form values bind helper
    data: formState,
    handleChange: handleChange,
    error: error,
  };
  return (
    <ModalDialog title={'Добавление аккаунта'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <ErrorBlock message={formState.error} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MyFormTextField name={'accountName'} label='Название' required {...form} />
        </Grid>
        <Grid item xs={12}>
          <MyFormTextField name={'ownerEmail'} label='E-mail владельца' required {...form} />
        </Grid>
        <Grid item xs={12}>
          <MyFormTextField name={'ownerDisplayName'} label='Имя владельца' required {...form} />
        </Grid>
        <Grid item xs={12}>
          <MyFormTextField name={'ownerPassword'} label='Пароль владельца' required {...form} />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

AccountAdd.propTypes = {};

export default AccountAdd;
