import { MenuItem, TextField, Select } from '@material-ui/core';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { valueByName } from './form_utils';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {RegFormType} from "../../api";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
}))

export const ECRadioGroupField = props => {
  const { name, options, form, defaultValue, initial, onchange, ...rest } = props;
  const classes = useStyles();

  const fname =
    props.fname ||
    (it => {
      return it.name;
    });
  const fid =
    props.fid ||
    (it => {
      return it.id;
    });

  const [value, setValue] = useState(valueByName(initial, name) || defaultValue || null);

  useEffect(() => {
    form.register({ name: name });
    form.setValue(name, value);
  }, [form.register]);

  const handleChange = (e) => {
    form.setValue(name, e.target.value);
    setValue(e.target.value);
    form.clearError(name)
    onchange && onchange(e.target.value)
  }

  return (
    <RadioGroup {...rest} name="formType" value={value} onChange={handleChange}>
      {options.map(it => (
        <FormControlLabel key={fid(it)} value={fid(it)} control={<Radio />} label={fname(it)}/>
      ))}
    </RadioGroup>
  );
};

ECRadioGroupField.propTypes = {
  name: PropTypes.string,

  options: PropTypes.array,
  fname: PropTypes.func,
  fid: PropTypes.func,

  form: PropTypes.object,

  defaultValue: PropTypes.any,
  initial: PropTypes.any,
};
