import React, { useState, useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { AppContext } from '../../AppContext';
import SAInnerMenu from '../../components/SAInnerMenu/SAInnerMenu';
import PaymentsTable from './components/PaymentsTable/PaymentsTable';
import PaymentView from './components/PaymentView/PaymentView';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const PaymentList = props => {
  const appContext = useContext(AppContext);

  const classes = useStyles();

  const [refreshCounter, setRefresh] = useState(0);
  const [payments, setPayments] = useState([]);
  useEffect(() => {
    appContext.accountApi
      .listAllPayments()
      .then(({ data }) => {
        setPayments(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const goBack = () => {
    appContext.history.push('/sa/payment');
  };

  return (
    <div className={classes.root}>
      <SAInnerMenu />

      <PaymentsTable payments={payments} />

      <Route path={'/sa/payment/:accountId/:paymentId'} exact>
        <PaymentView goBack={goBack} />
      </Route>
    </div>
  );
};

export default PaymentList;
