import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { renderBadgePDF_ToDataUrl } from '../../../../BadgeDetails/components/BadgeView/BadgeView';
import { saveAs } from 'file-saver';
import DropDownMenu from '../../../../../components/DropDownMenu/DropDownMenu';
import PrintVisitors from '../../../../VisitorList/components/VisitorsToolbar/PrintVisitors';
import ColorPicker from 'components/ColorPicker/ColorPicker'
import { HelpHint } from 'components/HelpHint/HelpHint'
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
  hint: {
    marginLeft: 10,
    height: 16,
    width: 16,
    minHeight: 16,
  },
  photo: {
    maxWidth: '70px',
    maxHeight: '70px',
    borderRadius: '7%',
  },
}));

const SpeakersTable = props => {
  const { className, meetupId, speakers, handleDelete, ...rest } = props;

  const classes = useStyles();

  if (!speakers) {
    return null
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell>Компания</TableCell>
                <TableCell>Должность</TableCell>
                <TableCell>Фото</TableCell>
                <TableCell>Выступления</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {speakers.map((speaker, i) => (
                <TableRow className={classes.tableRow} hover key={speaker.speakerId}>
                  <TableCell>
                    <RouterLink to={'/m/' + meetupId + '/program/speaker/id/' + speaker.speakerId}>
                      <Typography style={{fontWeight:"bold"}}>{speaker.firstName + " " + speaker.lastName}</Typography>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={speaker.company} />
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={speaker.position} />
                  </TableCell>
                  <TableCell>
                    {speaker.imageId &&
                    <img className={classes.photo} src={'/api/image/' + speaker.imageId} alt={"Фото"}/>
                    }
                  </TableCell>
                  <TableCell>
                    {speaker.timeSlots.map(s => (
                      <ListItemText primary={s.name} />
                    ))}
                  </TableCell>


                  <TableCell style={{ textAlign: 'center', width: '100px' }}>
                    <DropDownMenu data-test-id="speaker-menu">
                      <MenuItem>
                        <RouterLink to={'/m/' + meetupId + '/program/speaker/id/' + speaker.speakerId} data-test-id="speaker-menu-edit">
                          <ListItemText primary='Редактировать' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(meetupId, speaker.speakerId)} data-test-id="speaker-menu-delete">
                        <ListItemText primary='Удалить' />
                      </MenuItem>
                    </DropDownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

SpeakersTable.propTypes = {
  className: PropTypes.string,
  speakers: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
};

export default SpeakersTable;
