import {makeStyles} from "@material-ui/styles";
import {Button, Checkbox, Grid, Link, Paper, Typography} from "@material-ui/core";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {FieldType, SystemFieldType} from "../../api";
import {fieldFullName, fieldKey} from "../VisitorList/components/ImportXLS/import_utils";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SystemField from "../VisitorList/components/VisitorEditForm/fields/SystemField";
import ExtendedField from "../VisitorList/components/VisitorEditForm/fields/ExtendedField";
import strings from "../../strings";
import useTheme from "@material-ui/core/styles/useTheme";
import {Link as RouterLink} from "react-router-dom";
import {PolicyForRegForm} from "./PolicyForRegForm";

const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    ["@media (max-width:700px)"] : {
      borderRadius: 0,
      height: '100%'
    },
  },
  space: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const VisitorEditForm = forwardRef((props, ref) => {
  const { data: initialData, fields, showStatus, errors, fieldsToHide, groups } = props;
  const classes = useStyles();
  const translitRefs = {};

  const isTransliterationField = name => typeof name === 'string' && name.startsWith('transliteration');

  const refs = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),];

  let shift = 0;
  fields.forEach((f, i) => {
    if (fieldsToHide.indexOf(fieldFullName(f)) !== -1) {
      shift += 1;
    }
    if (f.type === FieldType.Transliteration) {
      fields.forEach((f2, j) => {
        if (f.fieldForTranslit === fieldFullName(f2)) {
          translitRefs[f.fieldForTranslit] = i - shift;
        }
      });
    }
  })

  useImperativeHandle(ref, () => ({
    fillValue: data => {
      return Promise.all(
        refs.map(ref => {
          if (ref.current) {
            return ref.current.fillValue(data);
          } else {
            return true;
          }
        })
      ).then(res => res.reduce((acc, item) => acc && item, true));
      // возвращаем флаг корректности и сами данные
    },
  }));

  const topFieldError = () => {
    const registeredFields = {};
    fields
      .filter(f => f.systemField !== SystemFieldType.Photo && f.systemField !== SystemFieldType.Group)
      .map(f => (registeredFields[fieldFullName(f)] = true));

    return Object.keys(errors || {})
      .filter(e => !registeredFields[e])
      .map(k => {
        return (k !== 'undefined' ? k + ': ' : '') + errors[k];
      })
      .join(', \n');
  };

  const onChange = (field) => (value, inputRef) => {
    const fn = fieldFullName(field);
    if (field.capitalLetter && value.length > 0) {
      if (inputRef) {
        inputRef.current.value = value[0].toUpperCase() + value.slice(1);
      }
    }
    if (translitRefs[fn]) {
      refs[translitRefs[fn]].current.transliterate(inputRef.current.value);
    }
  };

  return (
    <div>
      <ErrorBlock message={topFieldError()} />
      <Grid container spacing={2}>
        {fields
          .filter((f) => { return fieldsToHide.indexOf(fieldKey(f)) === -1 })
          .map((f, i) => (
            <Grid item xs={12} key={fieldKey(f)}>
              {f.systemField ? (
                <SystemField
                  innerRef={refs[i]}
                  autoFocus={i === 0}
                  field={f}
                  data={initialData}
                  errors={errors}
                  onChange={onChange(f)}
                  groups={groups}
                />
              ) : (
                <
                  ExtendedField
                  innerRef={refs[i]}
                  autoFocus={i === 0}
                  field={f}
                  data={initialData}
                  errors={errors}
                  onChange={onChange(f)}
                />
              )}
            </Grid>
          ))}
      </Grid>
    </div>
  );
});


export const FlatRegistrationForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {regFormData, formRef, handleSave, errors} = props;

  const [policyCheckbox, setPolicyCheckbox] = useState(false)

  return (
    <Paper className={classes.paper} >
      <Typography variant={"h3"} className={classes.space}>{strings.formatString(strings.registrationOnTitle, { meetupName: regFormData.meetup.name })}</Typography>

      <VisitorEditForm data={{extended:{}}} errors={errors} ref={formRef} fields={regFormData.fields.fields.filter(f => f.systemField !== SystemFieldType.QrToken)} groups={regFormData.groups}
                       fieldsToHide={regFormData.regForm.fieldsToHide}/>

      <PolicyForRegForm value={policyCheckbox} onChange={(e) => {setPolicyCheckbox(e.target.checked)}}/>

      <Button color='primary' variant='contained' onClick={() => handleSave()} className={classes.space} style={{width:"100%"}} disabled={!policyCheckbox}>
        {strings.register}
      </Button>
    </Paper>
  );

};
