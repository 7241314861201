import React from 'react';
import { FieldType } from '../../../../../api/api';
import ExtendedTextField from './ExtendedTextField';
import ExtendedTranslitField from './ExtendedTranslitField';
import ExtendedChoiceField from "./ExtendedChoiceField";
import ExtendedMultiChoiceField from "./ExtendedMultiChoiceField";

const ExtendedField = props => {
  const { innerRef, field, data, autoFocus, errors, onChange } = props;

  if (field.type === FieldType.Text || field.type === FieldType.LimitedChoice) {
    return (
      <ExtendedTextField
        ref={innerRef}
        field={field}
        data={data}
        autoFocus={autoFocus}
        errors={errors}
        onChange={onChange}
      />
    );
  } else if (field.type === FieldType.Transliteration) {
    return (
      <ExtendedTranslitField
        ref={innerRef}
        field={field}
        data={data}
        autoFocus={autoFocus}
        errors={errors}
        onChange={onChange}
      />
    );
  } else if (field.type === FieldType.Choice) {
    return <ExtendedChoiceField ref={innerRef} field={field} data={data} autoFocus={autoFocus} errors={errors}
                                onChange={onChange}/>
  } else if (field.type === FieldType.MultiChoice) {
    return <ExtendedMultiChoiceField ref={innerRef} field={field} data={data} autoFocus={autoFocus} errors={errors} onChange={onChange}/>
  }
};

export default ExtendedField;
