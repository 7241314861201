import React, {useContext} from 'react';
import {useStyles} from "../RequestForChangePassword";
import {AppContext} from "../../../AppContext";

const ChangePasswordSuccess = () => {
  const appContext = useContext(AppContext);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <form className={classes.form} style={{height:"135px"}}>
            <div className={classes.title}>
              Ссылка для восстановления пароля отправлена на почту
            </div>
            <div className={classes.bottomText} style={{margin: 0}}>
              <p onClick={()=>appContext.history.push('/sign-in')} style={{cursor:"pointer"}}>Вход администратора</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordSuccess;
