import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {Button, Input, TextField, Typography, useTheme} from '@material-ui/core';
import { AppContext } from '../../AppContext';
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  contentContainer: {
    backgroundColor:"white",
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    backgroundColor:"white",
    height: "450px",
    width: "640px",
    padding:"30px",
    borderRadius: "8px",
  },
  formContainer: {
    height: "100%",
    padding: "20px  45px",
    border: "1px solid #ffffff",
    borderBottomColor: "#DEDEDE",
    borderTopColor: "#DEDEDE",
  },
  formInputsContainer:{
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  bottomText:{
    marginTop: "8px",
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#213955",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "16px",
  },
  title: {
    color: "#213955",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "28px",
    letterSpacing: "-0.06px",
  },
  form__link: {
    ["@media (max-width:768px)"] : {
      textAlign: 'center',
      fontSize: 20,
      margin: 25
    },
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignIn = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [inputType, setInputType] = useState("password")
  const [formState, setFormState] = useState({
    isValid: true,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const appContext = useContext(AppContext);

  const handleSignIn = event => {
    event.preventDefault();
    appContext.authApi
      .login({
        login: formState.values.email,
        password: formState.values.password,
      })
      .then(payload => {
        appContext.login(payload.data);
        appContext.history.push('/');
      })
      .catch(error => {
        const data = error.response.data;
        setFormState(formState => ({
          ...formState,
          errors: {
            email: [data.message],
            password: [data.message],
          },
        }));
      });
  };

  const showPassword=()=>{
    if (inputType === "password"){
      setInputType("text")
    }else {
      setInputType("password")
    }
  }
  const Redirect = (event) =>{
    // event.preventDefault()
    appContext.history.push('/request-change-password')
  }
  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <ErrorBlock message={formState.errors.token}/>
          <div className={classes.form}>
            <div style={{height: "100%"}}>
                <div style={{height:"28px", marginBottom:"24px", cursor:"pointer"}}>
                  <div className={classes.title}>
                    <div className={"text"} id={"sing-in"}>Вход администратора</div>
                  </div>
                </div>
                <div style={{height: "calc(100% - 145px)"}}>
                  <div className={classes.formContainer}>
                    <form className={classes.formInputsContainer} onSubmit={handleSignIn}>
                      <div style={{width:"100%", height: "100%"}}>
                        <TextField
                          className={classes.textField}
                          error={hasError('email')}
                          fullWidth
                          helperText={hasError('email') ? formState.errors.email[0] : null}
                          label='Адрес электронной почты'
                          name='email'
                          onChange={handleChange}
                          type='text'
                          value={formState.values.email || ''}
                          variant='outlined'
                        />
                        <TextField
                          className={classes.textField}
                          error={hasError('password')}
                          fullWidth
                          helperText={hasError('password') ? formState.errors.password[0] : null}
                          label='Пароль'
                          name='password'
                          onChange={handleChange}
                          type={inputType}
                          value={formState.values.password || ''}
                          variant='outlined'
                        />
                        <span style={{position: "relative"}}>
                          {inputType === "password"
                            ?
                            <VisibilityOff onClick={showPassword} style={{position:"absolute", top: 32,  right: 10, cursor: "pointer"}}/>
                            :
                            <Visibility onClick={showPassword} style={{position:"absolute", top: 32,  right: 10, cursor: "pointer" }}/>
                          }
                        </span>
                      </div>
                      <div  style={{width:"100%", height: "100%", marginTop: "4px"}}>
                        <Button
                          className={classes.signInButton}
                          color='primary'
                          fullWidth
                          size='large'
                          type='submit'
                          variant='contained'
                          data-test-id='sign-in-button'
                        >
                          Войти
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              <div className={classes.bottomText} style={{marginTop:"16px"}}>
                <p onClick={()=>appContext.history.push('/registration')} style={{cursor:"pointer"}}>Регистрация аккаунта</p>
              </div>
              <div className={classes.bottomText}>
                <p onClick={()=>appContext.history.push('/request-change-password')} style={{cursor:"pointer"}}>Восстановить пароль</p>
              </div>
              <div className={classes.bottomText}>
                <p onClick={()=>appContext.history.push('/sign-in-reception')} style={{cursor:"pointer", fontWeight: "bold"}}>Вход регистратора</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default SignIn;
