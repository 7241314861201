export const defaultFonts = [
  'Roboto',
  'Montserrat',
  'PT Sans',  
  'PT Serif'
];

export const fetchGoogleFonts =  async () => {
  let data = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GF_API_KEY}`);
  data = await data.json();
  return data;
}
const LOADED_FONTS = {}
export const loadFontToBrowser = (family, url) => {
  // if (document.fonts.check("12px " + family)) {
  //   console.log(`SKIP FONT LOADING ${family}`)
  // } else {
    if (LOADED_FONTS[family]) {
      // console.log(`SKIP2 FONT LOADING ${family}`)
    } else {
      LOADED_FONTS[family] = true;
      // console.log(`LOADING FONT ${family} from ${url}`)
      const font = new FontFace(family, `url(${url})`);
      return font.load().then(fontFace => {
        document.fonts.add(fontFace);
      })
        .catch(err => console.log(err))
    }
  // }
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const fetchFontTtf = async (url) => {
  let ttf = await fetch(url);
  ttf = await ttf.blob()
  ttf = await getBase64(ttf)
  
  return ttf;
}
