import React, { useContext, useEffect, useRef, useState } from 'react';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import { useParams } from 'react-router-dom';
import VisitorEditForm from '../VisitorEditForm/VisitorEditForm';
import useTheme from '@material-ui/core/styles/useTheme';
import {Button, Hidden} from '@material-ui/core';
import {
  buildBadgeDefaults,
  buildContentFromStage,
  createPdf,
  renderBadgePDF_ToBlob,
  renderToPrint,
} from '../../../BadgeDetails/components/BadgeView/BadgeView';
import print from 'print-js';
import {SystemFieldType, VisitorStatusType} from '../../../../api';
import { errorsToObject } from '../../../../components/Forms/form_utils';
import BadgeViewKonva from '../../../BadgeDetails/components/BadgeViewKonva/BadgeViewKonva';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {MeetupContext} from "../../../../MeetupContext";
import { debounce } from 'underscore';

const VisitorEditWithBadge = props => {
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const { visitorId } = useParams();
  const meetupId = useParams().mid || props.meetupId;
  const { goBack, saveAndCheckin, showStatus } = props;

  const [data, setData] = useState(undefined);
  const [errors, setErrors] = useState([]);
  const stageRef = useRef(null);

  useEffect(() => {
    setData(null);
    appContext.visitorApi.getVisitor(meetupId, visitorId).then(({ data }) => {
      setData(data);
    });
  }, [meetupId, visitorId]);

  const handleSave = async () => {
    const valid = await formRef.current.fillValue(data);
    if (valid) {
      // удалим те поля, которые нельзя обновлять
      delete data.groupName;
      if (!showStatus) {
        delete data.status;
      }
      if (saveAndCheckin) {
        data.status = data.status || {};
        data.status.status = VisitorStatusType.Come;
      }

      appContext.visitorApi
        .updateVisitor(meetupId, visitorId, data)
        .then(({ data }) => {
          goBack(data);
        })
        .catch(error => {
          setErrors(errorsToObject(error));
        });
    }
  };

  const [badgeData, setBadgeData] = useState(undefined);
  useEffect(() => {
    appContext.visitorApi.getAllData(meetupId, visitorId).then(({ data }) => {
      setBadgeData(data);
    });
  }, [visitorId]);

  const formRef = useRef();

  const handlePrint = async () => {
    // Подготавливаем объектную модель для PDF
    let dd = await buildBadgeDefaults(badgeData.badge, true, badgeData.visitor);
    // Тут нам надо подождать, пока компонент BadgeViewKonva закончит инициализацию,
    // в частности проверку шрифтов, и в нём срендерится Stage, на который нам нужна ссылка
    await new Promise(resolve => {
      const check = () => {
        if (stageRef.current != null) {
          resolve();
        } else {
          setTimeout(check, 100);
        }
      };
      check();
    });
    // Снимаем контент из Stage'а, т.е. с канвы, и перекладываем его в pfd
    dd = await buildContentFromStage(dd, stageRef.current);
    // Собственно рендерим pdf на основе её описания
    const doc = await createPdf(dd);
    renderToPrint(doc).then(data => {
      print({ printable: data, type: 'pdf', base64: true });
    });
    if (appContext.session.permissions.indexOf('reception') !== -1) {
      appContext.visitorApi.writeBadgeStatus(meetupId, visitorId); //требуется разрешение reception
    }

  };

  const [cropURL, setCropURL] = useState();
  const cropFromBlob = (blob) => {
    window.URL.revokeObjectURL(cropURL);
    setCropURL(window.URL.createObjectURL(blob));
  }

  const sm = useMediaQuery('(min-width:600px)');
  const md = useMediaQuery('(min-width:800px)');
  const lg = useMediaQuery('(min-width:1200px)');

  const dialogSize = lg ? 'lg' : (md ? 'md' : (sm ? 'sm' : 'xs'));

  const onChange = (field, value) => {
    debounce(() => {
      formRef.current.fillValue(data)
      setData({...data})
    }, 300)()
  }

  if (!data || !badgeData) {
    return null;
  }

  return (
    <ModalDialog
      title={'Редактирование участника'}
      okButtonText={saveAndCheckin ? 'Сохранить и отметить' : 'Сохранить'}
      size={ dialogSize }
      handleOk={handleSave}
      handleClose={goBack}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <VisitorEditForm 
            data={data /*initialData, don't update ever*/}
            onChange={onChange}
            errors={errors}
            ref={formRef}
            showStatus={showStatus}
            fields={meetupContext.fields.filter(f => showStatus || f.systemField !== SystemFieldType.QrToken)}
            
            cropFromBlob={cropFromBlob}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item lg={5}>
            <Grid container spacing={3}>
              <BadgeViewKonva
                visitor={data}
                group={badgeData.group}
                badge={badgeData.badge}
                style={{ minHeight: 358 }}
                getRef={ref => stageRef.current = ref}
                imagePreview={cropURL}
              />
            </Grid>
            <Button color='primary' variant='contained' onClick={handlePrint}>
              Печать
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    </ModalDialog>
  );
};

VisitorEditWithBadge.propTypes = {};

export default VisitorEditWithBadge;
