import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import { errorsToObject } from '../../../../components/Forms/form_utils';
import {MeetupContext} from "../../../../MeetupContext";
import VisitorEditForm from "../../../VisitorList/components/VisitorEditForm/VisitorEditForm";
import {VisitorSourceType, VisitorStatusType} from "../../../../api";

const RegistrationModal = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const meetupId = useParams().mid || props.meetupId;
  const [data, setData] = useState({
    extended: {}
  });
  const formRef = useRef();

  const [errors, setErrors] = useState({});

  const handleSave = async () => {
    const data = {};
    const valid = await formRef.current.fillValue(data);
    if (valid) {
      data.status = {
        source: VisitorSourceType.OnSite,
        status: VisitorStatusType.Come
      }
      appContext.visitorApi
        .createVisitor(meetupId, data)
        .then(({ data }) => {
          goBack(data);
        })
        .catch(error => {
          setErrors(errorsToObject(error));
        });
    }
  };


  return (
    <ModalDialog title={'Регистрация'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
        <VisitorEditForm data={data} errors={errors} ref={formRef} fields={meetupContext.fields} selfRegistration={true} />
    </ModalDialog>
  );
};

RegistrationModal.propTypes = {};

export default RegistrationModal;
