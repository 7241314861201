import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ECCommonErrorBlock } from '../../../components/Forms/ECCommonErrorBlock';
import { ECTextField } from '../../../components/Forms/ECTextField';
import {ECPhotoField} from "../../../components/Forms/ECPhotoField";
import {ECCheckboxField} from "../../../components/Forms/ECCheckboxField";
import Button from "@material-ui/core/Button";
import {ECSelectField} from "../../../components/Forms/ECSelectField";
import {fieldKey} from "../../VisitorList/components/ImportXLS/import_utils";
import {MeetupContext} from "../../../MeetupContext";
import Paper from "@material-ui/core/Paper";
import {ECQuillEditor} from "../../../components/Forms/ECQuillEditor";
import {AttachmentsList} from "../../OnlineRegistration/components/AttachmentsList";
import {makeStyles} from "@material-ui/styles";
import {NewsTypes} from "./NewsTable";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import {NewsType} from "../../../api";

const useStyles = makeStyles(theme => ({
  editor: {
    '& .ql-editor': {
      height: 200
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  spacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));


const NewsTypeForm = props => {
  const { form, initial } = props;
  const meetupContext = useContext(MeetupContext);
  const classes = useStyles();
  const [type, setType] = useState(NewsType.Notification)

  useEffect(() => {
    form.register({ name: "type" });
    form.setValue("type", type)
  }, [])

  return (
    <>
      <Grid container spacing={3}>
        <RadioGroup aria-label="type" name="type" value={type} onChange={e => {
          setType(e.target.value);
          form.setValue("type", e.target.value)
        }}>
          {Object.keys(NewsTypes).map(t =>
            <FormControlLabel value={t} control={<Radio />} label={NewsTypes[t]} key={t} />
          )}
        </RadioGroup>
      </Grid>
    </>
  );
};

export default NewsTypeForm;
