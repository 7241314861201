import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import InputIcon from '@material-ui/icons/Input';
import { AppContext } from '../../../../AppContext';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    ['@media (min-width: 0px) and (orientation: landscape)']: {
      minHeight: 56
    }

  },
  logo: {
    ["@media (max-width:700px)"] : {
      width: 120
    },
    ["@media (max-width:500px)"] : {
      width: 100
    },
    ["@media (max-width:375px)"] : {
      width: 80
    },
  },
  topLabel: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    color: 'inherit',
    ["@media (max-width:700px)"] : {
      fontSize: 16
    },
    ["@media (max-width:500px)"] : {
      fontSize: 12
    },
    ["@media (max-width:375px)"] : {
      fontSize: 10
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = props => {
  const { className, data, ...rest } = props;
  const theme = useTheme();

  const classes = useStyles();

  const appContext = useContext(AppContext);

  const handleSignOut = event => {
    event.preventDefault();
    appContext.logout();
    // history.push('/');
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color={theme.appBarColor} position='fixed'>
      <Toolbar>
        <RouterLink to='/reception'>
          <img alt='Logo' src={theme.topLogo} width={theme.topLogoWidth || '100%'}  className={classes.logo}/>
        </RouterLink>

        <div className={classes.flexGrow} />

        <Typography variant='h4' className={classes.topLabel} data-test-id='top-header'>
          {data.meetup.name} \ {data.deskName}
        </Typography>

        <IconButton className={classes.signOutButton} color='inherit' onClick={handleSignOut}>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
