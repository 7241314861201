import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../../AppContext';
import HallsToolbar from './components/HallsToolbar/HallsToolbar';
import HallsTable from './components/HallsTable/HallsTable';
import { Route, useParams } from 'react-router-dom';
import HallAdd from './components/HallAdd/HallAdd';
import HallEdit from './components/HallEdit/HallEdit';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const HallList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  // retrieve users list
  const [refreshCounter, setRefresh] = useState(0);
  const [halls, setHalls] = useState([]);

  useEffect(() => {

    appContext.hallApi
      .listHalls(meetupId)
      .then(({ data }) => {
        setHalls(data)
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const handleDelete = (meetupId, hallId) => {
    appContext.hallApi.deleteHall(meetupId, hallId).then(() => {
      setHalls(halls => halls.filter(u => u.hallId !== hallId));
    });
  };

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/program/hall');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };


  return (
    <div className={classes.root}>
      <HallsToolbar meetupId={meetupId} />
      <HallsTable
        meetupId={meetupId} 
        halls={halls}
        handleDelete={handleDelete} 
      />

      <Route path={'/m/:mid/program/hall/add'} exact>
        <HallAdd goBack={backAndRefresh} />
      </Route>

      <Route path={'/m/:mid/program/hall/id/:hallId'} exact>
        <HallEdit goBack={backAndRefresh} />
      </Route>
    </div>
  );
};

export default HallList;
