import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { AppContext } from '../../../../AppContext';

const useStyles = makeStyles(theme => ({}));

const ProfileInnerMenu = props => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const valueFromUrl = () => {
    const path = appContext.history.location.pathname;
    if (path.indexOf('profile/account') !== -1) {
      return 'account';
    }
    if (path.indexOf('profile/user') !== -1) {
      return 'user';
    }
    if (path.indexOf('profile/log') !== -1) {
      return 'log';
    }
    return '';
  };

  return (
    <Fragment>
      <Tabs
        className={classes.tabs}
        onChange={() => {}}
        scrollButtons='auto'
        value={valueFromUrl()}
        centered
      >
        <Tab
          label={'Тарифы и оплата'}
          value={'account'}
          onClick={() => {
            appContext.history.push('/profile/account');
          }}
        />
        {/*<Tab*/}
        {/*  label={'Пользователь'}*/}
        {/*  value={'user'}*/}
        {/*  onClick={() => {*/}
        {/*    appContext.history.push('/profile/user');*/}
        {/*  }}*/}
        {/*/>*/}
        <Tab
          label={'Журнал действий'}
          value={'log'}
          onClick={() => {
            appContext.history.push('/profile/log');
          }}
        />
      </Tabs>
      <Divider className={classes.divider} />
    </Fragment>
  );
};

export default ProfileInnerMenu;
