import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import { Route } from 'react-router-dom';
import UserAdd from './components/UserAdd/UserAdd';
import UserEdit from './components/UserEdit/UserEdit';
import UsersToolbar from './components/UsersToolbar/UsersToolbar';
import UsersTable from './components/UsersTable/UsersTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const UserList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  // retrieve users list
  const [refresh, setRefresh] = useState(0);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    appContext.userApi
      .listUsers()
      .then(({ data }) => {
        setUsers(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleDelete = userId => {
    appContext.userApi.deleteUser(userId).then(() => {
      setUsers(users => users.filter(u => u.userId !== userId));
    });
  };

  const backAndRefresh = data => {
    appContext.history.push('/user');
    if (data) {
      setRefresh(refresh + 1);
    }
  };

  return (
    <div className={classes.root}>
      <UsersToolbar />
      <UsersTable users={users} handleDelete={handleDelete} />

      <Route path='/user/add' exact>
        <UserAdd goBack={backAndRefresh} />
      </Route>

      <Route path='/user/id/:userId' exact>
        <UserEdit goBack={backAndRefresh} />
      </Route>
    </div>
  );
};

export default UserList;
