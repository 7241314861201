import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import RedoIcon from '@material-ui/icons/Redo';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { AppContext } from 'AppContext' 

const useStyles = makeStyles(theme => ({
  root: {},
  imageContainer: {
    height: 100,
    margin: '0 10px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    // width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center',
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
  actionIconContainer: {
    marginBottom: 10,
    cursor: 'pointer'
  },
  actionIcon: {
    color: '#4D6382',
    width: 22,
    height: 22
  },
  actions: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const BadgeCard = props => {
  const { className, badge, handleDelete, meetupId, setBadges, setBadgeToShare, ...rest } = props;

  const classes = useStyles();
  const appContext = useContext(AppContext)

  const handleCopy = () => {
    appContext.badgeApi
      .getBadge(badge.badgeId)
      .then(({ data:newBadge }) => {
        return appContext.badgeApi.createBadge({...newBadge, name: 'Copy-' + newBadge.name, badgeId: null}, meetupId)
      })
      .then(({ data }) => {
        setBadges(badges => badges.concat(data))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const link = (meetupId ? '/m/' + meetupId : '') + '/badge/id/' + badge.badgeId;
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <div className={classes.actions}>
        <div onClick={() => handleCopy() }  className={classes.actionIconContainer}> 
            <FilterNoneIcon  className={classes.actionIcon}/>
          </div>
          <div onClick={() => setBadgeToShare(badge)} className={classes.actionIconContainer}>
            <RedoIcon className={classes.actionIcon} style={{transform: 'rotate(-30deg)'}} />
          </div>
          <div onClick={() => handleDelete(badge.badgeId)} className={classes.actionIconContainer}>
            <DeleteIcon className={classes.actionIcon} />
          </div>
        </div>
        <div style={{flexShrink: 0, flexGrow: 1}}>
          <RouterLink to={link}>
            <div className={classes.imageContainer}>
              <img
                alt='Preview'
                className={classes.image}
                src={'/api/image/' + (badge.previewImageId || badge.imageId)}
              />
            </div>
          </RouterLink>

          <RouterLink to={link}>
            <Typography align='center' gutterBottom variant='h4'>
              {badge.name}
            </Typography>
          </RouterLink>
        </div>
        {/*<Typography*/}
        {/*  align="center"*/}
        {/*  variant="body1"*/}
        {/*>*/}
        {/*  {badge.description}*/}
        {/*</Typography>*/}
      </CardContent>
      <Divider />
      {/*<CardActions>*/}
      {/*  <Grid*/}
      {/*    container*/}
      {/*    justify="space-between"*/}
      {/*  >*/}
      {/*    <Grid*/}
      {/*      className={classes.statsItem}*/}
      {/*      item*/}
      {/*    >*/}
      {/*      <AccessTimeIcon className={classes.statsIcon} />*/}
      {/*      <Typography*/}
      {/*        display="inline"*/}
      {/*        variant="body2"*/}
      {/*      >*/}
      {/*        Updated 2hr ago*/}
      {/*      </Typography>*/}
      {/*    </Grid>*/}
      {/*    <Grid*/}
      {/*      className={classes.statsItem}*/}
      {/*      item*/}
      {/*    >*/}
      {/*      <GetAppIcon className={classes.statsIcon} />*/}
      {/*      <Typography*/}
      {/*        display="inline"*/}
      {/*        variant="body2"*/}
      {/*      >*/}
      {/*        {product.totalDownloads} Downloads*/}
      {/*      </Typography>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</CardActions>*/}
    </Card>
  );
};

BadgeCard.propTypes = {
  className: PropTypes.string,
  badge: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
};

export default BadgeCard;
