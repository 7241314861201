import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../AppContext';
import { useParams } from 'react-router-dom';
import MerchEditForm from './MerchEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../components/Forms/form_utils';

export const fixDotProperties = (obj) => {
  let ret = {};
  Object.keys(obj).forEach(k => {
    if (k.indexOf(".") !== -1) {
      const k1 = k.substr(0, k.indexOf("."))
      const k2 = k.substr(k.indexOf(".") + 1, k.length)

      ret[k1] = ret[k1] || {};
      ret[k1][k2] = obj[k];
    } else {
      ret[k] = obj[k]
    }
  })
  return ret;
}

const MerchEdit = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId, merchId } = useParams();
  const { goBack } = props;
  const form = useForm();

  const [initial, setInitial] = useState(undefined);
  useEffect(() => {
    appContext.merchApi.getMerch(meetupId, merchId).then(({ data }) => {
      setInitial(data);
    });
  }, [meetupId, merchId]);

  const handleSave = () => {
    let data = form.getValues();
    data = fixDotProperties(data);
    data.handling = fixDotProperties(data.handling);

    data.meetupId = meetupId;
    data.merchId = merchId;
    appContext.merchApi
      .updateMerch(meetupId, merchId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  if (!initial) return null;

  return (
    <ModalDialog title={'Редактирование товара'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <MerchEditForm meetupId={meetupId} form={form} initial={initial} />
    </ModalDialog>
  );
};

MerchEdit.propTypes = {};

export default MerchEdit;
