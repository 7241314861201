import {Button, Grid, TextField} from '@material-ui/core';
import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {errorsToForm, valueByName} from './form_utils';
import {MeetupContext} from "../../MeetupContext";
import {AppContext} from "../../AppContext";
import {makeStyles} from "@material-ui/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloseIcon from "@material-ui/icons/Close";

import FormLabel from "@material-ui/core/FormLabel";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  outerBorder: {
    border: "1px solid rgba(0, 0, 0, 0.25)",
    boxSizing: "border-box",
    borderRadius: "4px",

    marginTop: "8px",
    marginBottom: "4px",
    position: "relative",
  },
  hover: {
    border: "1px solid rgba(0, 0, 0, 1)",
  },
  secondDiv: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 10
  },
  uploadIcon: {
    width: "48px",
    height: "48px",
    color: "rgba(0, 0, 0, 0.5)",
    cursor: "pointer",

    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  deleteIcon: {
    width: "16px",
    height: "16px",
    color: "rgba(0, 0, 0, 0.5)",
    cursor: "pointer",

    position: "absolute",
    right: 8,
    top: 8,
  },
  icon_hover: {
    color: "rgba(0, 0, 0, 1)",
  },
  photo: {
    position: "absolute",
    maxWidth: "100%",
    display: "block",
    borderRadius: "4px",

    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  fieldLabel: {
    opacity: "1",
    background: "white",
    paddingLeft:"6px",
    paddingRight:"6px",
    transform: "translate(14px, -6px) scale(0.75)",
    position: "absolute",
    transformOrigin: "top left",
    pointerEvents: "none",
    zIndex: 11
  },
  fieldError: {
    marginLeft: 14,
    marginTop: 9,
    fontSize: 11,
    display: "block"
  },
  redError: {
    borderColor: "red",
    color: "red"
  }
}));

export const ECPhotoField = props => {
  const { name, label, form, initial, height, ...rest } = props;
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const outerRef = useRef();

  const [value, setValue] = useState(valueByName(initial, name));
  const [error, setError] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    form.register({ name: name });
    form.setValue(name, value);
  }, [form.register]);

  const uploadImage = event => {
    const file = event.target.files[0];
    appContext.imageApi.uploadImage(meetupContext && meetupContext.meetupId, undefined, file)
      .then(({ data }) => {
        form.setValue(name, data.imageId);
        form.clearError(name)
        setValue(data.imageId);
        setError(false);
      })
      .catch(error => {
        const data = error.response.data;
        form.setError(name, data.error, data.message);
        setError(true);
      });
  };

  const deleteImage = () => {
    form.setValue(name, null);
    form.clearError(name)
    setValue(null);
    setError(false);
  }

  return (
    <>
      <input
        accept='image/*'
        className={classes.input}
        style={{ display: 'none' }}
        id={"input-file-" + name}
        type='file'
        onChange={uploadImage}
      />

      <div className={clsx(classes.outerBorder, error && classes.redError, (hover && !error) && classes.hover)}
           style={{height:height || "270px"}}
           ref={outerRef}
           onMouseOver={() => setHover(true)}
           onMouseOut={() => setHover(false)}
      >
        <FormLabel className={clsx(classes.fieldLabel, error && classes.redError)}>{label}</FormLabel>

        {value &&
        <img className={classes.photo} style={{
          maxHeight:(outerRef.current ? outerRef.current.offsetHeight - 2 : "100%"),
          maxWidth:(outerRef.current ? outerRef.current.offsetWidth - 2 : "100%")}}
            src={'/api/image/' + value} alt={label}/>
        }
        <div className={classes.secondDiv}>
          <label htmlFor={"input-file-" + name}>
            <Tooltip title="Загрузить изображение">
              <CloudDownloadIcon className={clsx(classes.uploadIcon, hover && classes.icon_hover)}/>
            </Tooltip>
          </label>
          {value &&
          <Tooltip title="Удалить изображение">
            <CloseIcon className={clsx(classes.deleteIcon, hover && classes.icon_hover)} onClick={deleteImage}/>
          </Tooltip>
          }
        </div>
      </div>

      {form.errors[name] &&
        <FormLabel className={clsx(classes.fieldError, classes.redError)}>
          {form.errors[name].message}
        </FormLabel>
      }
    </>
  );
};

ECPhotoField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  form: PropTypes.object,
  initial: PropTypes.object,
};
