import { isObject, isNull } from 'underscore';
import {fetchGoogleFonts, loadFontToBrowser} from "../../../../common/fonts";

// Эта функция проверяет факт того, что требуемый набор шрифтов загружен
// На вход сюда будут подаваться все шрифты, используемые в бейджиках
export function waitForFonts(fonts, callback, counter) {

  // console.log("!!!!  waitForFonts " + JSON.stringify(fonts) + ", " + counter)
  fetchGoogleFonts()
    .then(googleFonts => {
      for (let font of fonts) {
        for (let item of googleFonts.items) {
          if (item.family === font) {
            loadFontToBrowser(item.family, item.files.regular.replace('http://', 'https://'))
          } else {
          }
        }
      }
    })
    .catch(console.error)

  let attemptCounter = counter || 50;
  // console.log("BEGIN waitForFonts " + attemptCounter)
  if (attemptCounter === 0) {
    // console.log("waitForFonts failed")
    callback()
  }
  if (fonts.every(checkFontIsLoaded)) {
    if (attemptCounter !== 50) {
      console.log("waitForFonts success after " + (50 - attemptCounter) + " attempts")
      setTimeout(function () {
        callback()
      }, 300);
    } else {
      callback()
    }
  } else {
    setTimeout(function () {
      waitForFonts(fonts, callback, attemptCounter - 1);
    }, 300);
  }
}

// Эта функция проверяет загружен ни шрифт путём сравнения предсказанной длины. Возвращает true/false
function checkFontIsLoaded(font) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const TEXT_TO_CHECK = 'Some random text to measure;';

  // сначала проверим не является ли требуемый дефолтным
  const defaultFont = ctx.font.substr(ctx.font.lastIndexOf(' ') + 1);
  if (defaultFont === font) {
    return true;
  }

  // измеряем длину при дефолтном шрифте
  // тут какая-то странная магия с дефолтным шрифтом для проверки..
  if (ctx.font.indexOf('serif') !== -1) {
    ctx.font = 'normal 20px serif'
  } else {
    ctx.font = 'normal 20px Arial'
  } // хотя по логике должно бы быть так: ctx.font = 'normal 20px ' + ctx.font.split(' ')[1];
  
  const defaultWidth = ctx.measureText(TEXT_TO_CHECK).width;

  // меняем шрифт на требуемый и повторяем сравнение
  ctx.font = `normal 20px ${font}`;
  const desiredWidth = ctx.measureText(TEXT_TO_CHECK).width;

  // console.log("Check font result: " + font + " => " + defaultWidth + " !== " + desiredWidth)
  // Если длины совпадают, значит шрифт не загружен.
  return defaultWidth !== desiredWidth;
}

export const setValue = (path, val, obj) => {
  const fields = path.split('.');
  let result = obj;
  for (let i = 0, n = fields.length; i < n && result !== undefined; i++) {
    let field = fields[i];
    if (i === n - 1) {
      result[field] = val;
    } else {
      if (typeof result[field] === 'undefined' || !isObject(result[field])) {
        result[field] = {};
      }
      result = result[field];
    }
  }
};

export const getValue = (obj, prop) => {
  if (typeof obj === 'undefined' || isNull(obj)) {
    return false;
  }
  let _index = prop.indexOf('.');
  if (_index > -1) {
    return getValue(obj[prop.substring(0, _index)], prop.substr(_index + 1));
  }

  return obj[prop] || null;
};
