import React, { useContext, useState, lazy, Suspense } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {Card, CardContent, Hidden, MenuItem, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';

import PrintIcon from '@material-ui/icons/Print';
import DoneIcon from '@material-ui/icons/Done';

import Fab from '@material-ui/core/Fab';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import { SystemFieldType } from '../../../../api';
import { MeetupContext } from '../../../../MeetupContext';
import { AppContext } from '../../../../AppContext';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

const BadgeCardForVisitor = lazy(() =>
  import('../../../BadgeDetails/components/BadgeViewWithDownload/BadgeViewForVisitor')
);

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
    height: '36px !important',
    width: '36px !important',
    '& svg': {
      height: '24px !important',
      width: '24px !important',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  photo: {
    maxWidth: '70px',
    maxHeight: '70px',
    borderRadius: '7%',
  },
  table: {
    overflow: 'scroll',
  },
}));

const makeFIO = visitor => {
  if (visitor.middleName) {
    return [visitor.lastName, visitor.firstName, visitor.middleName || ''].join(' ');
  } else {
    return [visitor.firstName, visitor.lastName].join(' ');
  }
};

const ReceptionTable = props => {
  const { className, meetupId, visitors, printFunction, checkinFunction, topRefresh, checkinMode, ...rest } = props;

  const classes = useStyles();
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);

  const groupsColor = {}
  for (let group of meetupContext.groups) {
    groupsColor[group.groupId] = group.color || '#FFF'
  }

  const [modal, setModal] = useState(undefined);

  const optionalFields = meetupContext.fields.filter(
    f =>
      f.systemField !== SystemFieldType.FirstName &&
      f.systemField !== SystemFieldType.LastName &&
      f.systemField !== SystemFieldType.MiddleName &&
      f.systemField !== SystemFieldType.Group
  );

  const handleClose = () => {
    setModal(undefined);
  };
  const showModal = visitorId => {
    setModal(visitorId);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ФИО</TableCell>
                <TableCell>Группа</TableCell>
                <Hidden smDown>
                  {optionalFields.map(f => (
                    <TableCell key={f.systemField || f.name}>{f.label}</TableCell>
                  ))}
                </Hidden>
                <TableCell>Статус</TableCell>
                <Hidden smDown>
                  <TableCell></TableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              {visitors.slice(0, 10).map(visitor => (
                <TableRow className={classes.tableRow} hover key={visitor.visitorId} style={{backgroundColor: groupsColor[visitor.groupId]}}>
                  <TableCell>
                    <RouterLink to={'/reception/visitor/id/' + visitor.visitorId}>
                      <ListItemText primary={makeFIO(visitor)} />
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={visitor.groupName} />
                  </TableCell>
                  <Hidden smDown>
                    {optionalFields.map(f => (
                      <TableCell key={f.systemField || f.name}>
                        {f.systemField === SystemFieldType.Photo ? (
                          visitor.photo && <img className={classes.photo} src={'/api/image/' + visitor.photo} />
                        ) : (
                          <ListItemText
                            primary={f.systemField ? visitor[f.systemField] : (visitor.extended || {})[f.name]}
                          />
                        )}
                      </TableCell>
                    ))}
                  </Hidden>

                  <TableCell>
                    {visitor.status &&
                    <ListItemText
                      primary={visitor.status.status === 'come' ? 'Уже отмечен' : ''}
                      secondary={visitor.status.badgeStatus === 'printed' ? 'Бейдж напечатан' : ''}
                    />
                    }
                  </TableCell>
                  <Hidden smDown>
                    <TableCell style={{ textAlign: 'center', width: '150px' }}>
                      {checkinMode ?
                      <Fab
                        size='small'
                        color={visitor.status.status === 'come' ? '' : 'primary'}
                        aria-label='checkin'
                        className={classes.fab}
                        onClick={() => checkinFunction(visitor)}
                      >
                        <DoneIcon style={visitor.status.status === 'come' ? {fill: "green"} : {} } />
                      </Fab>
                      :
                      <Fab
                        size='small'
                        color='secondary'
                        aria-label='print'
                        className={classes.fab}
                        onClick={() => printFunction(visitor)}
                      >
                        <PrintIcon />
                      </Fab>
                      }
                    </TableCell>
                  </Hidden>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>

      <Modal
        className={classes.modal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={!!modal}
        onClose={handleClose}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <div className={classes.paper}>
            <BadgeCardForVisitor meetupId={meetupId} visitorId={modal} />
          </div>
        </Suspense>
      </Modal>
    </Card>
  );
};

ReceptionTable.propTypes = {
  className: PropTypes.string,
  visitors: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
};

export default ReceptionTable;
