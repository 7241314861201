import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import DropDownMenu from '../../../components/DropDownMenu/DropDownMenu';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
  hint: {
    marginLeft: 10,
    height: 16,
    width: 16,
    minHeight: 16,
  },
  photo: {
    maxWidth: '70px',
    maxHeight: '70px',
    borderRadius: '7%',
  },
}));

const MerchsTable = props => {
  const { className, meetupId, merchs, handleDelete, ...rest } = props;

  const classes = useStyles();

  if (!merchs) {
    return null
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Изображение</TableCell>
                <TableCell>Стоимость</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {merchs.map((merch, i) => (
                <TableRow className={classes.tableRow} hover key={merch.merchId}>
                  <TableCell>
                    <RouterLink to={'/m/' + meetupId + '/merch/id/' + merch.merchId}>
                      <Typography style={{fontWeight:"bold"}}>{merch.name}</Typography>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    {merch.imageId &&
                    <img className={classes.photo} src={'/api/image/' + merch.imageId} alt={"Изображение"}/>
                    }
                  </TableCell>
                  <TableCell>
                      <Typography>{merch.price}</Typography>
                  </TableCell>

                  <TableCell style={{ textAlign: 'center', width: '100px' }}>
                    <DropDownMenu data-test-id="merch-menu">
                      <MenuItem>
                        <RouterLink to={'/m/' + meetupId + '/merch/id/' + merch.merchId} data-test-id="merch-menu-edit">
                          <ListItemText primary='Редактировать' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(meetupId, merch.merchId)} data-test-id="merch-menu-delete">
                        <ListItemText primary='Удалить' />
                      </MenuItem>
                    </DropDownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

MerchsTable.propTypes = {
  className: PropTypes.string,
  merchs: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
};

export default MerchsTable;
