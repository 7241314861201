import React from 'react';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { Grid } from '@material-ui/core';
import { MyFormTextField } from '../../../MeetupList/components/MeetupEditForm/MeetupEditForm';

const StatLogView = props => {
  const { data, handleClose } = props;

  if (!data) {
    return null;
  }

  const form = {
    // form values bind helper
    data: { values: data },
    handleChange: () => {},
    error: () => {},
  };

  return (
    <ModalDialog title={'Запись ' + data.statLogId} handleClose={handleClose} handleOk={handleClose}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MyFormTextField name={'action'} label='action' inputProps={{ readOnly: true }} {...form} />
          <MyFormTextField name={'comment'} label='comment' required inputProps={{ readOnly: true }} {...form} />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

StatLogView.propTypes = {};

export default StatLogView;
