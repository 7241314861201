import React, {createRef, useContext, useEffect, useState} from "react";
import {AppContext} from "../../../AppContext";
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Button, Card, Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {ECCommonErrorBlock} from "../../../components/Forms/ECCommonErrorBlock";
import {ECTextField} from "../../../components/Forms/ECTextField";
import {ECDateField} from "../../../components/Forms/ECDateField";
import {ECSelectField} from "../../../components/Forms/ECSelectField";
import moment from "moment-timezone";
import EventInfo from "../EventInfo";
import {makeStyles} from "@material-ui/styles";
import {errorsToForm} from "../../../components/Forms/form_utils";
import ModalDialog from "../../../components/ModalDialog/ModalDialog";
import {ECPhotoField} from "../../../components/Forms/ECPhotoField";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as _ from "underscore";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  tableContainer__title: {
    paddingLeft: 25,
    fontSize: 20,
  },
  cardContainer: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    paddingTop: 0
  },

}));

const InfoLocation = (props) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const { goBack } = props;
  const form = useForm();
  const [initial, setInitial] = useState(undefined);

  useEffect(() => {
    appContext.eventInfoApi.getLocation(meetupId).then(({ data }) => {
      setInitial(data);
    });
  }, [meetupId]);

  const handleSave =  () => {
    appContext.eventInfoApi.getLocation(meetupId).then(({ data:initial }) => {
      const data = _.extend(initial, form.getValues());
      appContext.eventInfoApi
        .updateLocation(meetupId, data)
        .then(({data}) => {
          goBack(data)
        })
        .catch(errorsToForm(form));
    });
  };

  if (!initial) {
    return null
  }

  return (
    <ModalDialog title={'Изображения'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <ECCommonErrorBlock form={form} />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ECPhotoField label='Программа мероприятия' name='timetableImage' form={form} initial={initial} />
        </Grid>
        <Grid item xs={6}>
          <ECPhotoField label='План площадки' name='locationPlanImage' form={form} initial={initial} />
        </Grid>
      </Grid>
    </ModalDialog>
  )
}

export default InfoLocation;
