import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, MenuItem, TextField } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { exportVisitorsXLS } from '../ExportXLS/ExportXLS';
import DropDownMenu from '../../../../components/DropDownMenu/DropDownMenu';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext } from '../../../../AppContext';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import * as _ from 'underscore';
import { MeetupContext } from '../../../../MeetupContext';
import PrintVisitors from './PrintVisitors';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  row: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1,
  },
  menu: {
    marginLeft: theme.spacing(1),
  },
  searchField: {
    width: '400px',
    margin: 0,
    paddingTop: '4px',
    paddingRight: '8px'
  },
  groupField: {
    width: '300px',
    margin: 0,
    paddingTop: '4px'
  },
  iconButton: {
    //marginTop: '14px',
    width: '36px',
    height: '36px',
  },
}));

const VisitorsToolbar = props => {
  const {
    className,
    meetupId,
    refresh,
    selected,
    search: outerSearch,
    setSearch: setOuterSearch,
    group: outerGroup,
    setGroup: setOuterGroup,
    setDeleteConf,
    ...rest
  } = props;

  const classes = useStyles();
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);

  const [printVisitors, setPrintVisitors] = useState([]);

  const deleteSelected = () => {
    if (Object.keys(selected).length > 0) {
      setDeleteConf('selected');
    }
    // let calls = [];
    // Object.keys(selected).forEach(id => {
    //   calls.push(appContext.visitorApi.deleteVisitor(meetupId, id))
    // });
    //
    // Promise.all(calls).then(() => refresh("refresh"))
  };
  useEffect(() => {
    document.getElementById('visitors-search-field').focus();
  }, []);

  const printSelected = () => {
    const arrSelected = Object.keys(selected);
    const printVisitors = props.visitors.filter(visitor => arrSelected.indexOf(visitor.visitorId + '') > -1);
    appContext.accountApi.writeStatLog({ action: 'VISITOR_BATCH_PRINT', count: printVisitors.length });
    setPrintVisitors(printVisitors);
  };

  const [search, setSearch] = React.useState(outerSearch);
  const handleSearchDebounced = React.useRef(
    _.debounce(function (query) {
      setOuterSearch(query);
    }, 300)
  ).current;
  const handleSearchChange = query => {
    setSearch(query);
    handleSearchDebounced(query);
  };

  const [groups, setGroups] = useState([]);
  useEffect(() => {
    appContext.groupApi
      .listGroups(meetupId)
      .then(({ data }) => {
        setGroups(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {printVisitors.length > 0 ? (
        <PrintVisitors printVisitors={printVisitors} meetupId={meetupId} setPrintVisitors={setPrintVisitors} />
      ) : null}
      <div className={classes.row}>
        <IconButton type='submit' size={'small'}>
          <SearchIcon />
        </IconButton>
        <TextField
          id={'visitors-search-field'}
          fullWidth
          margin='dense'
          autoComplete='new-password'
          onChange={e => handleSearchChange(e.target.value)}
          value={search}
          className={classes.searchField}
        />

        <TextField
          select
          className={classes.groupField}
          margin='dense'
          value={outerGroup}
          onChange={e => setOuterGroup(e.target.value)}
        >
          <MenuItem key={''} value={''}>
            Все
          </MenuItem>
          {groups.map(g => (
            <MenuItem key={g.groupId} value={g.groupId}>
              {g.name}
            </MenuItem>
          ))}
        </TextField>

        <span className={classes.spacer} />
        <RouterLink to={'/m/' + meetupId + '/visitor/add'}>
          <Button color='primary' variant='contained'>
            Добавить посетителя
          </Button>
        </RouterLink>

        <DropDownMenu className={classes.menu} data-test-id="visitors-menu">
          <MenuItem>
            <RouterLink to={'/m/' + meetupId + '/visitor/import'}>
              <ListItemText primary='Импорт посетителей' />
            </RouterLink>
          </MenuItem>
          <MenuItem>
            <RouterLink to={'/m/' + meetupId + '/visitor/import-photo'}>
              <ListItemText primary='Импорт фотографий' />
            </RouterLink>
          </MenuItem>
          <MenuItem
            onClick={() => exportVisitorsXLS(appContext, meetupContext, meetupId, selected, outerSearch, outerGroup)}
          >
            <ListItemText primary='Экспорт' />
          </MenuItem>
          <MenuItem onClick={() => deleteSelected()}>
            <ListItemText primary='Удалить' />
          </MenuItem>
          <MenuItem onClick={() => printSelected()}>
            <ListItemText primary='Печатать' />
          </MenuItem>
        </DropDownMenu>
      </div>
    </div>
  );
};

VisitorsToolbar.propTypes = {
  className: PropTypes.string,
  meetupId: PropTypes.string,
};

export default VisitorsToolbar;
