import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../AppContext';
import { useParams } from 'react-router-dom';
import NewsEditForm from './NewsEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../components/Forms/form_utils';
import {fixDotProperties} from "../../Merch/components/MerchEdit";
import {NewsStatusType} from "../../../api";

const NewsEdit = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId, newsId } = useParams();
  const { goBack } = props;
  const form = useForm();

  const [initial, setInitial] = useState(undefined);
  useEffect(() => {
    appContext.newsApi.getNews(meetupId, newsId).then(({ data }) => {
      if (Array.isArray(data.recipients.tags)) {
        data.recipients.tagsStr = data.recipients.tags.join(", ");
      }
      setInitial(data);
      if (data.status !== NewsStatusType.Draft) {
        form.setError('', "ALREADY_PUBLISHED", "Редактировать можно только новости в статусе Черновик")
      }
    });
  }, [meetupId, newsId]);

  const handleSave = () => {
    let data = form.getValues();
    data = fixDotProperties(data);

    data.type = initial.type;
    data.meetupId = meetupId;
    data.newsId = newsId;
    data.recipients.tags = data.recipients.tagsStr && data.recipients.tagsStr.split(",") || []

    appContext.newsApi
      .updateNews(meetupId, newsId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  if (!initial) return null;

  const title = {
    "news": "Редактирование новости",
    "notification": "Редактирование нотификации",
    "question": "Редактирование опроса"
  }
  return (
    <ModalDialog title={title[initial.type]} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <NewsEditForm meetupId={meetupId} form={form} initial={initial} type={initial.type} />
    </ModalDialog>
  );
};

NewsEdit.propTypes = {};

export default NewsEdit;
