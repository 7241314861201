import { MenuItem, TextField, Select } from '@material-ui/core';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { valueByName } from './form_utils';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  fixLabel: {
    transform: "translate(14px, 12px) scale(1)"
  },
}))

export const ECMultiSelectField = props => {
  const { name, label, options, form, fid: a1, fname: a2, defaultValue, initial, ...rest } = props;
  const classes = useStyles();

  const fname =
    props.fname ||
    (it => {
      return it.name;
    });
  const fid =
    props.fid ||
    (it => {
      return it.id;
    });

  const [value, setValue] = useState(valueByName(initial, name) || defaultValue || []);
  const [labelWidth, setLabelWidth] = useState(70);

  const inputRef = useRef(null);

  useEffect(() => {
    form.register({ name: name });
    form.setValue(name, value);
  }, [form.register]);

  const handleChange = (e) => {
    form.setValue(name, e.target.value);
    setValue(e.target.value);
    form.clearError(name)
  }

  const valueString = () => {
    return options.filter(it => value.includes(fid(it))).map(it => fname(it)).join(", ");
  }

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setLabelWidth(node.getBoundingClientRect().width + 14);
    }
  }, []);

  return (
    <FormControl variant="outlined" fullWidth error={!!form.errors[name]} style={{marginTop: 8}}>
      <InputLabel ref={measuredRef} className={clsx(value.length === 0 && classes.fixLabel)}>
        {label}
      </InputLabel>
      <Tooltip title={valueString()}>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          input={
            <OutlinedInput labelWidth={labelWidth} style={{height: 40}} />
          }
        >
          {options.map(it => (
            <MenuItem key={fid(it)} value={fid(it)}>
              {fname(it)}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
      {form.errors[name] &&
      <FormHelperText>{form.errors[name].message}</FormHelperText>
      }
    </FormControl>
  );
};

ECMultiSelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,

  options: PropTypes.array,
  fname: PropTypes.func,
  fid: PropTypes.func,

  form: PropTypes.object,

  defaultValue: PropTypes.any,
  initial: PropTypes.object,
};
