import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import NewsToolbar from './components/NewsToolbar';
import NewsTable from './components/NewsTable';
import { Route, useParams } from 'react-router-dom';
import NewsAdd from './components/NewsAdd';
import NewsEdit from './components/NewsEdit';
import {MeetupContextProvider} from "../../MeetupContext";
import filter from "../../common/filterVisitors";
import {visitorsToCells} from "../VisitorList/components/ExportXLS/ExportXLS";
import XLSX from "xlsx";
import {SystemFieldType} from "../../api";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const NewsList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  const [refreshCounter, setRefresh] = useState(0);
  const [news, setNews] = useState([]);

  useEffect(() => {
    appContext.newsApi
      .listNews(meetupId)
      .then(({ data }) => {
        setNews(data)
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const handleDelete = (meetupId, newsId) => {
    appContext.newsApi.deleteNews(meetupId, newsId).then(() => {
      setNews(news => news.filter(u => u.newsId !== newsId));
    });
  };
  const handleSendNow = (meetupId, newsId) => {
    // appContext.newsApi.deleteNews(meetupId, newsId).then(() => {
    //   setNews(news => news.filter(u => u.newsId !== newsId));
    // });
  };
  const handleReport = (meetupId, newsId) => {
    appContext.newsApi.getNews(meetupId, newsId).then(({data:news}) => {
      appContext.newsApi.getPoolResults(meetupId, newsId).then(({data:voteResults}) => {
        exportVoteResultsXLS(news, voteResults)
      });
    });
  };

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/news');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };

  return (
    <div className={classes.root}>
      <NewsToolbar meetupId={meetupId} refresh={() => setRefresh(refreshCounter + 1)} />
      <NewsTable
        meetupId={meetupId}
        news={news}
        handleDelete={handleDelete}
        handleSendNow={handleSendNow}
        handleReport={handleReport}
      />

      <Route path={'/m/:mid/news/add'} exact>
        <MeetupContextProvider meetupId={meetupId}>
          <NewsAdd goBack={backAndRefresh} />
        </MeetupContextProvider>
      </Route>

      <Route path={'/m/:mid/news/id/:newsId'} exact>
        <MeetupContextProvider meetupId={meetupId}>
          <NewsEdit goBack={backAndRefresh} />
        </MeetupContextProvider>
      </Route>
    </div>
  );
};

export default NewsList;

const exportVoteResultsXLS = (news, voteResults) => {
      let wb = XLSX.utils.book_new();

      let table = dataToCells(voteResults);
  console.log(table)
      let ws = XLSX.utils.aoa_to_sheet(table);
      XLSX.utils.book_append_sheet(wb, ws, 'Results');

      XLSX.writeFile(wb, 'Результаты_опроса__' + news.newsId + '.xlsx');
};

export const dataToCells = (voteResults) => {
  const colls = [["tgName", "visitorId", "visitorName", "choice", "timestamp"]]

  const votes = voteResults.votes.map(function (v) {
    return [v.tgName, v.visitorId, v.visitorName, v.choice, v.timestamp]
  })

  return colls.concat(votes);
};
