import { Link, makeStyles, MenuItem, TableCell, TextField, Typography, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MeetupContext } from '../../../../../MeetupContext';
import Grid from '@material-ui/core/Grid';
import { convertDataToVisitors, generateVisitorXLS } from '../import_utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Popover from '@material-ui/core/Popover';
import { AppContext } from '../../../../../AppContext';
import * as _ from 'underscore';
import Fab from '@material-ui/core/Fab';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { HelpHint } from '../../../../../components/HelpHint/HelpHint';

const useStyles = makeStyles(theme => ({
  copied: {
    padding: theme.spacing(2),
  },
}));

const convertClientCheckErrors = errors => {
  // {row:r.__row__, error: e}
  return (errors || []).map(e => 'Строка: ' + e.row + ', ошибка: ' + e.error);
};

const convertServerCheckErrors = (visitors, errors) => {
  // {"0":[{"error":"VALIDATION_MUST_NOT_BE_NULL","args":{"field":"extended.jobPosition"}}],
  return Object.keys(errors || {}).map(k => {
    const p0 = 'Строка: ' + visitors[k].__row__;
    const p1 = Array(p0.length).fill(' ').join('');
    return errors[k]
      .map((e, i) => (i === 0 ? p0 : p1) + ', поле: ' + e.args.field + ', ошибка: ' + e.message)
      .join('\n');
  });
};

export const convertErrors = (clientErrors, serverErrors, visitors) => {
  const duplicates = Object.keys(serverErrors || {})
    .filter(i => serverErrors[i].filter(e => e.error === 'VISITOR_DUPLICATE').length > 0)
    .map(i => visitors[i]);
  // удалим ошибки дублирования из лога ошибок
  Object.keys(serverErrors || {}).forEach(
    i => (serverErrors[i] = serverErrors[i].filter(e => e.error !== 'VISITOR_DUPLICATE'))
  );
  Object.keys(serverErrors || {}).forEach(i => {
    if (serverErrors[i].length === 0) delete serverErrors[i];
  });

  const errors = convertClientCheckErrors(clientErrors).concat(convertServerCheckErrors(visitors, serverErrors));
  //   список дубликатов достаём фильтруя сообщения об ошибках
  return [errors, duplicates];
};

export const ValidationStep = props => {
  const { stepData, onChange } = props;
  const data = stepData[0].data;
  const fields = stepData[1];
  const theme = useTheme();
  const classes = useStyles();

  const [result, setResult] = useState();
  const [copied, setCopied] = useState(false);
  const anchorEl = useRef();
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const onErrorRef = useRef();
  const onDupsRef = useRef();

  const validate = async () => {
    // 1. конвертируем таблицу в визиторов
    //   выполняем предобработку, запоминаем ошибки
    //   получаем список visitor, запоминаем его, позволяем выгрузить "нормализованный файл"
    const [visitors, clientErrors] = convertDataToVisitors(data, fields, meetupContext);
    console.log('conversion result');
    console.log(visitors);

    // 2. отправляем таблицу на сервер в режиме dry-run
    //   на сервере выполняется валидация данных, валидация обязательных полей, проверка дубликатов
    const importRequest = {
      visitors: visitors,
      dryRun: true,
    };
    const checkResult = await appContext.visitorApi.importVisitors(meetupContext.meetupId, importRequest);
    const { visitors: okRecords, errors: serverErrors } = checkResult.data;

    // 3. из результирующих данных достаём:
    //   список ошибок, соединяем его с теми что получили тут на фронте, и отображаем в логе
    const [errors, duplicates] = convertErrors(clientErrors, serverErrors, visitors);

    setResult({
      visitors: visitors,
      errors: errors,
      duplicates: duplicates,
    });

    stepData[2].ready = errors.length === 0 || stepData[2].onError === 'skip';
    onChange(stepData[2]);
  };

  useEffect(() => {
    validate(data, fields);
  }, [data, fields]);

  if (!result) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography variant={'h3'}>Идёт валидация ...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Typography variant={'h3'}>Валидация</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography>Обработано: {data.records.length} записей</Typography>
      </Grid>
      {/*<Grid item xs={12} md={12}>*/}
      {/*  <Typography>Скачать (без ошибок): &nbsp;*/}
      {/*    <Link color="primary" underline="always" style={{cursor:"pointer"}} onClick={() => generateVisitorXLS(result.visitors, meetupContext, "clean.xlsx")}>*/}
      {/*      clean.xlsx*/}
      {/*    </Link>*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
      <Grid item xs={12} md={12}>
        <Typography>
          Ошибок: {result.errors.length}
          {result.errors.length > 0 && (
            <>
              &nbsp;(
              <CopyToClipboard text={result.errors.join('\n')} onCopy={() => setCopied(true)}>
                <Link color='primary' underline='always' style={{ cursor: 'pointer' }} ref={anchorEl}>
                  скопировать
                </Link>
              </CopyToClipboard>
              )
              {copied && (
                <Popover
                  open={true}
                  anchorEl={anchorEl.current}
                  onClose={() => setCopied(false)}
                  anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                >
                  <Typography className={classes.copied}>Лог скопирован</Typography>
                </Popover>
              )}
            </>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography>
          Дубликатов: {result.duplicates.length}
          {result.duplicates.length > 0 && (
            <>
              &nbsp;(
              <Link
                color='primary'
                underline='always'
                style={{ cursor: 'pointer' }}
                onClick={() => generateVisitorXLS(result.duplicates, meetupContext, 'duplicates.xlsx')}
              >
                duplicates.xlsx
              </Link>
              )
            </>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography style={{ marginTop: theme.spacing(1) }}>Поведение при ошибках:</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          select
          fullWidth
          margin='dense'
          value={stepData[2].onError}
          onChange={e => {
            stepData[2].onError = e.target.value;
            stepData[2].ready = result.errors.length === 0 || stepData[2].onError === 'skip';
            onChange(stepData[2]);
          }}
          ref={onErrorRef}
        >
          <MenuItem value='stop'>Остановиться</MenuItem>
          <MenuItem value='skip'>Пропустить</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} md={1}></Grid>
      <Grid item xs={12} md={5}>
        <Typography style={{ marginTop: theme.spacing(1) }}>Поведение при дубликатах:</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          select
          fullWidth
          margin='dense'
          value={stepData[2].onDuplicate}
          onChange={e => {
            stepData[2].onDuplicate = e.target.value;
            onChange(stepData[2]);
          }}
          ref={onDupsRef}
        >
          <MenuItem value='skip'>Пропустить</MenuItem>
          <MenuItem value='overwrite'>Перезаписать</MenuItem>
          <MenuItem value='merge'>Объединить</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} md={1}>
        <HelpHint
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <ul>
            <li>Пропустить - дубликаты из файла будут проигнорированы, значения в базе останутся без изменения.</li>
            <li>Перезаписать - поля в базе обновятся на значения полей из файла.</li>
            <li>
              Объединить - поля, которые есть в базе, но нет в файле - останутся без изменений. Поля, которые есть и в
              базе, и в файле - обновятся на значения полей из файла. Поля, которые есть только в файле - добавятся.
            </li>
          </ul>
        </HelpHint>
      </Grid>
    </Grid>
  );
};
