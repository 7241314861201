import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// import { getThreads } from 'src/actions/chatActions';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Input,
  SvgIcon,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import ThreadItem from './ThreadItem';

function filterThreads(threads, searchText) {
  if (!searchText) {
    return Object.keys(threads);
  }
  return Object.keys(threads).filter(threadKey =>
    threads[threadKey].name.toLowerCase().includes(searchText.toLowerCase())
  );
}

const useStyles = makeStyles(theme => ({
  root: {},
  searchContainer: {},
  search: {
    display: 'flex',
    alignItems: 'center',
    height: 68,
    borderRadius: 22,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.dark,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  contactAvatar: {
    height: 32,
    width: 32,
  },
  threadList: {},
  hideThreadList: {
    display: 'none',
  },
}));

function ThreadList({ className, contacts, threads, ...rest }) {
  const classes = useStyles();
  const location = useLocation();
  // const dispatch = useDispatch();
  // const { threads, contacts } = useSelector((state) => state.chat);

  const [searchText, setSearchText] = useState('');
  const [displaySearchResults, setDisplaySearchResults] = useState(false);

  const handleSearchFocus = event => {
    event.persist();
    setDisplaySearchResults(true);
  };

  const handleSearchChange = event => {
    event.persist();
    setSearchText(event.target.value);
  };

  const handleSearchClickAway = () => {
    if (displaySearchResults) {
      setSearchText('');
      setDisplaySearchResults(false);
    }
  };

  // useEffect(() => {
  //   dispatch(getThreads());
  // }, [dispatch]);

  useEffect(() => {
    if (displaySearchResults) {
      setDisplaySearchResults(false);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <ClickAwayListener onClickAway={handleSearchClickAway}>
        <div className={classes.searchContainer}>
          <div className={classes.search}>
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              onFocus={handleSearchFocus}
              placeholder='Найти пользователя'
              value={searchText}
            />
            <SvgIcon fontSize='small' color='action'>
              <SearchIcon />
            </SvgIcon>
          </div>
          <Divider />
          {/* {displaySearchResults && (
          <Box mt={2}>
            <Typography
              variant="h6"
              color="textSecondary"
            >
              Contacts
            </Typography>
            <List>
              {filterContacts(contacts, searchText).map((contact) => {
                return (
                  <ListItem
                    button
                    component={RouterLink}
                    key={contact.id}
                    to={`/app/chat/${contact.username}`}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src='https://cdn.iconscout.com/icon/free/png-512/avatar-372-456324.png'
                        className={classes.contactAvatar}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={contact.name}
                      primaryTypographyProps={{
                        noWrap: true,
                        variant: 'h6',
                        color: 'textPrimary'
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
          )} */}
        </div>
      </ClickAwayListener>
      <List className={classes.threadList}>
        {filterThreads(threads, searchText).map(threadKey => (
          <ThreadItem key={threadKey} thread={threads[threadKey]} />
        ))}
      </List>
    </div>
  );
}

ThreadList.propTypes = {
  className: PropTypes.string,
};

export default ThreadList;
