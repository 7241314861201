import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../AppContext";
import {useParams} from "react-router-dom";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AntSwitch from "../../../components/AntSwitch/AntSwitch";
import {makeStyles} from "@material-ui/styles";
import FormFields from "./FormFields";
import MatchingSettings from "./MatchingSettings";
import Switch from "@material-ui/core/Switch";
import CardBlock from "../../../components/CardBlock/CardBlock";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import {useForm} from "react-hook-form";
import {ECTextField} from "../../../components/Forms/ECTextField";
import {TextField} from "@material-ui/core";
import {errorsToForm} from "../../../components/Forms/form_utils";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24,
  },
  topBar: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));


const Networking = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const classes = useStyles();

  const [settings, setSettings] = useState(null);
  const [data, setData] = useState(null);
  const form = useForm();

  useEffect(() => {
    appContext.networkingApi.getNetworkingSettings(meetupId)
      .then(({ data }) => {
        setSettings(data);
        setData(JSON.stringify(data, null, 2))
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId]);

  const handleSave = () => {
    appContext.networkingApi.updateNetworkingSettings(meetupId, JSON.parse(data))
      .then(({ data }) => {
      })
      .catch(appContext.errorHandler);
  }

  if (!data) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <FormControlLabel
          control={<Switch checked={settings.enabled} name='checkedB' color='secondary' />}
          label='Включить модуль нетворкинга'
        />
      </div>

      <CardBlock title={"Настройка модуля"} form={form} save={handleSave}>
        <Grid container spacing={3} style={{margin:"0px"}}>
          <Grid xs={12}>
            <TextField
              name='data'
              fullWidth margin='dense' variant='outlined'
              value={data} onChange={e => setData(e.target.value)}
              multiline rows={10}
            />
          </Grid>
        </Grid>
      </CardBlock>

      {/*<FormFields/>*/}
      {/*<MatchingSettings/>*/}
    </div>
  );
};

export default Networking;

