import {makeStyles} from "@material-ui/styles";
import { Link as RouterLink, Redirect } from 'react-router-dom';
import React, {useContext, useEffect, useRef, useState} from "react";
import {Box, Button, Paper, TextField, Link, Typography, Checkbox} from "@material-ui/core";
import { AppContext } from '../../../AppContext';
// import VisitorEditForm from "../VisitorList/components/VisitorEditForm/VisitorEditForm";
import {PolicyForRegForm} from "./PolicyForRegForm";
// import ExtendedTextField from "./ExtendedTextField";

const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    ["@media (max-width:700px)"] : {
      borderRadius: 0,
      height: '100%'
    },
  },
  space: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginBottom: "10px",
  },
  policyCheckbox: {
    marginLeft: '-14px',
  },

}));

export const BarQrConfirmForm = (props) => {
  const classes = useStyles();
  const {visitorToken, reqFormToken, handleSave, handleUpdate, handleCancel, errors, data} = props;
  const [policyCheckbox, setPolicyCheckbox] = useState(false)
  const appContext = useContext(AppContext);
  const externalUrl = `/regform/${reqFormToken}?visitor=${visitorToken}`

  console.log(data)
  return (
    <Paper className={classes.paper} style={{textAlign:"center"}}>
        <Typography variant={"h3"} className={classes.space}>Получение QR для входа</Typography>
      
        <TextField
              className={classes.textField}
              fullWidth
              label='Имя'
               name='name'
              type='text'
              value={data.firstName}
              variant='outlined'
              required={false}
              autoComplete={'off'}
              InputProps={{
                readOnly: true 
              }}
              />
        <TextField
            className={classes.textField}
            fullWidth
            label='Фамилия'
            name='surname'
            type='text'
            value={data.lastName}
            variant='outlined'
            required={false}
            autoComplete={'off'}
            InputProps={{
                readOnly: true 
            }}
            />
        <TextField
              className={classes.textField}
              fullWidth
              label='Адрес электронной почты'
               name='email'
              type='text'
              value={data.email}
              variant='outlined'
              required={false}
              autoComplete={'off'}
              InputProps={{
                readOnly: true 
            }}
              />

      <Typography className={classes.policyText} color='textSecondary' variant='body1' style={{marginBottom: '10px', textAlign: 'left', fontSize: '12px'}}>
        На указанный email придет QR-код.&nbsp;
        <Link
          color='primary'
          component={RouterLink}
          to={externalUrl}
          underline='always'
          variant='h6'
        >
          Изменить почту
        </Link>
      </Typography>

      <div>
          <Checkbox
            checked={policyCheckbox}
            style={{
              float: "left"
            }}
            color='primary'
            name='policy'
            onChange={() => {setPolicyCheckbox(!policyCheckbox)}}
            required={true}
          />

          <Typography
            style={{
              textAlign: 'left',
              // fontWeight: 'bold',
              color: '#213955'
            }}
          >
            Согласен на получение уникального персонального проверочного  QR кода без права сообщать и передавать его третьим лицам
          </Typography>
        </div>

        <Button style={{width: '100%', marginLeft: 0, marginRight:0}} color='primary' variant='contained' onClick={() => handleSave()} className={classes.space} disabled={!policyCheckbox}>
          Получить QR код
        </Button>

    </Paper>
  );
};
