import React, { useContext,useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Route } from 'react-router-dom';

import ReceptionBarScanner from '../ReceptionBarScanner/ReceptionBarScanner';
import ReceptionFaceScanner from '../ReceptionFaceScanner/ReceptionFaceScanner';
import ReceptionToolbar from '../ReceptionToolbar/ReceptionToolbar';
import ReceptionTable from '../ReceptionTable/ReceptionTable';
import VisitorAdd from '../../../VisitorList/components/VisitorAdd/VisitorAdd';
import VisitorEditWithBadge from '../../../VisitorList/components/VisitorEditWithBadge/VisitorEditWithBadge';
import filter from 'common/filterVisitors';
import { AppContext } from 'AppContext'
import {ScannerEventContext} from "ScannerEventContext";
import {VisitorBadgeStatusType, VisitorStatusType} from "../../../../api";
import {MeetupContext} from "../../../../MeetupContext";
import {errorsToObject} from "../../../../components/Forms/form_utils";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 64,
    height: '100%',
    maxWidth: '1200px',
    margin: 'auto',
  }

}));

const onlyNumbers = new RegExp('\\d+');

const ReceptionDesk = ({
	meetupId,
	setPrintVisitors, 
	receptionData, 
	search, 
	visitors,
	setVisitors,
	encodings,
	setSearch,searchFieldRef,
	filteredVisitors
}) => {
	const classes = useStyles();
	const appContext = useContext(AppContext)
  const scannerContext = useContext(ScannerEventContext)
  const meetupContext = useContext(MeetupContext)

	const [refreshCounter, setRefresh] = useState(0);

  const backAndRefresh = data => {
    appContext.history.push('/reception');
    if (data) {
      refresh(data)
    }
    setRefresh(refreshCounter + 1);
  };

  const refresh = data => {
    for (let i = 0; i < visitors.length; ++i) {
      if (visitors[i].visitorId === data.visitorId) {
        if (!data.status) {
          data.status = visitors[i].status
        }
        if (!data.groupName) {
          data.groupName = meetupContext.groups.filter(g => g.groupId === data.groupId)[0].name
        }
        visitors[i] = data;
      }
    }
    for (let i = 0; i < filteredVisitors.length; ++i) {
      if (filteredVisitors[i].visitorId === data.visitorId) {
        filteredVisitors[i] = data;
      }
    }
    setVisitors(visitors)
  };

  const handlePrintClick = visitor => {
    printBadge(visitor.visitorId)
	};
  const printBadge = visitorId => {
    // prePrintHandler
    // вызываем хэндлер, в ответе ставим задание на принт
    appContext.visitorApi.prePrintHook(meetupId, visitorId).then(({data}) => {
      for (let i = 0; i < visitors.length; ++i) {
        if (visitors[i].visitorId === data.visitorId) {
          visitors[i] = data
        }
      }
      setVisitors(visitors)
      setPrintVisitors([{ visitorId: visitorId }]);
    })
  };

  const checkinClick = visitor => {
    visitor.status = visitor.status || {}
    visitor.status.status = VisitorStatusType.Come;

    let data = JSON.parse(JSON.stringify(visitor))

    //удалим те поля, которые нельзя обновлять
    delete data.groupName;
    data.status = { status:VisitorStatusType.Come};

    appContext.visitorApi
      .updateVisitor(meetupId, visitor.visitorId, data)
      .then(({ data }) => {
        setSearch('')
        document.getElementById('visitors-search-field').focus();
      })
  }

	const handleVisit = visitorId => {
    const openVisitorCard = () => {
      appContext.history.push('/reception/visitor/id/' + visitorId);
    };
    const meetupId = receptionData.meetup.meetupId;
    if (!receptionData.deskSettings.actionCheckinEnabled) {
      appContext.visitorApi.writeDeskVisit(meetupId, visitorId);
    }

    const vv = visitors.filter( v => v.visitorId === visitorId);
    const badgePrinted = vv.length > 0 && vv[0].status && vv[0].status.badgeStatus === VisitorBadgeStatusType.Printed;
    const checkedIn = vv.length > 0 && vv[0].status && vv[0].status.status === VisitorStatusType.Come;

    if (receptionData.deskSettings.actionPrintBadgeEnabled) {
      if (receptionData.deskSettings.actionCheckinEnabled && !checkedIn) {
        appContext.visitorApi.checkin(meetupId, visitorId).then(({data}) => {
          refresh(data)
          !badgePrinted && printBadge(visitorId)
        });
      } else {
        !badgePrinted && printBadge(visitorId);
      }
    } else if (receptionData.deskSettings.actionCheckinEnabled) {
      if (!checkedIn) {
        appContext.visitorApi.checkin(meetupId, visitorId).then(({data}) => {
          refresh(data)
          if (!receptionData.deskSettings.dontOpenCard) {
            openVisitorCard()
          }
        });
      }
    } else {
      if (!receptionData.deskSettings.dontOpenCard) {
        openVisitorCard();
      }
    }
    setRefresh(refreshCounter+1)
  };
	
	const viewAndRefresh = data => {
    if (!data) {
      appContext.history.push('/reception');
      return;
    }

    if (data.visitorId) {
      setVisitors(visitors.concat(data));

      appContext.visitorApi.writeDeskVisit(meetupId, data.visitorId);

      if (receptionData.deskSettings.actionCheckinEnabled) {
        appContext.visitorApi.checkin(meetupId, data.visitorId);
      }
      if (receptionData.deskSettings.actionPrintBadgeEnabled) {
        printBadge(data.visitorId);
      }
      if (receptionData.deskSettings.actionCheckinEnabled || receptionData.deskSettings.actionPrintBadgeEnabled) {
        appContext.history.push('/reception');
      } else {
        appContext.history.push('/reception/visitor/id/' + data.visitorId);
      }
    }

    setRefresh(refreshCounter + 1);
	};
	
	const handleCodeFromScanner = code => {
    setSearch(() => code);
  };

  useEffect(() => {
    if (filteredVisitors.length === 1 && onlyNumbers.test(search)) {
      handleVisit(filteredVisitors[0].visitorId)
    }
  }, [filteredVisitors])

  useEffect(() => {
    scannerContext.addListener(handleCodeFromScanner);

    return (
      () => {
        scannerContext.removeListener(handleCodeFromScanner);
      }
    )
  }, [visitors, refreshCounter])


	return (
		<div className={classes.root}>
      {receptionData.deskSettings.webCamBarScannerEnabled && (
        <ReceptionBarScanner meetupId={meetupId} search={search} setSearch={handleCodeFromScanner} />
      )}
      {receptionData.deskSettings.faceScannerEnabled && (
        <ReceptionFaceScanner meetupId={meetupId} visitors={visitors} encodings={encodings} />
      )}
      <ReceptionToolbar meetupId={meetupId} search={search} setSearch={setSearch} searchFieldRef={searchFieldRef} />
      <ReceptionTable
        meetupId={meetupId}
        visitors={filteredVisitors}
        topRefresh={refreshCounter}
        printFunction={handlePrintClick}
        checkinFunction={checkinClick}
        checkinMode={receptionData.deskSettings.actionCheckinEnabled}
      />
      <Route path={'/reception/visitor/add'} exact>
        <VisitorAdd meetupId={meetupId} goBack={viewAndRefresh} />
      </Route>
      <Route path={'/reception/visitor/id/:visitorId'} exact>
        <VisitorEditWithBadge
          meetupId={meetupId}
          goBack={backAndRefresh}
          showStatus={false}
          saveAndCheckin={receptionData.deskSettings.actionCheckinEnabled}
        />
      </Route>
      
    </div>  
	);
}

export default ReceptionDesk
