import { IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { Fragment, useContext, useEffect } from 'react';
import { MeetupContext } from '../../../../../MeetupContext';
import Grid from '@material-ui/core/Grid';
import { ECSelectField } from '../../../../../components/Forms/ECSelectField';
import { Controller } from 'react-hook-form';
import { Settings, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { defaultFieldsMapping, fieldKey } from '../import_utils';

const useStyles = makeStyles(theme => ({
  gearIcon: {
    marginTop: theme.spacing(1),
  },
}));

export const FieldsSetupStep = props => {
  const { stepData, onChange } = props;
  const data = stepData[0].data;
  const fields = stepData[1];

  const meetupContext = useContext(MeetupContext);
  const classes = useStyles();

  const updateField = (f, v) => {
    if (v) {
      fields.mapping[fieldKey(f)] = v;
    } else {
      delete fields.mapping[fieldKey(f)];
    }
    fields.ready = Object.keys(fields.mapping).length > 0;
    onChange(fields);
  };

  useEffect(() => {
    if (Object.keys(fields.mapping).length === 0) {
      fields.mapping = defaultFieldsMapping(meetupContext.fields, data.headers);
      fields.ready = Object.keys(fields.mapping).length > 0;
      onChange(fields);
    }
  }, [data]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Typography variant={'h3'}>Настройка полей</Typography>
      </Grid>

      <Grid item xs={6} md={6}>
        <Typography variant={'h5'}>Откуда</Typography>
      </Grid>
      <Grid item xs={false} md={2}>
        <Typography variant={'h5'}>--&gt;</Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        <Typography variant={'h5'}>Куда</Typography>
      </Grid>

      <Grid item xs={12} md={12}>
        {meetupContext.fields.map(f => (
          <Grid container spacing={1} key={fieldKey(f)}>
            <Grid item xs={5} md={5}>
              <TextField
                select
                fullWidth
                margin='dense'
                value={fields.mapping[fieldKey(f)] || ''}
                onChange={e => {
                  updateField(f, e.target.value);
                }}
              >
                {data.headers.map(h => (
                  <MenuItem key={h[0]} value={h[0]}>
                    {h[1]}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2} md={2}>
              <IconButton
                size='small'
                className={classes.gearIcon}
                onClick={() => {
                  updateField(f, undefined);
                }}
              >
                <Clear />
              </IconButton>
              <IconButton size='small' className={classes.gearIcon} disabled={!fields.mapping[fieldKey(f)]}>
                <Settings />
              </IconButton>
            </Grid>
            <Grid item xs={false} md={1}></Grid>
            <Grid item xs={3} md={4}>
              <TextField fullWidth margin='dense' value={f.label} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
