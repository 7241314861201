import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Topbar from './components/Topbar/Topbar';

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: 'linear-gradient(#203955, #E73B52, #F2A9B3)',
    paddingTop: 64,
    ["@media (max-width:700px)"] : {
      paddingTop: 56,
    },
    height: '100%',
  },
  content: {
    height: '100%',
  },
}));

const Minimal = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
