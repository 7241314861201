import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import {valueByName} from "../../../../../components/Forms/form_utils";

const cyrilicToLatin = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'e',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'kh',
  ц: 'ts',
  ч: 'ch',
  ш: 'sh',
  щ: 'shch',
  ъ: '',
  ы: 'y',
  ь: '',
  э: 'e',
  ю: 'ju',
  я: 'ja',
};

export const transliterate = s => {
  if (typeof s !== 'string') {
    return s
  } else {
    return s
      .split('')
      .map(c => {
        if (cyrilicToLatin.hasOwnProperty(c.toLowerCase())) {
          if (c.toUpperCase() === c) {
            return cyrilicToLatin[c.toLowerCase()][0].toUpperCase() + cyrilicToLatin[c.toLowerCase()].slice(1);
          } else {
            return cyrilicToLatin[c];
          }
        }
        return c;
      })
      .join('');
  }
}

const ExtendedTransliterationField = forwardRef((props, ref) => {
  const { field: f, data: initial, errors, autoFocus, onChange } = props;
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    fillValue: data => {
      data.extended[f.name] = inputRef.current.value.trim();
      return true;
    },
    transliterate: s => {
      if (s && s !== '') {
        inputRef.current.value = transliterate(s);
      }
    },
  }));

  return (
    <TextField
      name={f.name}
      label={f.label}
      required={f.required}
      inputRef={inputRef}
      fullWidth
      margin='dense'
      variant='outlined'
      autoComplete='new-password'
      helperText={errors[f.name]}
      error={!!errors[f.name]}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={e => onChange(e.target.value)}
      defaultValue={(initial.extended && initial.extended[f.name]) || (transliterate(valueByName(initial, f.fieldForTranslit)))}
    />
  );
});

export default ExtendedTransliterationField;
