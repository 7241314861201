import React, { createRef, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardActions, Divider, Grid, Button, TextField } from '@material-ui/core';
import validate from 'validate.js';
import { AppContext } from '../../../../AppContext';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorBlock from '../../../../components/ErrorBlock/ErrorBlock';
import { groupForPreview, visitorForPreview } from '../BadgeView/BadgeView';
import BadgeFieldsAdder, { BadgeField, buildFieldSchema } from './BadgeFields';
import { useParams } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MeetupContext } from '../../../../MeetupContext';
import BadgeViewKonva from '../BadgeViewKonva/BadgeViewKonva';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {},
  editor: {
    border: 'solid',
    borderColor: 'black',
    height: '300px',
    overflow: 'auto',
  },
  pseudoCard: {
    padding: '28px',
    width: '100%',
  },
  headerRoot: {
    padding: 0,
  },
  action: {
    alignSelf: 'center',
    marginTop: 0,
    marginRight: 8,
  },
}));

function useForceUpdate() {
  const [value, setValue] = useState(true); //boolean state
  return () => setValue(!value); // toggle the state to force render
}

const b64toBlob = (b64Data, contentType = 'image/png', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

const BadgeEdit = props => {
  const { badgeId, ...rest } = props;
  const { mid } = useParams();
  const meetupContext = useContext(MeetupContext);
  const appContext = useContext(AppContext);

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      name: 'Новый бейдж',
      width: 148,
      height: 105,
      imageId: 'default-background-image',
      fields: [
        {
          text: '{{firstName}}',
          type: 'text',
          position: { x: 10, y: 10 },
          size: { x: 100, y: 20 },
          font: 'PT Sans',
          bold: false,
          italics: false,
          color: 'black',
          fontSize: 12,
          align: 'center',
          adjustSize: true,
          allowWrap: true,
        },
      ],
    },
    touched: {},
    errors: {},
  });
  const forceUpdate = useForceUpdate();

  const [selectedField, setSelectedField] = useState(-1);

  useEffect(() => {
    if (badgeId) {
      appContext.badgeApi
        .getBadge(badgeId)
        .then(({ data }) => {
          setFormState({
            values: data,
            touched: {},
            errors: {},
            loaded: true,
          });
          // console.log('loading badge data', data)
        })
        .catch(appContext.errorHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgeId]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => {
      return {
        ...formState,
        isValid: !errors,
        errors: errors || {},
      };
    });
  }, [formState.values]);

  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  const handleChange = event => {
    event.persist();
    // console.log('Handle change: ' + event.target.name + ' - ' + event.target.value);

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleChangeInt = event => {
    event.persist();
    // console.log('Handle change: ' + event.target.name + ' - ' + event.target.value);

    let value = parseInt(event.target.value);
    if (Number.isNaN(value)) {
      value = 0;
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  
  const [borders, setBorders] = useState(false);

  const handleSave = event => {
    event.preventDefault();
    const errors = validate(formState.values, schema) || {};

    if (Object.keys(errors || {}).length > 0) {
      setFormState(formState => {
        return {
          ...formState,
          isValid: false,
          errors: errors,
        };
      });
    } else {
      const backingUrl = mid ? '/m/' + mid + '/badge' : '/badge';
      setSelectedField(-1);
      const target = event.target;
      setTimeout(() => {
        const canvas = target.getElementsByTagName('canvas');
        const base64Image = canvas[0].toDataURL('image/png');
        const imageByte = b64toBlob(base64Image.replace(/^data:image\/(\w+);base64,/g, ''));
        appContext.imageApi.uploadImage(undefined, undefined, imageByte).then(({ data }) => {
          formState.values.previewImageId = data.imageId;
          for (let field of formState.values.fields) {
            if ((field.type === 'text' || field.type === 'textarea') && field.font) {
              window.localStorage.setItem(`lastUsedFont-${appContext.session.accountId}-${meetupContext.meetupId}-${badgeId}`, field.font)
            }
          }

          if (badgeId) {
            appContext.badgeApi
              .updateBadge(badgeId, formState.values)
              .then(payload => {
                appContext.history.push(backingUrl);
              })
              .catch(error => {
                handleServerError(error);
              });
          } else {
            appContext.badgeApi
              .createBadge(formState.values, mid)
              .then(({ data }) => {
                appContext.history.push(backingUrl);
              })
              .catch(error => {
                handleServerError(error);
              });
          }
        });
      }, 16);
    }
  };

  const handleServerError = error => {
    const data = error.response.data;
    const errors = data.errors || [data];
    const fieldErrors = {};
    let mainError = null;
    errors.forEach(e => {
      if (e.args.field) {
        fieldErrors[e.args.field] = fieldErrors[e.args.field] || [];
        fieldErrors[e.args.field].push(e.message);
      } else if (mainError === null) {
        mainError = e.message;
      }
    });
    setFormState(formState => {
      return {
        ...formState,
        isValid: false,
        error: mainError,
        errors: fieldErrors,
        touched: {
          name: true,
          text: true,
        },
      };
    });
  };

  const inputBgRef = createRef();
  const uploadImage = event => {
    const file = event.target.files[0];
    appContext.imageApi.uploadImage(undefined, undefined, file).then(({ data }) => {
      formState.values.imageId = data.imageId;
      formState.touched.imageId = true;
      setFormState(fs => formState);
      forceUpdate();
    });
  };

  const removeImage = event => {
    formState.values.imageId = 'default-background-image';
    formState.touched.imageId = true;
    setFormState(fs => formState);
    inputBgRef.current.value = '';
    forceUpdate();
  };

  if (badgeId && !formState.loaded) {
    return null;
  }

  const setFormStateFieldPosition = (fieldIndex, values) => {
    let field = { ...formState.values.fields[fieldIndex], ...values };
    let newFields = [...formState.values.fields];

    newFields.splice(fieldIndex, 1, field);

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        fields: [...newFields],
      },
    });
  };

  const fieldSchema = buildFieldSchema(meetupContext.fields);

  return (
    <>
      <Card {...rest} className={clsx(classes.root)}>
        <form autoComplete='off' noValidate onSubmit={handleSave}>
          <CardHeader
            classes={{
              root: clsx(classes.headerRoot),
              action: classes.action,
            }}
            action={
              <Button color='primary' variant='contained' type='submit'>
                Сохранить
              </Button>
            }
            avatar={
              <IconButton onClick={() => appContext.history.goBack()}>
                <ArrowBackIcon />
              </IconButton>
            }
            title={
              mid
                ? badgeId
                  ? 'Редактирование бейджа'
                  : 'Создание бейджа'
                : badgeId
                ? 'Редактирование шаблона'
                : 'Создание шаблона'
            }
            subheader={badgeId ? 'badgeId: ' + badgeId : null}
          />

          <Divider />
          <ErrorBlock message={formState.error} />
          <Grid container spacing={3}>
            <div className={classes.pseudoCard}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      helperText={hasError('name') ? formState.errors.name[0] : null}
                      error={hasError('name')}
                      label='Название'
                      margin='dense'
                      name='name'
                      onChange={handleChange}
                      required
                      value={formState.values.name}
                      variant='outlined'
                      autoComplete={'off'}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          helperText={hasError('width') ? formState.errors.width[0] : null}
                          error={hasError('width')}
                          label='Ширина (мм)'
                          margin='dense'
                          name='width'
                          onChange={handleChangeInt}
                          required
                          value={formState.values.width}
                          variant='outlined'
                          autoComplete={'off'}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          helperText={hasError('height') ? formState.errors.height[0] : null}
                          error={hasError('height')}
                          label='Высота (мм)'
                          margin='dense'
                          name='height'
                          onChange={handleChangeInt}
                          required
                          value={formState.values.height}
                          variant='outlined'
                          autoComplete={'off'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Divider />
          <Grid container spacing={3}>
            <div className={classes.pseudoCard}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <BadgeFieldsAdder
                      fields={formState.values.fields}
                      onChange={(fields, removedFieldIndex) => {
                        if (removedFieldIndex > -1) {
                          setSelectedField(-1);
                        }
                        formState.values.fields = fields;
                        setFormState(formState);
                        forceUpdate();
                      }}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                      fieldSchema={fieldSchema}
                      badgeId={badgeId}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <input
                          ref={inputBgRef}
                          accept='image/*'
                          className={classes.input}
                          style={{ display: 'none' }}
                          id='raised-button-file'
                          type='file'
                          onChange={uploadImage}
                        />
                        <label htmlFor='raised-button-file'>
                          <Button component='span' className={classes.button}>
                            Загрузить фон
                          </Button>
                        </label>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          component='span'
                          className={classes.button}
                          onClick={removeImage}
                          disabled={formState.values.imageId === 'default-background-image'}
                        >
                          Удалить фон
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={e => {
                                setFormState(formState => ({
                                  ...formState,
                                  values: {
                                    ...formState.values,
                                    dontPrintImage: e.target.checked,
                                  },
                                  touched: {
                                    ...formState.touched,
                                    dontPrintImage: true,
                                  },
                                }));
                              }}
                              checked={formState.values.dontPrintImage}
                            />
                          }
                          label='Не печатать фон'
                          labelPlacement={'start'}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <BadgeViewKonva
                        badge={formState.values}
                        group={groupForPreview()}
                        visitor={visitorForPreview(meetupContext.fields)}
                        setFormStateFieldPosition={setFormStateFieldPosition}
                        showBorders={borders}
                        externalImage={''}
                        setSelectedField={setSelectedField}
                        selectedField={selectedField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={e => {
                              setBorders(e.target.checked);
                            }}
                            checked={borders}
                          />
                        }
                        label='Показывать рамки полей'
                        labelPlacement={'start'}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <BadgeField
                      fieldSchema={fieldSchema}
                      field={formState.values.fields[selectedField]}
                      onChange={field => {
                        
                        formState.values.fields[selectedField] = field;
                        setFormState(formState);
                        forceUpdate();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Divider />
          <CardActions>
            <Button color='primary' variant='contained' type='submit'>
              Сохранить
            </Button>
          </CardActions>
        </form>
      </Card>
    </>
  );
};

BadgeEdit.propTypes = {
  badgeId: PropTypes.string,
};

export default BadgeEdit;
