import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { AppContext } from '../../../../../AppContext';
import { ECCommonErrorBlock } from '../../../../../components/Forms/ECCommonErrorBlock';
import { ECTextField } from '../../../../../components/Forms/ECTextField';
import { ECSelectField } from '../../../../../components/Forms/ECSelectField';
import {ECPhotoField} from "../../../../../components/Forms/ECPhotoField";

const HallEditForm = props => {
  const { meetupId, form, initial } = props;

  return (
    <>
      <ECCommonErrorBlock form={form} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ECTextField label='Название' name='name' initial={initial} form={form} />
        </Grid>
        <Grid item xs={12}>
          <ECTextField label='Как добраться' name='location' initial={initial} form={form}
                       multiline
                       rows={4}/>
        </Grid>
        <Grid item xs={12}>
          <ECPhotoField label='Фото' name='imageId' form={form} initial={initial} />
        </Grid>
      </Grid>
    </>
  );
};

export default HallEditForm;
