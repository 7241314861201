import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Button, Typography} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  row: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1,
  },
  balance: {
    marginTop: theme.spacing(1)+2,
    marginLeft: theme.spacing(2),
    fontWeight: "bold"
  }
}));

const PurchasesToolbar = props => {
  const { className, meetupId, info, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Typography className={classes.balance}>Баланс: {info.balance}</Typography>
        <span className={classes.spacer} />
        <Button color='primary' variant='contained'>
          Вывести деньги
        </Button>
      </div>
    </div>
  );
};

export default PurchasesToolbar;
