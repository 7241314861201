import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
}));

const TariffCard = props => {
  const { tariff, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify='space-between'>
          <Grid item>
            <Typography className={classes.title} color='textSecondary' gutterBottom variant='body2'>
              {tariff.name}
            </Typography>
            <Typography variant='h3'>{tariff.price} руб.</Typography>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          <Typography className={classes.caption} variant='caption'>
            {tariff.description}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

TariffCard.propTypes = {};

export default TariffCard;
