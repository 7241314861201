import React, { useContext, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, TextField, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ModalDialog from 'components/ModalDialog/ModalDialog';
import { AppContext } from 'AppContext';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center'
  },

  label: {
    flexBasis: '50%',
    fontSize: 15
  },

  input: {
    height: 46,
    boxSizing: 'border-box',
    padding: '10px 15px',
    lineHeight: '26px',
  }

}));


const ChangeTariffStandart = ({ accountId, cardNumber, price}) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const [card, setCard] = useState(cardNumber ? 'cardNumber' : 'newCard');
  const [rememberCard, setRememberCard] = useState(true);


  const handleOk = () => {

    if (card === 'newCard') {
      let origin = window.location.hostname === 'localhost' ? process.env.REACT_APP_EVENTOS_PROXY : window.location.origin
      appContext.accountApi.buyOnline(accountId, {
        amount: price,
        orderDescription: `Ежемесячный платеж по Стандартному тарифу`,
        currency: 'RUB',
        returnUrl: `${origin}/profile/account`,
        failUrl: `${origin}/profile/account/buy-pack-error/changePlan`,
        rememberCard: rememberCard,
        goal: 'changePlan',
        name: 'standart_30d',
      })
        .then((res) => {
          appContext.history.push('/profile/account')
          window.open(res.data)
        })
        .catch(appContext.errorHandler)
    } else {
      appContext.accountApi.buyWithRebill(accountId, {
        amount: price,
        orderDescription: `Ежемесячный платеж по Стандартному тарифу`,
        currency: 'RUB',
        goal: 'changePlan',
        name: 'standart_30d'
      })
        .then((res) => {
          if (res.data.result === 'OK') {
            window.location.href = window.location.origin + '/profile/account/buy-rebill-result/ok';
          } else {
            appContext.history.push('/profile/account/buy-rebill-result/error');
          }
        })
        .catch(() => { appContext.history.push('/profile/account/buy-rebill-result/error'); })
    }

  }

  return (
    <ModalDialog
      size='sm'
      handleClose={() => appContext.history.push('/profile/account')}
      title={'Подключение Стандартного тарифа'}
      handleOk={() => handleOk()}
      okButtonText={'Оплатить'}
    >
      <>
        <Typography style={{ textAlign: 'center', marginBottom: 20 }}>
          После оплаты ежемесячного платежа станут доступны все опции тарифа.
        </Typography>
        <div className={classes.row} style={{ marginBottom: 20 }}>

          <Typography className={classes.label} style={{ fontWeight: 'bold' }}>
            Стоимость
          </Typography>
          <Typography className={classes.label} style={{ fontWeight: 'bold' }}>
            {price} руб.
          </Typography>
        </div>
        <div className={classes.row} style={{ marginBottom: 20 }}>
          <Typography
            component='label'
            className={classes.label}
            for='card'
          >
            Карта для оплаты:
          </Typography>
          <TextField
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            style={{ flexBasis: '50%' }}
            variant='outlined'
            size='small'
            value={card}
            onChange={event => setCard(event.target.value)}
            select
            id='card'
          >
            <MenuItem value='newCard'>Новая карта</MenuItem>
            {cardNumber && <MenuItem value='cardNumber'>{cardNumber}</MenuItem>}
          </TextField>
        </div>
        {
          card === 'newCard' &&
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberCard}
                onChange={e => setRememberCard(e.target.checked)}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            }
            label='Запомнить карту'
          />
        }
      </>
    </ModalDialog>
  );
}

export default ChangeTariffStandart;