import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Fab from '@material-ui/core/Fab';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

const UsersTable = props => {
  const { className, users, handleDelete, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Пользователь</TableCell>
                <TableCell>Последняя активность</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow className={classes.tableRow} hover key={user.userId}>
                  <TableCell>
                    <ListItemText primary={user.displayName} secondary={user.email} />
                  </TableCell>
                  <TableCell>
                    {user.active ? (user.lastActivity ? user.lastActivity.substr(0, 10) : 'Never seen') : 'Inactive'}
                  </TableCell>
                  <TableCell>
                    <RouterLink to={'/user/id/' + user.userId}>
                      <Fab size='small' color='secondary' aria-label='edit' className={classes.fab}>
                        <EditIcon />
                      </Fab>
                    </RouterLink>
                    <Fab
                      size='small'
                      color='secondary'
                      aria-label='delete'
                      className={classes.fab}
                      onClick={() => handleDelete(user.userId)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
};

export default UsersTable;
