import React, { useContext, Fragment } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Typography, Divider, Button } from '@material-ui/core';

import { AppContext } from 'AppContext';

const useStyles = makeStyles(theme => ({
  info: {
    height: 340,
    width: 260,
    padding: '20px 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 4,
    border: '2px solid #213955',
    boxShadow: '0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15)'
  },
  name: {
    textTransform: 'uppercase',
    color: '#607D8B',
    fontSize: 11,
    marginTop: 20,
    fontWeight: 500,
  },
  description: {
    textTransform: 'uppercase',
    color: '#607D8B',
    fontSize: 11,
    marginTop: 15,
    fontWeight: 500,
    textAlign: 'center'
  },
  price: {
    marginTop: 15,
    fontSize: 20,
    color: '#263238',
    fontWeight: 500,
  },
  divider: {
    marginTop: 15,
    width: 182
  },
  feature: {
    fontSize: 10,
    textAlign: 'center',
    lineHeight: '15px',
    marginTop: 10
  },
  featureList: {
    listStyleType: 'none'
  },
  button: {
    margin: '45px auto 0',
    width: 235,
    display: 'block'
  }
}));

const Tariff = ({ data, disabled }) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const { icon, price, description, features, name, id} = data;

  return (
    <div>
      <div className={classes.info}>
        {icon}
        <Typography className={classes.name}>{name}</Typography>
        <Typography className={classes.price}>{price}</Typography>
        <Typography className={classes.description}>
          {
            description.map(r => (<> {r} <br/> </>))
          }
        </Typography>
        <Divider className={classes.divider}/>
        <ul className={classes.featureList}>
          {
            features.map((f, i) => <li><Typography key={i} className={classes.feature}>{f}</Typography></li>)
          }
        </ul>
      </div>
      <Button 
        className={classes.button} 
        variant='outlined' 
        disabled={disabled}
        onClick={() => appContext.history.push('/profile/account/change-tariff/' + id)}
      > 
        { disabled ? 'Подключен': 'Подключить'} 
      </Button>
    </div>
   
  );
}

export default Tariff;