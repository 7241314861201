import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../AppContext';
import { useParams } from 'react-router-dom';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import RegFormToolbar from "./components/RegFormToolbar";
import RegFormSettings from "./components/RegFormSettings";
import RegFormMail from "./components/RegFormMail";
import RegFormClose from "./components/RegFormClose";
import {errorsToForm} from "../../components/Forms/form_utils";
import * as _ from "underscore";

const useStyles = makeStyles(theme => ({
  link: {
    marginTop: '8px',
  },
  root: {
    padding: theme.spacing(3),
  },
}));

const OnlineRegistration = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const [settings, setSettings] = useState(undefined);
  const [regForm, setRegForm] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    appContext.configApi.getSettings(meetupId).then(({ data }) => {
      setSettings(data)
      setRegForm(data.regForm);
    });
  }, [meetupId]);

  const save = (data, form) => {
    appContext.configApi.getSettings(meetupId).then(({ data: original }) => {
      data = _.extend(original.regForm, data)

      appContext.configApi
        .setRegFormSettings(meetupId, data)
        .then(({ data: newData }) => {
          setRegForm(newData);
          setError(undefined);
          if (form) {
            Object.keys(form.errors).forEach(k => form.clearError(k));
          }
        })
        .catch(error => {
          const data = error.response.data;
          setError(data.message);
          if (form) {
            errorsToForm(form)(error)
          }
        });
    });

  };

  if (!regForm) {
    return null;
  }

  return (
    <div className={classes.root}>
      <RegFormToolbar regForm={regForm} save={save}/>

      <RegFormSettings regForm={regForm} fields={settings.fields.fields} save={save}/>
      <RegFormMail regForm={regForm} fields={settings.fields.fields} save={save}/>
      <RegFormClose regForm={regForm} save={save}/>
    </div>
  );
};

export default OnlineRegistration;
