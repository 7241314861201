import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../../AppContext';
import TimeSlotEditForm from '../TimeSlotEditForm/TimeSlotEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../../../components/Forms/form_utils';

const TimeSlotAdd = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const form = useForm();

  const handleSave = () => {
    const data = form.getValues();
    if (data.hallId) {
      data.hall = {hallId: data.hallId}
    }
    if (data.speakerIds) {
      data.speakers = data.speakerIds.map(v => {return {speakerId:v}})
    }
    appContext.timeSlotApi
      .createTimeSlot(meetupId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  return (
    <ModalDialog title={'Добавление слота'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <TimeSlotEditForm meetupId={meetupId} form={form} />
    </ModalDialog>
  );
};

TimeSlotAdd.propTypes = {};

export default TimeSlotAdd;
