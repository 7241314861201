import {makeStyles} from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Button} from "@material-ui/core";
import React from "react";
import {ECCommonErrorBlock} from "../Forms/ECCommonErrorBlock";
import Grid from "@material-ui/core/Grid";
import TopNotice from "../TopNotice/TopNotice";


const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: theme.shadows[5],
    margin: 'auto',
    marginBottom: theme.spacing(4),
  },
  topDiv: {
    padding: theme.spacing(2, 4),
  },
  middleDiv: {
    padding: theme.spacing(2, 4),
  },
  bottomDiv: {
    textAlign: 'right',
    padding: theme.spacing(2, 4),
  },
  divider1: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(3),
  },
  divider2: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(2),
  },
  space: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));


const CardBlock = props => {
  const { title, form, save, hint, children, ...rest } = props;

  const classes = useStyles();


  return (
    <Paper className={classes.paper}>
      <Grid container spacing={0} className={classes.topDiv}>
        <Grid xs={8}>
          <Typography variant='h4'>{title}</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography style={{marginTop:"4px"}}>{hint}</Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider1} />

      <div className={classes.middleDiv}>
      <ECCommonErrorBlock form={form} />
      {children}
      </div>

      <Divider className={classes.divider2} />
      <div className={classes.bottomDiv}>
        <Button color='primary' variant='contained' className={classes.space} onClick={form.handleSubmit(save)}>
          Сохранить
        </Button>
      </div>
    </Paper>
  )
}

export default CardBlock;
