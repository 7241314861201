import React, { createContext } from 'react';
import { FieldData, GroupData } from './api';
import { AppContext, AppContextData } from 'AppContext';

interface MeetupContextProps {
  meetupId: number;
}

interface MeetupContextData {
  meetupId: number;
  fields: Array<FieldData>;
  groups: GroupData[];
  update: () => void
}

interface MeetupContextState {
  loading: boolean;
  fields: Array<FieldData>;
  groups: GroupData[];
}

const MeetupContext = createContext<MeetupContextData>({
  meetupId: -1,
  fields: [],
  groups: [],
  update: () => {}
});

class MeetupContextProvider extends React.Component<MeetupContextProps, MeetupContextState> {
  static contextType = AppContext;

  constructor(props: MeetupContextProps) {
    super(props);
    this.state = {
      fields: [],
      groups: [],
      loading: true,
    };
  }

  componentDidMount(): void {
    this.getSettings();
  }

  getSettings = () => {
    if (this.props.meetupId) {
      let ctx = this.context as AppContextData;
      ctx.configApi
        .getSettings(this.props.meetupId)
        .then(({data:settings}) => {
          ctx.groupApi
            .listGroups(this.props.meetupId)
            .then(({data:groups}) => {
              this.setState({
                fields: settings.fields.fields!,
                groups: groups!,
                loading: false,
              });
            })
            .catch(ctx.errorHandler);
        })
        .catch(ctx.errorHandler);
    } else {
      this.setState({
        fields: [],
        groups: [],
        loading: false,
      });
    }
  };

  errorHandler = (error: any) => {
    console.log('MeetupContext error handler', error);
    const { config, data } = error.response;
  };

  render() {
    if (this.state.loading) return null;
    return (
      <MeetupContext.Provider
        value={{
          meetupId: this.props.meetupId,
          fields: this.state.fields,
          groups: this.state.groups,
          update: this.getSettings
        }}
      >
        {this.props.children}
      </MeetupContext.Provider>
    );
  }
}

export { MeetupContext, MeetupContextProvider };
