import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { WSContext } from '../../WSContext';

const RootRedirect = () => {
  const appContext = useContext(AppContext);
  const wsContext = useContext(WSContext);

  if (appContext.loading || !wsContext.connected) {
    return <>waiting for session...</>;
  }
  if (!appContext.session) {
    return <Redirect to='/sign-in' />;
  } else if (appContext.session.accountId === -1) {
    return <Redirect to='/sa/account' />;
  } else if (appContext.session.permissions.indexOf('reception') !== -1) {
    return <Redirect to='/reception' />;
  } else {
    return <Redirect to='/meetup' />;
  }
};

export default RootRedirect;
