import React, { Component, useContext } from 'react';
import { Router, useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import themeDefault from './theme/default';
import themePoly from './theme/polytech';
import themeEventos from './theme/eventos';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import { AppContext, AppContextProvider } from './AppContext';
import { Switch, Redirect } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';

import UserList from './views/UserList/UserList';
import MeetupList from './views/MeetupList/MeetupList';
import SignIn from './views/SignIn/SignIn';
import NotFound from './views/NotFound/NotFound';
import Minimal from './layouts/Minimal/Minimal';
import Main from './layouts/Main/Main';
import Visitors from './views/Visitors/Visitors';
import VisitorRegistration from './views/VisitorRegistration/VisitorRegistration';
import OnlineRegistration from './views/OnlineRegistration/OnlineRegistration';
import BadgeList from './views/BadgeList/BadgeList';
import BadgeDetails from './views/BadgeDetails/BadgeDetails';
import moment from 'moment';
import 'moment/locale/ru';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Registration from './views/Registration/Registration';
import Favicon from 'react-favicon';
import { SnackbarProvider } from 'notistack';
import Chat from './views/Chat/Chat';
import DeskList from './views/DeskList/DeskList';
import Report from './views/Report/Report';
import SignInReception from './views/SignInReception/SignInReception';
import Reception from './views/Reception/Reception';
import Empty from './layouts/Empty/Empty';
import { WSContextProvider, WSContext } from './WSContext';
import Account from './views/AccountList/Account';
import RootRedirect from './views/RootRedirect/RootRedirect';
import TariffList from './views/TariffList/TariffList';
import PaymentList from './views/PaymentList/PaymentList';
import Profile from './views/Profile/Profile';
import RegistrationConfirmed from './views/Registration/components/RegistrationConfirmed';
import ConfirmationFailed from './views/Registration/components/ConfirmationFailed';
import RouteWithLayout from './components/RouteWithLayout/RouteWithLayout';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { TopNoticeContext, TopNoticeContextProvider } from './components/TopNotice/TopNoticeContext';
import { RestaurantRounded } from '@material-ui/icons';
import RegistrationCompleted from 'views/Registration/components/RegistrationCompleted';
import {ScannerEventContextProvider} from "./ScannerEventContext";
import { defaultFonts, fetchGoogleFonts, loadFontToBrowser } from 'common/fonts'
import EventInfo from "./views/EventInfo/EventInfo";
import EventProgram from "./views/EventProgramm/EventProgram";
import RegForm from "./views/RegForm/RegForm";
import BadgePrintInternal from "./views/BadgePrintInternal/BadgePrintInternal";
import MailTemplateParams from "./views/OnlineRegistration/components/MailTemplateParams";
import MerchList from "./views/Merch/MerchList";
import NewsList from "./views/News/NewsList";
import strings from "./strings";
import RequestForChangePassword from "./views/ChangePassword/RequestForChangePassword";
import ChangePassword from "./views/ChangePassword/commponents/ChangePassword";
import ChangePasswordSuccess from "./views/ChangePassword/commponents/ChangePasswordSuccess";
import qrConfirm from 'views/QrConfirm/QrConfirm';
import QrConfirm from 'views/QrConfirm/QrConfirm';

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators,
};

export const selectTheme = () => {
  strings.setLanguage("ru")
  if (window.location.hostname.indexOf('olyreg') !== -1) {
    return themePoly;
  }
  return themeEventos;
};

const isProduction = window.location.hostname === 'app.eventos42.ru';

export default class App extends Component {
  static contextType = AppContext;

  appendYM = () => {
    if (window.location.href.indexOf("regform") !== -1 || window.location.href.indexOf("reception") !== -1 || window.location.href.indexOf("localhost") !== -1 ) {
      return
    }
    if (!isProduction) {
      return
    }
    browserHistory.listen(location => {
      ym('hit', location.pathname);
    });
  }

  appendGoogleTM = () => {
    // if (window.location.href.indexOf("regform") !== -1 || window.location.href.indexOf("reception") !== -1 || window.location.href.indexOf("localhost") !== -1 ) {
    //   return
    // }
    // if (!isProduction) {
    //   return
    // }
    //
    // const googleTMScript = document.createElement('script');
    // googleTMScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    //     })(window,document,'script','dataLayer','GTM-MNWRM6K');`
    // document.head.insertBefore(googleTMScript, document.head.firstElementChild)
    //
    // const googleTMNoScript = document.createElement('noscript');
    // googleTMNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MNWRM6K"
    //   height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    // document.body.insertBefore(googleTMNoScript, document.body.firstElementChild)
  }

  appendSupportWidgetJAICP = () => {
    const theme = selectTheme();
    let value = this.context

    let zenBot = document.createElement('script');
    zenBot.async = true;
    if (window.location.href.indexOf("regform") !== -1 || window.location.href.indexOf("reception") !== -1 || window.location.href.indexOf("localhost") !== -1 ) {
      return
    }
    if (isProduction) {
      browserHistory.listen(location => {
        ym('hit', location.pathname);
      });
      zenBot.src =
        'https://zenbot-dev.just-ai.com/chatadapter/chatwidget/aemwWjyV:1a90845d40e6d0a0adb950b05c539053868dd963/justwidget.js';

      const googleTMScript = document.createElement('script');
      googleTMScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MNWRM6K');`
      document.head.insertBefore(googleTMScript, document.head.firstElementChild)

      const googleTMNoScript = document.createElement('noscript');
      googleTMNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MNWRM6K"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      document.body.insertBefore(googleTMNoScript, document.body.firstElementChild)
    } else {
      zenBot.src =
        'https://zenbot-dev.just-ai.com/chatadapter/chatwidget/AItFWCsw:156c5ea2102b18471b60c9e337c790f4817ac5ce/justwidget.js';
    }
    if (theme.chatIcon) {
      document.body.appendChild(zenBot);
    }
  }

  appendSupportCrisp = () => {
    const theme = selectTheme();

    if (window.location.href.indexOf("regform") !== -1 || window.location.href.indexOf("reception") !== -1 || window.location.href.indexOf("localhost") !== -1 ) {
      return
    }
    if (!theme.chatIcon) {
      return
    }
    if (!isProduction) {
      return
    }

    window.$crisp=[];
    window.CRISP_WEBSITE_ID="b8fa4d5f-8e7c-4c1f-bd7b-2acb5d661771"

    const {session} = this.context;
    if (session) {
      window.$crisp.push(["set", "user:email", session.userEmail]);
      window.$crisp.push(["set", "session:data", ["accountId", session.accountId]]);
    }

    const cripsScript = document.createElement('script');
    cripsScript.src="https://client.crisp.chat/l.js";
    cripsScript.async=true;
    document.getElementsByTagName("head")[0].appendChild(cripsScript);
  }

  componentDidMount() {
    this.appendYM()
    this.appendGoogleTM()
    this.appendSupportCrisp()

    // this.appendSupportWidgetJAICP()
  }

  render() {
    moment.locale('ru');

    const arr = window.location.href.split('/');
    const url = arr[0] + '//' + arr[2];

    const theme = selectTheme();
    document.title = theme.documentTitle;
    return (
      <ThemeProvider theme={theme}>
        {isProduction && <YMInitializer accounts={[62529322]} options={{ webvisor: true }} version='2' />}
        <Favicon url={theme.topIcon} />

        <ScannerEventContextProvider>
        <TopNoticeContextProvider>
          <TopNoticeContext.Consumer>
            {tn => (
              // Такой странный способ (topNotice={tn}) передачи зависимости я использую только потому, что не нашёл другого для класс-компонентов.
              // По идее, зависимости на нужные контексты должны быть упакованы внутри компонентов WSContextProvider и AppContextProvider,
              // а консьюмеров здесь быть не должно
              <WSContextProvider topNotice={tn}>
                <WSContext.Consumer>
                  {ws => (
                    <AppContextProvider apiUrl={url} history={browserHistory} ws={ws} topNotice={tn}>
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'ru'}>
                        <AppContext.Consumer>{({ loading }) => loading && <LoadingSpinner />}</AppContext.Consumer>
                        <AppContext.Consumer>
                          {({ session }) => {
                            if (session) {
                              let savedFonts = JSON.parse(window.localStorage.getItem('fonts' + session.accountId)) || [] 
                              for (let item of savedFonts) {
                                loadFontToBrowser(item.family, item.file.replace('http://', 'https://'))
                              }
                            }

                            fetchGoogleFonts()
                                .then(googleFonts => {
                                  for (let defaultFont of defaultFonts) {
                                    for (let item of googleFonts.items) {
                                      if (item.family === defaultFont) {
                                        loadFontToBrowser(item.family, item.files.regular.replace('http://', 'https://'))
                                      }
                                    }
                                  }
                                })
                                .catch(console.error)
                               
                            window.JustWidgetSendRawData = function () {
                              let path = window.location.pathname;
                              let meetupId;
                              if (path.startsWith('/m/')) {
                                path = path.slice(3);
                                let slashIndex = path.indexOf('/');
                                meetupId = path.slice(0, slashIndex);
                              }
                              return {
                                accountId: session.accountId,
                                userId: session.userId,
                                meetupId: meetupId,
                              };
                            };
                          }}
                        </AppContext.Consumer>
                        <SnackbarProvider>
                          <Router history={browserHistory}>
                            <Switch>
                              <RouteWithLayout component={RootRedirect} layout={Empty} path='/' exact />

                              <RouteWithLayout component={UserList} layout={Main} path='/user' auth={'admin'} />

                              <RouteWithLayout path='/meetup' component={MeetupList} layout={Main} auth={'admin'} />

                              <RouteWithLayout
                                path='/regform/:token'
                                component={RegForm}
                                layout={Empty}
                              />

                              <RouteWithLayout
                                path='/m/:mid/visitor'
                                component={Visitors}
                                layout={Main}
                                auth={'user'}
                              />

                              <RouteWithLayout path='/m/:mid/group' component={Visitors} layout={Main} auth={'admin'} />

                              <RouteWithLayout
                                path='/m/:mid/badge/id/:badgeId'
                                exact
                                component={BadgeDetails}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout
                                path='/m/:mid/badge'
                                component={BadgeList}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout
                                path='/m/:mid/info'
                                component={EventInfo}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout
                                path='/m/:mid/program'
                                component={EventProgram}
                                layout={Main}
                                auth={'admin'}
                              />

                              <RouteWithLayout path='/badge' exact component={BadgeList} layout={Main} auth={'admin'} />
                              <RouteWithLayout
                                path='/badge/add'
                                exact
                                component={BadgeDetails}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout
                                path='/badge/id/:badgeId'
                                exact
                                component={BadgeDetails}
                                layout={Main}
                                auth={'admin'}
                              />

                              <RouteWithLayout
                                path='/m/:mid/settings'
                                component={Visitors}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout
                                path='/m/:mid/chat'
                                component={Chat}
                                layout={Main}
                                auth={'admin'}
                                feature={'chat'}
                              />
                              <RouteWithLayout
                                path='/m/:mid/desk'
                                component={VisitorRegistration}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout
                                path='/m/:mid/merch'
                                component={MerchList}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout
                                path='/m/:mid/news'
                                component={NewsList}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout
                                path='/m/:mid/purchase'
                                component={MerchList}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout
                                path='/m/:mid/online-registration'
                                component={VisitorRegistration}
                                layout={Main}
                                auth={'admin'}
                              />
                              <RouteWithLayout path='/m/:mid/stat' component={Report} layout={Main} auth={'user'} />

                              <RouteWithLayout
                                component={Reception}
                                layout={Empty}
                                path='/reception'
                                auth={'reception'}
                              />

                              <RouteWithLayout
                                component={Account}
                                layout={Main}
                                path='/sa/account'
                                auth={'superAdmin'}
                              />

                              <RouteWithLayout
                                component={TariffList}
                                layout={Main}
                                path='/sa/tariff'
                                auth={'superAdmin'}
                              />

                              <RouteWithLayout
                                component={PaymentList}
                                layout={Main}
                                path='/sa/payment'
                                auth={'superAdmin'}
                              />

                              <RouteWithLayout
                                component={BadgePrintInternal}
                                exact
                                layout={Empty}
                                path='/badge-print-internal'
                              />

                              <RouteWithLayout
                                component={MailTemplateParams}
                                exact
                                layout={Main}
                                path='/m/:mid/template-params'
                                auth={'admin'}
                              />

                              <RouteWithLayout component={Profile} layout={Main} path='/profile' auth={'user'} />

                              <RouteWithLayout component={SignIn} exact layout={Minimal} path='/sign-in' />
                              <RouteWithLayout
                                component={SignInReception}
                                exact
                                layout={Minimal}
                                path='/sign-in-reception'
                              />
                              <RouteWithLayout
                                component={Registration}
                                exact
                                layout={Minimal}
                                path='/registration'
                                withRegistrationAvailable
                              />
                              <RouteWithLayout
                                component={RegistrationConfirmed}
                                exact
                                layout={Minimal}
                                path='/registration-completed'
                                withRegistrationAvailable
                              />
                              <RouteWithLayout
                                component={RegistrationCompleted}
                                exact
                                layout={Minimal}
                                path='/registration-success'
                                withRegistrationAvailable
                              />
                              <RouteWithLayout
                                component={ConfirmationFailed}
                                exact
                                layout={Minimal}
                                path='/registration-failed'
                                withRegistrationAvailable
                              />
                              <RouteWithLayout
                                component={RequestForChangePassword}
                                layout={Minimal}
                                path='/request-change-password'
                                withRegistrationAvailable
                              />
                              <RouteWithLayout
                                component={ChangePassword}
                                layout={Minimal}
                                path='/change-password/:token'
                                withRegistrationAvailable
                              />
                              <RouteWithLayout
                                component={ChangePasswordSuccess}
                                layout={Minimal}
                                path='/change-password-success'
                                withRegistrationAvailable
                              />
                              <RouteWithLayout
                                component={QrConfirm}
                                layout={Empty}
                                path='/qrConfirm/:regFormToken'  
                              />
                              <RouteWithLayout component={NotFound} exact layout={Minimal} path='/not-found' />
                              <Redirect to='/not-found' />
                            </Switch>
                          </Router>
                        </SnackbarProvider>
                      </MuiPickersUtilsProvider>
                    </AppContextProvider>
                  )}
                </WSContext.Consumer>
              </WSContextProvider>
            )}
          </TopNoticeContext.Consumer>
        </TopNoticeContextProvider>
        </ScannerEventContextProvider>
      </ThemeProvider>
    );
  }
}

App.contextType = AppContext;
