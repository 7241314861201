import { Link, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { convertDataToVisitors } from '../import_utils';
import { AppContext } from '../../../../../AppContext';
import { MeetupContext } from '../../../../../MeetupContext';
import { convertErrors } from './ValidationStep';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ErrorBlock from 'components/ErrorBlock/ErrorBlock';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles(theme => ({
  copied: {
    padding: theme.spacing(2),
  },
}));

export const FinishStep = props => {
  const { stepData, setError, error } = props;
  const data = stepData[0].data;
  const fields = stepData[1];
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const [result, setResult] = useState();

  // TODO: create dedicated component to show error logs
  const [copied, setCopied] = useState(false);
  const anchorEl = useRef();
  const classes = useStyles();

  useEffect(() => {
    const [visitors, clientErrors] = convertDataToVisitors(data, fields, meetupContext);

    const importRequest = {
      onError: stepData[2].onError,
      onDuplicate: stepData[2].onDuplicate,
      visitors: visitors,
      dryRun: false,
    };

    appContext.visitorApi
      .importVisitors(meetupContext.meetupId, importRequest)
      .then(({ data: result }) => {
        const [errors, duplcates] = convertErrors(clientErrors, result.errors, visitors);

        setResult({
          addedCount: result.addedCount,
          updatedCount: result.updatedCount,
          skippedCount: result.skippedCount,

          errors: errors,
          duplicates: duplcates,
        });
  
      })
      .then(() => meetupContext.update())
      .catch(error => {
        console.log(error);
        setError(error.response.data.message);
      });
  }, [stepData]);

  if (error) {
    return null;
  }

  if (!result) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography variant={'h3'}>Идёт загрузка ...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} data-cy={'visitors-import-result'}>
        <Typography variant={'h3'}>Результат</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography>Добавлено {result.addedCount} записей</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography>Обновлено {result.updatedCount} записей</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography>Пропущено {result.skippedCount} записей</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography>
          Ошибок: {result.errors.length}
          {result.errors.length > 0 && (
            <>
              &nbsp;(
              <CopyToClipboard text={result.errors.join('\n')} onCopy={() => setCopied(true)}>
                <Link color='primary' underline='always' style={{ cursor: 'pointer' }} ref={anchorEl}>
                  скопировать
                </Link>
              </CopyToClipboard>
              )
              {copied && (
                <Popover
                  open={true}
                  anchorEl={anchorEl.current}
                  onClose={() => setCopied(false)}
                  anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                >
                  <Typography className={classes.copied}>Лог скопирован</Typography>
                </Popover>
              )}
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
