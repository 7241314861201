import React, { useEffect, useState, useMemo } from 'react';
import { Transformer, Rect, Line, Group, Image } from 'react-konva';
import QRCode from 'qrcode-svg';
import { calcPoints, loadImage } from './utils';
import {template} from "underscore";

const KonvaImageEvent = new Event('KonvaImageEvent');

const BadgeQrField = ({ field, visitor, onSelect, isSelected, setFormStateFieldPosition, fieldIndex, showBorders }) => {
  const textRef = React.useRef();
  const trRef = React.useRef();
  const qrRef = React.useRef();

  const [position, setPosition] = useState({
    x: field.position.x,
    y: field.position.y,
  });
  const [size, setSize] = useState({
    x: field.size.x,
    y: field.size.y,
  });
  const [rotation, setRotation] = useState(field.rotation);

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.setNode(textRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, visitor]);

  useEffect(() => {
    if (!visitor.qrToken) {
      return
    }
    const value = template(field.text)(visitor)
    let svg = new QRCode({
      content: value,
      margin: 0,
      padding: 0,
      width: 50,
      height: 50,
    }).svg();
    let originalSvgFixed = svg.replace('<svg ', `<svg viewBox="0 0 50 50" transform="rotate(${field.rotation})" `);
    const pureSvgTransform = svg
      .replace('<svg ', `<svg viewBox="0 0 50 50" transform="rotate(${field.rotation} 0 0)" `)
      .replace(/\s{2,}|\n/g, '');
    const url = 'data:image/svg+xml;base64,' + window.btoa(originalSvgFixed);
    loadImage(url, image => {
      if (qrRef.current) {
        qrRef.current.image(image);
        qrRef.current.setAttr('svg', pureSvgTransform);
        const stage = qrRef.current.getStage();
        stage.batchDraw();
        document.dispatchEvent(KonvaImageEvent);
      }
    });
  }, []);

  const points = useMemo(() => calcPoints(position.x, position.y, size.x, size.y, rotation, true), [
    position.x,
    position.y,
    size.x,
    size.y,
    rotation,
  ]);

  useEffect(() => {
    if (field.position.x !== position.x || field.position.y !== position.y) {
      setPosition(field.position);
    }
    if (field.size.x !== size.x || field.size.y !== size.y) {
      setSize(field.size);
    }

    if (field.rotation !== rotation) {
      const pureSvgTransform = qrRef.current
        .getAttr('svg')
        .replace(/rotate\((-|)\d*\s\d*\s\d*\)/g, `rotate(${field.rotation})`);
      const url = 'data:image/svg+xml;base64,' + window.btoa(pureSvgTransform);
      loadImage(url, image => {
        qrRef.current.image(image);
        const stage = qrRef.current.getStage();
        stage.batchDraw();
      });
      setRotation(field.rotation);
    }
  }, [field.position.x, field.position.y, field.size.x, field.size.y, field.rotation]);

  return (
    <Group /*onMouseEnter={onMouseEnter}
           onMouseLeave={onMouseLeave}*/>
      <Image
        ref={qrRef}
        x={position.x}
        y={position.y}
        width={size.x}
        height={size.y}
        name={'.qr'}
        offsetX={size.x / 2}
        offsetY={size.y / 2}
        angle={rotation}
        scaleFactor={{
          x: size.x / 50,
          y: size.y / 50,
        }}
        // rotation={rotation}
      />
      {showBorders ? (
        <Line points={points} stroke={'rgba(0,0,0,.5)'} strokeWidth={1} lineJoin={'round'} dash={[5, 5]} />
      ) : null}
      {setFormStateFieldPosition ? (
        <>
          <Rect
            ref={textRef}
            draggable
            x={position.x}
            y={position.y}
            width={size.x}
            height={size.y}
            offsetX={size.x / 2}
            offsetY={size.y / 2}
            // rotation={rotation}

            onClick={onSelect}
            onDragStart={onSelect}
            onDragMove={e => {
              setPosition({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
            onDragEnd={e => {
              setFormStateFieldPosition(fieldIndex, {
                position: {
                  x: e.target.x(),
                  y: e.target.y(),
                },
              });
            }}
            onTransform={e => {
              const node = textRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
              node.scaleX(1);
              node.scaleY(1);

              setPosition({
                x: e.currentTarget.x(),
                y: e.currentTarget.y(),
              });
              setSize({
                x: Math.max(5, node.width() * scaleX),
                y: Math.max(node.height() * scaleY),
              });
              setRotation(node.getRotation());
            }}
            onTransformEnd={e => {
              // transformer is changing scale of the node
              // and NOT its width or height
              // but in the store we have only width and height
              // to match the data better we will reset scale on transform end
              const node = textRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
              // we will reset it back
              node.scaleX(1);
              node.scaleY(1);

              const size = {
                x: Math.max(5, node.width() * scaleX),
                y: Math.max(node.height() * scaleY),
              };
              const position = {
                x: e.target.x(),
                y: e.target.y(),
              };

              setPosition(position);
              setSize(size);
              setRotation(node.getRotation());

              setFormStateFieldPosition(fieldIndex, {
                size: size,
                position: position,
                rotation: rotation,
              });
            }}
          />
          {isSelected && (
            <Transformer
              ref={trRef}
              keepRatio={true}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </>
      ) : null}
    </Group>
  );
};

export default BadgeQrField;
