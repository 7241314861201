import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SystemField from './fields/SystemField';
import ExtendedField from './fields/ExtendedField';
import SystemSourceField from './fields/SystemSourceField';
import SystemStatusField from './fields/SystemStatusField';
import SystemBadgeStatusField from './fields/SystemBadgeStatusField';
import Divider from '@material-ui/core/Divider';
import ErrorBlock from '../../../../components/ErrorBlock/ErrorBlock';
import { fieldFullName, fieldKey } from '../ImportXLS/import_utils';
import {FieldType, SystemFieldType} from '../../../../api';
import {valueByName} from "../../../../components/Forms/form_utils";

const useStyles = makeStyles(theme => ({
  root: {},
  divider1: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  topContainer: {
    width: '1000px',
  },
  formContainer: {
    // display: "inline-block",
    // width: "600px"
  },
  badgeContainer: {
    display: 'inline',
    verticalAlign: 'top',
  },
}));

const VisitorEditForm = forwardRef((props, ref) => {
  const { data: initialData, onChange: onChangeOuter, fields, showStatus, errors, fieldsToHide, cropFromBlob = () => {}, groups } = props;
  const classes = useStyles();
  const translitRefs = {};

  // Имеем массив филдов из контекста. Создаём параллельный массив рефов
  const refs = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),];


  let shift = 0;
  fields.forEach((f, i) => {
    if (fieldsToHide && fieldsToHide.indexOf(fieldFullName(f)) !== -1) {
      shift += 1;
    }
    if (f.type === FieldType.Transliteration) {
      fields.forEach((f2, j) => {
        if (f.fieldForTranslit === fieldFullName(f2)) {
          translitRefs[f.fieldForTranslit] = i - shift;
        }
      });
    }
  })

  useImperativeHandle(ref, () => ({
    fillValue: data => {
      return Promise.all(
        refs.map(ref => {
          if (ref.current) {
            return ref.current.fillValue(data);
          } else {
            return true;
          }
        })
      ).then(res => res.reduce((acc, item) => acc && item, true));
      // возвращаем флаг корректности и сами данные
    },
  }));

  const topFieldError = () => {
    const registeredFields = {};
    fields
      .filter(f => f.systemField !== SystemFieldType.Photo && f.systemField !== SystemFieldType.Group)
      .map(f => (registeredFields[fieldFullName(f)] = true));

    return Object.keys(errors || {})
      .filter(e => !registeredFields[e])
      .map(k => {
        return (k !== 'undefined' ? k + ': ' : '') + errors[k];
      })
      .join(', \n');
  };

  const onChange = (field) => (value, inputRef) => {
    const fn = fieldFullName(field);
    if (field.capitalLetter && value.length > 0) {
      if (inputRef) {
        inputRef.current.value = value[0].toUpperCase() + value.slice(1);
      }
    }
    if (translitRefs[fn]) {
      refs[translitRefs[fn]].current.transliterate(inputRef.current.value);
    }
    if (onChangeOuter) {
      onChangeOuter(field, value)
    }
  };

  return (
    <div>
      <ErrorBlock message={topFieldError()} />
      <Grid container spacing={2}>
        {fields
          .filter((f) => { return (fieldsToHide || []).indexOf(fieldKey(f)) === -1 })
          .map((f, i) => (
          <Grid item xs={f.sizeOnCard} key={f.systemField || f.name}>
            {f.systemField ? (
              <SystemField
                innerRef={refs[i]}
                autoFocus={i === 0}
                field={f}
                data={initialData}
                errors={errors}
                onChange={onChange(f)}
                cropFromBlob={cropFromBlob}
                groups={groups}
              />
            ) : (
              <ExtendedField
                innerRef={refs[i]}
                autoFocus={i === 0}
                field={f}
                data={initialData}
                errors={errors}
                onChange={onChange(f)}
              />
            )}
          </Grid>
        ))}
      </Grid>

      {showStatus && (
        <>
        <Divider className={classes.divider1} />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SystemSourceField ref={refs[12]} data={initialData} />
          </Grid>
          <Grid item xs={4}>
            <SystemStatusField ref={refs[13]} data={initialData} />
          </Grid>
          <Grid item xs={4}>
            <SystemBadgeStatusField ref={refs[14]} data={initialData} />
          </Grid>
        </Grid>
        </>
      )}
    </div>
  );
});

export default VisitorEditForm;
