import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import DropDownMenu from '../../../../components/DropDownMenu/DropDownMenu';

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

const DesksTable = props => {
  const { className, meetupId, desks, handleDelete, generatePin, ...rest } = props;

  const classes = useStyles();

  const settings = {
    faceScannerEnabled: 'Сканер лица',
    webCamBarScannerEnabled: 'Встроенный сканер бар-кода',
    actionCheckinEnabled: 'Ставить флаг checkin',
    actionPrintBadgeEnabled: 'Печатать бейдж автоматически',
    dontOpenCard: 'Не открывать карточку посетителя'
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Тип</TableCell>
                <TableCell>Даты активности</TableCell>
                <TableCell>Действия</TableCell>
                <TableCell>PIN-код входа</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {desks.map(desk => (
                <TableRow className={classes.tableRow} hover key={desk.deskId}>
                  <TableCell>
                    <RouterLink to={'/m/' + meetupId + '/desk/id/' + desk.deskId}>
                      <ListItemText primary={desk.name} />
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    {{
                      'registrationDesk': 'Стойка администратора',
                      'accessDesk': 'Контроль доступа',
                      'selfServiceDesk': 'Стойка саморегистрации',
                    }[desk.settings.type] || desk.settings.type}
                  </TableCell>
                  <TableCell>
                    {desk.forTest
                      ? 'тестовая стойка'
                      : desk.activeDays.sort().map(date => (
                          <div key={date}>
                            {new Date(date).toLocaleDateString('ru', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </div>
                        ))}
                  </TableCell>
                  <TableCell>
                    {Object.keys(settings)
                      .filter(setting => desk.settings[setting] === true)
                      .map(setting => (
                        <div key={setting}>{settings[setting]}</div>
                      ))}
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={desk.pin} data-test-id='desk-pin'/>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '100px' }}>
                    <DropDownMenu data-test-id="desk-menu">
                      <MenuItem>
                        <RouterLink to={'/m/' + meetupId + '/desk/id/' + desk.deskId}>
                          <ListItemText primary='Редактировать' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem onClick={() => generatePin(meetupId, desk.deskId)}>
                        <ListItemText primary='Сгенерировать пин-код' />
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(meetupId, desk.deskId)}>
                        <ListItemText primary='Удалить' />
                      </MenuItem>
                    </DropDownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

DesksTable.propTypes = {
  className: PropTypes.string,
  desks: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
};

export default DesksTable;
