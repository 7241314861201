import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppContext } from '../../AppContext';
import SAInnerMenu from '../../components/SAInnerMenu/SAInnerMenu';
import TariffCard from './components/TariffCard/TariffCard';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const TariffList = props => {
  const appContext = useContext(AppContext);

  const classes = useStyles();

  const [tariffs, setTariffs] = useState(undefined);
  useEffect(() => {
    appContext.configApi
      .getServicePlanConfig()
      .then(({ data }) => {
        setTariffs(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!tariffs) {
    return null;
  }

  return (
    <div className={classes.root}>
      <SAInnerMenu />

      {tariffs.servicePlans.map(sp => (
        <TariffCard tariff={sp} />
      ))}
    </div>
  );
};

export default TariffList;
