import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../AppContext';
import MerchEditForm from './MerchEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../components/Forms/form_utils';

const MerchAdd = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const form = useForm();

  const handleSave = () => {
    const data = form.getValues();
    appContext.merchApi
      .createMerch(meetupId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  return (
    <ModalDialog title={'Добавить товар'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <MerchEditForm meetupId={meetupId} form={form} />
    </ModalDialog>
  );
};

MerchAdd.propTypes = {};

export default MerchAdd;
