import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { VisitorBadgeStatusType } from '../../../../../api';

const SystemBadgeStatusField = forwardRef((props, ref) => {
  const { data: initial } = props;
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    fillValue: data => {
      data.status = data.status || [];
      data.status['badgeStatus'] = inputRef.current.value;
      return true;
    },
  }));

  return (
    <TextField
      select
      name={'status'}
      label={'Бейдж'}
      inputRef={inputRef}
      fullWidth
      margin='dense'
      variant='outlined'
      autoComplete='new-password'
      defaultValue={(initial.status && initial.status['badgeStatus']) || VisitorBadgeStatusType.NoBadge}
    >
      {Object.keys(VisitorBadgeStatusType).map(k => (
        <MenuItem key={k} value={VisitorBadgeStatusType[k]}>
          {k}
        </MenuItem>
      ))}
    </TextField>
  );
});

export default SystemBadgeStatusField;
