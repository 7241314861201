import Konva from 'konva';
import { template } from 'underscore';
import React, { useEffect } from 'react';

export const mmToPx = (mm, dpi = 72) => {
  // делим на 25,4 - получаем дюймы, умножаем на dpi - получаем пиксели
  return (mm / 25.4) * dpi;
};

export const makeFieldText = (field, visitor) => {
  if (field.type === 'textarea') {
    return field.text;
  }
  let text = field.text;

  try {
    text = template(field.text)(visitor);
  } catch (e) {
    console.log(e);
  }
  if (field.allCaps) {
    text = text.toUpperCase();
  } else if (field.firstCaps) {
    text = text
      .split(' ')
      .map(e => e.charAt(0).toUpperCase() + e.slice(1))
      .join(' ');
  }

  return text;
};

function wrapText(context, text, x, y, maxWidth, lineHeight) {
  let words = text.split(' ');
  let line = '';
  let lines = [];

  for (let n = 0; n < words.length; n++) {
    if (line) {
      line += ' ';
    }
    let testLine = line + words[n];
    let metrics = context.measureText(testLine);
    let testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x, y);
      line = words[n];
      y += lineHeight;
      lines.push(line);
    } else {
      line = testLine;
      lines[lines.length > 0 ? lines.length - 1 : 0] = line;
    }
  }
  context.fillText(line, x, y);
  return [lines, y + lineHeight];
}

const measureResult = (context, fontSize, lines, width, height) => {
  let results = lines.map(line => {
    let w = context.measureText(line).width
    return (w + 2) <= width // 2 pixels for left and right border
  });
  results.push(lines.length * fontSize <= height);
  return results.some(result => result === false);
};

const splitLines = (context, text, x, y, maxWidth, lineHeight) => {
  const preLines = text.split('\n');
  let lines = [];
  let newY = 0;
  preLines.forEach(lineText => {
    let [linesFromFunc, height] = wrapText(context, lineText, 0, newY, maxWidth, lineHeight);
    newY += height;
    lines = [...lines, ...linesFromFunc];
  });
  return lines;
};

export const fitTextOnCanvas = (text, field) => {
  // console.log("fitTextOnCanvas 0000")
  if (!field.adjustSize) return field.fontSize;

  const width = field.size.x;
  const height = field.size.y;
  const fontFace = field.font;

  let fontSize = parseInt(field.fontSize);

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext('2d');

  context.font = fontSize + 'px ' + fontFace;

  let lines = [text];

  if (field.allowWrap) {
    lines = splitLines(context, text, 0, 0, canvas.width, fontSize);
  }

  let currentFontSize = 0;
  let notMeasured = true;
  do {
    ++currentFontSize

    context.font = currentFontSize + 'px ' + fontFace;
    if (field.allowWrap) {
      lines = splitLines(context, text, 0, 0, canvas.width, currentFontSize);
    }
    notMeasured = measureResult(context, currentFontSize, lines, width, height);
  } while (!notMeasured && currentFontSize <= fontSize);
  context.font = (currentFontSize - 1) + 'px ' + fontFace;
  // console.log("fitTextOnCanvas return: " + (currentFontSize - 1))
  let w = context.measureText(text).width;
  // console.log("MaxWith: " + width + ", predictedWidth: " + w)
  // console.log(context)
  return currentFontSize - 1;
};

export const loadImage = (src, callback) => {
  let image = new Image();
  image.onload = function () {
    callback(image);
  };
  image.src = src;
};

export const calcPoints = (x, y, width, height, rotation = 0, useOffset = false) => {
  const newRotation = Konva.getAngle(rotation);

  if (useOffset) {
    x = x - ((width / 2) * Math.cos(newRotation) + (height / 2) * Math.sin(-newRotation));
    y = y - ((height / 2) * Math.cos(newRotation) + (width / 2) * Math.sin(newRotation));
  }

  return [
    x,
    y,
    x + width * Math.cos(newRotation),
    y + width * Math.sin(newRotation),
    x + width * Math.cos(newRotation) + height * Math.cos(newRotation + Math.PI / 2),
    y + width * Math.sin(newRotation) + height * Math.sin(newRotation + Math.PI / 2),
    x + height * Math.cos(newRotation + Math.PI / 2),
    y + height * Math.sin(newRotation + Math.PI / 2),
    x,
    y,
  ];
};

export function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useCompare = val => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};
