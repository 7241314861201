import {MenuItem, TextField, Select, Checkbox} from '@material-ui/core';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { valueByName } from './form_utils';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {RegFormType} from "../../api";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: "red",
    paddingTop: 11,
    paddingBottom: 6
  }
}))

export const ECMultiCheckboxField = props => {
  const { name, options, form, defaultValue, initial, invert, ...rest } = props;
  const classes = useStyles();

  const fname =
    props.fname ||
    (it => {
      return it.name;
    });
  const fid =
    props.fid ||
    (it => {
      return it.id;
    });

  const [value, setValue] = useState(valueByName(initial, name) || defaultValue || []);

  useEffect(() => {
    form.register({ name: name });
    form.setValue(name, value);
  }, [form.register]);

  const handleChange = (id) => (e) => {
    if (invert ^ e.target.checked) {
      value.push(id)
    } else {
      const index = value.indexOf(id);
      if (index > -1) {
        value.splice(index, 1);
      }
    }
    form.setValue(name, value);
    setValue(value);
    form.clearError(name)
  }

  return (
    <>
      {form.errors[name] &&
        <Typography className={classes.errorMessage}>{form.errors[name] && form.errors[name].message}</Typography>
      }
      {options.map(it =>
        <>
          <FormControlLabel
            key={fid(it)}
            control={<Checkbox checked={invert ^ value.indexOf(fid(it)) !== -1} onChange={handleChange(fid(it))}/>}
            label={fname(it)}
          /><br/>
        </>
      )}
    </>
  );
};

ECMultiCheckboxField.propTypes = {
  name: PropTypes.string,

  options: PropTypes.array,
  fname: PropTypes.func,
  fid: PropTypes.func,

  form: PropTypes.object,

  defaultValue: PropTypes.any,
  initial: PropTypes.any,
};
