import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { MyFormTextField } from '../../../MeetupList/components/MeetupEditForm/MeetupEditForm';

const PaymentView = props => {
  const appContext = useContext(AppContext);
  const { accountId, paymentId } = useParams();
  const { goBack } = props;

  const [data, setData] = useState(undefined);

  useEffect(() => {
    appContext.accountApi
      .getPayment(accountId, paymentId)
      .then(({ data }) => {
        setData(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, paymentId]);

  if (!data) {
    return null;
  }

  const form = {
    // form values bind helper
    data: { values: data },
    handleChange: () => {},
    error: () => {},
  };

  return (
    <ModalDialog title={'Карточка платежа'} handleClose={goBack} handleOk={goBack}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* <MyFormTextField name={'amount'} label='amount' inputProps={{ readOnly: true }} {...form} /> */}
          {/* <MyFormTextField name={'comment'} label='comment' required inputProps={{ readOnly: true }} {...form} /> */}
          <pre>
          {
            JSON.stringify(data, null, 2)
          }
          </pre>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

PaymentView.propTypes = {};

export default PaymentView;
