import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ErrorBlock from '../../../components/ErrorBlock/ErrorBlock';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { convertErrors, MyFormTextField } from '../../MeetupList/components/MeetupEditForm/MeetupEditForm';
import MenuItem from '@material-ui/core/MenuItem';
import { FieldType, SystemFieldType } from '../../../api';
import { HelpHint } from 'components/HelpHint/HelpHint';
import { AppContext } from 'AppContext';
import { useParams } from 'react-router-dom';
import { MeetupContext } from 'MeetupContext';
import {fieldFullName, fieldKey} from "../../VisitorList/components/ImportXLS/import_utils";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 15,
    color: '#213955',
  },
  label_extra: {
    color: 'rgba(33, 57, 85, 0.7)',
  },
  hint: {
    marginLeft: 10,
    height: 16,
    width: 16,
    minHeight: 16,
  },
}));

const sizeLabel = {
  12: 'во всю строку',
  6: 'половина строки',
  4: '1/3 строки',
};

const DisplayFields = {
  middleName: {
    label: 'Отчество',
    system: true
  },
  phoneNumber: {
    label: 'Телефонный номер',
    system: true
  },
  email: {
    label: 'E-mail',
    system: true
  },
  photo: {
    label: 'Фото',
    system: true
  },
  qrToken: {
    label: 'QR код',
    system: true
  },
  text: {
    label: 'Текстовое поле',
    system: false
  },
  transliteration: {
    label: 'Транслитерация',
    system: false
  },
  choice: {
    label: 'Выбор из списка',
    system: false
  },
  multiChoice: {
    label: 'Выбор вариантов',
    system: false
  },
  limitedChoice: {
    label: 'Выбор варианта из ограниченного списка',
    system: false
  }


};

const FieldEditForm = props => {
  // data и errors - передают Начальные значения данных и ошибок. Далее они копируются в стейт и обрабатываются там.
  // handleDataChange - передаёт изменённую копию данных наверх, но она не переносится в data
  const { data, errors, errorMessage, handleDataChange, idx, uniqueTextFieldName, groups, fields } = props;
  const [formState, setFormState] = useState({
    values: data,
    errors: {},
    touched: {},
    isValid: true,
  });
  const styles = useStyles();

  useEffect(() => {
    if (errors) {
      setFormState({
        ...formState,
        ...convertErrors(errors, idx),
      });
    }
  }, [errors]);

  const handleChange = (name, value) => {
    formState.values[name] = value;
    formState.touched[name] = true;

    if (name === 'fieldForTranslit') {
      let v = value
      if (v.startsWith("extended.")) {
        v = v.substr("extended.".length)
      }
      formState.values['name'] = v + "_translit";
      formState.values['label'] = v.charAt(0).toUpperCase() + v.slice(1)
    }
    if (name === 'options') {
      formState.values[name] = value.split("\n");
    }

    setFormState(formState => {
      return {
        ...formState,
        isValid: !errors,
        errors: errors || {},
      };
    });

    handleDataChange(formState.values, !errors);
  };

  const error = field => {
    if (!formState.touched || !formState.errors) {
      return null;
    }
    if (formState.touched[field] && formState.errors[field]) {
      return formState.errors[field];
    } else {
      return null;
    }
  };

  const form = {
    // form values bind helper
    data: formState,
    handleChange: handleChange,
    error: error,
  };

  const valueDisplayFieldType = () => {
    return formState.values['systemField'] || formState.values['type'];
  }

  const handleDisplayFieldType = (e) => {
    const v = e.target.value
    if (DisplayFields[v].system) {
      formState.values['systemField'] = v;
      formState.values['type'] = null;
      formState.values['name'] = null;
      formState.values['label'] = DisplayFields[v].label;
      if (v === SystemFieldType.Photo) {
        formState.values['sizeOnCard'] = 12;
      }
    } else {
      formState.values['systemField'] = null;
      formState.values['type'] = v;
      if (v === FieldType.Text) {
        formState.values['label'] = DisplayFields.text.label;
        formState.values['name'] = uniqueTextFieldName || 'textField';
      }
      if (v === FieldType.Transliteration) {
        formState.values['label'] = DisplayFields.transliteration.label;
        formState.values['name'] = 'translit';
      }
      if (v === FieldType.Choice) {
        formState.values['label'] = DisplayFields.choice.label;
        formState.values['name'] = 'choice';
      }
      if (v === FieldType.MultiChoice) {
        formState.values['label'] = DisplayFields.multiChoice.label;
        formState.values['name'] = 'multiChoice';
      }
      if (v === FieldType.LimitedChoice) {
        formState.values['label'] = DisplayFields.limitedChoice.label;
        formState.values['name'] = 'limitedChoice';
      }
    }

    setFormState(formState => {
      return {
        ...formState,
        isValid: !errors,
        errors: errors || {},
      };
    });

    handleDataChange(formState.values, !errors);
  }

  const isSystemField = () => {
    return !!formState.values['systemField']
  }

  const textFields = fields

  return (
    <>
      <ErrorBlock message={formState.error} />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography className={styles.label}>Выберите поле *</Typography>
          <TextField
            select
            style={{ width: '100%' }}
            value={valueDisplayFieldType()}
            onChange={handleDisplayFieldType}
            name='fieldType'
            margin='dense'
            variant='outlined'
          >
            {Object.keys(DisplayFields).map(k => {
              return (
                <MenuItem key={k} value={k}>
                  {DisplayFields[k].label}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <Typography className={styles.label}>Название поля *</Typography>
          <MyFormTextField name={'label'} required {...form} />
        </Grid>

        {formState.values['type'] === FieldType.Transliteration && (
          <Grid item xs={6}>
            <Typography className={styles.label}>Поле для автоматической транслитерации *</Typography>
            <TextField
              select
              style={{ width: '100%' }}
              value={formState.values['fieldForTranslit']}
              onChange={e => handleChange('fieldForTranslit', e.target.value)}
              margin='dense'
              variant='outlined'
            >
              {textFields.map(f => (
                <MenuItem key={fieldFullName(f)} value={fieldFullName(f)}>
                  {f.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography className={styles.label}>
            Имя переменной *
            <HelpHint className={styles.hint} iconSize={17}>
              Системное название поля для идентификации данных из разных полей (не отображается). Значение поля должно
              быть уникально, можно использовать латинский алфавит и цифры.
            </HelpHint>
          </Typography>
          <MyFormTextField
            name={isSystemField() ? 'systemField' : 'name'}
            disabled={isSystemField()}
            {...form}
          />
        </Grid>

        {formState.values['type'] !== FieldType.Transliteration && (
          <Grid item xs={6} style={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.values.required}
                  name=''
                  onChange={e => handleChange('required', e.target.checked)}
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              }
              label='Обязательное'
              style={{paddingTop:"20px"}}
            />
          </Grid>
        )}

        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography style={{ fontSize: 16, color: 'rgba(33, 57, 85, 0.7)' }}>Дополнительные настройки:</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={clsx(styles.label, styles.label_extra)}>
            Значение по умолчанию
            <HelpHint className={styles.hint} iconSize={17}>
              Будет присваиваться всем посетителям, у которых при добавлении не будет указано значение данного поля.
            </HelpHint>
          </Typography>

          {data.systemField === 'group' ? (
            <TextField
              select
              value={formState.values.defaultValue}
              style={{ width: '100%' }}
              margin='dense'
              variant='outlined'
              onChange={e => handleChange('defaultValue', e.target.value)}
            >
              {groups.map(group => (
                <MenuItem value={group} key={group}>
                  {group}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <MyFormTextField name={'defaultValue'} {...form} />
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography className={clsx(styles.label, styles.label_extra)}>
            Размер поля на форме
            <HelpHint className={styles.hint} iconSize={17}>
              Размер поля на форме добавления/редактирования посетителей.
            </HelpHint>
          </Typography>
          <TextField
            select
            style={{ width: '100%' }}
            value={formState.values.sizeOnCard}
            name='sizeOnCard'
            onChange={e => handleChange('sizeOnCard', e.target.value)}
            margin='dense'
            variant='outlined'
          >
            {Object.keys(sizeLabel)
              .reverse()
              .map(size => (
                <MenuItem key={sizeLabel[size]} value={size}>
                  {sizeLabel[size]}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        {((formState.values.systemField === SystemFieldType.FirstName ||
          formState.values.systemField === SystemFieldType.MiddleName ||
          formState.values.systemField === SystemFieldType.LastName ||
          !formState.values.systemField) && formState.values.type !== FieldType.Transliteration) && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.values.capitalLetter}
                  name=''
                  onChange={e => handleChange('capitalLetter', e.target.checked)}
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              }
              label='Автоматически делать первую букву заглавной'
            />
          </Grid>
        )}
        {formState.values['systemField'] === SystemFieldType.Photo && (
          <Grid item xs={12} >
            <Typography className={clsx(styles.label, styles.label_extra)}>
              Размер фото *
            </Typography>
            <TextField
              select
              value={formState.values.photoProportion || "1x1"}
              style={{ width: '100%' }}
              margin='dense'
              variant='outlined'
              onChange={e => handleChange('photoProportion', e.target.value)}
            >
              <MenuItem value={"3x4"} key={"3x4"}>
                3x4
              </MenuItem>
              <MenuItem value={"1x1"} key={"1x1"}>
                1x1
              </MenuItem>
            </TextField>
          </Grid>
        )}
        {(formState.values['type'] === FieldType.Choice || formState.values['type'] === FieldType.MultiChoice || formState.values['type'] === FieldType.LimitedChoice) && (
          <Grid item xs={12} >
            <Typography className={clsx(styles.label, styles.label_extra)}>
              Список вариантов, через перевод строки *
            </Typography>
            <TextField
              multiline
              value={(formState.values.options || []).join("\n")}
              style={{ width: '100%' }}
              margin='dense'
              variant='outlined'
              onChange={e => handleChange('options', e.target.value)}
            />
          </Grid>
        )}

      </Grid>
    </>
  );
};

export default FieldEditForm;
