import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Controller } from 'react-hook-form';
import { valueByName } from './form_utils';

const useStyles = makeStyles(theme => ({

  switch__track: {
    backgroundColor: '#213955 !important',
    opacity: 0.5,
  },

  switch__thumb: {
    background: '#213955',
  },

  switch: {
    fontFamily: 'Roboto, sans-serif',
    color: '#90A4AE',
    fontSize: 14,
  },

  switch__label_check: {
    color: '#3F51B5',
  }
}));


export const ECSwitchField = props => {
  const { name, label, form, defaultValue, initial, ...rest } = props;

  const classes = useStyles();

  const dv = valueByName(initial, name) || defaultValue || false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.setValue(name, dv), []);

  return (
    <FormControlLabel
      control={
        <Controller
          as={Switch}
          control={form.control}
          name={name}
          defaultValue={dv}
          inputProps={{
            'aria-label': 'primary switch',
          }}
          classes={{
            track: classes.switch__track,
            thumb: classes.switch__thumb,

          }}
          {...rest}
        />
      }
      label={label}
      style={{marginRight: 0}}
    />
  );
};

ECSwitchField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  initial: PropTypes.object,
};
