import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes, { object } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppContext } from '../../AppContext';
import Topbar from './components/Topbar/Topbar';
import { MeetupContext } from '../../MeetupContext';
import ReceptionDesk from './components/ReceptionDesk/ReceptionDesk'
import filter from 'common/filterVisitors';
import { WSContext } from '../../WSContext';
import { usePrevious } from '../BadgeDetails/components/BadgeViewKonva/utils';
import TopNotice from 'components/TopNotice/TopNotice';

import * as _ from "underscore";
import AccessControlDesk from './components/AccessControlDesk/AccessControlDesk';
import {TableCell} from "@material-ui/core";
import SelfServiceDesk from "./components/SelfServiceDesk/SelfServiceDesk";
import PrintVisitors from "../VisitorList/components/VisitorsToolbar/PrintVisitors";
import {VisitorBadgeStatusType} from "../../api";

const useStyles = makeStyles(theme => ({
  receptionContainer: {
    width: '100%',
    height: '100%'
  }
}));

const Reception = props => {
  const appContext = useContext(AppContext);
  const wsContext = useContext(WSContext);
  const classes = useStyles();

  const [receptionData, setReceptionData] = useState(undefined);
  const [visitors, setVisitors] = useState();
  const [filteredVisitors, setFilteredVisitors] = useState([]);
  const [visitorUpdates, setVisitorUpdates] = useState([]);
  const [encodings, setEncodings] = useState([]);
  const [search, setSearch] = React.useState('');
  const [notice, setNotice] = useState();
  const searchFieldRef = useRef(null)
  const [printVisitors, setPrintVisitors] = useState([]);
  const prevPrintVisitors = usePrevious(printVisitors);


  useEffect(() => {
    if (prevPrintVisitors && prevPrintVisitors.length > 0 && printVisitors.length === 0) {
      prevPrintVisitors.forEach(visitor => {
        appContext.visitorApi.writeBadgeStatus(receptionData.meetup.meetupId, visitor.visitorId);
        for (let i = 0; i < visitors.length; ++i) {
          if (visitors[i].visitorId === visitor.visitorId) {
            visitors[i].status = visitors[i].status || {};
            visitors[i].status.badgeStatus = VisitorBadgeStatusType.Printed;
          }
        }
      });
      setVisitors(visitors)
    }
  }, [printVisitors, receptionData]);

  useEffect(() => {
    appContext.receptionApi.receptionInfo().then(({ data }) => {
      setReceptionData(data);
      return data
    })

  }, []);

  useEffect(
    () => {
      if (receptionData) {
        appContext.visitorApi
        .listVisitors(receptionData.meetup.meetupId)
        .then(({ data }) => {
          setVisitors(data);
        })
        .catch(appContext.errorHandler);
      }
    },
    [receptionData]
  )


  // useEffect(
  //   () => {
  //     if (receptionData) {
  //       appContext.imageApi
  //         .encodings(receptionData.meetup.meetupId)
  //         .then(({ data }) => {
  //           console.log(data);
  //           setEncodings(data);
  //         })
  //         .catch(appContext.errorHandler);
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [receptionData]
  // );

  const wsMessageReceiver = message => {
    if (message.visitorUpdate) {
      // console.log('wsMessageReceiver');
      // console.log(visitors);
      let vn = visitors;
      if (!message.visitorUpdate.firstName) {
        // если нет firstName - значит удалили
        vn = visitors.filter(v => {
          return v.visitorId !== message.visitorUpdate.visitorId;
        });
      } else {
        vn = visitors
          .filter(v => {
            return v.visitorId !== message.visitorUpdate.visitorId;
          })
          .concat(message.visitorUpdate);
        vn = vn.sort((a, b) => {
          if (a.lastName !== b.lastName) {
            return a.lastName > b.lastName ? 1 : -1;
          }
          if (a.firstName !== b.firstName) {
            return a.firstName > b.firstName ? 1 : -1;
          }
          return 0;
        });
      }
      setVisitors(vn);
    }
    if (message.deskSettingsUpdate) {
      setNotice('deskUpdated');
    }
    if (message.logout) {
      appContext.logout();
    }
  };

  useEffect(() => {
    wsContext.addListener(wsMessageReceiver);
    return () => {
      wsContext.removeListener(wsMessageReceiver);
    };
  }, [visitors]);



  useEffect(() => {
    let filterTimer = setTimeout(() => {
      setFilteredVisitors(filter(visitors, search, null, true));
    }, 100)

    return () => {
      clearTimeout(filterTimer)
    }
  }, [search]);

  if (!receptionData || !visitors) {
    return null;
  }

  const meetupId = receptionData.meetup.meetupId;

  return (
    <>
      <MeetupContext.Provider
        value={{
          meetupId: meetupId,
          fields: receptionData.fields,
          groups: receptionData.groups,
        }}
      >
        {notice && <TopNotice message={notice} />}
        <div 
          id='reception-container'
          className={classes.receptionContainer} 
        > 
          <Topbar data={receptionData} />
            {{
            'registrationDesk': <ReceptionDesk
              printVisitors={printVisitors}
              setPrintVisitors={setPrintVisitors}
              receptionData={receptionData}
              search={search}
              setSearch={setSearch}
              searchFieldRef={searchFieldRef}
              meetupId={meetupId}
              visitors={visitors}
              encodings={encodings}
              filteredVisitors={filteredVisitors}
              setVisitors={setVisitors}
            />,
            'accessDesk': <AccessControlDesk
              receptionData={receptionData}
              visitors={visitors}
            />,
            'selfServiceDesk': <SelfServiceDesk
              receptionData={receptionData}
              visitors={visitors}
              setPrintVisitors={setPrintVisitors}
            />,
            }[receptionData.deskSettings.type]}
        </div>
        {printVisitors.length > 0 ? (
          <PrintVisitors printVisitors={printVisitors} meetupId={meetupId} setPrintVisitors={setPrintVisitors} />
        ) : null}
      </MeetupContext.Provider>
    </>
  );
};

Reception.propTypes = {
  history: PropTypes.object,
};

export default Reception;
