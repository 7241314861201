import React, { useContext, useEffect, useState } from 'react';

import { Typography, TextField, MenuItem } from '@material-ui/core';
import { AppContext } from 'AppContext';
import ModalDialog from 'components/ModalDialog/ModalDialog';

const BadgeShare = ({ open, setBadgeToShare, badgeToShare, meetupId }) => {
  const appContext = useContext(AppContext);

  const [meetupsList, setMeetupsList] = useState()
  const [selectedMeetup, setSelectedMeetup] = useState()

  useEffect(() => {
    appContext.meetupApi
      .listMeetups(false)
      .then(({ data }) => {
        let meetups = data.filter(meetup => meetup.meetupId != meetupId)
        setMeetupsList(meetups)
        if (meetups.length > 0) {
          setSelectedMeetup(meetups[0].meetupId)
        }
      })
      .catch(console.error)
  }, [])

  const handleOk = () => {
    appContext.badgeApi
      .getBadge(badgeToShare.badgeId)
      .then(({ data:newBadge }) => {
        return appContext.badgeApi.createBadge({...newBadge, name: 'Copy-' + newBadge.name, badgeId: null}, selectedMeetup)
      })
      .then(() => setBadgeToShare(null))
      .catch(console.error)
  }

  if (!meetupsList || !selectedMeetup) {
    return null;
  }
  return (
    <ModalDialog
      size='sm'
      okButtonText={'Cохранить'}
      title={'Дублировать бейдж в другое мероприятие'}
      handleClose={() => setBadgeToShare(null)}
      handleOk={() => handleOk()}
      open={open}
    >
      <Typography style={{ fontSize: 16, marginBottom: 10 }}>
        Выберите мероприятие
      </Typography>
      <TextField
        select
        style={{ width: '100%' }}
        value={selectedMeetup}
        onChange={e => setSelectedMeetup(e.target.value)}
        name='field'
        margin='dense'
        variant='outlined'
      >
        {meetupsList.map(meetup => (
          <MenuItem key={meetup.meetupId} value={meetup.meetupId}>
            {meetup.name}
          </MenuItem>
        ))}
      </TextField>

    </ModalDialog>
  );
}

export default BadgeShare;
