import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppContext } from '../../AppContext';
import { Route } from 'react-router-dom';
import ProfileInnerMenu from './components/ProfileInnerMenu/ProfileInnerMenu';
import ProfileAccountView from './components/ProfileAccountView/ProfileAccountView';
import ProfileUserView from './components/ProfileUserView/ProfileUserView';
import StatLogTable from './components/StatLogTable/StatLogTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const Profile = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [logFilter, setLogFilter] = useState('all');

  // retrieve users list
  const [data, setData] = useState(undefined);
  useEffect(() => {
    appContext.profileApi
      .getProfileData()
      .then(({ data }) => {
        setData(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tariffs, setTariffs] = useState(undefined);
  useEffect(() => {
    appContext.configApi
      .getServicePlanConfig()
      .then(({ data }) => {
        setTariffs(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data || !tariffs)  {
    return null;
  }

  // Тут должны быть: данные по организации и тарифу, по пользователю и возможность сменить имя и пароль, лог действий
  // Организация / Пользователь / Лог
  return (
    <div className={classes.root}>
      <ProfileInnerMenu />

      <Route path={'/profile/account'}>
        <ProfileAccountView data={data} tariffs={tariffs} setLogFilter={setLogFilter}/>
      </Route>

      <Route path={'/profile/user'} exact>
        <ProfileUserView data={data} />
      </Route>

      <Route path={'/profile/log'} >
        <StatLogTable logFilter={logFilter} setLogFilter={setLogFilter} />
      </Route>
    </div>
  );
};

export default Profile;
