import React, { Component } from 'react';
import { AppContext } from '../../../../../AppContext';
import { Modal, withStyles } from '@material-ui/core';
import BadgeViewKonva from '../../../../BadgeDetails/components/BadgeViewKonva/BadgeViewKonva';
import Grid from '@material-ui/core/Grid';
import {
  addPageBreak,
  buildBadgeDefaults,
  buildContentFromStage,
  createPdf,
  renderToPrint,
  renderToSave,
} from '../../../../BadgeDetails/components/BadgeView/BadgeView';
import print from 'print-js';
import LinearProgress from '@material-ui/core/LinearProgress';
import {waitForFonts} from '../../../../BadgeDetails/components/BadgeEdit/utils';
import { saveAs } from 'file-saver';
import { CollectionsOutlined } from '@material-ui/icons';
import * as _ from "underscore";

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 500,
    height: 550,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'relative',
  },
  invisibleKonva: {
    visibility: 'hidden',
  },
  linearPos: {
    position: 'absolute',
    top: '50%',
    marginTop: -2,
    width: '90%',
    left: '50%',
    marginLeft: '-225px',
  },
});

class PrintVisitors extends Component {
  static contextType = AppContext;

  state = {
    renderVisitor: null,
    konvaProps: null,
    eventCount: 0,
    ddCreated: [],
    completed: 0,
    fontsLoaded: false,
    printVisitors: [],
  };

  konvaImageEventCount = 0;
  dd = {};
  stageRef = null;
  percent = 0;
  groups = [];
  badges = [];
  allFonts = [];
  readyToPrintDD = [];

  getGroup = async groupId => {
    const { groupApi } = this.context;

    const { data: groupData } = await groupApi.getGroup(this.props.meetupId, groupId);
    return groupData;
  };

  getBadge = async badgeId => {
    const { badgeApi } = this.context;

    const { data: badgeData } = await badgeApi.getBadge(badgeId);
    return badgeData;
  };

  setRenderVisitor = visitor => {
    this.setState({
      renderVisitor: visitor,
    });
  };
  setKonvaProps = konvaProps => {
    this.setState({
      konvaProps,
    });
  };
  setEventCount = eventCount => {
    this.setState({
      eventCount,
    });
  };
  setDdCreated = groupId => {
    this.setState(prevState => ({
      ddCreated: {
        ...prevState.ddCreated,
        [groupId]: true,
      },
    }));
  };

  loadVisitors = visitorsWithoutGroup => {
    const { visitorApi } = this.context;
    Promise.all(
      visitorsWithoutGroup.map(visitor => visitorApi.getAllData(this.props.meetupId, visitor.visitorId))
    ).then(payloads => {
      const badges = new Set(this.badges);
      const groups = new Set(this.groups);

      let printVisitors = [];
      payloads.forEach(allVisitorsData => {
        badges.add(allVisitorsData.data.badge);
        groups.add(allVisitorsData.data.group);
        printVisitors.push(allVisitorsData.data.visitor);
      });
      this.badges = Array.from(badges);
      this.groups = Array.from(groups);
      this.groups.forEach(group => {
        this.dd[group.groupId] = null;
      });
      this.setState(
        {
          printVisitors,
        },
        () => {
          this.loadData();
        }
      );
    });
  };

  loadData = () => {
    const unloadedGroups = this.state.printVisitors.filter(
      visitor => this.groups.findIndex(group => group.groupId === visitor.groupId) === -1
    );
    if (unloadedGroups.length === 0) {
      waitForFonts(this.allFonts, () => {
        this.setState({
          fontsLoaded: true,
        });
        this.setRenderVisitor(this.state.printVisitors[0]);
      })
    } else {
      const groups = new Set(unloadedGroups.map(visitor => visitor.groupId));
      const getGroups = Array.from(groups).map(group => this.getGroup(group));
      Promise.all(getGroups).then(groupsDataset => {
        this.groups = [...this.groups, ...groupsDataset];
        const badges = new Set(groupsDataset.map(groupData => groupData.badgeId));
        const getBadges = Array.from(badges).map(badge => this.getBadge(badge));
        Promise.all(getBadges).then(badgesData => {
          this.badges = [...this.badges, ...badgesData];
          this.allFonts = _.uniq(badgesData.flatMap(b => b.fields.map( e=> e.font)));
          waitForFonts(this.allFonts, () => {
            this.setState({
              fontsLoaded: true,
            });
            this.setRenderVisitor(this.state.printVisitors[0]);
          })
        });
      });
    }
  };

  componentDidMount() {
    document.addEventListener('KonvaDidMount', this.mountCounter);
    document.addEventListener('KonvaImageEvent', this.imageCounter);

    if (this.props.printVisitors.length > 0) {
      this.percent = 100 / this.props.printVisitors.length;
      this.setState({ printVisitors: this.props.printVisitors }, () => {
        const withoutGroup = this.props.printVisitors.filter(visitor => !visitor.groupId);

        if (withoutGroup.length > 0) {
          this.loadVisitors(withoutGroup);
        } else {
          this.loadData();
        }
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('KonvaImageEvent', this.imageCounter);
    document.removeEventListener('KonvaDidMount', this.mountCounter);
    window.onafterprint = null;
  }

  buildKonvaVisitorData = visitorId => {
    const visitor = this.state.printVisitors.find(pVis => pVis.visitorId === visitorId);
    const group = this.groups.find(group => group.groupId === visitor.groupId);
    const badge = this.badges.find(badge => badge.badgeId === group.badgeId);
    visitor.group = group;
    return {
      visitor: visitor,
      group: group,
      badge: badge,
    };
  };

  componentDidUpdate(prevProps, prevState) {
    // const { visitorApi } = this.context;
    if (
      (!prevState.renderVisitor && this.state.renderVisitor) ||
      (prevState.renderVisitor && prevState.renderVisitor.visitorId !== this.state.renderVisitor.visitorId)
    ) {
      const data = this.buildKonvaVisitorData(this.state.renderVisitor.visitorId);

      if (!this.dd[data.group.groupId]) {
        const groupId = data.group.groupId;
        const allV = this.state.printVisitors.filter(v => v.groupId === groupId);
        buildBadgeDefaults(data.badge, true, allV).then(_dd => {
          this.dd[data.group.groupId] = _dd;
          this.setKonvaProps(data);
          this.setEventCount(data.badge.fields.filter(_field => _field.type === 'bar' || _field.type === 'qr').length);
          this.setDdCreated(data.group.groupId);
        });
      } else {
        const curVisitorIndex = this.state.printVisitors.findIndex(
          _pVis => _pVis.visitorId === this.state.renderVisitor.visitorId
        );
        this.setState({
          completed: (curVisitorIndex + 1) * this.percent - this.percent / (this.konvaImageEventCount || 1),
        });
        this.setKonvaProps(data);
      }
    }

  }

  mountCounter = async () => {
    if (this.state.eventCount === 0) {
      this.dd[this.state.konvaProps.visitor.group.groupId] = await buildContentFromStage(
        this.dd[this.state.konvaProps.visitor.group.groupId],
        this.stageRef
      );
      const curVisitorIndex = this.props.printVisitors.findIndex(
        _pVis => _pVis.visitorId === this.state.renderVisitor.visitorId
      );
      if (curVisitorIndex > -1 && this.props.printVisitors[curVisitorIndex + 1]) {
        this.setKonvaProps(null);
        this.setRenderVisitor(this.props.printVisitors[curVisitorIndex + 1]);
      } else {
        this.print();
      }
    }
  };

  imageCounter = async () => {
    this.konvaImageEventCount += 1;
    if (
      this.konvaImageEventCount >= this.state.eventCount &&
      this.state.ddCreated[this.state.konvaProps.visitor.group.groupId]
    ) {
      this.dd[this.state.konvaProps.visitor.group.groupId] = await buildContentFromStage(
        this.dd[this.state.konvaProps.visitor.group.groupId],
        this.stageRef
      );

      const curVisitorIndex = this.props.printVisitors.findIndex(
        _pVis => _pVis.visitorId === this.state.renderVisitor.visitorId
      );
      this.setState({
        completed: (curVisitorIndex + 1) * this.percent,
      });
      if (curVisitorIndex > -1 && this.props.printVisitors[curVisitorIndex + 1]) {
        this.konvaImageEventCount = 0;
        this.setKonvaProps(null);
        this.setRenderVisitor(this.props.printVisitors[curVisitorIndex + 1]);
      } else {
        this.print();
      }
    }
  };

  getRef = ref => {
    this.stageRef = ref;
    if (
      this.state.ddCreated[this.state.konvaProps.visitor.group.groupId] &&
      this.dd[this.state.konvaProps.visitor.group.groupId].content.length > 0
    ) {
      addPageBreak(this.dd[this.state.konvaProps.visitor.group.groupId]);
    }
  };

  print = () => {
    this.groups.forEach(async group => {
      const doc = await createPdf(this.dd[group.groupId]);
      if (this.props.saveAs) {
        renderToSave(doc).then(data => {
          this.readyToPrintDD.push(data);
          if (this.groups.length === this.readyToPrintDD.length) {
            this.printQueue();
          }
        });
      } else {
        renderToPrint(doc).then(data => {
          this.readyToPrintDD.push(data);
          if (this.groups.length === this.readyToPrintDD.length) {
            this.printQueue();
          }
        });
      }
    });
  };

  printFinish = () => {
    if (this.readyToPrintDD.length > 1) {
      this.readyToPrintDD.splice(0, 1);
      this.printQueue(this.groups.length - this.readyToPrintDD.length);
    } else {
      this.printFinished();
    }
  };

  printQueue = index => {
    if (this.props.saveAs) {
      saveAs(this.readyToPrintDD[0], this.props.saveName + '__' + this.groups[index || 0].name || 'groupSave' + '.pdf');
      
    } else {
      print({ printable: this.readyToPrintDD[0], type: 'pdf', base64: true, onPrintDialogClose: this.printFinish });
    }
    this.printFinish();
  };

  printFinished = () => {
    this.props.setPrintVisitors([]);
  };

  render() {
    const { classes } = this.props;
    return (
      <Modal open={true} className={classes.modal}>
        <div className={classes.paper}>
          <div className={classes.linearPos}>
            {this.state.fontsLoaded ? (
              <LinearProgress variant='determinate' value={this.state.completed} />
            ): (
              <h3>Загружаются шрифты...</h3>
            )}
          </div>
          <div className={classes.invisibleKonva}>
            <Grid container spacing={0}>
              {this.state.renderVisitor &&
              this.state.konvaProps &&
              this.state.ddCreated[this.state.konvaProps.visitor.group.groupId] &&
              this.state.fontsLoaded ? (
                <BadgeViewKonva {...this.state.konvaProps} getRef={this.getRef} noFontsCheck={true} />
              ) : null}
            </Grid>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(PrintVisitors);
