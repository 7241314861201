import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import validate from 'validate.js';
import ErrorBlock from '../../../../components/ErrorBlock/ErrorBlock';
import { convertErrors, MyFormTextField } from '../../../MeetupList/components/MeetupEditForm/MeetupEditForm';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  displayName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {},
}));

const UserEditForm = props => {
  // data и errors - передают Начальные значения данных и ошибок. Далее они копируются в стейт и обрабатываются там.
  // handleDataChange - передаёт изменённую копию данных наверх, но она не переносится в data
  const { data, errors, handleDataChange } = props;

  const [formState, setFormState] = useState({
    values: data,
    errors: {},
    touched: {},
    isValid: true,
  });

  useEffect(() => {
    if (errors) {
      setFormState({
        ...formState,
        ...convertErrors(errors),
      });
    }
  }, [errors]);

  const handleChange = (name, value) => {
    formState.values[name] = value;
    formState.touched[name] = true;

    const errors = validate(formState.values, schema) || {};

    if (!data.userId && !formState.values.password) {
      errors['password'] = ['Необходимо задать пароль'];
    } else if (formState.values.password && formState.values.password.length < 8) {
      errors['password'] = ['Пароль должен быть не короче 8 символов'];
    } else if (formState.values.password !== formState.values.password2) {
      errors['password2'] = ['Пароли должны быть одинаковы'];
    }

    setFormState(formState => {
      return {
        ...formState,
        isValid: !errors,
        errors: errors || {},
      };
    });

    handleDataChange(formState.values, !errors);
  };

  const error = field => {
    if (!formState.touched || !formState.errors) {
      return null;
    }
    if (formState.touched[field] && formState.errors[field]) {
      return formState.errors[field];
    } else {
      return null;
    }
  };

  const form = {
    // form values bind helper
    data: formState,
    handleChange: handleChange,
    error: error,
  };
  return (
    <>
      <ErrorBlock message={formState.error} />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <MyFormTextField name={'email'} label='Почта (используется как логин)' required {...form} />
        </Grid>
        <Grid item xs={6}>
          <MyFormTextField name={'displayName'} label='Имя пользователя' required {...form} />
        </Grid>
        <Grid item xs={6}>
          <MyFormTextField name={'password'} label='Пароль' type='password' {...form} />
        </Grid>
        <Grid item xs={6}>
          <MyFormTextField name={'password2'} label='Подтверждение пароля' type='password' {...form} />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            labelPlacement={'start'}
            control={
              <Checkbox
                checked={formState.values.active}
                name='active'
                onChange={e => handleChange('active', e.target.checked)}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            }
            label='Активен'
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserEditForm;
