import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Fab from '@material-ui/core/Fab';
import ListItemText from '@material-ui/core/ListItemText';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { fieldFullName } from '../../VisitorList/components/ImportXLS/import_utils';
import { SystemFieldType, FieldType } from '../../../api';

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  tableRow: {
    background: 'white',
  },
  firstCol: {
    width: 63,
  },
  lastCol: {
    width: 200,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
  fab2: {
    marginRight: theme.spacing(1),
  },
}));

const SystemFieldNames = {
  phoneNumber: 'Телефонный номер',
  email: 'E-mail',
  photo: 'Фото',
  firstName: 'Имя',
  lastName: 'Фамилия',
  middleName: 'Отчество',
  group: 'Группа',
};

const FieldsTable = props => {
  const { className, meetupId, data, updateFields, updateUniqueFields, ...rest } = props;
  const { fields, uniqueFields } = data;

  const classes = useStyles();

  const handleDelete = idx => {
    fields.splice(idx, 1);
    updateFields(fields);
  };

  const isMandatory = field => {
    if (!field.systemField) {
      return false;
    }
    return (
      field.systemField === SystemFieldType.FirstName ||
      field.systemField === SystemFieldType.LastName ||
      field.systemField === SystemFieldType.Group
    );
  };

  const tableRef = useRef();
  const onBeforeDragStart = () => {
    const tableRows = tableRef.current.children;

    for (let tableRow of tableRows) {
      for (let tableCell of tableRow.children) {
        tableCell.style.width = getComputedStyle(tableCell).width;
      }
    }
  };

  const onDragEnd = result => {
    const { destination, source } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const f = fields[source.index];
    fields.splice(source.index, 1);
    fields.splice(destination.index, 0, f);
    updateFields(fields);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
      <Droppable droppableId={'fieldsTable'}>
        {provided => (
          <Card
            {...rest}
            {...provided.droppableProps}
            innerRef={provided.innerRef}
            className={clsx(classes.root, className)}
          >
            <CardContent className={classes.content}>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.firstCol}></TableCell>
                      <TableCell>Поле</TableCell>
                      <TableCell>Тип</TableCell>
                      <TableCell>Обязательность</TableCell>
                      <TableCell>Уникальность</TableCell>
                      <TableCell className={classes.lastCol}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody ref={tableRef}>
                    {fields.map((field, idx) => (
                      <Draggable
                        draggableId={field.systemField || field.name}
                        key={field.systemField || field.name}
                        index={idx}
                      >
                        {(provided, snapshot) => {
                          return (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              {...provided.draggableProps}
                              innerRef={provided.innerRef}
                            >
                              <TableCell {...provided.dragHandleProps}>
                                <DragIndicatorIcon fontSize='large' color='disabled' />
                              </TableCell>
                              <TableCell>
                                <ListItemText primary={field.label} secondary={fieldFullName(field)} />
                              </TableCell>
                              <TableCell>
                                <ListItemText primary={SystemFieldNames[field.systemField] || (field.type === FieldType.Transliteration ? 'Транслитерация' : 'Текстовое поле')} />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={fields[idx].required}
                                  onChange={e => {
                                    fields[idx].required = !fields[idx].required;
                                    updateFields(fields);
                                  }}
                                  disabled={isMandatory(field)}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={uniqueFields && uniqueFields.indexOf(fieldFullName(field)) !== -1}
                                  onChange={e => updateUniqueFields(fieldFullName(field), e.target.checked)}
                                  disabled={field.systemField === SystemFieldType.Group}
                                />
                              </TableCell>
                              <TableCell className={classes.lastCol}>
                                <RouterLink to={'/m/' + meetupId + '/settings/field/i/' + idx}>
                                  <Fab size='small' color='secondary' aria-label='edit' className={classes.fab}>
                                    <EditIcon />
                                  </Fab>
                                </RouterLink>

                                <Fab
                                  size='small'
                                  color='secondary'
                                  aria-label='delete'
                                  className={classes.fab}
                                  disabled={isMandatory(field)}
                                  onClick={() => handleDelete(idx)}
                                >
                                  <DeleteIcon />
                                </Fab>
                              </TableCell>
                            </TableRow>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              </div>
            </CardContent>
          </Card>
        )}
      </Droppable>
    </DragDropContext>
  );
};

FieldsTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  updateFields: PropTypes.func,
  updateUniqueFields: PropTypes.func,
};

export default FieldsTable;
