import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';

import { useParams } from 'react-router-dom';
import BadgeEdit from './components/BadgeEdit/BadgeEdit';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AppContext } from '../../AppContext';
import { MeetupContextProvider } from '../../MeetupContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  contentHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    height: '42px',
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const BadgeDetails = () => {
  const classes = useStyles();

  const { badgeId, mid: meetupId } = useParams();

  return (
    <div className={classes.root}>
      <MeetupContextProvider meetupId={meetupId}>
        <BadgeEdit badgeId={badgeId} />
      </MeetupContextProvider>
    </div>
  );
};

export default BadgeDetails;
