import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../../AppContext';
import { useParams } from 'react-router-dom';
import HallEditForm from '../HallEditForm/HallEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../../../components/Forms/form_utils';

const HallEdit = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId, hallId } = useParams();
  const { goBack } = props;
  const form = useForm();

  const [initial, setInitial] = useState(undefined);
  useEffect(() => {
    appContext.hallApi.getHall(meetupId, hallId).then(({ data }) => {
      setInitial(data);
    });
  }, [meetupId, hallId]);

  const handleSave = () => {
    const data = form.getValues();
    data.meetupId = meetupId;
    data.hallId = hallId;
    appContext.hallApi
      .updateHall(meetupId, hallId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  if (!initial) return null;

  return (
    <ModalDialog title={'Редактирование зала'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <HallEditForm meetupId={meetupId} form={form} initial={initial} />
    </ModalDialog>
  );
};

HallEdit.propTypes = {};

export default HallEdit;
