import React, { useEffect, useState, useMemo } from 'react';
import { Transformer, Rect, Line, Group, Image } from 'react-konva';
import JsBarcode from 'jsbarcode';
import { calcPoints, loadImage } from './utils';

const makeBarCode = (qrToken, field) => {
  let svg = document.getElementById('svg_barcode');
  if (!svg) {
    svg = document.createElement('svg');
    svg.id = 'svg_barcode';
    document.body.appendChild(svg);
  }
  const displayValue = (field.barOptions && field.barOptions.displayValue) || false;
  JsBarcode('#svg_barcode', qrToken, {
    margin: 0,
    height: 50,
    displayValue: displayValue,
    format: (field.barOptions && field.barOptions.format) || 'CODE128',
  });
  const xml = new XMLSerializer().serializeToString(svg);
  const svgTag = xml.replace(
    `<svg id="svg_barcode" width="180px" height="${displayValue ? 72 : 50}px" x="0px" y="0px" viewBox="0 0 180 ${
      displayValue ? 72 : 50
    }" xmlns="http://www.w3.org/2000/svg" version="1.1" style="transform: translate(0,0)">`,
    `<svg viewBox="0 0 180 ${
      displayValue ? 72 : 50
    }" xmlns="http://www.w3.org/2000/svg" version="1.1" transform="rotate(${field.rotation} 0 0) scale(${
      field.size.x / 180
    } ${field.size.y / (displayValue ? 72 : 50)})">`
  ); //scale(${field.size.x/180} ${field.size.y/50})
  return ['data:image/svg+xml;base64,' + window.btoa(xml), svgTag];
};

const KonvaImageEvent = new Event('KonvaImageEvent');

const BadgeBarField = ({
  field,
  visitor,
  onSelect,
  isSelected,
  setFormStateFieldPosition,
  fieldIndex,
  showBorders,
}) => {
  const textRef = React.useRef();
  const trRef = React.useRef();
  const qrRef = React.useRef();

  const [bar, svg] = useMemo(() => makeBarCode(visitor.qrToken, field), [visitor, field]);

  const [position, setPosition] = useState({
    x: field.position.x,
    y: field.position.y,
  });
  const [size, setSize] = useState({
    x: field.size.x,
    y: field.size.y,
  });
  const [rotation, setRotation] = useState(field.rotation);

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.setNode(textRef.current);
      trRef.current.getLayer().batchDraw();
    }

    loadImage(bar, image => {
      if (qrRef.current) {
        qrRef.current.image(image);
        qrRef.current.setAttr('svg', svg);
        const stage = qrRef.current.getStage();
        stage.batchDraw();
        document.dispatchEvent(KonvaImageEvent);
      }
    });
  }, [isSelected, visitor, bar, svg]);

  useEffect(() => {
    if (field.position.x !== position.x || field.position.y !== position.y) {
      setPosition(field.position);
    }
    if (field.size.x !== size.x || field.size.y !== size.y) {
      setSize(field.size);
    }

    if (field.rotation !== rotation) {
      setRotation(field.rotation);
      setPosition(position);
      setFormStateFieldPosition(fieldIndex, {
        position: position,
      });
    }
  }, [field.position.x, field.position.y, field.size.x, field.size.y, field.rotation]);
  const displayValue = (field.barOptions && field.barOptions.displayValue) || false;

  const points = useMemo(() => calcPoints(position.x, position.y, size.x, size.y, rotation, true), [
    position.x,
    position.y,
    size.x,
    size.y,
    rotation,
  ]);
  return (
    <Group /*onMouseEnter={onMouseEnter}
           onMouseLeave={onMouseLeave}*/>
      <Image
        ref={qrRef}
        x={position.x}
        y={position.y}
        width={size.x}
        height={size.y}
        name={'.bar'}
        rotation={rotation}
        offsetX={size.x / 2}
        offsetY={size.y / 2}
        scaleFactor={{
          x: size.x / 180,
          y: size.y / (displayValue ? 72 : 50),
        }}
      />
      {showBorders ? (
        <Line points={points} stroke={'rgba(0,0,0,.5)'} strokeWidth={1} lineJoin={'round'} dash={[5, 5]} />
      ) : null}
      {setFormStateFieldPosition ? (
        <>
          <Rect
            ref={textRef}
            draggable
            x={position.x}
            y={position.y}
            width={size.x}
            height={size.y}
            rotation={rotation}
            offsetX={size.x / 2}
            offsetY={size.y / 2}
            onClick={onSelect}
            onDragStart={onSelect}
            onDragMove={e => {
              setPosition({
                x: e.target.x(),
                y: e.target.y(),
              });
            }}
            onDragEnd={e => {
              setFormStateFieldPosition(fieldIndex, {
                position: {
                  x: e.target.x(),
                  y: e.target.y(),
                },
              });
            }}
            onTransform={e => {
              const node = textRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
              node.scaleX(1);
              node.scaleY(1);

              setPosition({
                x: e.currentTarget.x(),
                y: e.currentTarget.y(),
              });
              setSize({
                x: Math.max(5, node.width() * scaleX),
                y: Math.max(node.height() * scaleY),
              });
              setRotation(node.getRotation());
            }}
            onTransformEnd={e => {
              // transformer is changing scale of the node
              // and NOT its width or height
              // but in the store we have only width and height
              // to match the data better we will reset scale on transform end
              const node = textRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
              // we will reset it back
              node.scaleX(1);
              node.scaleY(1);

              const size = {
                x: Math.max(5, node.width() * scaleX),
                y: Math.max(node.height() * scaleY),
              };
              const position = {
                x: e.target.x(),
                y: e.target.y(),
              };

              setPosition(position);
              setSize(size);
              setRotation(node.getRotation());

              setFormStateFieldPosition(fieldIndex, {
                size: size,
                position: position,
                rotation: node.getRotation(),
              });
            }}
          />
          {isSelected && (
            <Transformer
              ref={trRef}
              keepRatio={true}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </>
      ) : null}
    </Group>
  );
};

export default BadgeBarField;
