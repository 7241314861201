import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Lightbox } from 'react-modal-image';
import { Avatar, Box, Link, Typography, makeStyles } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import AndroidTwoToneIcon from '@material-ui/icons/AndroidTwoTone';

function senderSelector(state, senderId) {
  const { user } = state.account;
  const { contacts } = state.chat;

  if (senderId !== user.id) {
    const contact = contacts.byId[senderId];

    return {
      avatar: contact.avatar,
      name: contact.name,
      type: 'contact',
    };
  }

  return {
    avatar: user.avatar,
    name: 'Me',
    type: 'user',
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  avatar: {
    height: 32,
    width: 32,
    backgroundColor: 'white',
  },
  image: {
    cursor: 'pointer',
    height: 'auto',
    maxWidth: '100%',
    width: 380,
  },
}));

function Message({ className, message, ...rest }) {
  const classes = useStyles();
  const [openedFile, setOpenedFile] = useState(null);
  const sender = {};
  // console.log(message)
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display='flex' maxWidth={500} ml={message.participant !== 'client' ? 'auto' : 0}>
        <Avatar className={classes.avatar}>
          {message.participant === 'client' ? (
            <FaceIcon color='secondary' fontSize='large' />
          ) : (
            <AndroidTwoToneIcon color='error' fontSize='large' />
          )}
        </Avatar>
        <Box ml={2}>
          <Box
            bgcolor={message.participant === 'client' ? 'secondary.main' : 'background.default'}
            color={message.participant === 'client' ? 'secondary.contrastText' : 'text.primary'}
            py={1}
            px={2}
            borderRadius='borderRadius'
            boxShadow={1}
          >
            <Typography
              style={{
                fontSize: 12,
                color: message.participant === 'client' ? '#D1D1D1' : '#90A4AE',
              }}
            >
              {new Date(message.timestamp).toLocaleDateString('ru', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Typography>
            <Box mt={1}>
              {message.contentType === 'image' ? (
                <Box mt={2} onClick={() => setOpenedFile(message.body)}>
                  <img alt='Attachment' className={classes.image} src={message.body} />
                </Box>
              ) : (
                <Typography color='inherit' variant='body1' dangerouslySetInnerHTML={{ __html: message.message }} />
              )}
            </Box>
          </Box>
          {/* <Box
            mt={1}
            display="flex"
            justifyContent="flex-end"
          >
            <Typography
              noWrap
              color="textSecondary"
              variant="caption"
            >
              {moment(message.createdAt).fromNow()}
            </Typography>
          </Box> */}
        </Box>
      </Box>
      {openedFile && <Lightbox large={openedFile} onClose={() => setOpenedFile(null)} />}
    </div>
  );
}

Message.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object.isRequired,
};

export default Message;
