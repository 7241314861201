import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import ErrorBlock from 'components/ErrorBlock/ErrorBlock';
import { AppContext } from 'AppContext';

const BuyPackSuccess = () => {
  const appContext = useContext(AppContext);
  const {result} = useParams();

  return (
    <ModalDialog
      size={result === 'ok' ? 'xs' : 's'}
      noDivider
      okButtonText='ok'
      handleOk={() => appContext.history.push('/profile/account')}
    >
      <Typography style={{ textAlign: 'center', padding: 20 }}>
        {
          result === 'ok' ? (
            'Платеж прошел успешно!'
          ) : (
            <ErrorBlock message='При проведении платежа возникла ошибка. Проверьте доступные средства на карте и попробуйте ещё раз.'/>
          )
        }
      </Typography>
    </ModalDialog>
  );
}

export default BuyPackSuccess;