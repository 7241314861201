import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { AppContext } from 'AppContext';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';

const UnlinkingCardConfirmation = ({ handleOk, goBack }) => {
  const {name:packName} = useParams();
  const appContext = useContext(AppContext);

  return (
    <ModalDialog
      size='sm'
      noDivider
      okButtonText='Ok'
      handleClose={goBack}
      handleOk={handleOk}
    >
      <Typography style={{textAlign: 'center', padding: 20}}>
        После отвязки карты в момент следующего автоплатежа вы перейдете на Базовый тариф с ограничениями
      </Typography>

      <Typography style={
        {
          textAlign: 'center', 
          fontWeight: 'bold', 
          paddingBottom: 20,
          fontSize: 18
        }
      }>
        Вы точно хотите отвязать карту ?
      </Typography>
    </ModalDialog>
  );
}

export default UnlinkingCardConfirmation;