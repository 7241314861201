import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, TextField, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from 'AppContext';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';


const useStyles = makeStyles(theme => ({
  header: {
    textAlign: 'center',
    fontSize: 16,
    color: '#37474F',
    marginBottom: 13
  },

  row: {
    display: 'flex',
    alignItems: 'center'
  },

  label: {
    flexBasis: '50%',
    fontSize: 15
  },

  input: {
    height: 46,
    boxSizing: 'border-box',
    padding: '10px 15px',
    lineHeight: '26px'
  }

}));



const BuyOptionsPack = ({ packs, cardNumber, accountId }) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { name:packName } = useParams();
  const [count, setCount] = useState('1');
  const [card, setCard] = useState(cardNumber ? 'cardNumber' : 'newCard');
  const [rememberCard, setRememberCard] = useState(true)
  const [validCount, setValidCount] = useState(true);

  if (!packs || !packName) {
    return null;
  }

  

  const onCountChange = (event) => {
    let newVal = event.target.value;
    let number = parseInt(newVal);
    if (isNaN(number)) {
      newVal = '';
      setValidCount(false);
    } else if (number < 1) {
      newVal='0';
      setValidCount(false);
    } else {
      newVal = number
      setValidCount(true);
    }

    setCount(newVal)
    
  }

  const digitFilter = e => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  }

  const buy = () => {
    if (!validCount) {
      return;
    }
    if (card === 'newCard') {
        let origin = window.location.hostname === 'localhost' ? process.env.REACT_APP_EVENTOS_PROXY : window.location.origin
        appContext.accountApi.buyOnline(accountId, {
          amount: count * packs[packName].price,
          orderDescription: `Покупка стоек, количество - ${count * packs[packName].amount}`,
          currency: 'RUB',
          returnUrl: `${origin}/profile/account`,
          failUrl: `${origin}/profile/account/buy-pack-error/` + packName,
          rememberCard: rememberCard,
          goal:'applyPack',
          name: packName,
          count: count
        })
        .then((res) => {
          window.open(res.data);
          appContext.history.push('/profile/account');
        })
        .catch((err) => {appContext.history.push('/profile/account/buy-pack-error/' + packName)})
    } else {
      appContext.accountApi.buyWithRebill(accountId, {
          amount: count * packs[packName].price,
          orderDescription: `Покупка ${packs[packName].limit}, количество - ${count*packs[packName].amount}`,
          currency: 'RUB',
          goal:'applyPack',
          name: packName,
          count: count
      })
      .then((res) => {
        if (res.data.result === 'OK') {
          window.location.href =  window.location.origin + '/profile/account/buy-rebill-result/ok';
        } else {
          appContext.history.push('/profile/account/buy-rebill-result/error');
        }
      })
      .catch(() => {appContext.history.push('/profile/account/buy-rebill-result/error');})
    }
   
  }

  return (
    <ModalDialog
      title='Покупка пакета опций'
      size='sm'
      okButtonText='Оплатить'
      handleClose={() => appContext.history.push('/profile/account')}
      handleOk={buy}
    >
        <Typography className={classes.header}>
          {packs[packName].description}
        </Typography>
        <div className={classes.row} style={{marginBottom: 20}}>
          <Typography
            component='label'
            className={classes.label}
            for='count'
          >
            Количество пакетов:
          </Typography>
          <TextField
            InputProps={{ 
              classes: { 
                input: classes.input
              } 
            }}
            style={{flexBasis: '50%'}} 
            id='count'
            variant='outlined'
            size='small'
            type='number'
            defaultValue='1'
            min='1'
            onChange={onCountChange}
            onKeyPress={digitFilter}
            value={count}
            helperText={ !validCount && 'Количество пакетов должно быть не менее 1'}
            error={!validCount}
          />
        </div>
        <div className={classes.row}>
      
          <Typography className={classes.label} >
            Количество{packs[packName].limit === 'deskPerDay' && <span> стоек</span>}:
          </Typography>
          <Typography className={classes.label}>
            {(count || 0) * packs[packName].amount}
          </Typography>
        </div>
        <div className={classes.row} style={{marginBottom: 20}}>
      
          <Typography className={classes.label}  style={{fontWeight: 'bold'}}>
            Стоимость
          </Typography>
          <Typography className={classes.label} style={{fontWeight: 'bold'}}>
          {(count || 0) * packs[packName].price} руб.
          </Typography>
        </div>
        <div className={classes.row} style={{marginBottom: 20}}>
        <Typography
            component='label'
            className={classes.label}
            for='card'
          >
            Карта для оплаты:
          </Typography>
          <TextField 
            InputProps={{ 
              classes: { 
                input: classes.input
              } 
            }}
            style={{flexBasis: '50%'}}
            variant='outlined'
            size='small'
            value={card}
            onChange={event => setCard(event.target.value)}
            select
            id='card'
          >
            <MenuItem value='newCard'>Новая карта</MenuItem>
            {cardNumber && <MenuItem value='cardNumber'>{cardNumber}</MenuItem> }
          </TextField>
        </div>
        {
          card === 'newCard' && 
          <FormControlLabel
              control={
                <Checkbox
                  checked={rememberCard}
                  onChange={e => setRememberCard(e.target.checked)}
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              }
              label='Запомнить карту'
          />
        }
    </ModalDialog>
  )
}

export default BuyOptionsPack;
