import React, { useContext, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { BarRegistrationForm } from 'views/RegForm/BarRegistrationForm';
import Minimal from 'layouts/Minimal/Minimal';
import { Typography, Button } from '@material-ui/core';
import { AppContext } from '../../AppContext';
import { Route, useParams } from 'react-router-dom';
import { BarQrConfirmForm } from './components/BarQrConfirmForm';

const useStyles = makeStyles(theme => ({
regFormContainer: {
    width: '100%',
    height: '100%',
    zIndex: 1000000,
    // paddingTop: 64,
    // ["@media (max-width:700px)"] : {
    //   paddingTop: 56,
    // },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default
},
space: {
    // whiteSpace: 'nowrap',
    color: '#213955', 
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  MainContainer: {
    width: 500,
    // height: 700,
    ["@media (max-width:500px)"] : {
    width: '100%',
    height: '100%'
    },
  },
  FormOuter: {
    width: 400,
    margin: 'auto',
    // height: 700,
    ["@media (max-width:400px)"] : {
    width: '100%',
    height: '100%'
    },
},
}));

const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return undefined;
  };

const QrConfirm = () => {
  const classes = useStyles();
  const {regFormToken} = useParams();
  const appContext = useContext(AppContext);
  const visitorToken = getQueryVariable("visitor")
  const [regFormData, setRegFormData] = useState();
  const formRef = useRef();
  const [errors, setErrors] = useState({});
  const [data, setData] = useState();
  const [isLoad, setIsLoad] =useState(false)
  const [isQrCodeSend, setIsQrCodeSend] = useState(false)

  useEffect(() => {
    if (visitorToken) {
      appContext.regFormApi
        .getVisitorByToken(regFormToken, visitorToken)
        .then(({ data }) => {
          console.log(data)
          setData(data);
        })
        .catch(appContext.errorHandler)
        .finally(() => {
            setIsLoad(true)
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regFormToken, visitorToken]);

  const submitForm = ()=>{
    appContext.visitorApi.qrConfirm(regFormToken, data.meetupId, visitorToken).then(()=>{
        setIsQrCodeSend(true)
    })
  }

  return (
    <Minimal>
        <div className={classes.regFormContainer}>
          {isLoad ?
            <>
                {isQrCodeSend ?
                    <Typography variant={"h2"} className={classes.space}>QR-код отправлен вам на почту.</Typography>
                :
                    <div className={classes.MainContainer} style={{textAlign:"center"}}>
                        {data ?
                        <>
                            <Typography variant={"h2"} className={classes.space}>Мой бизнес FORUM 4‑го сентября 2024г</Typography>
                            <div className={classes.FormOuter}>
                                    <BarQrConfirmForm regFormData={regFormData} 
                                        visitorToken={visitorToken}
                                        reqFormToken={regFormToken}
                                        formRef={formRef} 
                                        handleSave={submitForm} 
                                        // handleUpdate={console.log('updata')} 
                                        // handleCancel={console.log('Cancel')} 
                                        errors={errors}
                                        data={data}/>
                            </div>
                        </>
                        :
                            <Typography variant={"h2"} className={classes.space}>Незарегистрированное НЛО!</Typography>
                        }

                    </div>
                }
            </>
          :
          <></>
          }
        </div>
    </Minimal>
  );
};

export default QrConfirm;
