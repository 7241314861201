import React, {Fragment, useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';

import {Button, Grid, IconButton, Typography} from '@material-ui/core';
import InfoContacts from "./components/InfoContacts";
import InfoLocation from "./components/InfoLocation";
import {Link as RouterLink, Route, useParams} from "react-router-dom";
import MeetupEdit from "../MeetupList/components/MeetupEdit/MeetupEdit";
import {AppContext} from "../../AppContext";
import {shortenDate} from "../MeetupList/components/MeetupsTable/MeetupsTable";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import GearIcon from "@material-ui/icons/Settings";
import InfoImages from "./components/InfoImages";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  cardContainer: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    paddingTop: 0
  },

  tableContainer__title: {
    paddingLeft: 25,
    fontSize: 20,
  },
  space: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  photo: {
    maxWidth: 300,
    maxHeight: 300,
  },

  paper: {
    boxShadow: theme.shadows[5],
    // margin: 'auto',
    // marginBottom: theme.spacing(4),
  },
  header: {
    padding: theme.spacing(2, 3, 2, 3),
    position: "relative"
  },
  body: {
    padding: theme.spacing(3, 5, 4),
  },
  divider1: {
  },
  label: {
    color: "rgba(55, 71, 79, 0.6)",
    fontWeight: "bold"
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(2),
  }

}));

const EventInfo = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  // retrieve users list
  const [refreshCounter, setRefresh] = useState(0);
  const [infoAbout, setInfoAbout] = useState();
  const [infoContact, setInfoContact] = useState();
  const [infoLocation, setInfoLocation] = useState();

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/info');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };

  useEffect(() => {
    appContext.meetupApi.getMeetup(meetupId).then(({ data }) => setInfoAbout(data));
    appContext.eventInfoApi.getContacts(meetupId).then(({ data }) => setInfoContact(data));
    appContext.eventInfoApi.getLocation(meetupId).then(({ data }) => setInfoLocation(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  if (!infoAbout || !infoContact || !infoLocation) {
    return null
  }

  return (
    <div className={classes.root}>

      <Paper>
        <div className={classes.header}>
          <Typography variant='h4'>Общая информация</Typography>
          <RouterLink to={'/m/' + meetupId + '/info/about'}>
            <IconButton size='small' className={classes.editButton}>
              <EditIcon />
            </IconButton>
          </RouterLink>
        </div>
        <Divider className={classes.divider1}/>
        <div className={classes.body}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography className={classes.label}>Название мероприятия:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{fontWeight:"bold"}}>{infoAbout.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>Даты проведения:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{shortenDate(infoAbout.startTime)} - {shortenDate(infoAbout.endTime)}</Typography>
              </Grid>
            </Grid>
        </div>

        <div className={classes.header}>
          <Typography variant='h4'>Локация</Typography>
          <RouterLink to={'/m/' + meetupId + '/info/location'}>
            <IconButton size='small' className={classes.editButton}>
              <EditIcon />
            </IconButton>
          </RouterLink>
        </div>
        <Divider className={classes.divider1}/>
        <div className={classes.body}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography className={classes.label}>Место проведения:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{infoLocation.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.label}>Адрес:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{whiteSpace: 'pre-line'}}>{infoLocation.address}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.label}>Как добраться:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{whiteSpace: 'pre-line'}}>{infoLocation.directions}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography className={classes.label}>Схема проезда:</Typography>
            </Grid>
            <Grid item xs={8}>
              {infoLocation.directionsImage &&
                <img className={classes.photo} src={'/api/image/' + infoLocation.directionsImage} alt={"схема"}/>
              }
            </Grid>
          </Grid>
        </div>

        <div className={classes.header}>
          <Typography variant='h4'>Об организаторе</Typography>
          <RouterLink to={'/m/' + meetupId + '/info/contact'}>
            <IconButton size='small' className={classes.editButton}>
              <EditIcon />
            </IconButton>
          </RouterLink>
        </div>
        <Divider className={classes.divider1}/>
        <div className={classes.body}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography className={classes.label}>Контакты:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{whiteSpace: 'pre-line'}}>{infoContact.contacts}</Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.header}>
          <Typography variant='h4'>Изображения</Typography>
          <RouterLink to={'/m/' + meetupId + '/info/images'}>
            <IconButton size='small' className={classes.editButton}>
              <EditIcon />
            </IconButton>
          </RouterLink>
        </div>
        <Divider className={classes.divider1}/>
        <div className={classes.body}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography className={classes.label}>Программа мероприятия:</Typography>
            </Grid>
            <Grid item xs={8}>
              {infoLocation.timetableImage &&
              <img className={classes.photo} src={'/api/image/' + infoLocation.timetableImage} alt={"расписание"}/>
              }
            </Grid>

            <Grid item xs={4}>
              <Typography className={classes.label}>Схема залов:</Typography>
            </Grid>
            <Grid item xs={8}>
              {infoLocation.locationPlanImage &&
              <img className={classes.photo} src={'/api/image/' + infoLocation.locationPlanImage} alt={"схема"}/>
              }
            </Grid>
          </Grid>
        </div>

      </Paper>


      <Route path={'/m/:mid/info/about'} exact>
        {/*<InfoAbout goBack={backAndRefresh} />*/}
        <MeetupEdit goBack={backAndRefresh} />
      </Route>
      <Route path={'/m/:mid/info/location'} exact>
        <InfoLocation goBack={backAndRefresh} />
      </Route>
      <Route path={'/m/:mid/info/contact'} exact>
        <InfoContacts goBack={backAndRefresh} />
      </Route>
      <Route path={'/m/:mid/info/images'} exact>
        <InfoImages goBack={backAndRefresh} />
      </Route>

    </div>
  );
};

export default EventInfo;
