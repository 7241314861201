import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import GroupEditForm from '../GroupEditForm/GroupEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../../components/Forms/form_utils';

const GroupAdd = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const form = useForm();

  const handleSave = () => {
    const data = form.getValues();
    appContext.groupApi
      .createGroup(meetupId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  return (
    <ModalDialog title={'Добавление группы'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <GroupEditForm meetupId={meetupId} form={form} />
    </ModalDialog>
  );
};

GroupAdd.propTypes = {};

export default GroupAdd;
