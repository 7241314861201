import { makeStyles } from '@material-ui/styles';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Fab from '@material-ui/core/Fab';
import React, { useRef, useState } from 'react';
import { Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(2),
    width: '300px',
  },
}));

export const HelpHint = props => {
  const { text, anchorEL, children, anchorOrigin, transformOrigin, iconSize, ...rest } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const internalAnchorEl = useRef();

  return (
    <>
      <Fab size='small' onClick={() => setOpen(!open)} ref={internalAnchorEl} {...rest}>
        <HelpIcon style={{ width: iconSize }} />
      </Fab>
      <Popover
        open={open}
        anchorEl={!!anchorEL ? anchorEL.current : internalAnchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={anchorOrigin || { vertical: 'center', horizontal: 'right' }}
        transformOrigin={transformOrigin || { vertical: 'center', horizontal: 'left' }}
      >
        <Typography className={classes.padding}>{children}</Typography>
      </Popover>
    </>
  );
};
