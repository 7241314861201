import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import ErrorBlock from '../../../../components/ErrorBlock/ErrorBlock';
import { Grid, TextField } from '@material-ui/core';
import { convertErrors, MyFormTextField } from '../../../MeetupList/components/MeetupEditForm/MeetupEditForm';
import MenuItem from '@material-ui/core/MenuItem';
import validate from 'validate.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const schema = {};

const ApplyServicePack = props => {
  const appContext = useContext(AppContext);
  const { accountId } = useParams();
  const { goBack } = props;

  const [errors, setErrors] = useState([]);

  const [formState, setFormState] = useState({
    values: {
      packId: '',
      noCharge: false,
      count: 1,
      comment: '',
    },
    errors: {},
    touched: {},
    isValid: true,
  });

  const [tariffs, setTariffs] = useState(undefined);
  useEffect(() => {
    appContext.configApi
      .getServicePlanConfig()
      .then(({ data }) => {
        setTariffs(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    appContext.accountApi
      .applyPack(accountId, formState.values)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(error => {
        const data = error.response.data;
        setErrors(data.errors || [data]);
      });
  };

  useEffect(() => {
    if (errors) {
      setFormState({
        ...formState,
        ...convertErrors(errors),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleChange = (name, value) => {
    formState.values[name] = value;
    formState.touched[name] = true;

    const errors = validate(formState.values, schema);

    setFormState(formState => {
      return {
        ...formState,
        isValid: !errors,
        errors: errors || {},
      };
    });
  };

  const error = field => {
    if (!formState.touched || !formState.errors) {
      return null;
    }
    if (formState.touched[field] && formState.errors[field]) {
      return formState.errors[field];
    } else {
      return null;
    }
  };

  if (!tariffs) {
    return null;
  }

  const form = {
    // form values bind helper
    data: formState,
    handleChange: handleChange,
    error: error,
  };
  return (
    <ModalDialog title={'Применение пакета'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <ErrorBlock message={formState.error} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            select
            label='Пакет'
            style={{ width: '100%' }}
            value={formState.values.packId}
            onChange={e => handleChange('packId', e.target.value)}
            margin='dense'
            variant='outlined'
          >
            {tariffs.servicePacks.map(plan => {
              return (
                <MenuItem key={plan.name} value={plan.name}>
                  {plan.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <MyFormTextField name={'count'} label='Количество' required {...form} />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            labelPlacement={'start'}
            control={
              <Checkbox
                checked={formState.values.noCharge || false}
                name='noCharge'
                onChange={e => handleChange('noCharge', e.target.checked)}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            }
            label='Бесплатно'
          />
        </Grid>
        <Grid item xs={12}>
          <MyFormTextField name={'comment'} label='Комментарий' {...form} />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

ApplyServicePack.propTypes = {};

export default ApplyServicePack;
