import React from 'react';
import SystemTextField from './SystemTextField';
import SystemGroupField from './SystemGroupField';
import { SystemFieldType } from '../../../../../api/api';
import SystemEmailField from './SystemEmailField';
import SystemPhoneField from './SystemPhoneField';
import SystemPhotoField from './SystemPhotoField';

const SystemField = props => {
  const { innerRef, field, data, autoFocus, errors, onChange, cropFromBlob, groups } = props;

  if (field.systemField === SystemFieldType.Group) {
    return (
      <SystemGroupField
        ref={innerRef}
        field={field}
        data={data}
        autoFocus={autoFocus}
        errors={errors}
        groups={groups}
        onChange={onChange}
      />
    );
  } else if (field.systemField === SystemFieldType.PhoneNumber) {
    return (
      <SystemPhoneField
        ref={innerRef}
        field={field}
        data={data}
        autoFocus={autoFocus}
        errors={errors}
        onChange={onChange}
      />
    );
  } else if (field.systemField === SystemFieldType.Email) {
    return (
      <SystemEmailField
        ref={innerRef}
        field={field}
        data={data}
        autoFocus={autoFocus}
        errors={errors}
        onChange={onChange}
      />
    );
  } else if (field.systemField === SystemFieldType.Photo) {
    return <SystemPhotoField ref={innerRef} field={field} data={data} errors={errors} onChange={onChange} cropFromBlob={cropFromBlob}/>;
  } else {
    return (
      <SystemTextField
        ref={innerRef}
        field={field}
        data={data}
        autoFocus={autoFocus}
        errors={errors}
        onChange={onChange}
      />
    );
  }
};

export default SystemField;
