import React, { useContext, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, TextField, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ModalDialog from 'components/ModalDialog/ModalDialog';
import { AppContext } from 'AppContext';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center'
  },

  label: {
    flexBasis: '50%',
    fontSize: 15
  },

  input: {
    height: 46,
    boxSizing: 'border-box',
    padding: '10px 15px',
    lineHeight: '26px',
  }

}));


const ChangeTariffUltimate = ({ accountId, userId }) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const [message, setMessage] = useState();
  const [email, setEmail] = useState();
  const [errors, setErrors] = useState({});

  const handleOk = () => {
    appContext.accountApi.sendPlanRequest(accountId, {
      accountId: accountId,
      userId: userId,
      email: email,
      planDescription: message
    })
      .then(() => {
        setErrors({});
        appContext.history.push('/profile/account')
      })
      .catch((error) => {
        let data = error.response.data;
        data = data.errors || [data];

        let serverErrors = {};
        for (let error of data) {
          serverErrors[error.args.field] = error.message
        }

        setErrors(serverErrors);
      })
  }

  return (
    <ModalDialog
      noDivider
      size='sm'
      handleClose={() => appContext.history.push('/profile/account')}
      handleOk={() => handleOk()}
      okButtonText={'Отправить заявку'}
    >
      <Typography style={{ marginBottom: 20, fontSize: 16 }}>
        Стоимость Корпоративного тарифа рассчитывается в зависимости от ваших потребностей. Укажите, какой функционал вам нужен и мы с вами свяжемся для обсуждения цены.
      </Typography>
      <TextField
        multiline
        variant='outlined'
        placeholder='Желаемый функционал'
        style={{ width: '100%', marginBottom: 20 }}
        rows={3}
        value={message}
        helperText={errors.planDescription}
        error={!!errors.planDescription}
        onChange={(e) => setMessage(e.target.value)}
      />
      <TextField
        variant='outlined'
        placeholder='Email для обратной связи'
        style={{ width: '50%', marginBottom: 20 }}
        InputProps={{
          classes: {
            input: classes.input
          }
        }}
        helperText={errors.email}
        error={!!errors.email}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </ModalDialog>
  );
}

export default ChangeTariffUltimate;
