import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Button, Checkbox} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {ECTextField} from "../../../components/Forms/ECTextField";
import {ECRadioGroupField} from "../../../components/Forms/ECRadioGroupField";
import {ClosureType, RegFormType} from "../../../api";
import {useForm} from "react-hook-form";
import * as _ from "underscore";
import {ECDateTimeField} from "../../../components/Forms/ECDateTimeField";
import CardBlock from "../../../components/CardBlock/CardBlock";

const useStyles = makeStyles(theme => ({
  groupLabel: {
    paddingTop: 12 //theme.spacing(1)
  }
}));

const RegFormClose = props => {
  const { className, regForm, save, ...rest } = props;

  const classes = useStyles();
  const form = useForm();

  const handleSave = (data) => {
      save(data, form)
  }

  const closeType = form.watch("closureType")
  return (
    <CardBlock title={"Закрытие регистрации"} form={form}  save={handleSave}>
      <Grid container spacing={1}>
        <Grid xs={4} className={classes.groupLabel}>
          <Typography>Закрытие регистрации:</Typography>
        </Grid>
        <Grid xs={8}>
          <ECRadioGroupField name={"closureType"} form={form} initial={regForm} defaultValue={ClosureType.Eternal} options={[
            {id:ClosureType.ByTime, name:"По времени"},
            {id:ClosureType.ByLimit, name:"По участникам"},
          ]} row/>
          {closeType === ClosureType.ByTime &&
          <ECDateTimeField label={"Время закрытия"} name={"closeByTime"} initial={regForm} form={form}/>
          }
          {closeType === ClosureType.ByLimit &&
          <ECTextField label={"Лимит участников"} name={"closeByLimit"} initial={regForm} form={form}/>
          }
        </Grid>

        <Grid xs={4} className={classes.groupLabel}>
          <Typography>Сообщение при закрытой регистрации:</Typography>
        </Grid>
        <Grid xs={8}>
          <ECTextField label='' name='closedText' initial={regForm} form={form}
                       multiline
                       rows={4}
          />
        </Grid>

      </Grid>
    </CardBlock>
  )

}

export default RegFormClose;
