import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { AppContext } from 'AppContext';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';

const BuyPackError = () => {
  const {name:packName} = useParams();
  const appContext = useContext(AppContext);

  const handleOk = () => {
    if (packName === 'changePlan') {
      appContext.history.push('/profile/account#tariffs');
    } else {
      appContext.history.push('/profile/account/buy-pack/' + packName)
    }
  }

  return (
    <ModalDialog
      size='sm'
      noDivider
      okButtonText='Попробовать ещё раз'
      handleClose={() => appContext.history.push('/profile/account')}
      handleOk={() => handleOk()}
    >
      <Typography style={{textAlign: 'center', padding: 20}}>
        При проведении платежа возникла ошибка. Попробуйте ещё раз и внимательно введите данные карты.
      </Typography>
    </ModalDialog>
  );
}

export default BuyPackError;