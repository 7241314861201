import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Button, Checkbox} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/styles";
import CardBlock from "../../../components/CardBlock/CardBlock";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {useForm} from "react-hook-form";
import * as _ from "underscore";
import {ECTextField} from "../../../components/Forms/ECTextField";
import {ECCheckboxField} from "../../../components/Forms/ECCheckboxField";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Link, useParams} from "react-router-dom";
import {AppContext} from "../../../AppContext";
import ReactQuill from "react-quill";
import {ECQuillEditor} from "../../../components/Forms/ECQuillEditor";
import Chip from "@material-ui/core/Chip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {AttachmentsList} from "./AttachmentsList";

const useStyles = makeStyles(theme => ({
  groupLabel: {
    paddingTop: 12 //theme.spacing(1)
  },
  editor: {
    '& .ql-editor': {
      height: 200
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  spacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const RegFormMail = props => {
  const { className, fields, regForm, save, ...rest } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();

  const classes = useStyles();
  const form = useForm();

  const handleSave = (data) => {
    save(data, form)
  }

  const handleTestMail = () => {
    form.handleSubmit((data) => {
      appContext.configApi.sendTestEmail(meetupId, data.completionMailTemplate).then(({ data }) => {
        alert("Письмо отправлено на адрес: " + data.to)
      }).catch(error => {
        const data = error.response.data;
        alert("Ошибка отправки: " + JSON.stringify(data))
      })
    })();
  }

  return (
    <CardBlock title={"Письмо подтверждения"} form={form}  save={handleSave}>
      <Grid container spacing={1}>
        <Grid xs={4} className={classes.groupLabel}>
          <Typography>Отправлять письмо при регистрации:</Typography>
        </Grid>
        <Grid xs={8}>
          {fields.filter(e => e.systemField === "email").length !== 0 ?
            <ECCheckboxField label='Отправлять' name='sendCompletionEmail' initial={regForm} form={form}/>
          :
            <>
            <ECCheckboxField label='Отправлять' name='sendCompletionEmail' initial={regForm} form={form} disabled={true}/>
            <Typography>Отправлять письма можно только при наличии поля email в профиле посетителя. Добавить поле можно в <Link style={{ cursor: 'pointer' }} to={`/m/${meetupId}/settings`}>Настройках полей</Link></Typography>
            </>
          }

        </Grid>

        <Grid xs={4} className={classes.groupLabel}>
          <Typography>Заголовок письма:</Typography>
        </Grid>
        <Grid xs={8}>
          <ECTextField label='' name='completionMailTemplate.title' initial={regForm} form={form}/>
        </Grid>

        <Grid xs={4} className={classes.groupLabel}>
          <Typography>Текст письма:</Typography>
        </Grid>
        <Grid xs={8}>
          <Paper variant="outlined">
            <ECQuillEditor label='' name='completionMailTemplate.body' initial={regForm} form={form} className={classes.editor}/>
          </Paper>

          <Typography>Посмотреть&nbsp;
            <Link style={{ cursor: 'pointer' }} to={`/m/${meetupId}/template-params`} target={'_blank'}>список доступных подстановок</Link>
            &nbsp;в шаблоне</Typography>
        </Grid>

        <Grid xs={4} className={classes.groupLabel}>
          <Typography>Вложения:</Typography>
        </Grid>
        <Grid xs={8} className={classes.spacing}>
          <AttachmentsList initial={regForm} form={form} name='completionMailTemplate.attachments'/>
        </Grid>

        <Grid xs={4} className={classes.groupLabel}>
          <Typography>Тест:</Typography>
        </Grid>
        <Grid xs={8}>
          <Button color='secondary' onClick={handleTestMail} >
            Отправить тестовое письмо
          </Button>
        </Grid>

      </Grid>
    </CardBlock>
  )

}

export default RegFormMail;
