import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import { useParams } from 'react-router-dom';
import DeskEditForm from '../DeskEditForm/DeskEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../../components/Forms/form_utils';

const DeskEdit = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId, deskId } = useParams();
  const { goBack } = props;
  const form = useForm();

  const [initial, setInitial] = useState(undefined);

  const [conditions, setConditions] = useState([ {
    field: null,
    operator: 'equal',
    value: '',
  }])

  useEffect(() => {
    appContext.receptionApi.getDesk(meetupId, deskId).then(({ data }) => {
      setInitial(data);
      setConditions(data.settings.conditions || [{
        field: null,
        operator: 'equal',
        value: '',
      }])
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, deskId]);

  const [visitors, setVisitors] = useState();
  useEffect(() => {
    appContext.visitorApi
      .listVisitors(meetupId)
      .then(({ data }) => {
        setVisitors(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId]);

  const handleSave = data => {
    data.meetupId = meetupId;
    data.deskId = deskId;
    data.settings.conditions = [...conditions]
    appContext.receptionApi
      .updateDesk(meetupId, deskId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  if (!initial || !visitors ){

    return null;
  }

  return (
    <ModalDialog
      title={'Редактирование стойки'}
      okButtonText={'Сохранить'}
      handleOk={form.handleSubmit(handleSave)}
      handleClose={goBack}
    >
      <DeskEditForm meetupId={meetupId} form={form} initial={initial} visitors={visitors} conditions={conditions} setConditions={setConditions} edit />
    </ModalDialog>
  );
};

DeskEdit.propTypes = {};

export default DeskEdit;
