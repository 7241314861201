import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../../AppContext';
import { useParams } from 'react-router-dom';
import SpeakerEditForm from '../SpeakerEditForm/SpeakerEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../../../components/Forms/form_utils';

const SpeakerEdit = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId, speakerId } = useParams();
  const { goBack } = props;
  const form = useForm();

  const [initial, setInitial] = useState(undefined);
  useEffect(() => {
    appContext.speakerApi.getSpeaker(meetupId, speakerId).then(({ data }) => {
      setInitial(data);
    });
  }, [meetupId, speakerId]);

  const handleSave = () => {
    const data = form.getValues();
    data.meetupId = meetupId;
    data.speakerId = speakerId;
    appContext.speakerApi
      .updateSpeaker(meetupId, speakerId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  if (!initial) return null;

  return (
    <ModalDialog title={'Редактирование спикера'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <SpeakerEditForm meetupId={meetupId} form={form} initial={initial} />
    </ModalDialog>
  );
};

SpeakerEdit.propTypes = {};

export default SpeakerEdit;
