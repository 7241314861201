import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Fab from "@material-ui/core/Fab";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  row: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1,
  }
}));

const NewsToolbar = props => {
  const { className, meetupId, refresh, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <RouterLink to={'/m/' + meetupId + '/news/add'}>
          <Button color='primary' variant='contained' style={{marginTop: "3px"}}>
            Добавить новость
          </Button>
        </RouterLink>

        <Fab size='small'  style={{marginLeft: "16px"}} onClick={refresh}>
          <RefreshIcon />
        </Fab>
      </div>
    </div>
  );
};

NewsToolbar.propTypes = {
  className: PropTypes.string,
  meetupId: PropTypes.string,
};

export default NewsToolbar;
