import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Button, Hidden, TextField} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  row: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1,
  },
  menu: {
    marginLeft: theme.spacing(1),
  },
  searchField: {
    width: '400px',
    padding: '2px 4px',
  },
  groupField: {
    width: '300px',
    padding: '2px 4px',
  },
  iconButton: {
    marginTop: '14px',
    width: '36px',
    height: '36px',
  },
}));

const ReceptionToolbar = props => {
  const {
    className,
    meetupId,
    refresh,
    selected,
    searchFieldRef,
    search: outerSearch,
    setSearch: setOuterSearch,
    group: outerGroup,
    setGroup: setOuterGroup,
    ...rest
  } = props;

  const classes = useStyles();
  const appContext = useContext(AppContext);

  // const [search, setSearch] = React.useState(outerSearch);
  // const handleSearchDebounced = React.useRef(_.debounce(function (query) {
  //   setOuterSearch(query);
  // }, 300)).current;
  // const handleSearchChange = (query) => {
  //   setSearch(query);
  //   handleSearchDebounced(query);
  // };
  const handleSearchChange = query => {
    setOuterSearch(query);
  };

  const handleBlur = () => {
    // if (window.location.pathname === '/reception') {
    //   document.getElementById('visitors-search-field').focus();
    // }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <IconButton type='submit'>
          <SearchIcon />
        </IconButton>

        <TextField
          id={'visitors-search-field'}
          autoFocus={true}
          // vggonBlur={handleBlur}
          fullWidth
          margin='dense'
          autoComplete='off'
          onChange={e => handleSearchChange(e.target.value)}
          value={outerSearch}
          className={classes.searchField}
          ref={searchFieldRef}
        />

        <Hidden smDown>
          <span className={classes.spacer} />
          <RouterLink to={'/reception/visitor/add'}>
            <Button color='primary' variant='contained' style={{ marginTop: '6px' }} onClick={() => setOuterSearch('')}>
              Добавить посетителя
            </Button>
          </RouterLink>
        </Hidden>
      </div>
    </div>
  );
};

ReceptionToolbar.propTypes = {
  className: PropTypes.string,
  meetupId: PropTypes.number,
};

export default ReceptionToolbar;
