import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import { useForm } from 'react-hook-form';
import { ECTextField } from '../../../../components/Forms/ECTextField';
import { ECSelectField } from '../../../../components/Forms/ECSelectField';
import { errorsToForm } from '../../../../components/Forms/form_utils';
import { ECCommonErrorBlock } from '../../../../components/Forms/ECCommonErrorBlock';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  row: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1,
  },
}));

const BadgeAdd = props => {
  const { className, meetupId, ...rest } = props;

  const classes = useStyles();
  const appContext = useContext(AppContext);
  const form = useForm();

  const [templates, setTemplates] = useState(undefined);
  useEffect(() => {
    appContext.badgeApi
      .listBadges()
      .then(({ data }) => {
        setTemplates(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId]);

  const createNewBadge = data => {
    appContext.badgeApi
      .getBadge(data.badgeId)
      .then(({ data: newBadgeData }) => {
        newBadgeData.name = data.name;
        newBadgeData.badgeId = undefined;
        appContext.badgeApi
          .createBadge(newBadgeData, meetupId)
          .then(({ data }) => {
            appContext.history.push('/m/' + meetupId + '/badge/id/' + data.badgeId);
          })
          .catch(errorsToForm(form));
      })
      .catch(appContext.errorHandler);
  };

  const goBack = () => {
    appContext.history.push('/m/' + meetupId + '/badge');
  };

  const onSubmit = data => createNewBadge(data);

  if (!templates) {
    return null;
  }

  return (
    <ModalDialog title={'Создание бейджа'} handleOk={form.handleSubmit(onSubmit)} handleClose={goBack}>
      <ECCommonErrorBlock form={form} />

      <ECSelectField
        label='Шаблон'
        name='badgeId'
        options={templates}
        fid={it => it.badgeId}
        defaultValue={templates[0].badgeId}
        form={form}
      />

      <ECTextField label='Название' name='name' form={form} />
    </ModalDialog>
  );
};

BadgeAdd.propTypes = {
  className: PropTypes.string,
};

export default BadgeAdd;
