import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const PurchasesTable = props => {
  const { className, meetupId, purchases, ...rest } = props;

  const classes = useStyles();

  if (!purchases) {
    return null
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Товар</TableCell>
                <TableCell>Цена</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchases.map((purch, i) => (
                <TableRow className={classes.tableRow} hover key={purch.purchaseId}>
                  <TableCell>
                    <Typography>{purch.merch.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{purch.payment.amount}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{purch.payment.date}</Typography>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default PurchasesTable;
