import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import {Divider, Grid, Tab, Tabs} from '@material-ui/core';
import { Route, useParams } from 'react-router-dom';
import Main from '../../layouts/Main/Main';
import RouteWithLayout from '../../components/RouteWithLayout/RouteWithLayout';
import VisitorList from "../VisitorList/VisitorList";
import GroupList from "../GroupList/GroupList";
import FieldSettings from "../FieldSettings/FieldSettings";
import HallList from "./HallList/HallList";
import SpeakerList from "./SpeakerList/SpeakerList";
import TimeSlotList from "./TimeSlotList/TimeSlotList";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const EventProgram = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();

  const valueFromUrl = () => {
    const path = appContext.history.location.pathname;
    if (path.indexOf('hall') !== -1) {
      return 'hall';
    }
    if (path.indexOf('speaker') !== -1) {
      return 'speaker';
    }
    if (path.indexOf('time-slot') !== -1) {
      return 'time-slot';
    }
    return '';
  };

  return (
    <div className={classes.root}>
      <Tabs
        onChange={() => {}}
        scrollButtons='auto'
        value={valueFromUrl()}
        variant='scrollable'
        style={{ margin: 'auto', maxWidth: 'max-content' }}
      >
        <Tab
          label={'Залы'}
          value={'hall'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/program/hall');
          }}
        />
        <Tab
          label={'Спикеры'}
          value={'speaker'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/program/speaker');
          }}
        />
        <Tab
          label={'Расписание'}
          value={'time-slot'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/program/time-slot');
          }}
        />
      </Tabs>
      <Divider />
      <Route path='/m/:mid/program/hall'>
        <HallList />
      </Route>
      <Route path='/m/:mid/program/speaker'>
        <SpeakerList />
      </Route>
      <Route path='/m/:mid/program/time-slot'>
        <TimeSlotList/>
      </Route>
    </div>
  );
};

export default EventProgram;
