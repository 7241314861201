import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import { useParams } from 'react-router-dom';
import UserEditForm from '../UserEditForm/UserEditForm';

const UserEdit = props => {
  const appContext = useContext(AppContext);
  const { userId } = useParams();
  const { goBack } = props;

  const [data, setData] = useState(undefined);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    appContext.userApi.getUser(userId).then(({ data }) => {
      data.lastActivity = undefined;
      setData(data);
    });
  }, [userId]);

  const handleDataChange = (data, isValid) => {
    setData(data);
  };

  const handleSave = () => {
    data.userId = userId;
    data.closed = false;
    if (data.password === '') {
      data.password = undefined;
    }
    appContext.userApi
      .updateUser(userId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(error => {
        const data = error.response.data;
        setErrors(data.errors || [data]);
      });
  };

  if (!data) {
    return null;
  }

  return (
    <ModalDialog
      title={'Редактирование администратора'}
      okButtonText={'Сохранить'}
      handleOk={handleSave}
      handleClose={goBack}
    >
      <UserEditForm data={data} errors={errors} handleDataChange={handleDataChange} />
    </ModalDialog>
  );
};

UserEdit.propTypes = {};

export default UserEdit;
