import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Link, FormHelperText, Checkbox, Typography } from '@material-ui/core';
import { AppContext } from '../../AppContext';
import { convertErrors } from '../MeetupList/components/MeetupEditForm/MeetupEditForm';
import RegistrationCompleted from './components/RegistrationCompleted';
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const schema ={
  policy: {
    presence: { allowEmpty: false, message: 'Поле обязательное' },
    checked: true,
  },
}
export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  contentContainer: {

  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    height: "592px",
    width: "600px",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "30px",
    // flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    textAlign: "center",
    // marginTop: theme.spacing(3),
    paddingBottom: "20px"
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  formContainer: {
    height: "calc(100% - 100px)",
    padding: "20px  45px",
    border: "1px solid #ffffff",
    borderBottomColor: "#DEDEDE",
    borderTopColor: "#DEDEDE",
  },
  bottomLink:{
    marginTop: "10px",
    width: "100%",
    textAlign:"center"
  },
  singIn: {
    textAlign: "center",
    marginTop: "20px",
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  policyCheckbox: {
    marginLeft: '-14px',
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
  },
}));

const Registration = props => {
  const appContext = useContext(AppContext);
  const [inputType, setInputType] = useState("password")
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (errors) {
      setFormState({
        ...formState,
        ...convertErrors(errors),
      });
    }
  }, [errors]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleRegister = event => {
    event.preventDefault();
    if(!formState.values.policy){
      setFormState(formState => ({
        ...formState,
        errors: {
          policy: "Поле является обязательным"
        },
        touched: {
          ...formState.touched,
          policy: true,
        },
      }));
      return null
    }
    appContext.accountApi
      .registerAccount(formState.values)
      .then(({ data }) => {
        appContext.history.push('/registration-success')
      })
      .catch(error => {
        const data = error.response.data;
        setErrors(data.errors || [data]);
      });
  };

  const showPassword=()=>{
    if (inputType === "password"){
      setInputType("text")
    }else {
      setInputType("password")
    }
  }


  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);
 
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {/*<div className={classes.contentHeader}></div>*/}
        <div   className={classes.contentBody}>
          <ErrorBlock message={formState.errors.token}/>
          <form className={classes.form} onSubmit={handleRegister}>
            <Typography className={classes.title} variant='h2'>
              Регистрация аккаунта
            </Typography>
            <div className={classes.formContainer}>
              <TextField
                className={classes.textField}
                error={hasError('accountName')}
                fullWidth
                helperText={hasError('accountName') ? formState.errors.accountName : null}
                label='Название организации'
                name='accountName'
                onChange={handleChange}
                type='text'
                value={formState.values.accountName || ''}
                variant='outlined'
                required={false}
                autoComplete={'off'}
              />
              <TextField
                className={classes.textField}
                error={hasError('ownerEmail')}
                fullWidth
                helperText={hasError('ownerEmail') ? formState.errors.ownerEmail : null}
                label='Адрес электронной почты'
                name='ownerEmail'
                onChange={handleChange}
                type='text'
                value={formState.values.ownerEmail || ''}
                variant='outlined'
                required={false}
                autoComplete={'off'}
              />
              <TextField
                className={classes.textField}
                error={hasError('ownerDisplayName')}
                fullWidth
                helperText={hasError('ownerDisplayName') ? formState.errors.ownerDisplayName : null}
                label='Имя пользователя'
                name='ownerDisplayName'
                onChange={handleChange}
                type='text'
                value={formState.values.ownerDisplayName || ''}
                variant='outlined'
                required={false}
                autoComplete={'off'}
              />
              <TextField
                className={classes.textField}
                error={hasError('ownerPassword')}
                fullWidth
                helperText={hasError('ownerPassword') ? formState.errors.ownerPassword : null}
                label='Пароль'
                name='ownerPassword'
                onChange={handleChange}
                type={inputType}
                value={formState.values.ownerPassword}
                variant='outlined'
                required={false}
                autoComplete='new-password'
              />
              <span style={{position: "relative"}}>
                  {inputType === "password"
                    ?
                    <VisibilityOff onClick={showPassword} style={{position:"absolute", top: 32,  right: 10, cursor: "pointer"}}/>
                    :
                    <Visibility onClick={showPassword} style={{position:"absolute", top: 32,  right: 10, cursor: "pointer" }}/>
                  }
              </span>
              <div className={classes.policy}>
                <Checkbox
                  checked={formState.values.policy || false}
                  className={classes.policyCheckbox}
                  color='primary'
                  name='policy'
                  onChange={handleChange}
                  // required={true}
                />
                <Typography className={classes.policyText} color='textSecondary' variant='body1'>
                  Я соглашаюсь с&nbsp;
                  <Link
                    color='primary'
                    component={RouterLink}
                    to='/docs/terms-of-service.pdf'
                    underline='always'
                    variant='h6'
                    target={'_blank'}
                  >
                    Условиями использования продукта
                  </Link>
                  &nbsp;и принимаю&nbsp;
                  <Link
                    color='primary'
                    component={RouterLink}
                    to='/docs/privacy-policy.pdf'
                    underline='always'
                    variant='h6'
                    target={'_blank'}
                  >
                    Политику конфиденциальности
                  </Link>
                  .
                </Typography>
              </div>
              {hasError('policy') && <FormHelperText error>{formState.errors.policy}</FormHelperText>}
              <Button
                className={classes.signUpButton}
                color='primary'
                fullWidth
                size='large'
                type='submit'
                variant='contained'
              >
                Зарегистрироваться
              </Button>
            </div>
            <div className={classes.singIn}>
              <div style={{display: "inline", opacity: "0.5", fontFamily: "ROBOTO"}}> Уже есть аккаунт?</div>
              <div
                style={{display: "inline", marginLeft: "10px", cursor: "pointer", fontFamily: "ROBOTO"}}
                onClick={()=>appContext.history.push('/sign-in')}>Войти</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Registration;
