import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { MeetupContext } from '../../../../MeetupContext';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, StepConnector, withStyles } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { Check, CloudUpload, InsertDriveFile, PlaylistAddCheck, Publish, TextFields } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { SelectFileStep } from './steps/SelectFileStep';
import { ValidationStep } from './steps/ValidationStep';
import { FieldsSetupStep } from './steps/FieldsSetupStep';
import { FinishStep } from './steps/FinishStep';
import ErrorBlock from 'components/ErrorBlock/ErrorBlock';

const steps = [
  {
    label: 'Выбор файла',
    icon: InsertDriveFile,
  },
  {
    label: 'Настройка полей',
    icon: TextFields,
  },
  {
    label: 'Валидация',
    icon: PlaylistAddCheck,
  },
  {
    label: 'Загрузка',
    icon: CloudUpload,
  },
];

const CustomStepConnector = withStyles(theme => ({
  vertical: {
    marginLeft: 19,
    padding: 0,
  },
  line: {
    borderColor: theme.palette.divider,
  },
}))(StepConnector);

const useCustomStepIconStyles = makeStyles(theme => ({
  root: {},
  active: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[10],
  },
  completed: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function CustomStepIcon({ active, completed, icon }) {
  const classes = useCustomStepIconStyles();

  const Icon = steps[icon - 1].icon;

  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <Icon size='20' />
    </Avatar>
  );
}

const ImportVisitors = props => {
  const { backAndRefresh } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [stepData, setStepData] = useState([
    {},
    { mapping: {}, settings: {} },
    { onDuplicate: 'skip', onError: 'stop' },
    {},
  ]);
  const [error, setError] = useState();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <ModalDialog title={'Импорт участников'} size={'md'} handleClose={backAndRefresh} noButtons={true}>
      <ErrorBlock message={error} />
      <Grid container>
        <Grid item xs={12} md={3}>
          <Stepper
            activeStep={activeStep}
            connector={<CustomStepConnector />}
            orientation='vertical'
            component={Box}
            bgcolor='transparent'
          >
            {steps.map(step => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={CustomStepIcon}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box p={3}>
            {activeStep === 0 && (
              <SelectFileStep data={stepData[0]} onChange={d => setStepData([d, stepData[1], stepData[2]])} />
            )}
            {activeStep === 1 && (
              <FieldsSetupStep stepData={stepData} onChange={d => setStepData([stepData[0], d, stepData[2]])} />
            )}
            {activeStep === 2 && (
              <ValidationStep stepData={stepData} onChange={d => setStepData([stepData[0], stepData[1], d])} />
            )}
            {activeStep === 3 && <FinishStep stepData={stepData} error={error} setError={setError} />}
          </Box>
        </Grid>
        <Grid item xs={false} md={4}></Grid>
        <Grid item xs={12} md={7}>
          <Box mt={6} display='flex'>
            {activeStep > 0 && activeStep !== 3 && (
              <Button onClick={handleBack} size='large'>
                Назад
              </Button>
            )}
            <Box flexGrow={1} />
            {activeStep < 3 && (
              <Button
                color='secondary'
                onClick={handleNext}
                type='submit'
                variant='contained'
                disabled={!stepData[activeStep].ready}
                size='large'
              >
                {activeStep !== 2 ? 'Дальше' : 'Загрузить'}
              </Button>
            )}
            {activeStep === 3 && (
              <Button color='primary' onClick={backAndRefresh} type='submit' variant='contained' size='large'>
                Завершить
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

export default ImportVisitors;
