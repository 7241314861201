import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import DesksToolbar from './components/DesksToolbar/DesksToolbar';
import DesksTable from './components/DesksTable/DesksTable';
import {Route, useParams} from 'react-router-dom';
import DeskAdd from './components/DeskAdd/DeskAdd';
import DeskEdit from './components/DeskEdit/DeskEdit';
import { MeetupContextProvider } from 'MeetupContext';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  helpLabel: {
    paddingTop: theme.spacing(2),
    textAlign: "right"
  }
}));

const DeskList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  // retrieve users list
  const [refreshCounter, setRefresh] = useState(0);
  const [desks, setDesks] = useState([]);
  useEffect(() => {
    appContext.receptionApi
      .listDesks(meetupId)
      .then(({ data }) => {
        setDesks(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const handleDelete = (meetupId, deskId) => {
    appContext.receptionApi.deleteDesk(meetupId, deskId).then(() => {
      setDesks(desks => desks.filter(u => u.deskId !== deskId));
    });
  };

  const generatePin = (meetupId, deskId) => {
    appContext.receptionApi.updateDeskPin(meetupId, deskId).then(() => {
      setRefresh(refreshCounter + 1);
    });
  };

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/desk');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };

  return (
    <MeetupContextProvider meetupId={meetupId}>
      <div className={classes.root}>
        <DesksToolbar meetupId={meetupId} />
        <DesksTable meetupId={meetupId} desks={desks} handleDelete={handleDelete} generatePin={generatePin} />
        <Typography className={classes.helpLabel}>
          <Link href="https://docs.eventos42.ru/offlain-registraciya/kak-zaiti-na-stoiku-registracii" target={'_blank'}>
            Как зайти на стойку?</Link></Typography>

        <Route path={'/m/:mid/desk/add'} exact>
          <DeskAdd goBack={backAndRefresh} desks={desks} />
        </Route>

        <Route path={'/m/:mid/desk/id/:deskId'} exact>
          <DeskEdit goBack={backAndRefresh} />
        </Route>
      </div>
    </MeetupContextProvider>
  );
};

export default DeskList;
