import React, {useState, useEffect, useContext, useRef} from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Grid from "@material-ui/core/Grid";

import TelegramLogo from 'assets/images/Telegram_2019_Logo.svg';
import FacebookLogo from 'assets/images/Facebook_circle_pictogram.svg';
import VKLogo from 'assets/images/iconfinder_vk_375340.svg';
import {IconButton, TextField, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from '@material-ui/icons/Check';
import * as _ from "underscore";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(12, 3),
  },
  channelIcon: {
    height: 40,
    width: 40,
  },
  editButton: {
    height: 40,
    width: 40,
  },
  helpLabel: {
    paddingTop: theme.spacing(2),
    textAlign: "right",
    width: "100%"
  }
}));

const ChannelEditBlock = props => {
  const classes = useStyles();
  const {channelType, logo, channels, update} = props;

  const [editMode, setEditMode] = useState(false);
  const [channel, setChannel] = useState(_.first(channels.filter(c => c.channelType === channelType)) || {channelType:channelType});
  const editRef = useRef();

  const handleButton = () => {
    setEditMode(!editMode)
    if (editMode) {
      update(channel)
    }
  }

  const handleCancel = () => {
    setEditMode(!editMode)
  }

  return (
    <>
      <Grid item xs={2}>
        <img alt={channelType} className={classes.channelIcon} src={logo} />
      </Grid>
      <Grid item xs={8}>
        {!editMode ?
          (channel.botLink ?
          <Link color='primary' underline='always' style={{ cursor: 'pointer', marginTop: 8, display: "block" }} href={channel.botLink}>
            {channel.botLink}
          </Link>
              :
          <TextField key={channelType+"prompt"} fullWidth disabled={true} value={"Вставьте токен..."}/>
          )
          :
          <TextField key={channelType+"botToken"} fullWidth autoFocus={true}
                     value={channel.channelToken || ""} onChange={(e) => setChannel({...channel, channelToken:e.target.value})}
                     onKeyDown={(e) => {
                       if (e.keyCode === 13) handleButton()
                       if (e.keyCode === 27) handleCancel()
                     }}
          />
        }

      </Grid>
      <Grid item xs={2} style={{textAlign:"right"}}>
        <IconButton className={classes.editButton} onClick={handleButton}>
          {!editMode ?
            <EditIcon />
          :
            <CheckIcon />
          }
        </IconButton>
      </Grid>
    </>
  )
}

const ChannelList = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();

  const classes = useStyles();

  const [refreshCounter, setRefresh] = useState(0);
  const [channels, setChannels] = useState();

  useEffect(() => {
    refresh()
  }, [meetupId, refreshCounter]);

  const refresh = () => {
    setChannels(null)
    appContext.chatConfigApi
      .listChatConfig(meetupId)
      .then(({ data }) => {
        setChannels(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const handleUpdate = (channel) => {
    // create update or delete
    const existed = _.first(channels.filter(c => {return c.channelType === channel.channelType}));
    const remove = channel.channelToken === "";

    if (!existed && !remove) {
      // create
      appContext.chatConfigApi.createChatConfig(meetupId, channel).then(refresh);
    }
    if (existed && !remove) {
      // update
      appContext.chatConfigApi.updateChatConfig(meetupId, existed.chatConfigId, channel).then(refresh);
    }
    if (existed && remove) {
      // delete
      appContext.chatConfigApi.deleteChatConfig(meetupId, existed.chatConfigId).then(refresh);
    }
  }

  if (channels === null || channels === undefined) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={5} style={{width:600, margin: "auto"}}>

        <ChannelEditBlock channelType={"TELEGRAM"} logo={TelegramLogo} channels={channels} update={handleUpdate}/>

        {/*<ChannelEditBlock channelType={"FACEBOOK"} logo={FacebookLogo} channels={channels} update={handleUpdate}/>*/}

        {/*<ChannelEditBlock channelType={"VK"} logo={VKLogo} channels={channels} update={handleUpdate}/>*/}

        <Typography className={classes.helpLabel}>
          <Link href="https://docs.eventos42.ru/kak-podklyuchit-chat-bota" target={'_blank'}>
            Как подключить чат-бота?</Link></Typography>
      </Grid>


    </div>
  );
};

export default ChannelList;
