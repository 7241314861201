import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import { WSContext } from '../../WSContext';
import { render } from 'react-dom';
import { IconButton, Toolbar } from '@material-ui/core';
import { Notifications as NsIcon } from '@material-ui/icons';
import useTheme from '@material-ui/core/styles/useTheme';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Badge from '@material-ui/core/Badge';
import { shortenDate } from '../../views/MeetupList/components/MeetupsTable/MeetupsTable';

const useStyles = makeStyles(theme => ({
  topBarIcon: {
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 320,
  },
}));

export const Notifications = () => {
  const theme = useTheme();
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const wsContext = useContext(WSContext);

  const ref = useRef(null);
  const [ns, setNs] = useState([]);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    wsContext.addListener(wsMessageReceiver);
    wsContext.send({ n11s: {} });
    return () => {
      wsContext.removeListener(wsMessageReceiver);
    };
  }, []);

  // TODO: refactor it. use correct state handling.
  let nsNotInState = ns;
  const wsMessageReceiver = message => {
    if (message.n11s) {
      nsNotInState = nsNotInState.concat(message.n11s).sort(e => -new Date(e.timestamp).getTime());
      setNs(nsNotInState);
    }
    if (message.n11sClear) {
      nsNotInState = [];
      setNs(nsNotInState);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clear = () => {
    if (ns.length > 0) {
      appContext.notificationApi.deleteNotifications(ns[0].timestamp);
    }
  };

  return (
    <>
      <Tooltip title='Notifications'>
        <IconButton
          style={{ color: theme.palette[theme.appBarColor].contrastText }}
          className={classes.topBarIcon}
          ref={ref}
          onClick={handleOpen}
          data-test-id='top-notifications'
        >
          <Badge badgeContent={ns.length} color='error'>
            <NsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        data-test-id="notifications-popover"
      >
        <Box p={2}>
          <Typography variant='h5' color='textPrimary'>
            Уведомления
          </Typography>
        </Box>
        {ns.length === 0 ? (
          <Box p={2}>
            <Typography color='textPrimary'>Новых уведомлений нет</Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {ns.map(notification => {
                //const Icon = iconsMap[notification.type];

                return (
                  <ListItem className={classes.listItem} divider key={notification.id} to='#'>
                    {/*<ListItemAvatar>*/}
                    {/*  <Avatar*/}
                    {/*    className={classes.icon}*/}
                    {/*  >*/}
                    {/*    <SvgIcon fontSize="small">*/}
                    {/*      <Icon />*/}
                    {/*    </SvgIcon>*/}
                    {/*  </Avatar>*/}
                    {/*</ListItemAvatar>*/}
                    <ListItemText
                      primary={notification.message.text}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={shortenDate(notification.timestamp)}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box p={1} display='flex' justifyContent='center'>
              <Button
                size='small'
                onClick={() => {
                  clear();
                  nsNotInState = [];
                }}
              >
                Очистить
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};
