import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';
import { AppContext } from '../../../../AppContext';
import { shortenDate } from '../../../MeetupList/components/MeetupsTable/MeetupsTable';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

const PaymentsTable = props => {
  const { className, payments, ...rest } = props;
  const appContext = useContext(AppContext);
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id аккаунта</TableCell>
                <TableCell>Название</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Заказ</TableCell>
                <TableCell>Транзакция</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Коммент</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((payment, i) => (
                <TableRow
                  hover
                  key={i}
                  onClick={() => appContext.history.push('/sa/payment/' + payment.accountId + '/' + payment.orderId)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <ListItemText primary={payment.accountId} />
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={payment.accountName} />
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={shortenDate(payment.date)} />
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={payment.orderId} />
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={payment.transactionId} />
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={payment.amount} />
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={payment.paymentResult ? 'Успешно' : 'Отклонён'} />
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={payment.comment} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

PaymentsTable.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.array.isRequired,
};

export default PaymentsTable;
