import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'AppContext';
import { WSContext } from 'WSContext';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  time: {
    padding: '0 20px',
    whiteSpace: 'nowrap',
    flexShrink: 0,
    color: 'inherit',
  },
}));

function Time() {
  const isTestHost = window.location.hostname === 'localhost' || window.location.hostname.startsWith('test');
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const wsContext = useContext(WSContext);
  const [time, setTime] = useState(1);
  const [offset, setOffset] = useState();
  let timeId;
  const setUpTimer = offset => {
    setOffset(offset);
    if (offset) {
      appContext.clockApi
        .get()
        .then(res => {
          let currentTime = Date.parse(res.data.date);
          setTime(currentTime);
          clearInterval(timeId);
          timeId = setInterval(() => {
            setTime(time => time + 1000);
          }, 1000);
        })
        .catch(err => console.log(err));
    } else {
      clearInterval(timeId);
    }
  };

  useEffect(() => {
    appContext.clockApi
      .get()
      .then(res => {
        setUpTimer(res.data.offset);
      })
      .catch(err => console.log(err));
  }, []);

  const wsMessageReceiver = message => {
    if (typeof message.offset === 'number') {
      setUpTimer(message.offset);
    }
  };

  useEffect(() => {
    wsContext.addListener(wsMessageReceiver);
    return () => {
      wsContext.removeListener(wsMessageReceiver);
    };
  }, []);
  if (!isTestHost || !time) {
    return null;
  }
  return offset != 0 && <Typography className={classes.time}>{new Date(time).toLocaleString('ru-RU')}</Typography>;
}

export default Time;
