import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../../AppContext';
import TimeSlotsToolbar from './components/TimeSlotsToolbar/TimeSlotsToolbar';
import TimeSlotsTable from './components/TimeSlotsTable/TimeSlotsTable';
import { Route, useParams } from 'react-router-dom';
import TimeSlotAdd from './components/TimeSlotAdd/TimeSlotAdd';
import TimeSlotEdit from './components/TimeSlotEdit/TimeSlotEdit';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const TimeSlotList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  // retrieve users list
  const [refreshCounter, setRefresh] = useState(0);
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {

    appContext.timeSlotApi
      .listTimeSlots(meetupId)
      .then(({ data }) => {
        setTimeSlots(data)
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const handleDelete = (meetupId, timeSlotId) => {
    appContext.timeSlotApi.deleteTimeSlot(meetupId, timeSlotId).then(() => {
      setTimeSlots(timeSlots => timeSlots.filter(u => u.timeSlotId !== timeSlotId));
    });
  };

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/program/time-slot');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };


  return (
    <div className={classes.root}>
      <TimeSlotsToolbar meetupId={meetupId} />
      <TimeSlotsTable
        meetupId={meetupId}
        timeSlots={timeSlots}
        handleDelete={handleDelete} 
      />

      <Route path={'/m/:mid/program/time-slot/add'} exact>
        <TimeSlotAdd goBack={backAndRefresh} />
      </Route>

      <Route path={'/m/:mid/program/time-slot/id/:timeSlotId'} exact>
        <TimeSlotEdit goBack={backAndRefresh} />
      </Route>
    </div>
  );
};

export default TimeSlotList;
