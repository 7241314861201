import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 800,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 2),
  },
  bottomDiv: {
    textAlign: 'center',
  },
  divider1: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  divider2: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  space: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const ConfirmationDialog = props => {
  const { title, message, ok, cancel, show } = props;
  const classes = useStyles();

  if (!show) {
    return null;
  }

  return (
    <Modal open={true} className={classes.modal} onClose={cancel}>
      <Paper className={classes.paper}>
        <Typography variant='h3'>{title}</Typography>
        <Divider className={classes.divider1} />

        <Typography>{message}</Typography>

        <Divider className={classes.divider2} />
        <div className={classes.bottomDiv}>
          <Button color='secondary' variant='contained' className={classes.space} onClick={cancel}>
            Отменить
          </Button>
          <Button color='primary' variant='contained' className={classes.space} onClick={ok}>
            {'Ok'}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  ok: PropTypes.func,
  cancel: PropTypes.func,
  show: PropTypes.bool,
};

export default ConfirmationDialog;
