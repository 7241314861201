import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../../AppContext';
import { useParams } from 'react-router-dom';
import TimeSlotEditForm from '../TimeSlotEditForm/TimeSlotEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../../../components/Forms/form_utils';

const TimeSlotEdit = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId, timeSlotId } = useParams();
  const { goBack } = props;
  const form = useForm();

  const [initial, setInitial] = useState(undefined);
  useEffect(() => {
    appContext.timeSlotApi.getTimeSlot(meetupId, timeSlotId).then(({ data }) => {
      setInitial(data);
    });
  }, [meetupId, timeSlotId]);

  const handleSave = () => {
    const data = form.getValues();
    data.meetupId = meetupId;
    data.timeSlotId = timeSlotId;
    if (data.hallId) {
      data.hall = {hallId: data.hallId}
    }
    if (data.speakerIds) {
      data.speakers = data.speakerIds.map(v => {return {speakerId:v}})
    }
    delete data.hallId;
    delete data.speakerIds;
    appContext.timeSlotApi
      .updateTimeSlot(meetupId, timeSlotId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  if (!initial) return null;

  return (
    <ModalDialog title={'Редактирование слота'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <TimeSlotEditForm meetupId={meetupId} form={form} initial={initial} />
    </ModalDialog>
  );
};

TimeSlotEdit.propTypes = {};

export default TimeSlotEdit;
