import React, { useState, useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import SettingsToolbar from './SettingsToolbar/SettingsToolbar';
import FieldsTable from './FieldsTable/FieldsTable';
import FieldAdd from './FieldAdd/FieldAdd';
import FieldEdit from './FieldEdit/FieldEdit';
import ErrorBlock from '../../components/ErrorBlock/ErrorBlock';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  area: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: '500px',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const FieldSettings = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();

  const classes = useStyles();

  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    appContext.configApi.getSettings(meetupId).then(({ data }) => {
      setData(data.fields);
    });
  }, [meetupId]);

  const save = (data0) => {
    appContext.configApi
      .setFieldSettings(meetupId, data0 || data)
      .then(({ data }) => {
        setData(data);
        setError(undefined);
      })
      .catch(error => {
        const data = error.response.data;
        setError(data.message);
      });
  };

  const updateFields = fields => {
    data.fields = fields;
    setData(data);
    save(data);
  };

  const updateUniqueFields = (name, value) => {
    const d = {...data}
    d.uniqueFields = d.uniqueFields || [];
    d.uniqueFields = d.uniqueFields.filter(f => f !== name);
    if (value) {
      d.uniqueFields.push(name);
    }
    setData(d);
    save(d);
  };

  const updateData = data => {
    setData(data);
    save(data);
  };

  const backAndRefresh = fields => {
    appContext.history.push('/m/' + meetupId + '/settings');
    if (Array.isArray(fields)) {
      updateFields(fields);
    }
  };

  const handleSendField = fields => {
    return appContext.configApi
      .setFieldSettings(meetupId, { ...data, fields })
      .then(({ data }) => {
        setData(data);
        return data;
      })
      .catch(error => error.response.data);
  };

  if (!data) {
    return null;
  }

  return (
    <div className={classes.root}>
      <SettingsToolbar meetupId={meetupId} />

      {error && <ErrorBlock message={error} />}

      <FieldsTable
        meetupId={meetupId}
        data={data}
        updateFields={updateFields}
        updateUniqueFields={updateUniqueFields}
      />

      <Route path='/m/:mid/settings/field/add' exact>
        <FieldAdd fields={data.fields} goBack={backAndRefresh} handleSendField={handleSendField} />
      </Route>

      <Route path='/m/:mid/settings/field/i/:idx' exact>
        <FieldEdit fields={data.fields} goBack={backAndRefresh} handleSendField={handleSendField} />
      </Route>
    </div>
  );
};

export default FieldSettings;
