import React from 'react';
import PropTypes from 'prop-types';

import Webcam from 'react-webcam';
import Button from '@material-ui/core/Button';
import Axios from 'axios';

const ReceptionFaceScanner = props => {
  const { encodings } = props;

  const webcamRef = React.useRef(null);

  const distance = (a, b) => {
    let sum = 0;
    for (let n = 0; n < a.length; n++) {
      sum += Math.pow(a[n] - b[n], 2);
    }
    return Math.sqrt(sum);
  };

  const findSimilar = enc => {
    encodings.forEach(f => {
      const d = distance(enc, f.encoding);
    });
  };

  const takePhoto = () => {
    const sc = webcamRef.current.getScreenshot();

    const byteString = atob(sc.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    Axios.post('/api/recog', ia, {
      headers: {
        'Content-Type': 'image/png',
      },
    }).then(({ data }) => {
      if (Array.isArray(data) && data.length > 0) {
        findSimilar(data[0]);
      }
    });
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
  };

  return (
    <div>
      <Webcam
        audio={false}
        width={400}
        height={300}
        ref={webcamRef}
        screenshotFormat='image/png'
        videoConstraints={videoConstraints}
      />
      <Button color='primary' variant='contained' onClick={takePhoto}>
        Щёлк
      </Button>
    </div>
  );
};

ReceptionFaceScanner.propTypes = {
  className: PropTypes.string,
  meetupId: PropTypes.number,
};

export default ReceptionFaceScanner;
