import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ECCommonErrorBlock } from '../../../components/Forms/ECCommonErrorBlock';
import { ECTextField } from '../../../components/Forms/ECTextField';
import {ECPhotoField} from "../../../components/Forms/ECPhotoField";
import {ECCheckboxField} from "../../../components/Forms/ECCheckboxField";
import Button from "@material-ui/core/Button";
import {ECSelectField} from "../../../components/Forms/ECSelectField";
import {fieldKey} from "../../VisitorList/components/ImportXLS/import_utils";
import {MeetupContext} from "../../../MeetupContext";
import Paper from "@material-ui/core/Paper";
import {ECQuillEditor} from "../../../components/Forms/ECQuillEditor";
import {AttachmentsList} from "../../OnlineRegistration/components/AttachmentsList";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  editor: {
    '& .ql-editor': {
      height: 200
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  spacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));


const MerchEditForm = props => {
  const { form, initial } = props;
  const meetupContext = useContext(MeetupContext);
  const classes = useStyles();

  const tweakProfile = form.watch("handling.tweakProfile")
  const sendMail = form.watch("handling.sendMail")

  return (
    <>
      <ECCommonErrorBlock form={form} />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <ECPhotoField label='Изображение' name='imageId' form={form} initial={initial} height={197}/>
        </Grid>
        <Grid item xs={9}>
          <Grid item xs={12}>
            <ECTextField label='Название' name='name' initial={initial} form={form} />
          </Grid>
          <Grid item xs={12}>
            <ECTextField label='Стоимость' name='price' initial={initial} defaultValue={0} form={form} />
          </Grid>
          <Grid item xs={12}>
            <ECTextField label='Описание' name='description' initial={initial} form={form} multiline rows={3} />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <ECCheckboxField label='Менять профиль' name='handling.tweakProfile' form={form} initial={initial}/>
        </Grid>
        <Grid item xs={5}>
          <ECSelectField label='Поле' name='handling.fieldName' options={meetupContext.fields} form={form} initial={initial} disabled={!tweakProfile}
                         fid={f => fieldKey(f)} fname={f => f.label}
          />
        </Grid>
        <Grid item xs={4}>
          <ECTextField label='Значение' name='handling.fieldValue' form={form} initial={initial} disabled={!tweakProfile}/>
        </Grid>

        <Grid item xs={3}>
          <ECCheckboxField label='Отправлять письмо' name='handling.sendMail' form={form} initial={initial}/>
        </Grid>
        <Grid item xs={9} container>
          {/*<Button>Настроить шаблон</Button>*/}

          <Grid item xs={12}>
            <ECTextField label='Заголовок письма' name='handling.mailTemplate.title' initial={initial} form={form}  disabled={!sendMail} defaultValue={"Товар куплен"}/>
          </Grid>
          {sendMail &&
          <>
            <Grid item xs={12}>
              <Paper variant="outlined">
                <ECQuillEditor label='Содержание письма' name='handling.mailTemplate.body' initial={initial} form={form} className={classes.editor} disabled={!sendMail} defaultValue={"Вы купили товар"}/>
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.spacing}>
              <AttachmentsList initial={initial} form={form} name='handling.mailTemplate.attachments'/>
            </Grid>
          </>
          }

        </Grid>

      </Grid>
    </>
  );
};

export default MerchEditForm;
