import React, {useContext, useEffect, useState} from 'react';
import {Button, TextField, Typography} from "@material-ui/core";
import {convertErrors} from "../MeetupList/components/MeetupEditForm/MeetupEditForm";
import {AppContext} from "../../AppContext";
import {makeStyles} from "@material-ui/styles";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  contentContainer: {
    backgroundColor:"white",
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    padding: "10px  45px",
    border: "1px solid #ffffff",
    borderBottomColor: "#DEDEDE",
    borderTopColor: "#DEDEDE",
    height: "calc(100% - 85px)",
    paddingBottom: 30
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    backgroundColor:"white",
    height: "320px",
    width: "640px",
    padding:"30px",
    borderRadius: "8px",
  },
  formInputsContainer:{
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  bottomText:{
    marginTop: "20px",
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#213955",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "16px",
  },
  title: {
    color: "#213955",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "28px",
    letterSpacing: "-0.06px",
    marginBottom: 20,
  },
  textField: {
    marginTop: "20px",
  },
  signUpButton: {
    marginTop: "24px",
  }
}));
const RequestForChangePassword = () => {
  const appContext = useContext(AppContext);

  const classes = useStyles();

  const [formState, setFormState] = useState({
    values: {},
    touched: {},
    errors: {},
  });


  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      errors: errors || {},
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (errors) {
      setFormState({
        ...formState,
        ...convertErrors(errors),
      });
    }
  }, [errors]);

  const handleRegister =event=>{
    event.preventDefault();
    appContext.authApi.requestChangePassword(formState.values)
      .then(({ data }) => {
        appContext.history.push('/change-password-success')
      })
      .catch(error => {
        const data = error.response.data;
        setErrors(data.errors || [data]);
      });
  }

  const handleChange = event =>{
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  }

  const hasError = field => {
    return (!!(formState.touched[field] && formState.errors[field]));
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <ErrorBlock message={formState.errors.token}/>
          <form className={classes.form} onSubmit={handleRegister}>
          <Typography className={classes.title} variant='h2'>
            Восстановить пароль
          </Typography>
              <div className={classes.formContainer} >
            <TextField
              className={classes.textField}
              error={hasError('email')}
              fullWidth
              helperText={hasError('email') ? formState.errors.email : null}
              label='Адрес электронной почты'
               name='email'
              onChange={handleChange}
              type='text'
              value={formState.values.email || ''}
              variant='outlined'
              required={false}
              autoComplete={'off'}
              />
              <Button
                className={classes.signUpButton}
                color='primary'
                fullWidth
                size='large'
                type='submit'
                variant='contained'
              >
                Отправить письмо
              </Button>
          </div>
            <div className={classes.bottomText}>
              <p onClick={()=>appContext.history.push('/sign-in')} style={{cursor:"pointer"}}>Вход администратора</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RequestForChangePassword;
