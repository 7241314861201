import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../../AppContext';
import { useParams } from 'react-router-dom';
import GroupEditForm from '../GroupEditForm/GroupEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../../components/Forms/form_utils';

const GroupEdit = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId, groupId } = useParams();
  const { goBack } = props;
  const form = useForm();

  const [initial, setInitial] = useState(undefined);
  useEffect(() => {
    appContext.groupApi.getGroup(meetupId, groupId).then(({ data }) => {
      setInitial(data);
    });
  }, [meetupId, groupId]);

  const handleSave = () => {
    const data = form.getValues();
    data.meetupId = meetupId;
    data.groupId = groupId;
    data.color = initial.color
    appContext.groupApi
      .updateGroup(meetupId, groupId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  if (!initial) return null;

  return (
    <ModalDialog title={'Редактирование группы'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <GroupEditForm meetupId={meetupId} form={form} initial={initial} />
    </ModalDialog>
  );
};

GroupEdit.propTypes = {};

export default GroupEdit;
