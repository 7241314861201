import {FieldType, SystemFieldType} from '../../../../api';
import { visitorForExport, visitorForPreview } from '../../../BadgeDetails/components/BadgeView/BadgeView';
import * as _ from 'underscore';
import { visitorsToCells } from '../ExportXLS/ExportXLS';
import XLSX from 'xlsx';
import {getFieldFromVisitor} from "../../../../common/filterVisitors";
import {valueByName} from "../../../../components/Forms/form_utils";
import {transliterate} from "../VisitorEditForm/fields/ExtendedTranslitField";

const normalize = v => {
  if (v === null || typeof v === 'undefined') {
    return undefined;
  }
  if (typeof v === 'string') {
    return v.trim();
  }
  return v;
};

const numberTo26Base = n => {
  let s = '';
  if (n === 0) {
    return 'A';
  }

  while (n) {
    s += String.fromCharCode('A'.charCodeAt(0) + (n % 26));
    n = Math.floor(n / 26);
  }

  return s.split('').reverse().join('');
};

export const parseWorkbook = book => {
  const sheet = book.Sheets[book.SheetNames[0]];

  let data = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: false });
  let headers = data[0];
  headers = headers.map((header, index) => [numberTo26Base(index), header]);

  let records = data.slice(1);
  records = records.map((record, index) => {
    let newRecord = {
      __row__: index + 2,
    };

    for (let i = 0; i < record.length; ++i) {
      if (record[i]) {
        newRecord[numberTo26Base(i)] = record[i];
      }
    }

    return newRecord;
  });

  return { headers, records };
};

export const fieldKey = field => field.systemField || field.name;

export const fieldFullName = field => field.systemField || 'extended.' + field.name;

export const defaultFieldsMapping = (meetupFields, workbookFields) => {
  let ret = {};
  meetupFields.forEach(f => {
    const wf = workbookFields.filter(w => f.label === w[1] || f.name === w[1] || f.systemField === w[2]);
    if (wf.length > 0) {
      ret[fieldKey(f)] = wf[0][0];
    }
  });
  return ret;
};

export const generateExampleData = async (appContext, meetupContext) => {
  const { data: visitors } = await appContext.visitorApi.listVisitors(meetupContext.meetupId);
  if (visitors.length > 0) {
    return _.shuffle(visitors).slice(0, 5);
  } else {
    const dd = visitorForExport(meetupContext.fields);
    return [dd, dd, dd, dd, dd];
  }
};

export const generateVisitorXLS = async (visitors, meetupContext, filename) => {
  const XLSX = await import('xlsx');
  let table = visitorsToCells(meetupContext.fields, meetupContext.groups, visitors, []);
  let ws = XLSX.utils.aoa_to_sheet(table);

  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Visitors');
  XLSX.writeFile(wb, filename || 'example.xlsx');
};

const putFieldValueIntoVisitor = (visitor, field, v, meetupContext) => {
  v = v || field.defaultValue;
  if (field.systemField) {
    if (field.systemField === SystemFieldType.Group) {
      let exactlyMatch = false;
      for (let group of meetupContext.groups) {
        if (v === group.name) {
          exactlyMatch = true;
          break;
        }
      }

      if (!exactlyMatch) {
        for (let group of meetupContext.groups) {
          if (group.name.toLowerCase() === v.toLowerCase()) {
            v = group.name;
          }
        }
      }

      visitor.groupName = v;
    } else if (field.systemField === SystemFieldType.Photo && v === "") {
      visitor[field.systemField] = null;
    } else {
      visitor[field.systemField] = v;
    }
  } else {
    visitor.extended = visitor.extended || {};
    visitor.extended[field.name] = v;
  }
};

export const convertDataToVisitors = (data, fieldsSettings, meetupContext) => {
  const visitors = [];
  const errors = [];

  const fields = meetupContext.fields.map(f => [f, fieldsSettings.mapping[fieldKey(f)]]);

  data.records.map(r => {
    try {
      const visitor = {};
      fields.forEach(f => {
        const [field, col] = f;
        let v = r[col];
        if (field.capitalLetter && v && v.length > 0) {
          v = v[0].toUpperCase() + v.slice(1);
        }

        putFieldValueIntoVisitor(visitor, field, v, meetupContext);
      });
      meetupContext.fields.filter(f => f.type === FieldType.Transliteration && f.fieldForTranslit).forEach(f => {
        const ov = valueByName(visitor, fieldFullName(f));
        if (!ov) {
          let v = valueByName(visitor, f.fieldForTranslit);
          v = transliterate(v)
          putFieldValueIntoVisitor(visitor, f, v, meetupContext);
        }
      });
      visitor.__row__ = r.__row__;
      visitors.push(visitor);
    } catch (e) {
      errors.push({ row: r.__row__, error: e });
    }
  });
  return [visitors, errors];
};
