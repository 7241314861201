import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Divider, Typography, SvgIcon, Input, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
// import {
//   markThreadAsSeen,
//   getThread
// } from 'src/actions/chatActions';
// import Toolbar from './Toolbar';
import Message from '../Message';
// import MessageAdd from '../MessageAdd';

function threadSelector(state, threadKey, history) {
  const { threads, contacts } = state.chat;
  const { user } = state.account;
  const thread = threads.byKey[threadKey];

  // When starting a new thread, we don't have it in store
  // So we can create a temporary new one where threadKey is the contact username
  if (!thread) {
    const contactId = contacts.allIds.filter(
      // eslint-disable-next-line no-underscore-dangle
      _contactId => contacts.byId[_contactId].username === threadKey
    )[0];

    if (!contactId) {
      history.push('/app/chat/new');
    }

    return {
      key: threadKey,
      type: 'ONE_TO_ONE', // We might add GROUP type in future
      participantIds: [contactId, user.id],
      messages: [],
    };
  }

  return thread;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  header: {
    height: 68,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    padding: '0 30px',
  },
  header__name: {
    fontSize: 14,
    fontWeight: 500,
  },
  header__search: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F5F6F7',
    borderRadius: 4,
    padding: `0 ${theme.spacing(1)}px`,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
  },
}));

function filterMessages(messages, searchText) {
  if (!searchText) {
    return messages;
  }
  return messages.filter(message => message.message.toLowerCase().includes(searchText.toLowerCase()));
}

function ThreadDetails({ threads }) {
  const classes = useStyles();
  const { clientId } = useParams();
  const thread = threads[clientId];
  const history = useHistory();
  // const thread = useSelector((state) => threadSelector(state, threadKey, history));
  const messagesRef = useRef(null);
  const [searchText, setSearchText] = useState('');

  function scrollMessagesToBottom() {
    if (messagesRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      messagesRef.current._container.scrollTop = messagesRef.current._container.scrollHeight;
    }
  }

  const handleSearchChange = event => {
    // event.persist();
    setSearchText(event.target.value);
  };

  // useEffect(() => {
  //   dispatch(getThread(threadKey));
  //   dispatch(markThreadAsSeen(threadKey));
  // }, [dispatch, threadKey]);

  useEffect(()=>{
    console.log(threads)
  },[threads])

  useEffect(() => {
    if (thread) {
      scrollMessagesToBottom();
    }
    // eslint-disable-next-line
  }, [thread.messages]);

  if (!thread) {
    return null;
  }

  return (
    <div className={classes.root}>
      {/* <Toolbar thread={thread} /> */}
      <div className={classes.header}>
        <Typography className={classes.header__name}>{thread.name}</Typography>
        {thread.tgUsername && 
          <a href={'https://t.me/'+thread.tgUsername}>
            <Typography className={classes.header__name}>{"@"+thread.tgUsername}</Typography>
          </a>
        }
        <div className={classes.header__search}>
          <SvgIcon fontSize='small' color='action'>
            <SearchIcon />
          </SvgIcon>
          <Input
            className={classes.searchInput}
            disableUnderline
            onChange={handleSearchChange}
            placeholder='Поиск по переписке'
            value={searchText}
          />
        </div>
      </div>
      <Divider />
      <Box flexGrow={1} p={2} ref={messagesRef} component={PerfectScrollbar} options={{ suppressScrollX: true }}>
        {thread.messages.length > 0 &&
          filterMessages(thread.messages, searchText).map(message => <Message key={message.id} message={message} />)}
      </Box>
      <Divider />
      {/* <MessageAdd thread={thread} /> */}
    </div>
  );
}

export default ThreadDetails;
