import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { AppContext } from '../../AppContext';
import palette from '../../theme/default/palette';

const useStyles = makeStyles(theme => ({
  divider: {
    borderBottom: `1px solid ${palette.secondary.main}`,
  },
}));

const SAInnerMenu = props => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const valueFromUrl = () => {
    const path = appContext.history.location.pathname;
    if (path.indexOf('account') !== -1) {
      return 'account';
    }
    if (path.indexOf('total-stat') !== -1) {
      return 'total-stat';
    }
    if (path.indexOf('tariff') !== -1) {
      return 'tariff';
    }
    if (path.indexOf('payment') !== -1) {
      return 'payment';
    }
    return '';
  };

  return (
    <Fragment>
      <Tabs
        className={classes.tabs}
        onChange={() => {}}
        scrollButtons='auto'
        value={valueFromUrl()}
        variant='scrollable'
      >
        <Tab
          label={'Аккаунты'}
          value={'account'}
          onClick={() => {
            appContext.history.push('/sa/account');
          }}
        />
        <Tab
          label={'Тарифы'}
          value={'tariff'}
          onClick={() => {
            appContext.history.push('/sa/tariff');
          }}
        />
        <Tab
          label={'Платежи'}
          value={'payment'}
          onClick={() => {
            appContext.history.push('/sa/payment');
          }}
        />
        <Tab
          label={'Статистика'}
          value={'total-stat'}
          // onClick={() => {appContext.history.push("/sa/total-stat")}}
        />
      </Tabs>
      <Divider className={classes.divider} />
    </Fragment>
  );
};

export default SAInnerMenu;
