import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import Typography from '@material-ui/core/Typography';
import { AppContext } from '../../../../AppContext';
import { useParams } from 'react-router-dom';
import ErrorBlock from '../../../../components/ErrorBlock/ErrorBlock';
import { Grid, TextField } from '@material-ui/core';
import { convertErrors, MyFormTextField } from '../../../MeetupList/components/MeetupEditForm/MeetupEditForm';
import validate from 'validate.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ServicePlanActionType } from '../../../../api';
import MenuItem from '@material-ui/core/MenuItem';
import { DateTimePicker } from '@material-ui/pickers';

const schema = {};

const AccountEdit = props => {
  const appContext = useContext(AppContext);
  const { accountId } = useParams();
  const { goBack } = props;

  const [errors, setErrors] = useState([]);

  const [limitsChange, setLimitsChange] = useState(false);
  const [formState, setFormState] = useState({
    values: undefined,
    errors: {},
    touched: {},
    isValid: true,
  });

  useEffect(() => {
    appContext.accountApi.getAccount(accountId).then(({ data }) => {
      setFormState({
        values: data,
        touched: {},
      });
    });
  }, [accountId]);
  const [tariffs, setTariffs] = useState(undefined);
  useEffect(() => {
    appContext.configApi
      .getServicePlanConfig()
      .then(({ data }) => {
        setTariffs(data);
        // console.log(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    if (!limitsChange) {
      formState.values.limits = undefined;
    }
    appContext.accountApi
      .updateAccount(accountId, formState.values)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(error => {
        const data = error.response.data;
        setErrors(data.errors || [data]);
      });
  };

  useEffect(() => {
    if (errors) {
      setFormState({
        ...formState,
        ...convertErrors(errors),
      });
    }
  }, [errors]);

  const handleChange = (name, value) => {
    if (name.startsWith('owner.')) {
      formState.values.owner[name.substr('owner.'.length)] = value;
    } else {
      formState.values[name] = value;
    }
    formState.touched[name] = true;

    const errors = validate(formState.values, schema);

    setFormState(formState => {
      return {
        ...formState,
        isValid: !errors,
        errors: errors || {},
      };
    });
  };

  const error = field => {
    if (!formState.touched || !formState.errors) {
      return null;
    }
    if (formState.touched[field] && formState.errors[field]) {
      return formState.errors[field];
    } else {
      return null;
    }
  };

  const form = {
    // form values bind helper
    data: formState,
    handleChange: handleChange,
    error: error,
  };

  if (!formState.values || !tariffs) {
    return null;
  }

  return (
    <ModalDialog
      title={'Редактирование аккаунта'}
      okButtonText={'Сохранить'}
      handleOk={handleSave}
      handleClose={goBack}
    >
      <ErrorBlock message={formState.error} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyFormTextField name={'name'} label='Название' required {...form} />
        </Grid>
        <Grid item xs={12}>
          <MyFormTextField name={'owner.email'} label='Email владельца' required {...form} />
        </Grid>
        <Grid item xs={12}>
          <MyFormTextField name={'internalComment'} label='Внутренний комментарий' {...form} />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            labelPlacement={'start'}
            control={
              <Checkbox
                checked={formState.values.active || false}
                name='active'
                onChange={e => handleChange('active', e.target.checked)}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            }
            label='Активен'
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            labelPlacement={'start'}
            control={
              <Checkbox
                checked={formState.values.confirmed || false}
                name='confirmed'
                onChange={e => handleChange('confirmed', e.target.checked)}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
                disabled={formState.values.confirmed || false}
              />
            }
            label='Подтверждён'
          />
        </Grid>
        <Grid item xs={12}>
          <MyFormTextField name={'balance'} label='Баланс' inputProps={{ readOnly: true }} {...form} />
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            label='Текущий тарифный план'
            style={{ width: '100%' }}
            value={formState.values.servicePlanId}
            onChange={e => handleChange('servicePlanId', e.target.value)}
            name='servicePlanId'
            margin='dense'
            variant='outlined'
            inputProps={{ readOnly: true }}
          >
            {tariffs.servicePlans.map(plan => (
              <MenuItem key={plan.name} value={plan.name}>
                {plan.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <DateTimePicker
            label='Дата следующего действия'
            inputVariant='outlined'
            value={formState.values.nextActionDate}
            onChange={v => {
              handleChange('nextActionDate', v);
            }}
            margin='dense'
            helperText={error('nextActionDate')}
            error={error('nextActionDate') != null}
            fullWidth
            ampm={false}
            disablePast
            format={'D MMMM YYYY HH:mm'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            label='Следующее действие'
            style={{ width: '100%' }}
            value={formState.values.nextActionType}
            onChange={e => handleChange('nextActionType', e.target.value)}
            name='nextActionType'
            margin='dense'
            variant='outlined'
          >
            {Object.keys(ServicePlanActionType).map(t => (
              <MenuItem key={t} value={ServicePlanActionType[t]}>
                {ServicePlanActionType[t]}
              </MenuItem>
            ))}
          </TextField>

          <MyFormTextField name={'nextServicePlanId'} label='Следующий тарифный план' {...form} />
        </Grid>

        <Grid item xs={4}>
          <Typography>Счётчик</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Значение</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Лимит</Typography>
        </Grid>

        <Grid container spacing={0} item xs={12}>
          {formState.values.limits.map((l, i) => (
            <Grid key={i} container spacing={0} item xs={12}>
              <Grid item xs={4}>
                <Typography>{l.limit}</Typography>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  value={l.current}
                  onChange={e => {
                    formState.values.limits[i].current = parseInt(e.target.value);
                    setFormState(formState => {
                      return { ...formState };
                    });
                    setLimitsChange(true);
                  }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  value={l.max}
                  onChange={e => {
                    formState.values.limits[i].max = parseInt(e.target.value);
                    setFormState(formState => {
                      return { ...formState };
                    });
                    setLimitsChange(true);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

AccountEdit.propTypes = {};

export default AccountEdit;
