import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { renderBadgePDF_ToDataUrl } from '../../../../BadgeDetails/components/BadgeView/BadgeView';
import { saveAs } from 'file-saver';
import DropDownMenu from '../../../../../components/DropDownMenu/DropDownMenu';
import PrintVisitors from '../../../../VisitorList/components/VisitorsToolbar/PrintVisitors';
import ColorPicker from 'components/ColorPicker/ColorPicker'
import { HelpHint } from 'components/HelpHint/HelpHint'
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {shortenTime} from "../../../../MeetupList/components/MeetupsTable/MeetupsTable";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 600,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  fab: {
    margin: theme.spacing(1),
  },
  hint: {
    marginLeft: 10,
    height: 16,
    width: 16,
    minHeight: 16,
  }
}));


const TimeSlotsTable = props => {
  const { className, meetupId, timeSlots, handleDelete, ...rest } = props;

  const classes = useStyles();

  if (!timeSlots) {
    return null
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Время</TableCell>
                <TableCell>Название</TableCell>
                <TableCell>Зал/Место</TableCell>
                <TableCell>Спикеры</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timeSlots.map((timeSlot, i) => (
                <TableRow className={classes.tableRow} hover key={timeSlot.timeSlotId}>
                  <TableCell>
                    <RouterLink to={'/m/' + meetupId + '/program/time-slot/id/' + timeSlot.timeSlotId}>
                      <ListItemText
                        primary={shortenTime(timeSlot.startTime)}
                        secondary={ moment(timeSlot.endTime).diff(moment(timeSlot.startTime), 'minutes') + "мин" }
                      />
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <RouterLink to={'/m/' + meetupId + '/program/time-slot/id/' + timeSlot.timeSlotId}>
                      <Typography style={{fontWeight:"bold"}}>{timeSlot.name}</Typography>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <Typography>{timeSlot.hall && timeSlot.hall.name}</Typography>
                  </TableCell>
                  <TableCell>
                    {timeSlot.speakers.map(s => (
                      <Typography>{s.firstName + " " + s.lastName}</Typography>
                    ))}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '100px' }}>
                    <DropDownMenu data-test-id="time-slot-menu">
                      <MenuItem>
                        <RouterLink to={'/m/' + meetupId + '/program/time-slot/id/' + timeSlot.timeSlotId} data-test-id="time-slot-menu-edit">
                          <ListItemText primary='Редактировать' />
                        </RouterLink>
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(meetupId, timeSlot.timeSlotId)} data-test-id="time-slot-menu-delete">
                        <ListItemText primary='Удалить' />
                      </MenuItem>
                    </DropDownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

TimeSlotsTable.propTypes = {
  className: PropTypes.string,
  timeSlots: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
};

export default TimeSlotsTable;
