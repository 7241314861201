import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import { Grid } from '@material-ui/core';
import { Route, useParams } from 'react-router-dom';
import BadgesToolbar from './components/BadgesToolbar/BadgesToolbar';
import BadgeCard from './components/BadgeCard/BadgeCard';
import TemplatesToolbar from './components/TemplatesToolbar/TemplatesToolbar';
import BadgeAdd from './components/BadgeAdd/BadgeAdd';
import BadgeShare from './components/BadgeShare/BadgeShare'
import BadgeDetails from '../BadgeDetails/BadgeDetails';
import Main from '../../layouts/Main/Main';
import RouteWithLayout from '../../components/RouteWithLayout/RouteWithLayout';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const BadgeList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();

  // retrieve users list
  const [badges, setBadges] = useState([]);
  const [badgeToShare, setBadgeToShare] = useState()

  useEffect(() => {
    appContext.badgeApi
      .listBadges(meetupId)
      .then(({ data }) => {
        setBadges(data);
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId]);

  const handleDelete = badgeId => {
    appContext.badgeApi.deleteBadge(badgeId).then(() => {
      setBadges(badges => badges.filter(u => u.badgeId !== badgeId));
    });
  };

  return (
    <div className={classes.root}>
      {meetupId ? (
        <>
          <BadgesToolbar meetupId={meetupId} />
        </>
      ) : (
        <TemplatesToolbar />
      )}

      <div className={classes.content}>
        <Grid container spacing={3}>
          {badges.map(badge => (
            <Grid item key={badge.badgeId} lg={4} md={6} xs={12}>
              <BadgeCard meetupId={meetupId} badge={badge} handleDelete={handleDelete} setBadges={setBadges} setBadgeToShare={setBadgeToShare}/>
            </Grid>
          ))}
        </Grid>
      </div>

      <Route path={'/m/:mid/badge/add'} exact>
        <BadgeAdd meetupId={meetupId} />
      </Route>

      <RouteWithLayout path='/m/:mid/badge/id/:badgeId' exact component={BadgeDetails} layout={Main} auth={'admin'} />
      <BadgeShare open={!!badgeToShare} badgeToShare={badgeToShare} setBadgeToShare={setBadgeToShare} meetupId={meetupId}/>
    </div>
  );
};

export default BadgeList;
