import { Link, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { generateExampleData, generateVisitorXLS, parseWorkbook } from '../import_utils';
import { AppContext } from '../../../../../AppContext';
import { MeetupContext } from '../../../../../MeetupContext';

export const SelectFileStep = props => {
  const { data, onChange } = props;
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);

  const uploadFile = async event => {
    event.persist();
    const XLSX = await import('xlsx');

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const parsed = parseWorkbook(workbook);
      onChange({
        filename: file.name,
        data: parsed,
        ready: true,
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const generateExampleXLS = () => {
    generateExampleData(appContext, meetupContext).then(v => generateVisitorXLS(v, meetupContext));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Typography variant={'h3'}>Выбор файла</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <input style={{ display: 'none' }} id='raised-button-file' type='file' onChange={uploadFile} />

        <Button
          color='primary'
          onClick={e => {
            e.preventDefault();
            document.getElementById('raised-button-file').click();
          }}
          variant='contained'
        >
          Выбрать файл
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography>
          Скачать пример:&nbsp;
          <Link color='primary' underline='always' style={{ cursor: 'pointer' }} onClick={generateExampleXLS}>
            example.xlsx
          </Link>
        </Typography>
      </Grid>
      {data.filename && (
        <Grid item xs={12} md={12} container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography>Файл: {data.filename}</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>Колонки: {data.data.headers.map(i => i[1]).join(', ')}</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>Записей: {data.data.records.length}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
