import React, { useContext } from 'react';
import { Divider, Tab, Tabs } from '@material-ui/core';
import { AppContext } from '../../AppContext';
import { Route, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import DeskList from '../DeskList/DeskList';
import Chat from '../Chat/Chat';
import OnlineRegistration from '../OnlineRegistration/OnlineRegistration';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const VisitorRegistration = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();

  const valueFromUrl = () => {
    const path = appContext.history.location.pathname;
    if (path.indexOf('desk') !== -1) {
      return 'desk';
    }
    if (path.indexOf('online-registration') !== -1) {
      return 'onlineRegistration';
    }
    return '';
  };

  return (
    <div className={classes.root}>
      <Tabs
        onChange={() => {}}
        scrollButtons='auto'
        value={valueFromUrl()}
        variant='scrollable'
        style={{ margin: 'auto', maxWidth: 'max-content' }}
      >
        <Tab
          label={'Онлайн'}
          value={'onlineRegistration'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/online-registration');
          }}
        />
        <Tab
          label={'Офлайн'}
          value={'desk'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/desk');
          }}
        />
      </Tabs>
      <Divider />
      <Route path='/m/:mid/desk'>
        <DeskList />
      </Route>
      <Route path='/m/:mid/online-registration'>
        <OnlineRegistration />
      </Route>
    </div>
  );
};

export default VisitorRegistration;
