import React, { useContext, useEffect, useState } from 'react';
import {Grid, IconButton, TextField} from '@material-ui/core';
import { ECCommonErrorBlock } from '../../../components/Forms/ECCommonErrorBlock';
import { ECTextField } from '../../../components/Forms/ECTextField';
import {ECPhotoField} from "../../../components/Forms/ECPhotoField";
import {ECCheckboxField} from "../../../components/Forms/ECCheckboxField";
import Button from "@material-ui/core/Button";
import {ECSelectField} from "../../../components/Forms/ECSelectField";
import {fieldKey} from "../../VisitorList/components/ImportXLS/import_utils";
import {MeetupContext} from "../../../MeetupContext";
import Paper from "@material-ui/core/Paper";
import {ECQuillEditor} from "../../../components/Forms/ECQuillEditor";
import {AttachmentsList} from "../../OnlineRegistration/components/AttachmentsList";
import {makeStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import {ECRadioGroupField} from "../../../components/Forms/ECRadioGroupField";
import FormLabel from "@material-ui/core/FormLabel";
import {Link, useParams} from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import {ECDateField} from "../../../components/Forms/ECDateField";
import {ECDateTimeField} from "../../../components/Forms/ECDateTimeField";
import ModalDialog from "../../../components/ModalDialog/ModalDialog";
import NewsTypeForm from "./NewsTypeForm";
import {ECMultiSelectField} from "../../../components/Forms/ECMultiSelectField";
import {NewsType, VisitorStatusType} from "../../../api";
import {AppContext} from "../../../AppContext";
import {fixDotProperties} from "../../Merch/components/MerchEdit";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles(theme => ({
  groupLabel: {
    paddingTop: theme.spacing(1)
  },
  groupLabel2: {
    paddingTop: theme.spacing(2)
  },
  editor: {
    '& .ql-editor': {
      height: 100
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  spacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const NewsEditForm = props => {
  const { form, initial, type } = props;
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const classes = useStyles();
  const { mid: meetupId } = useParams();

  let imd = 1;
  if (initial && initial.sendingTime && initial.sendingTime.immediate !== null) {
    imd = initial.sendingTime.immediate ? 1 : 2
  }
  const [immediate, setImmediate] = useState(imd)
  const [recipientsIsOpen, setRecipientsIsOpen] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [recipientsCount, setRecipientsCount] = useState()

  useEffect(() => {
    form.register("sendingTime.immediate")
    form.setValue("sendingTime.immediate", immediate)

    form.register("recipients.groups")
    form.setValue("recipients.groups", (initial && initial.recipients && initial.recipients.groups) || [])

    form.register("recipients.statuses")
    form.setValue("recipients.statuses", (initial && initial.recipients && initial.recipients.statuses) || [])

    form.register("recipients.tags")
    form.setValue("recipients.tags", (initial && initial.recipients && initial.recipients.tags) || [])
  }, [])

  const checkRec = (field) => {
    const v = form.getValues()["recipients." + field]
    if (v) {
      return v.length > 0
    }

    const v2 = (initial && initial.recipients && initial.recipients[field])
    return v2 && v2.length > 0
  }
  const isAll = (checkRec("registered") || checkRec("groups") || checkRec("statuses") || checkRec("tags")) ? 2 : 1

  useEffect(() => {
    const data = fixDotProperties(form.getValues())
    appContext.newsApi.checkRecipients(meetupId, data.recipients).then(({ data }) => {
      setRecipientsCount(data.preliminaryCount);
    });
  }, [refresh])

  return (
    <>
      <ECCommonErrorBlock form={form} />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Typography className={classes.groupLabel}>Время отправки:</Typography>
        </Grid>
        <Grid item xs={9}>
          <RadioGroup value={immediate} onChange={e => {
            const i = parseInt(e.target.value);
            setImmediate(i)
            form.setValue("sendingTime.immediate", i === 1)
          }}>
            <Grid container>
              <Grid item xs={2}>
                <FormControlLabel value={1} control={<Radio />} label="Сразу" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value={2} control={<Radio />} label="Запланировать" />
              </Grid>
              <Grid item xs={6}>
                <ECDateTimeField style={{paddingTop: 1}} name='sendingTime.time' form={form} initial={initial} disabled={immediate===1} inputVariant={"standard"}  />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid item xs={3}>
          <Typography  className={classes.groupLabel}>Получатели:</Typography>
        </Grid>
        <Grid item xs={9}>
          <RadioGroup value={isAll} onChange={e => {}}>
            <Grid container>
              <Grid item xs={2}>
                <FormControlLabel value={1} control={<Radio />} label="Все"
                                  onClick={() => {
                                    form.setValue("recipients.groups", [])
                                    form.setValue("recipients.statuses", [])
                                    form.setValue("recipients.tags", [])
                                    setRefresh(refresh+1)
                                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value={2} control={<Radio />} label="Выбрать получателей" style={{cursor:"pointer", textDecoration:"underline"}}
                                  onClick={() => setRecipientsIsOpen(true)}/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"body2"}>Количество получателей: {recipientsCount}</Typography>
              </Grid>
            </Grid>
          </RadioGroup>

        </Grid>

        <Grid item xs={12}>
          <ECTextField label='Заголовок' name='title' initial={initial} form={form} />
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined">
            <ECQuillEditor label='' name='text' initial={initial} form={form}  className={classes.editor} formats={'tg'} />
          </Paper>

          <Typography>Посмотреть&nbsp;
            <Link style={{ cursor: 'pointer' }} to={`/m/${meetupId}/template-params`} target={'_blank'}>список доступных подстановок</Link>
            &nbsp;в шаблоне</Typography>
        </Grid>

        {type !== NewsType.Question ?
          <>
            <Grid item xs={3}>
              <Typography style={{paddingTop: 5}}>Вложения:</Typography>
            </Grid>
            <Grid item xs={9} >
              <AttachmentsList initial={initial} form={form} name='attachments'/>
            </Grid>
          </>
          :
          <>
            <Grid item xs={3}>
              <Typography  className={classes.groupLabel2}>Варианты ответов:</Typography>
            </Grid>
            <Grid item xs={9} >
              <AnswersList initial={initial} form={form} name='answerOptions'/>
            </Grid>
          </>
        }

      </Grid>

      { recipientsIsOpen &&
        <ModalDialog title={'Выбор получателей'} okButtonText={'Ок'} handleOk={() => {setRecipientsIsOpen(false); setRefresh(refresh + 1)}}>

          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography className={classes.groupLabel2}>Регистрация:</Typography>
            </Grid>
            <Grid item xs={9}>
              <ECMultiSelectField
                name='recipients.registered'
                options={[{"id":true, "name":"Есть регистрация"}, {"id":false, "name":"Нет регистрации"}]}
                defaultValue={form.getValues()["recipients.registered"]}
                form={form}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.groupLabel2}>Группа:</Typography>
            </Grid>
            <Grid item xs={9}>
              <ECMultiSelectField
                name='recipients.groups'
                options={meetupContext.groups}
                fid={it => it.groupId}
                fname={it => it.name}
                defaultValue={form.getValues()["recipients.groups"]}
                form={form}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.groupLabel2}>Статус:</Typography>
            </Grid>
            <Grid item xs={9}>
              <ECMultiSelectField
                name='recipients.statuses'
                options={Object.entries(VisitorStatusType)}
                fid={it => it[1]}
                fname={it => it[0]}
                defaultValue={form.getValues()["recipients.statuses"]}
                form={form}
              />
            </Grid>
            {/*<Grid item xs={3}>*/}
            {/*  <Typography className={classes.groupLabel2}>Тэги:</Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={9}>*/}
            {/*  <ECTextField*/}
            {/*    name='recipients.tagsStr'*/}
            {/*    defaultValue={form.getValues()["recipients.tagsStr"]}*/}
            {/*    form={form}*/}
            {/*  />*/}
              {/*<ECMultiSelectField*/}
              {/*  name='recipients.tags'*/}
              {/*  options={[]}*/}
              {/*  fid={it => it[1]}*/}
              {/*  fname={it => it[0]}*/}
              {/*  defaultValue={form.getValues()["recipients.tags"]}*/}
              {/*  form={form}*/}
              {/*/>*/}
            {/*</Grid>*/}
          </Grid>

        </ModalDialog>
      }
    </>
  );
};

const AnswersList = props => {
  const { form, initial, type } = props;
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const classes = useStyles();
  const { mid: meetupId } = useParams();

  const [options, setOptions] = useState((initial && initial.answerOptions) || [{text:"", tag:""}])

  useEffect(() => {
    form.register({ name: "answerOptions" });
    form.setValue("answerOptions", options);
  }, [form.register]);

  const handleChange = (value) => {
    setOptions(value);
    form.setValue("answerOptions", value);
    form.clearError("answerOptions");
  }

  const handleDelete = (i) => () => {
    handleChange(options.filter((_, i2) => i2 !== i))
  }

  const handleAdd = () => {
    handleChange(options.concat([{text:"", tag:""}]))
  }

  return (
    <Grid container spacing={1}>
      {options.map( (o, i) =>
        <>
          <Grid item xs={7}>
            <TextField
              name={`answerOptions[${i}].text`}
              fullWidth margin='dense' variant='outlined'
              helperText={form.errors[`answerOptions[${i}].text`] && form.errors[`answerOptions[${i}].text`].message}
              error={!!form.errors[`answerOptions[${i}].text`]}
              value={options[i].text}
              onChange={e => {
                let clone = JSON.parse(JSON.stringify(options))
                clone[i].text = e.target.value
                handleChange(clone)
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={"Тэг"}
              name={`answerOptions[${i}].tag`}
              fullWidth margin='dense' variant='outlined'
              helperText={form.errors[`answerOptions[${i}].tag`] && form.errors[`answerOptions[${i}].tag`].message}
              error={!!form.errors[`answerOptions[${i}].tag`]}
              value={options[i].tag}
              onChange={e => {
                let clone = JSON.parse(JSON.stringify(options))
                clone[i].tag = e.target.value
                handleChange(clone)
              }}
            />
          </Grid>
          <Grid item xs={1} style={{ paddingTop: '20px' }}>
            <IconButton
              size='small'
              aria-label='delete'
              onClick={() => {
                handleChange(options.filter( (v, i2) => i !== i2));
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </>
      )
      }
      <Grid item xs={12} style={{ marginTop: '8px' }}>
        <IconButton  size={'small'} onClick={handleAdd}>
          <AddCircleIcon />
        </IconButton>
      </Grid>
    </Grid>
  )

}

export default NewsEditForm;
