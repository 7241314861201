import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tab, Tabs, Divider } from '@material-ui/core';
import { Route, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import ChannelList from './channels/ChannelList';
import ChatLogHistory from './history/ChatLogHistory';
import Networking from "./networking/Networking";

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  tabs: {
    flexShrink: 0,
    margin: '0 auto',
    maxWidth: 'max-content',
  },
}));

const Chat = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();

  const classes = useStyles();

  const valueFromUrl = () => {
    const path = appContext.history.location.pathname;
    if (path.indexOf('channels') !== -1) {
      return 'channels';
    }
    if (path.indexOf('live-channel') !== -1) {
      return 'live-channel';
    }
    if (path.indexOf('logs')  !== -1) {
      return 'logs';
    }
    if (path.indexOf('news')  !== -1) {
      return 'news';
    }
    if (path.indexOf('networking') !== -1) {
      return 'networking';
    }
    return '';
  };

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        onChange={() => {}}
        scrollButtons='auto'
        value={valueFromUrl()}
        variant='scrollable'
      >
        <Tab
          label={'Каналы'}
          value={'channels'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/chat/channels');
          }}
        />
        <Tab
          label={'Нетворкинг'}
          value={'networking'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/chat/networking');
          }}
        />
        <Tab
          label={'Переписки'}
          value={'logs'}
          onClick={() => {
            appContext.history.push('/m/' + meetupId + '/chat/logs');
          }}
        />
      </Tabs>
      <Divider />
      <Route path='/m/:mid/chat/channels'>
        <ChannelList />
      </Route>
      <Route path='/m/:mid/chat/logs'>
        <ChatLogHistory />
      </Route>

      <Route path='/m/:mid/chat/networking'>
        <Networking/>
      </Route>
    </div>
  );
};

export default Chat;
