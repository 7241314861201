import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import GroupsToolbar from './components/GroupsToolbar/GroupsToolbar';
import GroupsTable from './components/GroupsTable/GroupsTable';
import { Route, useParams } from 'react-router-dom';
import GroupAdd from './components/GroupAdd/GroupAdd';
import GroupEdit from './components/GroupEdit/GroupEdit';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const GroupList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  // retrieve users list
  const [refreshCounter, setRefresh] = useState(0);
  const [groups, setGroups] = useState([]);
  const [currentColors, setCurrentColors] = useState();

  useEffect(() => {

    appContext.groupApi
      .listGroups(meetupId)
      .then(({ data }) => {
        const colors = data.map(group => (group.color || '#FFFFFF'))
        setCurrentColors(colors)
        setGroups(data)
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const handleDelete = (meetupId, groupId) => {
    appContext.groupApi.deleteGroup(meetupId, groupId).then(() => {
      setGroups(groups => groups.filter(u => u.groupId !== groupId));
    }).catch(error => {
      if (error.response.data.message) {
        alert(error.response.data.message)
      } else {
        alert(error)
      }
    });
  };

  const handleChangeColor = (ind, color) => {
    let groupData = {
      ...groups[ind]
    }
    groupData.color = color;

    appContext.groupApi.updateGroup(meetupId, groups[ind].groupId, groupData)
      .then(() => {
        setRefresh(refreshCounter + 1)
      })
  } 

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/group');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };


  return (
    <div className={classes.root}>
      <GroupsToolbar meetupId={meetupId} />
      <GroupsTable 
        meetupId={meetupId} 
        groups={groups} 
        handleDelete={handleDelete} 
        currentColors={currentColors} 
        handleChangeColor={handleChangeColor} 
      />

      <Route path={'/m/:mid/group/add'} exact>
        <GroupAdd goBack={backAndRefresh} />
      </Route>

      <Route path={'/m/:mid/group/id/:groupId'} exact>
        <GroupEdit goBack={backAndRefresh} />
      </Route>
    </div>
  );
};

export default GroupList;
