import React, { useEffect, useState, createRef, useContext, useRef } from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import { loadImage, mmToPx } from './utils';
import BadgeViewField from './BadgeViewField';
import './BadgeViewKonva.scss';
import { MeetupContext } from '../../../../MeetupContext';
import BadgeQrField from './BadgeQrField';
import BadgeBarField from './BadgeBarField';
import { Grid } from '@material-ui/core';
import BadgeImageField from './BadgeImageField';
import { min } from 'underscore';
import * as _ from "underscore";
import {waitForFonts} from "../BadgeEdit/utils";

const BadgeViewKonva = props => {
  const stageRef = useRef(null);
  const bgRef = useRef(null);
  const layerRef = useRef(null);
  const scaleTimeout = useRef(0);

  const [cursor] = useState('');

  const [image, setImageState] = useState(null);

  const {
    badge,
    visitor,
    setFormStateFieldPosition,
    showBorders,
    getRef,
    style,
    setSelectedField,
    selectedField,
    imagePreview,
    noFontsCheck
  } = props;

  const [fontsAreReady, setFontsAreReady] = useState(noFontsCheck || false);
  const allFonts = _.uniq(badge.fields.map( e=> e.font))
  useEffect(() => {
    if (!noFontsCheck) {
      setFontsAreReady(false);
      waitForFonts(allFonts, () => {
        setFontsAreReady(true);
      })
    }
  }, [allFonts.join(",")])


  const badgeWidth = mmToPx(badge.width, 72);
  const badgeHeight = mmToPx(badge.height, 72);

  useEffect(() => {
    if (layerRef.current) {
      layerRef.current.draw();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor]);

  useEffect(() => {
    if (getRef && fontsAreReady) {
      getRef(stageRef.current);
      const event = new Event('KonvaDidMount');
      document.dispatchEvent(event);
    }
    loadImage('/api/image/' + badge.imageId, setImageState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontsAreReady]);

  useEffect(() => {
    loadImage('/api/image/' + badge.imageId, setImageState);
  }, [badge.imageId]);

  const setScale = newScale => {
    stageRef.current.scale({ x: newScale, y: newScale });
    stageRef.current.width(badgeWidth * newScale);
    stageRef.current.height(badgeHeight * newScale);
    stageRef.current.batchDraw();
  };

  //fit view
  useEffect(() => {
    if (stageRef.current == null) {
      return;
    }
    setScale(1);

    if (scaleTimeout.current) {
      clearTimeout(scaleTimeout.current);
    }

    scaleTimeout.current = setTimeout(() => {
      if (stageRef.current) {
        let clientWidth = stageRef.current.content.parentNode.clientWidth;

        let maxClientHeight = style && style.maxHeight ? parseInt(style.maxHeight) : 500;
        let clientHeight = stageRef.current.content.parentNode.clientHeight;

        let newScale = min([clientWidth / badgeWidth, maxClientHeight / clientHeight]);
        setScale(newScale);
      }
    }, 16);
  }, [badgeWidth, badgeHeight, stageRef.current == null ? 1 : 0]);

  useEffect(() => {
    if (stageRef.current == null) {
      return;
    }
    if (image) {
      const stageScale = stageRef.current.scale();

      bgRef.current.fillPatternImage(image);
      bgRef.current.fillPatternScaleX(stageRef.current.width() / image.width / stageScale.x);
      bgRef.current.fillPatternScaleY(stageRef.current.height() / image.height / stageScale.y);
      stageRef.current.batchDraw();
    }
  }, [image, badge.width, badge.height, stageRef.current == null ? 1 : 0]);

  if (!fontsAreReady) {
    return (
      <Grid item xs={12} style={{ minHeight: 550, maxHeight: 550, ...style }}>
        <Grid
          container
          style={{ width: '100%', height: '100%', background: '#E0E0E0' }}
          alignItems={'center'}
          justify={'center'}
        >
          <h3>Загружаются шрифты 111...</h3>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid item xs={12} style={{ minHeight: 550, maxHeight: 550, ...style }}>
      <Grid
        container
        style={{ width: '100%', height: '100%', background: '#E0E0E0' }}
        alignItems={'center'}
        justify={'center'}
        data-test-id={"badge-view"}
      >
        <Grid item xs={8}>
          <svg id={'svg_barcode'} />
          <Stage
            width={badgeWidth}
            height={badgeHeight}
            style={{ margin: '0 auto', cursor: cursor }}
            ref={stageRef}
            onMouseDown={e => {
              const clickedOnEmpty = e.target.getAttr('isStage');
              if (clickedOnEmpty) {
                setSelectedField && setSelectedField(-1);
              }
            }}
            isStage={true}
          >
            <Layer ref={layerRef}>
              <Rect width={badgeWidth} height={badgeHeight} ref={bgRef} cornerRadius={0} isStage={true} />
              {badge.fields.map((field, index) => {
                if (field.type === 'text' || field.type === 'textarea') {
                  return (
                    <BadgeViewField
                      field={field}
                      isSelected={index === selectedField}
                      onSelect={() => {
                        setSelectedField && setSelectedField(index);
                      }}
                      setFormStateFieldPosition={setFormStateFieldPosition}
                      visitor={visitor}
                      fieldIndex={index}
                      showBorders={showBorders}
                      key={field.type + index}
                    />
                  );
                }
                if (field.type === 'qr') {
                  return (
                    <BadgeQrField
                      field={field}
                      isSelected={index === selectedField}
                      onSelect={() => {
                        setSelectedField && setSelectedField(index);
                      }}
                      setFormStateFieldPosition={setFormStateFieldPosition}
                      visitor={visitor}
                      fieldIndex={index}
                      showBorders={showBorders}
                      key={field.type + index}
                    />
                  );
                }
                if (field.type === 'bar') {
                  return (
                    <BadgeBarField
                      field={field}
                      isSelected={index === selectedField}
                      onSelect={() => {
                        setSelectedField && setSelectedField(index);
                      }}
                      setFormStateFieldPosition={setFormStateFieldPosition}
                      visitor={visitor}
                      fieldIndex={index}
                      showBorders={showBorders}
                      key={field.type + index}
                    />
                  );
                }
                if (field.type === 'image') {
                  return (
                    <BadgeImageField
                      field={field}
                      isSelected={index === selectedField}
                      onSelect={() => {
                        setSelectedField && setSelectedField(index);
                      }}
                      setFormStateFieldPosition={setFormStateFieldPosition}
                      visitor={visitor}
                      fieldIndex={index}
                      showBorders={showBorders}
                      externalImage={props.externalImage}
                      imagePreview={imagePreview}
                      key={field.type + index}
                    />
                  );
                }

                return null;
              })}
            </Layer>
          </Stage>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BadgeViewKonva;
